import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import mediaApi from '../../api/media'
import useMediaKit from '../../controllers/mediaKit/useMediaKit'
import BottoniScheda from '../../components/scheda/BottoniScheda'
import FormVideoMedia from '../../components/mediaKit/FormVideoMedia'

function VideoMediaKit(props) {

    const {state} = useLocation()
    const navigate = useNavigate()

    const {addMedia, aggMedia, categorie, video} = useMediaKit()

    const [theVideo, setTheVideo] = useState(null)
    const [errore, setErrore] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isModifiche, setIsModifiche] = useState(false)

    useEffect(() => {
        const aggVideo = () => {
            if(state?.video) return setTheVideo({
                ...state?.video, 
                categoria: state?.video?.categoria ? state.video.categoria : 0
            })
            setTheVideo({
                id:0, categoria:0, nome_italiano:'', nome_inglese:'',
                nome_tedesco:'', nome_olandese:'', nome_danese:'', link:''
            })
        }
        aggVideo()
    }, [state])

    const scriviTitolo = () => {
        if(!theVideo) return 'MEDIA KIT'
        if(theVideo.id) return 'MEDIA KIT: MODIFICA VIDEO'
        return 'MEDIA KIT: NUOVA VIDEO'
    }

    const cambiaNomeVideo = (lingua, valore) => {
        const appo = {...theVideo}
        if(lingua == 1) appo.nome_italiano = valore
        else if(lingua == 2) appo.nome_inglese = valore
        else if(lingua == 3) appo.nome_tedesco = valore
        else if(lingua == 4) appo.nome_olandese = valore
        else if(lingua == 5) appo.nome_danese = valore
        setTheVideo(appo)
        if(!isModifiche) setIsModifiche(true)
    }

    const cambiaCategoriaVideo = (valore) => {
        const appo = {...theVideo}
        appo.categoria = valore
        setTheVideo(appo)
        if(!isModifiche) setIsModifiche(true)
    }

    const cambiaLinkVideo = (valore) => {
        const appo = {...theVideo}
        appo.link = valore
        setTheVideo(appo)
        if(!isModifiche) setIsModifiche(true)
    }

    const conferma = async() => {
        //controllo valori
        if(!theVideo.link?.length) return setErrore("E' necessario inserire il Link del video")
        if(!theVideo.categoria) return setErrore("Inserire la categoria del video")
        if(!theVideo.nome_italiano?.length) return setErrore('Inserire nome italiano')
        if(!theVideo.nome_inglese?.length) return setErrore('Inserire nome inglese')
        if(!theVideo.nome_tedesco?.length) return setErrore('Inserire nome tedesco')
        if(!theVideo.nome_olandese?.length) return setErrore('Inserire nome olandese')
        if(!theVideo.nome_danese?.length) return setErrore('Inserire nome danese')
        //proseguo con le api
        let results = null, filtro = null
        setLoading(true)
        if(theVideo.id) { //modifica
            results = await mediaApi.modificaMedia({...theVideo})
            if(!results.ok) {
                setLoading(false)
                return setErrore(results.data)
            }
            return setTimeout(() => {
                aggMedia(results.data)
                setLoading(false)
                setErrore(null)
                filtro = state?.filtro ? state.filtro : 0
                navigate("..", {replace: true, state:{isModificaVideo:true, filtro:filtro}});
            }, 700);
        }
        //creazione
        results = await mediaApi.creaMediaVideo({
            nome_italiano:theVideo.nome_italiano, nome_inglese:theVideo.nome_inglese, 
            nome_tedesco:theVideo.nome_tedesco, nome_olandese:theVideo.nome_olandese, 
            nome_danese:theVideo.nome_danese, categoria:theVideo.categoria, link:theVideo.link,
            importanza:calcolaImportanzaMax()
        })
        if(!results.ok) {
            setLoading(false)
            return setErrore(results.data)
        }
        setTimeout(() => {
            addMedia(results.data)
            setLoading(false)
            setErrore(null)
            filtro = state?.filtro ? state.filtro : 0
            navigate("..", {replace: true, state:{isNuovoVideo:true, filtro:filtro}});
        }, 700);
    }

    const calcolaImportanzaMax = () => {
        let importanza = 0;
        for(let i = 0; i < video.length; i++) {
            if(video[i].importanza >= importanza) importanza = video[i].importanza + 1;
        }
        return importanza
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setErrore(null);
    }

    return (
        <React.Fragment>
            <div className="admin-container">
                <h2 className="admin-titoletto">{scriviTitolo()}</h2>
                <BottoniScheda
                    loading={loading}
                    conferma={conferma}
                    annulla={() => navigate("..", {replace: true, state:{isVideoSel:true, filtro:state?.filtro ? state.filtro : 0}})}
                ></BottoniScheda>
            </div>

            {theVideo && <FormVideoMedia
                video={theVideo}
                categorie={categorie}
                cambiaNome={cambiaNomeVideo}
                cambiaLink={cambiaLinkVideo}
                cambiaCategoria={cambiaCategoriaVideo}
                cambiaErrore={setErrore}
            ></FormVideoMedia>}

            {isModifiche && <BottoniScheda
                loading={loading}
                conferma={conferma}
                annulla={() => navigate("..", {replace: true, state:{isVideoSel:true, filtro:state?.filtro ? state.filtro : 0}})}
            ></BottoniScheda>}

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default VideoMediaKit;