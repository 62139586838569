import { useContext } from "react";

import CampeggiContext from "./context";

const useStrutture = () => {
    const { campeggi, setCampeggi } = useContext(CampeggiContext);

    const popolaStrutture = (vett) => {
        const appo = {...campeggi};
        appo.strutture = vett;
        setCampeggi(appo);
    }

    const importanzaMaxStrutture = () => {
        let appo = campeggi.strutture;
        let valore = appo[appo.length - 1]?.importanza;
        return valore ? (valore + 1) : 1
    }

    const addStruttura = (s) => {
        const appo = {...campeggi};
        appo.strutture.push(s);
        setCampeggi(appo);
    }

    const aggStruttura = (stru) => {
        const appo = {...campeggi};
        for(let i = 0; i < appo.strutture.length; i++) {
            if(appo.strutture[i].id == stru.id) appo.strutture[i] = stru;
        }
        setCampeggi(appo);
    }

    const togliStruttura = (id) => {
        const appo = {...campeggi};
        appo.strutture = campeggi.strutture.filter((a) => a.id != id);
        setCampeggi(appo)
    }

    return { 
        strutture: campeggi.strutture,
        popolaStrutture,
        importanzaMaxStrutture,
        addStruttura,
        aggStruttura,
        togliStruttura
    }
}

export default useStrutture;