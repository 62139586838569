import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

function LinkComponente({index, componente, cambiaLink, lingua}) {

    return (
        <Box sx={{p:1, pt:2, pl:0}}>
            <Stack direction="row" spacing={2} paddingBottom={1}>
                <TextField 
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(event) => cambiaLink(index, lingua, event.target.value)}
                    value={lingua == 1 ? componente.link_italiano : (lingua == 2 ? componente.link_inglese : (lingua == 3 ? componente.link_tedesco : (lingua == 4 ? componente.link_olandese : componente.link_danese)))}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>
        </Box>
    );
}

export default LinkComponente;