import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


function HeaderCms({pagina, sezioneSel, sezioni, scegliSezione, cambiaPagina, isSuper, isModifiche, loading, goToModifica, premiAnnulla, confermaModifiche, goToNuovaSezione, goToModificaSezione, annullaSezione, vediPagina}) {
    
    return (
        <Box sx={{width:'94%', marginRight:'2%', backgroundColor:'white', borderRadius:'10px', border:'1px solid #dcdcdc', p:2, 
            marginTop:'20px', display:'flex', flexDirection:'row', alignItems:'flex-end'
        }}>
            <Box sx={{width:'85%'}}>
                <Typography sx={{fontSize:20, fontWeight:'bold'}}>Pagina Selezionata: <Typography sx={{fontSize:20, display: 'inline-block', fontWeight:'500', color:'chiaro.main'}}>{pagina ? pagina.nome : 'Nessuna'}</Typography></Typography>
                {!pagina && <Typography sx={{fontSize:17, fontWeight:'400'}}>Scegli la pagina dal Menù di Sinistra per iniziare.</Typography>}
                {pagina && <Stack direction="row" spacing={2}>
                    <Button
                        size="small"
                        variant={"contained"}
                        color={"primary"}
                        onClick={vediPagina}
                        startIcon={<VisibilityIcon />}
                    >VEDI PAGINA</Button>
                    <Button
                        size="small"
                        variant={"contained"}
                        color={"primary"}
                        onClick={cambiaPagina}
                        startIcon={<EditIcon />}
                    >CAMBIA PAGINA</Button>
                    {isSuper && <Button
                        size="small"
                        variant={"contained"}
                        color={"warning"}
                        onClick={goToModifica}
                        startIcon={<EditIcon />}
                    >MODIFICA PAGINA</Button>}
                </Stack>}
                
                {pagina && <Box sx={{paddingTop:2}}>
                    <Typography sx={{fontWeight:'bold', fontSize:20}}>Sezione Selezionata: <Typography sx={{fontSize:20, display: 'inline-block', fontWeight:'500', color:'chiaro.main'}}>{sezioneSel ? sezioneSel.nome.replaceAll('<br>', '').replaceAll('  ', ' ') : 'Nessuna'}</Typography></Typography>
                    {Boolean(sezioneSel) && <Stack direction="row" spacing={2}>
                        <Button
                            size="small"
                            variant={"contained"}
                            color={"primary"}
                            onClick={annullaSezione}
                            startIcon={<EditIcon />}
                        >SCEGLI ALTRA SEZIONE</Button>
                        {isSuper && <Button
                            size="small"
                            variant={"contained"}
                            color={"warning"}
                            onClick={goToModificaSezione}
                            startIcon={<EditIcon />}
                        >MODIFICA SEZIONE</Button>}
                    </Stack>}
                    {Boolean(!sezioneSel) && sezioni.length > 0 && <Stack direction='column' spacing={1}>
                        <Typography sx={{fontWeight:'400', fontSize:17}}>Scegli la sezione di questa pagina che vuoi modificare.</Typography>
                        <Select
                            value={sezioneSel ? sezioneSel.id : 0}
                            onChange={(event) => scegliSezione(event.target.value)}
                            autoWidth
                            label="Sezione"
                            size="small"
                            sx={{width:'40%'}}
                        >
                            <MenuItem value={0}><em>Nessuna</em></MenuItem>
                            {sezioni.map((sezione, index) => (
                                <MenuItem value={sezione.id} selected={sezione.id == sezioneSel?.id} key={index}>{sezione.nome.replaceAll('<br>', '')}</MenuItem>
                            ))}
                        </Select>
                    </Stack>}
                    {Boolean(!sezioneSel) && sezioni.length == 0 &&
                        <Typography sx={{fontSize:17, color:'red'}}>Nessuna sezione disponibile per questa pagina.</Typography>
                    }
                    {isSuper && Boolean(!sezioneSel) && <Button
                        size="small"
                        variant={"contained"}
                        color={"warning"}
                        onClick={goToNuovaSezione}
                        startIcon={<AddCircleOutlineIcon />}
                        sx={{marginTop:2}}
                    >NUOVA SEZIONE</Button>}
                    {Boolean(sezioneSel) && <Box sx={{paddingTop:2}}>
                    <Typography sx={{fontWeight:'bold', fontSize:20}}>Componenti</Typography>
                        <Typography sx={{fontWeight:'400', fontSize:17, lineHeight:0.7}}>Sotto sono elencati i componenti di questa sezione.</Typography>
                        <Typography sx={{fontWeight:'400', fontSize:17}}>Ora puoi modificare i componenti desiderati.</Typography>
                    </Box>}
                </Box>}

                {isModifiche && <Stack direction="row" spacing={2} paddingTop={2}>
                    <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        size="small"
                        variant={"contained"}
                        color={"success"}
                        onClick={confermaModifiche}
                        type="submit"
                        startIcon={<CheckIcon />}
                    >CONFERMA</LoadingButton>
                    <Button
                        size="small"
                        variant={"contained"}
                        color={"error"}
                        onClick={premiAnnulla}
                        startIcon={<HighlightOffIcon />}
                    >ANNULLA</Button>
                </Stack>}
            </Box>

            <Box sx={{width:'15%', height:'100%', display:'flex', alignItems:'flex-end', justifyContent:'flex-end'}}>
                <Typography sx={{color:'primary.main', fontWeight:'bold', fontSize:20}}>Fase {pagina ? (sezioneSel ? "3" : "2") : "1"} di 3</Typography>
            </Box>
        </Box>
    );
}

export default HeaderCms;