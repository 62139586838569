import { useContext } from "react";

import MediaKitContext from "./context";

const useMediaKit = () => {
    const { mediaKit, setMediaKit } = useContext(MediaKitContext);

    const addCategoria = (c) => {
        const appo = {...mediaKit};
        appo.categorie.push(c);
        setMediaKit(appo);
    }

    const aggCategoria = (c) => {
        const appo = {...mediaKit};
        for(let i = 0; i < appo.categorie.length; i++) {
            if(appo.categorie[i].id == c.id) appo.categorie[i] = c;
        }
        setMediaKit(appo);
    }

    const togliCategoria = (id) => {
        //tolgo la categoria
        const appo = {...mediaKit};
        appo.categorie = mediaKit.categorie.filter((c) => c.id != id);
        //pulisco categoria ai media
        for(let i = 0; i < appo.media.length; i++) {
            if(appo[i]?.categoria == id) appo[i].categoria = 0
        }
        //aggiorno 
        setMediaKit(appo);
    }

    const popolaCategorieMedia = (vett) => {
        setMediaKit({...mediaKit, categorie:vett})
    }

    const addMedia = (m) => {
        const appo = {...mediaKit};
        appo.media.push(m);
        setMediaKit(appo);
    }

    const aggMedia = (m) => {
        const appo = {...mediaKit};
        for(let i = 0; i < appo.media.length; i++) {
            if(appo.media[i].id == m.id) appo.media[i] = m;
        }
        setMediaKit(appo);
    }

    const togliMedia = (id) => {
        const appo = {...mediaKit};
        appo.media = mediaKit.media.filter((m) => m.id != id);
        setMediaKit(appo);
    }

    const popolaMedia = (vett) => {
        setMediaKit({...mediaKit, media:vett})
    }

    return {
        categorie: mediaKit.categorie,
        media: mediaKit.media,
        immagini: mediaKit.media.filter((m) => !m.is_video),
        video: mediaKit.media.filter((m) => m.is_video),
        
        addCategoria,
        aggCategoria,
        togliCategoria,
        popolaCategorieMedia,

        addMedia,
        aggMedia,
        togliMedia,
        popolaMedia
    }
}

export default useMediaKit;