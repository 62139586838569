import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';

function ElementoLaterale({nome, attivo, attiva, sottotitolo, onClick, selezionato, disableAttiva, provided}) {
    return (
        <ListItemButton
          selected={selezionato}
          onClick={onClick}
          sx={{paddingLeft: 1, paddingRight: 1}}
        >
            <div className="elemento-laterale" ref={provided?.innerRef} {...provided?.draggableProps} {...provided?.dragHandleProps}>
                <div className="riga-elemento-laterale">
                    <span className="nome-elemento-laterale">{nome}</span>
                    {!disableAttiva && <div className={(attivo || attiva) ? "cerchio-verde-admin" : "cerchio-rosso-admin"}></div>}
                </div>
                {sottotitolo && <span className="descrizione-elemento-laterale">{sottotitolo}</span>}
            </div>
        </ListItemButton>
    );
}

export default ElementoLaterale;