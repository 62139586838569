import React, {useState} from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import authApi from '../../api/auth'
import validators from '../../utils/validators'
import defaultValues from '../../utils/defaultValues'
import FormCreaAdmin from '../../components/form/FormCreaAdmin'
import BottoniScheda from '../../components/scheda/BottoniScheda'

function CreaAdmin(props) {

    const navigate = useNavigate()

    const [errore, setErrore] = useState(null)
    const [loading, setLoading] = useState(false)

    const formik = useFormik({
        validationSchema: validators.admin,
        initialValues: defaultValues.admin,
        onSubmit: async(values) => {
            if(values.pwd != values.ripeti) return setErrore('La password deve coincidere')
            setLoading(true)
            const results = await authApi.creaAdmin({
                username: values.username, pwd: values.pwd, is_admin: values.is_admin
            })
            if(!results.ok) {
                setErrore(results.data)
                return setLoading(false)
            }
            setTimeout(() => {
                setLoading(false)
                setErrore(null)
                navigate('..', {state:{nuovoAdmin:results.data}})
            }, 500);
        }
    });

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setErrore();
    }

    return (
        <React.Fragment>
            <Box sx={{width:'70%', backgroundColor:'white', borderRadius:'10px', border:'1px solid #dcdcdc', p:2, 
                marginTop:'20px', display:'flex', flexDirection:'column'
            }}>
                <Typography sx={{fontSize:24, fontWeight:'bold'}}>Crea Amministratore</Typography>
                <Box sx={{marginTop:1}}>
                    <Button
                        size="small"
                        variant={"contained"}
                        color={"error"}
                        onClick={() => navigate('..')}
                        sx={{width:'120px'}}
                    >Annulla</Button>
                </Box>
            </Box>

            <Box sx={{width:'70%', backgroundColor:'white', borderRadius:'10px', border:'1px solid #dcdcdc', p:2, 
                marginTop:'30px', display:'flex', flexDirection:'column'
            }}>
                <FormCreaAdmin
                    values={formik.values}
                    errors={formik.errors}
                    touched={formik.touched}
                    setFieldValue={formik.setFieldValue}
                    handleChange={(value) => formik.handleChange(value)}
                ></FormCreaAdmin>
                <BottoniScheda
                    loading={loading}
                    conferma={formik.handleSubmit}
                    annulla={() => navigate('..')}
                ></BottoniScheda>
            </Box>

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default CreaAdmin;