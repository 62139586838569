import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';

function SceltaLocalita({localita, listaSel, cambiaLocalita, isModifica}) {

    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <h3 className="admin-titoletto">LOCALITA'</h3>
            <Stack direction="column" paddingTop={1}>
                {localita.map((loc, index) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="medium"
                                color="primary" 
                                name={loc.nome_italiano}
                                checked={listaSel[index]}
                                onChange={() => cambiaLocalita(index)}
                            />}
                        label={loc.nome_italiano}
                        key={index}
                    />
                ))}
            </Stack>
        </div>
    );
}

export default SceltaLocalita;