import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function SceltaEsperienze({
    nome, elementi, selezionati, cambiaSelezione, categorieEsperienza, macroCategorie, isModifica, isSceltaMacro, idMacroFissa
}) {

    const [ricerca, setRicerca] = useState('')
    const [macro, setMacro] = useState(0)
    const [categoria, setCategoria] = useState(0)

    const filtraElementi = () => {
        //filtro la macro se presente
        let lista = Array.from(elementi)
        if(macro) {
            lista = lista.filter((el, index) => {
                const appo = el.categorie.filter((catElemento) => {
                    const idCategoria = catElemento.categoria;
                    const categoria = categorieEsperienza.filter((cat) => cat.id == idCategoria)[0]
                    return categoria.macro == macro;
                })
                return appo.length > 0
            })
        }
        //filtro la macro fissa se presente
        if(!isSceltaMacro && idMacroFissa) {
            lista = lista.filter((el, index) => {
                const appo = el.categorie.filter((catElemento) => {
                    const idCategoria = catElemento.categoria;
                    const categoria = categorieEsperienza.filter((cat) => cat.id == idCategoria)[0]
                    return categoria.macro == idMacroFissa;
                })
                return appo.length > 0
            })
        }
        //filtro la categoria se presente
        if(categoria) {
            lista = lista.filter((el, index) => {
                const appo = el.categorie.filter((catElemento) => {
                    const idCategoria = catElemento.categoria;
                    return idCategoria == categoria;
                })
                return appo.length > 0
            })
        }
        //fitro per ricerca
        if(!ricerca.length) return lista
        return lista.filter((e) => {
            var appo = ricerca.toLowerCase();
            var appo2 = e.nome_italiano.toLowerCase();
            return appo2.includes(appo);
        });
    }

    const isSelezionato = (id) => {
        const appo = selezionati.filter((s) => s == id)[0]
        if(appo) return true
        return false
    }

    const catPerMacroFissa = () => {
        if(!idMacroFissa) return categorieEsperienza
        return categorieEsperienza.filter((c) => c.macro == idMacroFissa)
    }

    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <h3 className="admin-titoletto">{nome}</h3>

            <Stack direction="row" spacing={2} paddingTop={2} paddingBottom={2} sx={{paddingRight:10}}>
                {isSceltaMacro && <Stack direction="row">
                    <FormControl sx={{ minWidth: 150, maxWidth: 300 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Macro</InputLabel>
                        <Select
                            value={macro}
                            onChange={(event) => setMacro(event.target.value)}
                            autoWidth
                            label="Macro"
                            size="small"
                        >
                            <MenuItem value={0}><em>Tutte</em></MenuItem>
                            {macroCategorie.map((cat, index) => (
                                <MenuItem value={cat.id} key={index}>{cat.nome_italiano}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>}
                {!isSceltaMacro && <Stack direction="row">
                    <FormControl sx={{ minWidth: 150, maxWidth: 300 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Categoria</InputLabel>
                        <Select
                            value={categoria}
                            onChange={(event) => setCategoria(event.target.value)}
                            autoWidth
                            label="Categoria"
                            size="small"
                        >
                            <MenuItem value={0}><em>Tutte</em></MenuItem>
                            {catPerMacroFissa().map((cat, index) => (
                                <MenuItem value={cat.id} key={index}>{cat.nome_italiano}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>}
                <TextField 
                    id="outlined-basic"
                    label="Ricerca"
                    variant="outlined"
                    name="ricerca"
                    onChange={(event) => setRicerca(event.target.value)}
                    value={ricerca}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>

            <Stack direction="column">
                {filtraElementi().map((elemento, index) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="medium"
                                color="primary" 
                                name={elemento.nome_italiano}
                                checked={isSelezionato(elemento.id)}
                                onChange={() => cambiaSelezione(elemento.id)}
                            />} 
                        label={elemento.nome_italiano}
                        key={index}
                    />
                ))}
            </Stack>
        </div>
    );
}

export default SceltaEsperienze;