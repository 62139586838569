import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import TitoloComponente from './TitoloComponente';
import TestoComponente from './TestoComponente';
import TitoloImgComponente from './TitoloImgComponente';
import LinkComponente from './LinkComponente';
import TitleIcon from '@mui/icons-material/Title';
import BannerComponente from './BannerComponente';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import Italiana from '../../images/it.png';
import Inglese from '../../images/uk.png';
import Tedesco from '../../images/de.png';
import Olandese from '../../images/ne.png';
import Danese from '../../images/dk.png';

const tipi = [{id:1, nome:'Testo'}, {id:2, nome:'Titolo'}, {id:3, nome:'Immagine'}, {id:4, nome:'Link'}, {id:5, nome:'Banner'}]
const sottotipiTitolo = [{id:1, nome:'H1'}, {id:2, nome:'H2'}, {id:3, nome:'H3'}, {id:4, nome:'H4'}, {id:4, nome:'H5'}]
const sottotipiImmagine = [{id:1, nome:'Immagine'}, {id:2, nome:'Icona'}]

function GestisciComponenti({componenti, cambiaLink, cambiaTitolo, cambiaImmagine, cambiaTesto, cambiaTitoloImmagine, cambiaBanner}) {

    const [lingua, setLingua] = useState(1)
    
    const scriviTipo = (tipo) => {
        const appo = tipi.filter((t) => t.id == tipo)[0]
        if(appo) return appo.nome
        return "-"
    }

    const scriviSottotipo = (tipo, sottotipo) => {
        if(tipo != 2 && tipo != 3) return ""
        const vett = tipo == 2 ? sottotipiTitolo : sottotipiImmagine;
        const appo = vett.filter((s) => s.id == sottotipo)[0]
        if(appo) return " - " + appo.nome
        return ""
    }

    return (
        <Box sx={{width:'96%', marginRight:'2%', borderRadius:'10px',marginTop:'20px', display:'flex', flexDirection:'column'}}>
            
            <Box sx={{
                borderRadius:'10px', border:'1px solid #dcdcdc', padding:2, marginBottom:2, backgroundColor:'white'
            }}>
                <Typography sx={{fontSize:20, fontWeight:'bold'}}>
                    Seleziona la lingua
                </Typography>
                <Stack direction="row" spacing={2} paddingTop={1} paddingBottom={1}>
                    <FormControlLabel value={1} control={<Radio checked={lingua === 1} onChange={() => setLingua(1)} />} label="Italiano" />
                    <FormControlLabel value={2} control={<Radio checked={lingua === 2} onChange={() => setLingua(2)} />} label="Inglese" />
                    <FormControlLabel value={3} control={<Radio checked={lingua === 3} onChange={() => setLingua(3)} />} label="Tedesco" />
                    <FormControlLabel value={4} control={<Radio checked={lingua === 4} onChange={() => setLingua(4)} />} label="Olandese" />
                    <FormControlLabel value={5} control={<Radio checked={lingua === 5} onChange={() => setLingua(5)} />} label="Danese" />
                </Stack>
            </Box>

            {componenti.map((comp, index) => (
                <Box sx={{
                    borderRadius:'10px', border:'1px solid #dcdcdc', padding:2, marginBottom:2,
                    backgroundColor:'white'
                }} key={index}>

                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                        <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <img src={lingua == 1 ? Italiana : (lingua == 2 ? Inglese : (lingua == 3 ? Tedesco : (lingua == 4 ? Olandese : Danese)))} className="admin-bandiera-cms"></img>
                            <Typography sx={{fontSize:20, fontWeight:'bold', paddingLeft:1.5}}>
                                {comp.nome}&nbsp;
                                <Typography sx={{fontSize:20, display: 'inline-block', fontWeight:'500', color:'chiaro.main'}}>({scriviTipo(comp.tipo) + scriviSottotipo(comp.tipo, comp.sottotipo)})</Typography>
                            </Typography>
                        </Box>
                        {comp.is_titolo &&
                            <TitleIcon sx={{color:'primary.main'}} />
                        }
                    </Box>

                    {comp.tipo == 1 &&
                        <TestoComponente
                            index={index}
                            componente={comp}
                            cambiaTesto={cambiaTesto}
                            lingua={lingua}
                        ></TestoComponente>
                    }

                    {comp.tipo == 2 &&
                        <TitoloComponente 
                            index={index}
                            componente={comp}
                            cambiaTitolo={cambiaTitolo}
                            lingua={lingua}
                        ></TitoloComponente>
                    }

                    {comp.tipo == 3 &&
                        <Box sx={{p: 1, pt:2}}>
                            {!Boolean(comp.id_immagine) && <>
                                <Typography sx={{fontSize:15, color:'red'}}>Immagine non inserita</Typography>
                                <TextField
                                    id={"raised-button-file" + comp.id}
                                    label="Immagine"
                                    variant="outlined"
                                    value=""
                                    onChange={async(event) => await cambiaImmagine(index, event)}
                                    type="file"
                                    size={"small"}
                                    fullWidth
                                    autoComplete="off"
                                    style={{ display: 'none' }}
                                />
                                <label className="admin-linea-bottone-immagine" htmlFor={"raised-button-file" + comp.id}>
                                    <LoadingButton loading={false} variant="contained" color="primary" component="span" startIcon={<FileUploadIcon />}>Upload</LoadingButton>
                                </label>
                            </>}
                            {Boolean(comp.id_immagine) && <>
                                <Box sx={{width:'100%', display:'flex', flexDirection:'row'}}>
                                    <Box sx={{maxWidth:'70%'}}>
                                        {comp.sottotipo == 1 && <img className='immagine-cms' src={comp.link_immagine}></img>}
                                        {comp.sottotipo == 2 && <img className='immagine-icona-cms' src={comp.link_immagine}></img>}
                                    </Box>

                                    <Stack direction='column' spacing={1} sx={{width:'30%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                        <Typography sx={{fontSize:17, color:'black', fontWeight:500}}>Cambia Immagine</Typography>
                                        <TextField
                                            id={"raised-button-file" + comp.id}
                                            label="Immagine"
                                            variant="outlined"
                                            value=""
                                            onChange={async(event) => await cambiaImmagine(index, event)}
                                            type="file"
                                            size={"small"}
                                            fullWidth
                                            autoComplete="off"
                                            style={{ display: 'none' }}
                                        />
                                        <label className="admin-linea-bottone-immagine" htmlFor={"raised-button-file" + comp.id}>
                                            <LoadingButton loading={false} variant="contained" color="primary" component="span" startIcon={<FileUploadIcon />}>Upload</LoadingButton>
                                        </label>
                                    </Stack>
                                </Box>

                                {comp.sottotipo != 2 && <TitoloImgComponente
                                    index={index}
                                    componente={comp}
                                    cambiaTitoloImmagine={cambiaTitoloImmagine}
                                    lingua={lingua}
                                ></TitoloImgComponente>}
                            </>}
                        </Box>
                    }

                    {comp.tipo == 4 &&
                        <LinkComponente
                            index={index}
                            componente={comp}
                            cambiaLink={cambiaLink}
                            lingua={lingua}
                        ></LinkComponente>
                    }

                    {comp.tipo == 5 &&
                        <BannerComponente
                            index={index}
                            componente={comp}
                            cambiaBanner={cambiaBanner}
                        ></BannerComponente>
                    }

                </Box>
            ))}
        </Box>
    );
}

export default GestisciComponenti;