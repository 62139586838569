import client from "./client";
import config from '../config.json';

const endpoint = 'admin/'

//LOCALITA
const creaLocalita = (data) => {
    return client.post(config.serverUrl + endpoint + "localita", data);
}
const modificaLocalita = (data) => {
    return client.put(config.serverUrl + endpoint + "localita", data);
}
const eliminaLocalita = (id) => {
    return client.delete(config.serverUrl + endpoint + "localita/" + id);
}
const importanzaLocalita = (data) => {
    return client.put(config.serverUrl + endpoint + "localita/importanza", data);
}

//ITINERARIO
const creaItinerario = (itinerario, stagioni, meteo, localita, strutture, tematiche, categorie) => {
    return client.post(config.serverUrl + endpoint + "itinerario", {itinerario, stagioni, meteo, localita, strutture, tematiche, categorie});
}
const modificaItinerario = (itinerario, stagioni, meteo, localita, strutture, tematiche, categorie) => {
    return client.put(config.serverUrl + endpoint + "itinerario", {itinerario, stagioni, meteo, localita, strutture, tematiche, categorie});
}
const eliminaItinerario = (id) => {
    return client.delete(config.serverUrl + endpoint + "itinerario/" + id);
}
const importanzaItinerario = (data) => {
    return client.put(config.serverUrl + endpoint + "itinerario/importanza", data);
}

//ATTRAZIONE
const creaAttrazione = (attrazione, stagioni, meteo, localita, strutture, tematiche, categorie) => {
    return client.post(config.serverUrl + endpoint + "attrazione", {attrazione, stagioni, meteo, localita, strutture, tematiche, categorie});
}
const modificaAttrazione = (attrazione, stagioni, meteo, localita, strutture, tematiche, categorie) => {
    return client.put(config.serverUrl + endpoint + "attrazione", {attrazione, stagioni, meteo, localita, strutture, tematiche, categorie});
}
const eliminaAttrazione = (id) => {
    return client.delete(config.serverUrl + endpoint + "attrazione/" + id);
}
const importanzaAttrazione = (data) => {
    return client.put(config.serverUrl + endpoint + "attrazione/importanza", data);
}

//EVENTO
const creaEvento = (evento, dateEvento, stagioni, meteo, localita, strutture, tematiche, categorie) => {
    return client.post(config.serverUrl + endpoint + "evento", {evento, dateEvento, stagioni, meteo, localita, strutture, tematiche, categorie});
}
const modificaEvento = (evento, dateEvento, stagioni, meteo, localita, strutture, tematiche, categorie) => {
    return client.put(config.serverUrl + endpoint + "evento", {evento, dateEvento, stagioni, meteo, localita, strutture, tematiche, categorie});
}
const eliminaEvento = (id) => {
    return client.delete(config.serverUrl + endpoint + "evento/" + id);
}
const importanzaEvento = (data) => {
    return client.put(config.serverUrl + endpoint + "evento/importanza", data);
}

//GUIDA
const creaGuida = (guida, stagioni, meteo, localita, tematiche, categorie) => {
    return client.post(config.serverUrl + endpoint + "guida", {guida, stagioni, meteo, localita, tematiche, categorie});
}
const modificaGuida = (guida, stagioni, meteo, localita, tematiche, categorie) => {
    return client.put(config.serverUrl + endpoint + "guida", {guida, stagioni, meteo, localita, tematiche, categorie});
}
const eliminaGuida = (id) => {
    return client.delete(config.serverUrl + endpoint + "guida/" + id);
}
const importanzaGuida = (data) => {
    return client.put(config.serverUrl + endpoint + "guida/importanza", data);
}

//NEWS
const creaNews = (news, localita, strutture) => {
    return client.post(config.serverUrl + endpoint + "news", {news, localita, strutture});
}
const modificaNews = (news, localita, strutture) => {
    return client.put(config.serverUrl + endpoint + "news", {news, localita, strutture});
}
const eliminaNews = (id) => {
    return client.delete(config.serverUrl + endpoint + "news/" + id);
}
const importanzaNews = (data) => {
    return client.put(config.serverUrl + endpoint + "news/importanza", data);
}

//SERVIZIO
const creaServizio = (data) => {
    return client.post(config.serverUrl + endpoint + "servizio", data);
}
const modificaServizio = (data) => {
    return client.put(config.serverUrl + endpoint + "servizio", data);
}
const eliminaServizio = (id) => {
    return client.delete(config.serverUrl + endpoint + "servizio/" + id);
}
const importanzaServizi = (data) => {
    return client.put(config.serverUrl + endpoint + "servizio/importanza", data);
}

//BANNER
const creaBanner = (data) => {
    return client.post(config.serverUrl + endpoint + "banner", data);
}
const modificaBanner = (data) => {
    return client.put(config.serverUrl + endpoint + "banner", data);
}
const eliminaBanner = (id) => {
    return client.delete(config.serverUrl + endpoint + "banner/" + id);
}
const importanzaBanner = (data) => {
    return client.put(config.serverUrl + endpoint + "banner/importanza", data);
}

//PARTNER
const creaPartner = (partner, localita, attrazioni, itinerari, guide, eventi, news) => {
    return client.post(config.serverUrl + endpoint + "partner", {partner, localita, attrazioni, itinerari, guide, eventi, news});
}
const modificaPartner = (partner, localita, attrazioni, itinerari, guide, eventi, news) => {
    return client.put(config.serverUrl + endpoint + "partner", {partner, localita, attrazioni, itinerari, guide, eventi, news});
}
const eliminaPartner = (id) => {
    return client.delete(config.serverUrl + endpoint + "partner/" + id);
}
const importanzaPartner = (data) => {
    return client.put(config.serverUrl + endpoint + "partner/importanza", data);
}

//STRUTTURA
const creaStruttura = (struttura, stagioni, categorie, servizi, tematiche, localita) => {
    return client.post(config.serverUrl + endpoint + "struttura", {struttura, stagioni, categorie, servizi, tematiche, localita});
}
const modificaStruttura = (struttura, stagioni, categorie, servizi, tematiche, localita) => {
    return client.put(config.serverUrl + endpoint + "struttura", {struttura, stagioni, categorie, servizi, tematiche, localita});
}
const eliminaStruttura = (id) => {
    return client.delete(config.serverUrl + endpoint + "struttura/" + id);
}
const importanzaStruttura = (data) => {
    return client.put(config.serverUrl + endpoint + "struttura/importanza", data);
}

//OFFERTA
const creaOfferta = (offerta, dateOfferta, stagioni, localita, categorieSistemazioni, tematiche, strutture) => {
    return client.post(config.serverUrl + endpoint + "offerta", {offerta, dateOfferta, stagioni, localita, categorieSistemazioni, tematiche, strutture});
}
const modificaOfferta = (offerta, dateOfferta, stagioni, localita, categorieSistemazioni, tematiche, strutture) => {
    return client.put(config.serverUrl + endpoint + "offerta", {offerta, dateOfferta, stagioni, localita, categorieSistemazioni, tematiche, strutture});
}
const eliminaOfferta = (id) => {
    return client.delete(config.serverUrl + endpoint + "offerta/" + id);
}
const importanzaOfferta = (data) => {
    return client.put(config.serverUrl + endpoint + "offerta/importanza", data);
}

//TEMATICA
const creaTematica = (data) => {
    return client.post(config.serverUrl + endpoint + "tematica", data);
}
const modificaTematica = (data) => {
    return client.put(config.serverUrl + endpoint + "tematica", data);
}
const eliminaTematica = (id) => {
    return client.delete(config.serverUrl + endpoint + "tematica/" + id);
}
const importanzaTematica = (data) => {
    return client.put(config.serverUrl + endpoint + "tematica/importanza", data);
}

//MACRO CATEGORIE
const creaMacroCategoria = (data) => {
    return client.post(config.serverUrl + endpoint + "categoria/macro", data);
}
const modificaMacroCategoria = (data) => {
    return client.put(config.serverUrl + endpoint + "categoria/macro", data);
}
const eliminaMacroCategoria = (id) => {
    return client.delete(config.serverUrl + endpoint + "categoria/macro/" + id);
}

//CATEGORIE ESPERIENZE
const creaCategoriaEsperienza = (data) => {
    return client.post(config.serverUrl + endpoint + "categoria", data);
}
const modificaCategoriaEsperienza = (data) => {
    return client.put(config.serverUrl + endpoint + "categoria", data);
}
const eliminaCategoriaEsperienza = (id) => {
    return client.delete(config.serverUrl + endpoint + "categoria/" + id);
}
const importanzaCategorieEsperienza = (data) => {
    return client.put(config.serverUrl + endpoint + "categoria/importanza", data);
}

//CATEGORIA SERVIZIO
const creaCategoriaServizio = (data) => {
    return client.post(config.serverUrl + endpoint + "servizio/categoria", data);
}
const modificaCategoriaServizio = (data) => {
    return client.put(config.serverUrl + endpoint + "servizio/categoria", data);
}
const eliminaCategoriaServizio = (id) => {
    return client.delete(config.serverUrl + endpoint + "servizio/categoria/" + id);
}
const importanzaCategorieServizi = (data) => {
    return client.put(config.serverUrl + endpoint + "servizio/categoria/importanza", data);
}

//CATEGORIA SISTEMAZIONE
const creaCategoriaSistemazione = (data) => {
    return client.post(config.serverUrl + endpoint + "sistemazione/categoria", data);
}
const modificaCategoriaSistemazione = (data) => {
    return client.put(config.serverUrl + endpoint + "sistemazione/categoria", data);
}
const eliminaCategoriaSistemazione = (id) => {
    return client.delete(config.serverUrl + endpoint + "sistemazione/categoria/" + id);
}

//CATEGORIA PARTNER
const creaCategoriaPartner = (data) => {
    return client.post(config.serverUrl + endpoint + "partner/categoria", data);
}
const modificaCategoriaPartner = (data) => {
    return client.put(config.serverUrl + endpoint + "partner/categoria", data);
}
const eliminaCategoriaPartner = (id) => {
    return client.delete(config.serverUrl + endpoint + "partner/categoria/" + id);
}

export default {
    //localita
    creaLocalita,
    modificaLocalita,
    eliminaLocalita,
    importanzaLocalita,

    //itinerario
    creaItinerario,
    modificaItinerario,
    eliminaItinerario,
    importanzaItinerario,

    //attrazione
    creaAttrazione,
    modificaAttrazione,
    eliminaAttrazione,
    importanzaAttrazione,

    //evento
    creaEvento,
    modificaEvento,
    eliminaEvento,
    importanzaEvento,

    //news
    creaNews,
    modificaNews,
    eliminaNews,
    importanzaNews,

    //servizio
    creaServizio,
    modificaServizio,
    eliminaServizio,
    importanzaServizi,

    //guida
    creaGuida,
    modificaGuida,
    eliminaGuida,
    importanzaGuida,

    //banner
    creaBanner,
    modificaBanner,
    eliminaBanner,
    importanzaBanner,

    //partner
    creaPartner,
    modificaPartner,
    eliminaPartner,
    importanzaPartner,

    //struttura
    creaStruttura,
    modificaStruttura,
    eliminaStruttura,
    importanzaStruttura,

    //offerta
    creaOfferta,
    modificaOfferta,
    eliminaOfferta,
    importanzaOfferta,

    //vacanza tematica
    creaTematica,
    modificaTematica,
    eliminaTematica,
    importanzaTematica,
    
    //categorie esperienze
    creaMacroCategoria,
    modificaMacroCategoria,
    eliminaMacroCategoria,
    creaCategoriaEsperienza,
    modificaCategoriaEsperienza,
    eliminaCategoriaEsperienza,
    importanzaCategorieEsperienza,
    
    //categorie servizio
    creaCategoriaServizio,
    modificaCategoriaServizio,
    eliminaCategoriaServizio,
    importanzaCategorieServizi,

    //categorie sistemazione
    creaCategoriaSistemazione,
    modificaCategoriaSistemazione,
    eliminaCategoriaSistemazione,

    //categorie partners
    creaCategoriaPartner,
    modificaCategoriaPartner,
    eliminaCategoriaPartner,
}