import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function FormBanner({handleChange, values, errors, touched, setFieldValue, isModifica}) {
    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <FormControlLabel
                control={<Checkbox size="medium" color="primary" name="attivo" checked={values.attivo} onChange={handleChange}/>} 
                label="Attivo"
            />
            <Stack direction="row" spacing={7} paddingTop={1}>
                <TextField 
                    id="outlined-basic"
                    label="Link"
                    variant="outlined"
                    name="link"
                    onChange={handleChange}
                    value={values.link}
                    error={touched.link && Boolean(errors.link)}
                    helperText={touched.link && errors.link}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>
        </div>
    );
}

export default FormBanner;