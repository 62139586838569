const attrazione = {
    attiva: false,
    data_apertura: null,
    data_chiusura: null,
    sito: '',
    indirizzo: '',
    cap: '',
    latitudine: '',
    longitudine: '',
    telefono: '',
    wathsapp: '',
    email: '',
    video: '',
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: '',
    is_ecommerce: false,
    ecommerce_italiano: '',
    ecommerce_inglese: '',
    ecommerce_tedesco: '',
    ecommerce_olandese: '',
    ecommerce_danese: ''
}

const itinerario = {
    attivo: false,
    evidenza: false,
    indirizzo: '',
    cap: '',
    latitudine: '',
    longitudine: '',
    telefono: '',
    wathsapp: '',
    email: '',
    sito: '',
    video: '',
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: '',
    is_ecommerce: false,
    ecommerce_italiano: '',
    ecommerce_inglese: '',
    ecommerce_tedesco: '',
    ecommerce_olandese: '',
    ecommerce_danese: ''
}

const evento = {
    attivo: false,
    evidenza: false,
    is_ripetizione: false,
    giorno_ripetizione: 0,
    indirizzo: '',
    cap: '',
    latitudine: '',
    longitudine: '',
    telefono: '',
    wathsapp: '',
    email: '',
    sito: '',
    video: '',
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: '',
    is_ecommerce: false,
    ecommerce_italiano: '',
    ecommerce_inglese: '',
    ecommerce_tedesco: '',
    ecommerce_olandese: '',
    ecommerce_danese: '',
    data_visibile_inizio: null,
    data_visibile_fine: null
}

const guida = {
    attiva: false,
    latitudine: '',
    longitudine: '',
    is_ecommerce: false,
    ecommerce_italiano: '',
    ecommerce_inglese: '',
    ecommerce_tedesco: '',
    ecommerce_olandese: '',
    ecommerce_danese: ''
}

const news = {
    attivo: false,
    evidenza: false,
    tipologia: 0,
    indirizzo: '',
    cap: '',
    latitudine: '',
    longitudine: '',
    telefono: '',
    wathsapp: '',
    email: '',
    sito: '',
    video: '',
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: '',
    data_inizio: null,
    data_fine: null,
    data_visibile_inizio: null,
    data_visibile_fine: null
}

const localita = {
    video: '',
    latitudine: '',
    longitudine: '',
    attiva: false
}

const servizio = {
    categoria: 0
}

const banner = {
    attivo: false,
    link: '',
    data_inizio: null,
    data_fine: null
}

const partner = {
    attivo: false,
    categoria: 0,
    sito: '',
    indirizzo: '',
    cap: '',
    latitudine: '',
    longitudine: '',
    telefono: '',
    wathsapp: '',
    email: '',
    video: '',
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: ''
}

const struttura = {
    nome_completo:'',
    id_booking: '',
    id_trustyou: '',
    stelle: 0,
    attiva: false,
    sito: '',
    indirizzo: '',
    cap: '',
    latitudine: '',
    longitudine: '',
    data_apertura: null,
    data_chiusura: null,
    telefono: '',
    wathsapp: '',
    email: '',
    video: '',
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: '',
    tiktok: '',
    sito_italiano: '',
    sito_inglese: '',
    sito_tedesco: '',
    sito_olandese: '',
    sito_danese: ''
}

const offerta = {
    id_booking: '',
    link_booking: '',
    attiva: false,
    evidenza: false,
    categoria: 0,
    label: 0,
    prezzo: '',
    persone_min: '',
    persone_max: '',
    num_notti: '',
    num_notti_min: '',
    indirizzo: '',
    cap: '',
    latitudine: '',
    longitudine: '',
    telefono: '',
    wathsapp: '',
    email: '',
    sito: '',
}

const tematica = {
    attiva: false,
    video: ''
}

const cambioPassword = {
    vecchia: '',
    nuova: '',
    ripeti: ''
}

const admin = {
    username: '',
    pwd: '',
    ripeti: '',
    is_admin : false
}

export default {
    attrazione,
    itinerario,
    evento,
    localita,
    servizio,
    banner,
    guida,
    partner,
    struttura,
    offerta,
    tematica,
    news,
    cambioPassword,
    admin
}