import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';

function SceltaStrutture({strutture, struttureSelezionate, cambiaStruttura, isModifica}) {
    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <h3 className="admin-titoletto">STRUTTURE</h3>
            <Stack direction="column">
                {strutture.map((stru, index) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="medium"
                                color="primary" 
                                name={stru.nome_italiano}
                                checked={struttureSelezionate[index]}
                                onChange={() => cambiaStruttura(index)}
                            />} 
                        label={stru.nome_italiano}
                        key={index}
                    />
                ))}
            </Stack>
        </div>
    );
}

export default SceltaStrutture;