import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useFormik } from 'formik';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import HeaderScheda from '../components/scheda/HeaderScheda';
import creazione from '../api/creazione';
import usePartners from '../controllers/partners/usePartners';
import useAttrazioni from '../controllers/esperienze/useAttrazioni';
import useItinerari from '../controllers/esperienze/useItinerari';
import useEventi from '../controllers/esperienze/useEventi';
import useNews from '../controllers/news/useNews';
import useGuide from '../controllers/esperienze/useGuide';
import useLocalita from '../controllers/generale/useLocalita';
import BarraLaterale from '../components/barraLaterale/BarraLaterale';
import FormPartner from '../components/form/FormPartner';
import ContenutoScheda from '../components/scheda/ContenutoScheda';
import BottoniScheda from '../components/scheda/BottoniScheda';
import utils from '../utils/utils';
import validators from '../utils/validators';
import defaultValues from '../utils/defaultValues';
import useSchedaCopiata from '../controllers/schedaCopiata/useSchedaCopiata';
import SceltaLogo from '../components/scheda/SceltaLogo';
import SceltaImmagini from '../components/scheda/SceltaImmagini';
import SceltaAllegati from '../components/scheda/SceltaAllegati';
import ModalElimina from '../components/scheda/ModalElimina';
import SceltaEsperienze from '../components/scheda/SceltaEsperienze';
import SceltaLocalita from '../components/scheda/SceltaLocalita';
import useEsperienze from '../controllers/esperienze/useEsperienze';
import SceltaNews from '../components/scheda/SceltaNews';
import upload from '../api/upload';

function Partners(props) {

    const {state, pathname} = useLocation();
    const navigate = useNavigate();
    const {attrazioni} = useAttrazioni();
    const {itinerari} = useItinerari();
    const {eventi} = useEventi();
    const {guide} = useGuide();
    const {news} = useNews()
    const {localita} = useLocalita();
    const {macroCategorie, categorieEsperienza} = useEsperienze()
    const {partners, categoriePartner, aggPartner, togliPartner, popolaPartners} = usePartners();
    const {popolaSchedaCopiata} = useSchedaCopiata();

    const [openAlert, setOpenAlert] = useState(0)
    const [elemento, setElemento] = useState();
    const [situazione, setSituazione] = useState(1);
    const [lingua, setLingua] = useState(1);
    const [loading, setLoading] = useState(false);
    const [errore, setErrore] = useState();
    const [modifica, setModifica] = useState(false);
    const [visible, setVisible] = useState(false);
    const [altro, setAltro] = useState({localita:[], attrazioni:[], itinerari:[], eventi:[], guide:[], news:[], immagini:[], allegati:[]});
    const [imgSelezionata, setImgSelezionata] = useState({img: null, isModifica: false})
    const [allSelezionato, setAllSelezionato] = useState({allegato: null, isModifica: false})
    const [contenuto, setContenuto] = useState({italiano: EditorState.createEmpty(), inglese: EditorState.createEmpty(), tedesco: EditorState.createEmpty(), olandese: EditorState.createEmpty(), danese: EditorState.createEmpty()});
    const [titolo, setTitolo] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [valoriMeta, setValoriMeta] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [logo, setLogo] = useState({preview: null, file: null})

    useEffect(() => {
        cambioStato();
    }, [state])

    useEffect(() => {
        if(elemento) aggiornamentoElemento()
    }, [elemento])

    useEffect(() => {
        if(situazione && imgSelezionata.isModifica) pulisciImgSelezionata()
        if(situazione && allSelezionato.isModifica) pulisciAllegatoSelezionato()
    }, [situazione])

    const cambioStato = () => {
        if(!state) return;
        if(state.nuovoPartner) {
            setElemento(state.nuovoPartner);
            setOpenAlert(1);
            navigate(pathname, { replace: true });
        }
    }

    const cambiaElemento = (value) => setElemento(value)

    const formik = useFormik({
        validationSchema: validators.partner,
        initialValues: defaultValues.partner,
        onSubmit: async(v) => {
            if(!titolo.italiano?.length) return setErrore("Inserire il nome in Italiano.");
            setLoading(true);
            let values = {...v}
            let results;
            //aggiungo i titoli
            values.nome_italiano = titolo.italiano;
            values.nome_inglese = titolo.inglese;
            values.nome_tedesco = titolo.tedesco;
            values.nome_olandese = titolo.olandese;
            values.nome_danese = titolo.danese;
            //aggiungo le descrizioni
            values.descrizione_italiano = draftToHtml(convertToRaw(contenuto.italiano.getCurrentContent()));
            values.descrizione_inglese = draftToHtml(convertToRaw(contenuto.inglese.getCurrentContent()));
            values.descrizione_tedesco = draftToHtml(convertToRaw(contenuto.tedesco.getCurrentContent()));
            values.descrizione_olandese = draftToHtml(convertToRaw(contenuto.olandese.getCurrentContent()));
            values.descrizione_danese = draftToHtml(convertToRaw(contenuto.danese.getCurrentContent()));
            //aggiungo i meta tag
            values.meta_italiano = valoriMeta.italiano;
            values.meta_inglese = valoriMeta.inglese;
            values.meta_tedesco = valoriMeta.tedesco;
            values.meta_olandese = valoriMeta.olandese;
            values.meta_danese = valoriMeta.danese;
            //controllo lat e long e categoria
            values.latitudine = parseFloat(values.latitudine) ? parseFloat(values.latitudine) : null;
            values.longitudine = parseFloat(values.longitudine) ? parseFloat(values.longitudine) : null;
            values.categoria = parseInt(values.categoria) ? parseInt(values.categoria) : null;
            //controllo il logo
            if(logo.file) {
                let formData = new FormData();
                formData.append('immagine', logo.file, logo.file.name);
                results = await upload.creaImmagine(formData);
                if(!results.ok) {
                    setLoading(false)
                    return setErrore(results.data)
                }
                values.logo = results.data.link;
            } else {
                if(logo.preview) values.logo = elemento.logo;
                else values.logo = null;
            }
            //modifico l'oggetto
            results = await creazione.modificaPartner(values, utils.componiLista(localita, altro.localita), altro.attrazioni, altro.itinerari, altro.guide, altro.eventi, utils.componiLista(news, altro.news));
            if(!results.ok) {
                setErrore(results.data);
                return setLoading(false);
            }
            //modifico l'oggetto nel context
            aggPartner(results.data)
            //aggiorno la pagina
            setTimeout(() => {
                setOpenAlert(2);
                setLoading(false);
                setElemento(results.data);
            }, 500);
        }
    });

    const cambiaTitolo = (event) => {
        setModifica(true);
        const data = event.target.value.trim();
        const appo = {...titolo};
        if(lingua == 1) {
            appo.italiano = data;
            return setTitolo(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setTitolo(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setTitolo(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setTitolo(appo)
        } else {
            appo.danese = data;
            return setTitolo(appo)
        }
    }

    const cambiaValoriMeta = (event) => {
        setModifica(true);
        const data = event.target.value
        const appo = {...valoriMeta};
        if(lingua == 1) {
            appo.italiano = data;
            return setValoriMeta(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setValoriMeta(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setValoriMeta(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setValoriMeta(appo)
        } else {
            appo.danese = data;
            return setValoriMeta(appo)
        }
    }

    const cambiaContenuto = (newState) => {
        const appo = {...contenuto};
        if(lingua == 1) {
            if(appo.italiano.getCurrentContent() != newState.getCurrentContent()) setModifica(true);
            appo.italiano = newState;
            return setContenuto(appo)
        } else if(lingua == 2) {
            if(appo.inglese.getCurrentContent() != newState.getCurrentContent()) setModifica(true);
            appo.inglese = newState;
            return setContenuto(appo)
        } else if(lingua == 3) {
            if(appo.tedesco.getCurrentContent() != newState.getCurrentContent()) setModifica(true);
            appo.tedesco = newState;
            return setContenuto(appo)
        } else if(lingua == 4) {
            if(appo.olandese.getCurrentContent() != newState.getCurrentContent()) setModifica(true);
            appo.olandese = newState;
            return setContenuto(appo)
        } else {
            if(appo.danese.getCurrentContent() != newState.getCurrentContent()) setModifica(true);
            appo.danese = newState;
            return setContenuto(appo)
        }
    }

    const copiaContenuti = (appoTitolo, appoContenuto) => {
        setModifica(true);
        setTitolo(appoTitolo)
        setContenuto(appoContenuto)
        setOpenAlert(4)
    }

    const cambiaAttrazione = (id) => {
        setModifica(true);
        const appo = {...altro}
        const trovato = altro.attrazioni.filter((c) => c == id)[0]
        if(trovato) {
            appo.attrazioni = appo.attrazioni.filter((c) => c != id)
        } else {
            appo.attrazioni.push(id)
        }
        setAltro(appo);
    }

    const cambiaItinerario = (id) => {
        setModifica(true);
        const appo = {...altro}
        const trovato = altro.itinerari.filter((c) => c == id)[0]
        if(trovato) {
            appo.itinerari = appo.itinerari.filter((c) => c != id)
        } else {
            appo.itinerari.push(id)
        }
        setAltro(appo);
    }

    const cambiaEvento = (id) => {
        setModifica(true);
        const appo = {...altro}
        const trovato = altro.eventi.filter((c) => c == id)[0]
        if(trovato) {
            appo.eventi = appo.eventi.filter((c) => c != id)
        } else {
            appo.eventi.push(id)
        }
        setAltro(appo);
    }

    const cambiaGuida = (id) => {
        setModifica(true);
        const appo = {...altro}
        const trovato = altro.guide.filter((c) => c == id)[0]
        if(trovato) {
            appo.guide = appo.guide.filter((c) => c != id)
        } else {
            appo.guide.push(id)
        }
        setAltro(appo);
    }

    const cambiaLocalita = (position) => {
        setModifica(true);
        const appo = {...altro}
        const nuovo = altro.localita.map((item, index) =>
            index === position ? !item : item
        );
        appo.localita = nuovo;
        setAltro(appo);
    }

    const cambiaNews = (position) => {
        setModifica(true);
        const appo = {...altro}
        const nuovo = altro.news.map((item, index) =>
            index === position ? !item : item
        );
        appo.news = nuovo;
        setAltro(appo);
    }

    const creaImmagine = async(file, importanza) => {
        //preparo il body
        let formData = new FormData();
        formData.append('immagine', file, file.name);
        formData.append('idElemento', elemento.id)
        formData.append('tipologia', 10)
        formData.append('nome_italiano', '')
        formData.append('nome_inglese', '')
        formData.append('nome_tedesco', '')
        formData.append('nome_olandese', '')
        formData.append('nome_danese', '')
        formData.append('importanza', importanza)
        //chiama api per creare immagini
        const results = await upload.creaImmagine(formData);
        if(!results.ok) return setErrore(results.data)
        //modifico gli state
        const appo = {...altro}
        appo.immagini.push(results.data);
        elemento.immagini.push(results.data)
        setAltro(appo)
    }

    const aggiornaImmagine = async(titolo) => {
        //preparo il body
        const img = imgSelezionata.img;
        let formData = new FormData();
        formData.append('id', img.id)
        formData.append('nome_italiano', titolo.italiano)
        formData.append('nome_inglese', titolo.inglese)
        formData.append('nome_tedesco', titolo.tedesco)
        formData.append('nome_olandese', titolo.olandese)
        formData.append('nome_danese', titolo.danese)
        //chiama api per modificare immagini
        const results = await upload.modificaImmagine(formData);
        if(!results.ok) return setErrore(results.data)
        //modifico gli state
        const appo = {...altro}
        for(let i = 0; i < appo.immagini.length; i++) {
            if(appo.immagini[i].id == img.id) appo.immagini[i] = results.data;
        }
        elemento.immagini = appo.immagini;
        setAltro(appo)
    }

    const chiediEliminaImmagine = (img) => {
        setImgSelezionata({img, isModifica: false})
        setOpenAlert(200)
    }

    const chiudiModalImmagine = () => {
        setImgSelezionata({img: null, isModifica: false})
        setOpenAlert(0)
    }

    const eliminaImmagine = async() => {
        if(loading) return;
        setLoading(true)
        //elimino l'immagine
        const id = imgSelezionata.img.id
        const results = await upload.eliminaImmagine(id);
        if(!results.ok) return setErrore(results.data)
        setTimeout(() => {
            //modifico gli state
            const appo = {...altro}
            appo.immagini = appo.immagini.filter((img) => img.id != id);
            elemento.immagini = elemento.immagini.filter((img) => img.id != id);
            setAltro(appo)
            setLoading(false)
            setOpenAlert(0)
            setImgSelezionata({img: null, isModifica: false})
        }, 300);
    }

    const aggiornaImportanzaImmagini = async(listaId) => {
        //aggiorno sul server
        const results = await upload.aggiornaImportanzaImg(listaId)
        if(!results.ok) return setErrore(results.data)
        setErrore()
        //devo aggiornarle anche in locale
        const listaImg = []
        let appo = null
        for(let i = 0; i < listaId.length; i++) {
            appo = elemento.immagini.filter((img) => img.id == listaId[i])[0]
            if(appo) listaImg.push(appo)
        }
        elemento.immagini = listaImg
    }

    const creaAllegato = async(file, lingua) => {
        //preparo il body
        let formData = new FormData();
        formData.append('allegato', file, file.name);
        formData.append('idElemento', elemento.id)
        formData.append('tipologia', 10)
        formData.append('nome', '')
        formData.append('lingua', lingua)
        //chiama api per creare allegati
        const results = await upload.creaAllegato(formData);
        if(!results.ok) return setErrore(results.data)
        //modifico gli state
        const appo = {...altro}
        appo.allegati.push(results.data);
        elemento.allegati.push(results.data)
        setAltro(appo)
    }

    const isLinguaAllegato = (idLingua) => {
        if(idLingua == 1 && titolo.italiano.length) return true
        if(idLingua == 2 && titolo.inglese.length) return true
        if(idLingua == 3 && titolo.tedesco.length) return true
        if(idLingua == 4 && titolo.olandese.length) return true
        if(idLingua == 5 && titolo.danese.length) return true
        return false
    }

    const aggiornaAllegato = async(titolo) => {
        //preparo il body
        const all = allSelezionato.allegato;
        let formData = new FormData();
        formData.append('id', all.id)
        formData.append('nome', titolo)
        //chiama api per modificare allegati
        const results = await upload.modificaAllegato(formData);
        if(!results.ok) return setErrore(results.data)
        //modifico gli state
        const appo = {...altro}
        for(let i = 0; i < appo.allegati.length; i++) {
            if(appo.allegati[i].id == all.id) appo.allegati[i] = results.data;
        }
        elemento.allegati = appo.allegati;
        setAltro(appo)
    }

    const chiediEliminaAllegato = (allegato) => {
        setAllSelezionato({allegato, isModifica: false})
        setOpenAlert(100)
    }

    const chiudiModalAllegato = () => {
        setAllSelezionato({allegato: null, isModifica: false})
        setOpenAlert(0)
    }

    const eliminaAllegato = async() => {
        if(loading) return;
        setLoading(true)
        //elimino l'allegato
        const id = allSelezionato.allegato.id
        const results = await upload.eliminaAllegato(id);
        if(!results.ok) return setErrore(results.data)
        setTimeout(() => {
            //modifico gli state
            const appo = {...altro}
            appo.allegati = appo.allegati.filter((all) => all.id != id);
            elemento.allegati = elemento.allegati.filter((all) => all.id != id);
            setAltro(appo)
            setLoading(false)
            setOpenAlert(0)
            setAllSelezionato({allegato: null, isModifica: false})
        }, 400);
    }

    const aggiornamentoElemento = async() => {
        //cambio modifica
        setModifica(false)
        //cambio nome
        setTitolo({italiano: elemento.nome_italiano, inglese: elemento.nome_inglese, tedesco: elemento.nome_tedesco, olandese: elemento.nome_olandese, danese: elemento.nome_danese})
        //cambio meta
        setValoriMeta({
            italiano: elemento.meta_italiano ? elemento.meta_italiano : '', 
            inglese: elemento.meta_inglese ? elemento.meta_inglese : '', 
            tedesco: elemento.meta_tedesco ? elemento.meta_tedesco : '', 
            olandese: elemento.meta_olandese ? elemento.meta_olandese : '', 
            danese: elemento.meta_danese ? elemento.meta_danese : ''
        })
        //cambio contenuti
        setContenuto({italiano: aggEditorState(elemento.descrizione_italiano), inglese: aggEditorState(elemento.descrizione_inglese), tedesco: aggEditorState(elemento.descrizione_tedesco), olandese: aggEditorState(elemento.descrizione_olandese), danese: aggEditorState(elemento.descrizione_danese)})
        //cambio valori formik
        if(!elemento.latitudine) elemento.latitudine = '';
        if(!elemento.longitudine) elemento.longitudine = '';
        if(!elemento.categoria) elemento.categoria = 0;
        await formik.setValues(utils.omit(elemento, 'localita', 'attrazioni', 'itinerari', 'guide', 'eventi', 'news', 'immagini', 'allegati', 'data_creazione', 'data_modifica', 'nome_italiano', 'nome_inglese', 'nome_tedesco', 'nome_olandese', 'nome_danese', 'descrizione_italiano', 'descrizione_inglese', 'descrizione_tedesco', 'descrizione_olandese', 'descrizione_danese', 'slug_italiano', 'slug_inglese', 'slug_tedesco', 'slug_olandese', 'slug_danese', 'meta_italiano', 'meta_inglese', 'meta_tedesco', 'meta_olandese', 'meta_danese'))
        //pulisco i cross
        setAltro({
            localita: utils.componiListaBool(localita, elemento.localita, 'localita'),
            attrazioni: utils.componiListaAttrazioni(elemento.attrazioni),
            itinerari: utils.componiListaItinerari(elemento.itinerari),
            guide: utils.componiListaGuide(elemento.guide),
            eventi: utils.componiListaEventi(elemento.eventi),
            news: utils.componiListaBool(news, elemento.news, 'news'),
            immagini: elemento.immagini.map((img) => img),
            allegati: Array.from(elemento.allegati)
        });
        //pulisco le immagini e gli allegati
        pulisciImgSelezionata()
        pulisciAllegatoSelezionato()
        //carico il logo
        setLogo({file: null, preview: elemento.logo})
    }

    const aggEditorState = (valore) => {
        const blocksFromHtml = htmlToDraft(valore);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const appo = EditorState.createWithContent(contentState);
        return appo;
    }

    const pulisciImgSelezionata = () => setImgSelezionata({img: null, isModifica: false})
    const pulisciAllegatoSelezionato = () => setAllSelezionato({allegato: null, isModifica: false})

    const doPopolaSchedaCopiata = () => {
        if(!elemento) return;
        let valori = formik.values;
        valori.attivo = false;
        popolaSchedaCopiata({values: valori, titolo, contenuto: null, altro: {}})
        setOpenAlert(3)
    }

    const caricaLogo = async(event) => {
        const file = event.target.files[0]
        //controllo che sia immagine
        if(file.type.split("/")[0] != "image") return setErrore("Formato del file non valido. Richiesta Immagine.")
        setModifica(true)
        //creo l'immagine
        const preview = URL.createObjectURL(file);
        setLogo({file: file, preview: preview})
    }

    const pulisciLogo = () => {
        setModifica(true)
        setLogo({file: null, preview: null})
    }

    const eliminaElemento = async() => {
        if(!elemento || loading) return;
        setLoading(true)
        const results = await creazione.eliminaPartner(elemento.id)
        if(!results.ok) {
            setLoading(false)
            return setErrore(results.data)
        }
        setTimeout(() => {
            togliPartner(elemento.id)
            setOpenAlert(5)
            setLoading(false)
            setVisible(false)
            setElemento()
        }, 600);
    }

    const onDragEnd = async(result) => {
        if(!result.destination) return;
        let listCopy = Array.from(partners);
        //rimuovo elemento dalla lista di partenza
        const [removedElement, newSourceList] = utils.removeFromList(
            listCopy,
            result.source.index
        );
        listCopy = newSourceList;
        //aggiungo elemento alla  di destinazione
        listCopy = utils.addToList(
            listCopy,
            result.destination.index,
            removedElement
        );
        //aggiorno il tutto
        popolaPartners(listCopy)
        //aggiorno le api
        const vett = []
        for(let i = 0; i < listCopy.length; i++) vett.push(listCopy[i].id)
        const results = await creazione.importanzaPartner(vett)
        if(!results.ok) return setErrore(results.data)
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setOpenAlert(0);
        setErrore();
    }

    const goToNuova = () => navigate('/partners/crea')

    return (
        <div className="admin-corpo-modifica">
            <div className="admin-corpo-modifica-sx">
                <BarraLaterale
                    titolo={"LISTA di PARTNERS"}
                    titoloBottone={"NUOVA"}
                    lista={partners}
                    goToNuova={goToNuova}
                    cambiaElemento={cambiaElemento}
                    elementoSelezionato={elemento}
                    hasCategorie={true}
                    categorie={categoriePartner}
                    onDragEnd={onDragEnd}
                ></BarraLaterale>
            </div>

            <div className="admin-corpo-modifica-dx">
                <HeaderScheda
                    titolo={"PARTNERS"}
                    elementi={[true, true, false, false, false, false, false, false, false, true, true, false, false, false, true, true, true, true, true]}
                    situazione={situazione}
                    cambiaSituazione={v => {if(elemento) setSituazione(v)}}
                    nome={elemento?.nome_italiano.length > 0 ? elemento?.nome_italiano : null}
                    dataCreazione={elemento ? elemento.data_creazione : null}
                    dataModifica={elemento ? elemento.data_modifica : null}
                    doPopolaSchedaCopiata={() => doPopolaSchedaCopiata()}
                    copiaAbilitato={Boolean(elemento)}
                    doElimina={() => setVisible(true)}
                    loading={loading}
                    modifiche={modifica}
                    conferma={formik.handleSubmit}
                    annulla={() => aggiornamentoElemento()}
                    isModifica={true}
                ></HeaderScheda>

                {!elemento &&
                    <div className="admin-container-lungo"><h3 className="admin-titoletto">Nessun Partner Selezionato</h3></div>
                }
                {elemento &&
                    <>
                        {situazione == 1 && <>
                            <SceltaLogo
                                logo={logo}
                                caricaLogo={caricaLogo}
                                pulisciLogo={pulisciLogo}
                                isModifica={true}
                            ></SceltaLogo>
                            <FormPartner
                                values={formik.values}
                                errors={formik.errors}
                                touched={formik.touched}
                                handleChange={(value) => {setModifica(true);formik.handleChange(value)}}
                                categoriePartner={categoriePartner}
                                isModifica={true}
                            ></FormPartner>
                        </>}
                        {situazione == 2 &&
                            <ContenutoScheda
                                lingua={lingua}
                                cambiaLingua={(v) => setLingua(v)}
                                titolo={titolo}
                                cambiaTitolo={cambiaTitolo}
                                contenuto={contenuto}
                                cambiaContenuto={cambiaContenuto}
                                copiaContenuti={copiaContenuti}
                                isModifica={true}
                                isMeta={true}
                                valoriMeta={valoriMeta}
                                cambiaValoriMeta={cambiaValoriMeta}
                            ></ContenutoScheda>
                        }
                        {situazione == 4 &&
                            <SceltaLocalita
                                listaSel={altro.localita}
                                localita={localita}
                                cambiaLocalita={cambiaLocalita}
                                isModifica={true}
                            ></SceltaLocalita>
                        }
                        {situazione == 10 &&
                            <SceltaImmagini
                                immagini={altro.immagini}
                                creaImmagine={creaImmagine}
                                eliminaImmagine={chiediEliminaImmagine}
                                aggiornaImmagine={aggiornaImmagine}
                                selezionata={imgSelezionata}
                                cambiaSelezionata={(v) => setImgSelezionata(v)}
                                aggiornaImportanzaImmagini={aggiornaImportanzaImmagini}
                                isModifica={true}
                            ></SceltaImmagini>
                        }
                        {situazione == 11 &&
                            <SceltaAllegati
                                allegati={altro.allegati}
                                creaAllegato={creaAllegato}
                                eliminaAllegato={chiediEliminaAllegato}
                                aggiornaAllegato={aggiornaAllegato}
                                selezionato={allSelezionato}
                                cambioSelezionato={(v) => setAllSelezionato(v)}
                                isLinguaAllegato={isLinguaAllegato}
                                isModifica={true}
                            ></SceltaAllegati>
                        }
                        {situazione == 15 &&
                            <SceltaEsperienze
                                nome={"ATTRAZIONI"}
                                elementi={attrazioni}
                                selezionati={altro.attrazioni}
                                cambiaSelezione={cambiaAttrazione}
                                macroCategorie={macroCategorie.filter((el) => el.id != 7 && el.id != 8 && el.id != 9)}
                                categorieEsperienza={categorieEsperienza}
                                isSceltaMacro={true}
                                isModifica={true}
                            ></SceltaEsperienze>
                        }
                        {situazione == 16 &&
                            <SceltaEsperienze
                                nome={"ITINERARI"}
                                elementi={itinerari}
                                selezionati={altro.itinerari}
                                cambiaSelezione={cambiaItinerario}
                                macroCategorie={macroCategorie}
                                categorieEsperienza={categorieEsperienza}
                                isSceltaMacro={false}
                                idMacroFissa={7} //Itinerari e Tour
                                isModifica={true}
                            ></SceltaEsperienze>
                        }
                        {situazione == 17 &&
                            <SceltaEsperienze
                                nome={"GUIDE TURISTICHE"}
                                elementi={guide}
                                selezionati={altro.guide}
                                cambiaSelezione={cambiaGuida}
                                macroCategorie={macroCategorie}
                                categorieEsperienza={categorieEsperienza}
                                isSceltaMacro={false}
                                idMacroFissa={9} //Guide Turistiche
                                isModifica={true}
                            ></SceltaEsperienze>
                        }
                        {situazione == 18 &&
                            <SceltaEsperienze
                                nome={"EVENTI"}
                                elementi={eventi}
                                selezionati={altro.eventi}
                                cambiaSelezione={cambiaEvento}
                                macroCategorie={macroCategorie}
                                categorieEsperienza={categorieEsperienza}
                                isSceltaMacro={false}
                                idMacroFissa={8} //Eventi e festività
                                isModifica={true}
                            ></SceltaEsperienze>
                        }
                        {situazione == 19 &&
                            <SceltaNews
                                nome={"NEWS"}
                                elementi={news}
                                selezionati={altro.news}
                                cambiaSelezione={cambiaNews}
                                isModifica={true}
                            ></SceltaNews>
                        }
                        {modifica &&
                            <BottoniScheda
                                loading={loading}
                                conferma={formik.handleSubmit}
                                annulla={() => aggiornamentoElemento()}
                            ></BottoniScheda>
                        }
                    </>
                }
            </div>

            <Snackbar open={openAlert == 1} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Partner inserito con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 2} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Partner modificato con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 3} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Partner Copiato</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 4} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Contenuti copiati nelle diverse linue.</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 5} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Partner eliminato con successo.</Alert>
            </Snackbar>
            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>

            {elemento &&
                <ModalElimina
                    nome={"questo Partner"}
                    nome_vero={elemento.nome_italiano}
                    visible={visible}
                    loading={loading}
                    annulla={() => setVisible(false)}
                    conferma={eliminaElemento}
                ></ModalElimina>
            }
            {allSelezionato.allegato &&
                <ModalElimina
                    nome={"questo Allegato"}
                    nome_vero={allSelezionato.allegato.nome}
                    visible={openAlert === 100}
                    loading={loading}
                    annulla={() => chiudiModalAllegato()}
                    conferma={eliminaAllegato}
                ></ModalElimina>
            }
            {imgSelezionata.img &&
                <ModalElimina
                    nome={"questa Immagine"}
                    nome_vero={imgSelezionata.img.nome_italiano}
                    visible={openAlert === 200}
                    loading={loading}
                    annulla={() => chiudiModalImmagine()}
                    conferma={eliminaImmagine}
                ></ModalElimina>
            }
        </div>
    );
}

export default Partners;