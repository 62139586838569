import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import Radio from '@mui/material/Radio';

import BottoniScheda from './BottoniScheda';
import LogoPDF from '../../images/pdf.png';
import Italiana from '../../images/it.png';
import Inglese from '../../images/uk.png';
import Tedesco from '../../images/de.png';
import Olandese from '../../images/ne.png';
import Danese from '../../images/dk.png';

function SceltaAllegati({selezionato, cambioSelezionato, allegati, creaAllegato, aggiornaAllegato, eliminaAllegato, isModifica, isLinguaAllegato}) {

    const [openAlert, setOpenAlert] = useState(0);
    const [errore, setErrore] = useState();
    const [loading, setLoading] = useState(false);
    const [titolo, setTitolo] = useState('');
    const [linguaAll, setLinguaAll] = useState(0);
    const [lingua, setLingua] = useState(1); //1 italiano, ...

    const cambiaTitolo = (event) => {
        const data = event.target.value.trim();
        setTitolo(data)
    }

    const caricaAllegato = async(event) => {
        if(loading) return;
        setLoading(true)
        if(!linguaAll) return setErrore("Inserire la lingua dell'allegato.")
        const file = event.target.files[0]
        //controllo che sia pdf
        if(file.type.split("/")[1] != "pdf") {
            setLoading(false)
            return setErrore("Formato del file non valido. Richiesto file PDF.")
        }
        //creo l'allegato
        await creaAllegato(file, linguaAll);
        setLinguaAll(0)
        setTimeout(() => {
            setLoading(false)
        }, 200);
    }

    const confermaModificaAllegato = async() => {
        setLoading(true)
        await aggiornaAllegato(titolo);
        setTimeout(() => {
            cambioSelezionato({allegato: null, isModifica: false})
            setLoading(false)
            setLingua(1)
        }, 500);
    }

    const selezionaAllegato = (allegato) => {
        cambioSelezionato({allegato: allegato, isModifica: true})
        setTitolo(allegato.nome)
    }

    const annullaModificaAllegato = () => {
        setErrore()
        setLingua(1)
        cambioSelezionato({allegato: null, isModifica: false})
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;
        setErrore();
        setOpenAlert(0)
    }

    const copiaTitoloAllegato = (appoTitolo) => {
        setTitolo(appoTitolo)
        setOpenAlert(1)
    }

    const scriviNomeFile = (link) => {
        const vett = link.split('/');
        return vett[vett.length - 1]
    }

    return (
        <React.Fragment>
            {!selezionato.isModifica && <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <h3 className="admin-titoletto">NUOVO ALLEGATO</h3>
                <Stack direction="row" spacing={2} paddingTop={1}>
                    {isLinguaAllegato(1) && <FormControlLabel value={1} control={<Radio checked={linguaAll === 1} onChange={() => setLinguaAll(1)} />} label="Italiano" />}
                    {isLinguaAllegato(2) && <FormControlLabel value={2} control={<Radio checked={linguaAll === 2} onChange={() => setLinguaAll(2)} />} label="Inglese" />}
                    {isLinguaAllegato(3) && <FormControlLabel value={3} control={<Radio checked={linguaAll === 3} onChange={() => setLinguaAll(3)} />} label="Tedesco" />}
                    {isLinguaAllegato(4) && <FormControlLabel value={4} control={<Radio checked={linguaAll === 4} onChange={() => setLinguaAll(4)} />} label="Olandese" />}
                    {isLinguaAllegato(5) && <FormControlLabel value={5} control={<Radio checked={linguaAll === 5} onChange={() => setLinguaAll(5)} />} label="Danese" />}
                </Stack>
                <Stack paddingTop={1}>
                    {Boolean(linguaAll) && <TextField 
                        id="raised-button-file"
                        label="Allegato"
                        variant="outlined"
                        value=""
                        onChange={async(event) => await caricaAllegato(event)}
                        type="file"
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                        style={{ display: 'none' }}
                    />}
                    <label className="admin-linea-bottone-immagine" htmlFor="raised-button-file">
                        <LoadingButton loading={loading} disabled={!linguaAll} variant="contained" color="primary" component="span" startIcon={<FileUploadIcon />}>Upload</LoadingButton>
                    </label>
                </Stack>
            </div>}

            {!selezionato.isModifica && <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <h3 className="admin-titoletto">ALLEGATI</h3>
                {allegati.length == 0 && <span className="admin-titoletto">Nessun Allegato presente.</span>}
                {allegati.length > 0 &&
                    <div className="admin-container-lista-allegati">
                        <div className="admin-riga-allegato-header">
                            <span className="admin-allegato-elemento-3-header">Nome File</span>
                            <span className="admin-allegato-elemento-1-header">Titolo</span>
                            <span className="admin-allegato-elemento-2-header">Lingua</span>
                            <span className="admin-allegato-elemento-2-header">Modifica</span>
                            <span className="admin-allegato-elemento-2-header">Visualizza</span>
                            <span className="admin-allegato-elemento-2-header">Elimina</span>
                        </div>
                        {allegati.map((all, index) => (
                            <div className="admin-riga-allegato" key={index}>
                                <span className="admin-allegato-elemento-1">{scriviNomeFile(all.link)}</span>
                                <span className="admin-allegato-elemento-1">{all.nome ? all.nome : "Senza Nome"}</span>
                                <span className="admin-allegato-elemento-2">
                                    <img src={all.lingua === 1 ? Italiana : (all.lingua === 2 ? Inglese : (all.lingua === 3 ? Tedesco : (all.lingua === 4 ? Olandese : Danese)))} className="admin-bandiera"></img>
                                </span>
                                <span className="admin-allegato-elemento-2">
                                    <IconButton color="secondary" aria-label="upload picture" component="span" onClick={() => selezionaAllegato(all)}>
                                        <EditIcon />
                                    </IconButton>
                                </span>
                                <span className="admin-allegato-elemento-2">
                                    <a className="admin-link-bottone-download" href={all.link} download target="_blank">
                                        <IconButton color="secondary" aria-label="upload picture" component="span">
                                            <VisibilityIcon />
                                        </IconButton>
                                    </a>
                                </span>
                                <span className="admin-allegato-elemento-2">
                                    <IconButton color="secondary" aria-label="upload picture" component="span" onClick={async() => await eliminaAllegato(all)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </span>
                            </div>
                        ))}
                    </div>
                }
            </div>}

            {selezionato.isModifica && <>
                <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Stack direction="column" spacing={1}>
                            <h3 className="admin-titoletto">MODIFICA ALLEGATO</h3>
                            <label className="admin-linea-bottone-immagine">
                                <a className="admin-link-bottone-download" href={selezionato.allegato.link} download target="_blank">
                                    <Button variant="contained" color="primary" component="span" startIcon={<VisibilityIcon />}>Visualizza</Button>
                                </a>
                            </label>
                        </Stack>
                        <Stack direction="row" flex={1} alignItems="center" justifyContent="center">
                            <img className="admin-anteprima-allegato" src={LogoPDF}></img>
                            <Stack direction="column" paddingLeft={2}>
                                <h3 className="admin-titoletto">Nome File: <span className="admin-testo-date">{scriviNomeFile(selezionato.allegato.link)}</span></h3>
                                <h3 className="admin-titoletto">Titolo: <span className="admin-testo-date">{selezionato.allegato.nome ? selezionato.allegato.nome : "Senza Nome"}</span></h3>
                                <Stack direction='row' spacing={1} alignItems='center' paddingTop={0.5}>
                                    <h3 className="admin-titoletto">Lingua: </h3>
                                    <img src={selezionato.allegato.lingua === 1 ? Italiana : (selezionato.allegato.lingua === 2 ? Inglese : (selezionato.allegato.lingua === 3 ? Tedesco : (selezionato.allegato.lingua === 4 ? Olandese : Danese)))} className="admin-bandiera"></img>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </div>
                <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                    <Stack direction="row" spacing={2} paddingBottom={2}>
                        <img src={selezionato.allegato.lingua == 1 ? Italiana : (selezionato.allegato.lingua == 2 ? Inglese : (selezionato.allegato.lingua == 3 ? Tedesco : (selezionato.allegato.lingua == 4 ? Olandese : Danese)))} className="admin-bandiera"></img>
                        <h3 className="admin-titoletto">TITOLO dell'ALLEGATO</h3>
                    </Stack>
                    <Stack direction="row" spacing={2} paddingBottom={1}>
                        <TextField 
                            id="outlined-basic"
                            label="Titolo"
                            variant="outlined"
                            onChange={cambiaTitolo}
                            value={titolo}
                            size={"small"}
                            fullWidth
                            autoComplete="off"
                        />
                    </Stack>
                </div>
                <BottoniScheda
                    loading={loading}
                    conferma={confermaModificaAllegato}
                    annulla={annullaModificaAllegato}
                ></BottoniScheda>
            </>}

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 1} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Titolo copiato nelle diverse lingue.</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default SceltaAllegati;