import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import itLocale from 'date-fns/locale/it';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import utils from '../../utils/utils';

function FormOfferta({handleChange, values, errors, touched, setFieldValue, isModifica, categorieOfferta, labelOfferta, listaDate, aggiungiDate, togliDate, cambiaErrore}) {
    
    const [dataInizio, setDataInizio] = useState(null);
    const [dataFine, setDataFine] = useState(null);
    
    return (
        <React.Fragment>
            <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <Stack direction="row" spacing={7} paddingTop={1}>
                    <FormControlLabel
                        control={<Checkbox size="medium" color="primary" name="attiva" checked={values.attiva} onChange={handleChange}/>} 
                        label="Attiva"
                    />
                    <FormControlLabel
                        control={<Checkbox size="medium" color="primary" name="evidenza" checked={values.evidenza} onChange={handleChange}/>} 
                        label="Evidenza"
                    />
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Indirizzo"
                        variant="outlined"
                        name="indirizzo"
                        onChange={handleChange}
                        value={values.indirizzo}
                        error={touched.indirizzo && Boolean(errors.indirizzo)}
                        helperText={touched.indirizzo && errors.indirizzo}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    {/* <TextField 
                        id="outlined-basic"
                        label="CAP"
                        variant="outlined"
                        name="cap"
                        onChange={handleChange}
                        value={values.cap}
                        error={touched.cap && Boolean(errors.cap)}
                        helperText={touched.cap && errors.cap}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    /> */}
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Latitudine"
                        variant="outlined"
                        name="latitudine"
                        onChange={handleChange}
                        value={values.latitudine}
                        error={touched.latitudine && Boolean(errors.latitudine)}
                        helperText={touched.latitudine && errors.latitudine}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Longitudine"
                        variant="outlined"
                        name="longitudine"
                        onChange={handleChange}
                        value={values.longitudine}
                        error={touched.longitudine && Boolean(errors.longitudine)}
                        helperText={touched.longitudine && errors.longitudine}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Telefono"
                        variant="outlined"
                        name="telefono"
                        onChange={handleChange}
                        value={values.telefono}
                        error={touched.telefono && Boolean(errors.telefono)}
                        helperText={touched.telefono && errors.telefono}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Whatsapp"
                        variant="outlined"
                        name="wathsapp"
                        onChange={handleChange}
                        value={values.wathsapp}
                        error={touched.wathsapp && Boolean(errors.wathsapp)}
                        helperText={touched.wathsapp && errors.wathsapp}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={3} paddingBottom={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Sito"
                        variant="outlined"
                        name="sito"
                        onChange={handleChange}
                        value={values.sito}
                        error={touched.sito && Boolean(errors.sito)}
                        helperText={touched.sito && errors.sito}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
            </div>

            <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <Box sx={{paddingTop:3}}><h3 className="admin-titoletto">Link prenotazione offerta</h3></Box>
                <Stack direction="row" spacing={7} paddingTop={2}>
                    {/* <TextField 
                        id="outlined-basic"
                        label="ID Booking"
                        variant="outlined"
                        name="id_booking"
                        onChange={handleChange}
                        value={values.id_booking}
                        error={touched.id_booking && Boolean(errors.id_booking)}
                        helperText={touched.id_booking && errors.id_booking}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    /> */}
                    <TextField 
                        id="outlined-basic"
                        label="Link Offerta"
                        variant="outlined"
                        name="link_booking"
                        onChange={handleChange}
                        value={values.link_booking}
                        error={touched.link_booking && Boolean(errors.link_booking)}
                        helperText={touched.link_booking && errors.link_booking}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                {/* <Stack direction="row" spacing={7} paddingTop={3} >
                    <TextField 
                        id="outlined-basic"
                        label="Prezzo(€)"
                        variant="outlined"
                        name="prezzo"
                        onChange={handleChange}
                        value={values.prezzo}
                        error={touched.prezzo && Boolean(errors.prezzo)}
                        helperText={touched.prezzo && errors.prezzo}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Persone Minime"
                        variant="outlined"
                        name="persone_min"
                        onChange={handleChange}
                        value={values.persone_min}
                        error={touched.persone_min && Boolean(errors.persone_min)}
                        helperText={touched.persone_min && errors.persone_min}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Persone Massime"
                        variant="outlined"
                        name="persone_max"
                        onChange={handleChange}
                        value={values.persone_max}
                        error={touched.persone_max && Boolean(errors.persone_max)}
                        helperText={touched.persone_max && errors.persone_max}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack> */}
                {/* <Stack direction="row" spacing={7} paddingTop={3} paddingBottom={1}>
                    <TextField 
                        id="outlined-basic"
                        label="Numero Notti"
                        variant="outlined"
                        name="num_notti"
                        onChange={handleChange}
                        value={values.num_notti}
                        error={touched.num_notti && Boolean(errors.num_notti)}
                        helperText={touched.num_notti && errors.num_notti}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Numero Notti Minime"
                        variant="outlined"
                        name="num_notti_min"
                        onChange={handleChange}
                        value={values.num_notti_min}
                        error={touched.num_notti_min && Boolean(errors.num_notti_min)}
                        helperText={touched.num_notti_min && errors.num_notti_min}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack> */}
                <Box sx={{paddingTop:4}}><h3 className="admin-titoletto">Inserisci Date</h3></Box>
                <Stack direction="column" spacing={3} paddingTop={2} paddingBottom={5}>
                    <Stack direction='row' spacing={10}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
                            <DatePicker
                                label="Nuova Data Inizio"
                                value={dataInizio}
                                onChange={value => setDataInizio(value)}
                                renderInput={(params) => <TextField {...params} />}
                                mask='__/__/____'
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
                            <DatePicker
                                label="Nuova Data Fine"
                                value={dataFine}
                                onChange={value => setDataFine(value)}
                                renderInput={(params) => <TextField {...params} />}
                                mask='__/__/____'
                            />
                        </LocalizationProvider>
                    </Stack>
                    {dataInizio && dataFine &&
                        <Stack direction="column" spacing={2}>
                            <h3 className="admin-titoletto">
                                <span>Data Selezionata:  </span>
                                <span className="admin-testo-date">{utils.scriviData(dataInizio)}</span>
                                <span className="admin-testo-date"> - {utils.scriviData(dataFine)}</span>
                            </h3>
                            <Button
                                size="small"
                                variant={"contained"}
                                color={"primary"}
                                sx={{width: 220}}
                                onClick={() => {
                                    if(!(dataInizio instanceof Date && !isNaN(dataInizio))) return cambiaErrore("Data Inizio non valida.");
                                    if(!(dataFine instanceof Date && !isNaN(dataFine))) return cambiaErrore("Data Fine non valida.");
                                    cambiaErrore();
                                    aggiungiDate(dataInizio, dataFine);
                                    setDataInizio(null);
                                    setDataFine(null);
                                }}
                                startIcon={<AddCircleOutlineIcon />}
                            >AGGIUNGI</Button>
                        </Stack>
                    }
                </Stack>

                <h3 className="admin-titoletto">Lista Date</h3>
                <p>NB: Solo due date saranno visibili sul sito, filtrando le date solo del periodo attuale o future</p>
                <List component="div" aria-label="main mailbox folders">
                    {listaDate.length == 0 && <span>Nessuna data inserita.</span>}
                    {listaDate.map((data, index) => (
                        <ListItem key={index}>
                            <ListItemIcon onClick={() => togliDate(index)}><IconButton edge="end" aria-label="delete"><DeleteIcon/></IconButton></ListItemIcon>
                            <ListItemText primary={utils.scriviData(data.data_inizio) + " - " + utils.scriviData(data.data_fine)}></ListItemText>
                        </ListItem>
                    ))}
                </List>
            </div>

            <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <Stack direction="row" spacing={7} paddingTop={2} paddingBottom={2} alignItems="center">
                    <h3 className="admin-titoletto">Scegli la Categoria</h3>
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Categoria</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name="categoria"
                            value={values.categoria}
                            onChange={handleChange}
                            autoWidth
                            size="small"
                            label="Categoria"
                        >
                            <MenuItem value={0}><em>Nessuna</em></MenuItem>
                            {categorieOfferta.map((cat) => (
                                <MenuItem key={cat.id} value={cat.id}>{cat.nome_italiano}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={2} paddingBottom={2} alignItems="center">
                    <h3 className="admin-titoletto">Scegli il Label</h3>
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Label</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name="label"
                            value={values.label}
                            onChange={handleChange}
                            autoWidth
                            size="small"
                            label="Label"
                        >
                            <MenuItem value={0}><em>Nessuno</em></MenuItem>
                            {labelOfferta.map((lab) => (
                                <MenuItem key={lab.id} value={lab.id}>{lab.nome_italiano}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </div>
        </React.Fragment>
    );
}

export default FormOfferta;