import React, { useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import ElementoPaginaLaterale from './ElementoPaginaLaterale';
import './style.css';

function BarraPagineCms({pagine, paginaSel, isSuper, scegliPagina, goToNuova}) {

    const [ricerca, setRicerca] = useState();

    const filtraPagine = useCallback(() => {
        if(!ricerca || ricerca.length == 0) return pagine;
        return pagine.filter((e) => {
            var appo = ricerca.toLowerCase();
            var appo2 = e.nome.toLowerCase();
            return appo2.includes(appo);
        });
    }, [ricerca])

    return (
        <div className="container-laterale">
            
            <div className="prima-riga-container-laterale">
                <h3 className="admin-titoletto">Lista Pagine</h3>
                {isSuper && <Button
                    size="medium"
                    variant={"contained"}
                    color={"primary"}
                    onClick={goToNuova}
                    startIcon={<AddCircleOutlineIcon />}
                >NUOVA</Button>}
            </div>
            <Stack direction="row" paddingTop={2} paddingBottom={1}>
                <TextField 
                    id="outlined-basic"
                    label="Ricerca"
                    variant="outlined"
                    name="ricerca"
                    onChange={(event) => setRicerca(event.target.value)}
                    value={ricerca}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>

            <List component="div">
                {filtraPagine().map((pagina, index) => (
                    <ElementoPaginaLaterale
                        nome={pagina.nome}
                        onClick={() => scegliPagina(pagina)}
                        selezionato={paginaSel?.id == pagina.id}
                        key={index}
                    ></ElementoPaginaLaterale>
                ))}
            </List>
        </div>
    );
}

export default BarraPagineCms;