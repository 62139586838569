import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from '../components/header/Header';

import Attrazioni from '../pages/Attrazioni';
import Banner from '../pages/Banner';
import CreaLocalita from '../pages/CreaLocalita';
import Eventi from '../pages/Eventi';
import GuideTuristiche from '../pages/GuideTuristiche';
import Itinerari from '../pages/Itinerari';
import Localita from '../pages/Localita';
import Offerte from '../pages/Offerte';
import Partners from '../pages/Partners';
import Strutture from '../pages/Strutture';
import Tematiche from '../pages/Tematiche';
import useAuth from '../controllers/auth/useAuth';
import Login from '../pages/Login';
import CreaStruttura from '../pages/CreaStruttura';
import CreaAttrazione from '../pages/CreaAttrazione';
import CreaEvento from '../pages/CreaEvento';
import CreaOfferta from '../pages/CreaOfferta';
import CreaItinerario from '../pages/CreaItinerario';
import CreaTematica from '../pages/CreaTematica';
import CreaBunner from '../pages/CreaBunner';
import CreaPartners from '../pages/CreaPartners';
import CreaGuidaTuristica from '../pages/CreaGuidaTuristica';
import Servizi from '../pages/Servizi';
import CreaServizio from '../pages/CreaServizio';
import Categorie from '../pages/Categorie';
import CreaCategoria from '../pages/CreaCategoria';
import News from '../pages/News';
import CreaNews from '../pages/CreaNews';
import HomeCms from '../pages/cms/HomeCms';
import GestisciPagina from '../pages/cms/GestisciPagina';
import GestisciSezione from '../pages/cms/GestisciSezione';
import CatalogoIcone from '../pages/CatalogoIcone';
import HomeMediaKit from '../pages/mediaKit/HomeMediaKit';
import CategorieMediaKit from '../pages/mediaKit/CategorieMediaKit';
import ImmagineMediaKit from '../pages/mediaKit/ImmagineMediaKit';
import VideoMediaKit from '../pages/mediaKit/VideoMediaKit';
import Profilo from '../pages/utenti/Profilo';
import GestionUtenti from '../pages/utenti/GestionUtenti';
import CreaAdmin from '../pages/utenti/CreaAdmin';
import ModificaAdmin from '../pages/utenti/ModificaAdmin';

function Navigator(props) {

    const {utente} = useAuth();

    return (
        <BrowserRouter>
            <Routes>

                <Route path="/" element={utente ? <Header/> : <Login />}>
                    <Route index element={<Strutture />} />
                    <Route path="strutture">
                        <Route index element={<Strutture />} />
                        <Route path="crea" element={<CreaStruttura />} />
                    </Route>
                    <Route path="localita">
                        <Route index element={<Localita />} />
                        <Route path="crea" element={<CreaLocalita />} />
                    </Route>
                    <Route path="attrazioni">
                        <Route index element={<Attrazioni />} />
                        <Route path="crea" element={<CreaAttrazione />} />
                    </Route>
                    <Route path="eventi">
                        <Route index element={<Eventi />} />
                        <Route path="crea" element={<CreaEvento />} />
                    </Route>
                    <Route path="news">
                        <Route index element={<News />} />
                        <Route path="crea" element={<CreaNews />} />
                    </Route>
                    <Route path="offerte">
                        <Route index element={<Offerte />} />
                        <Route path="crea" element={<CreaOfferta />} />
                    </Route>
                    <Route path="servizi">
                        <Route index element={<Servizi />} />
                        <Route path="crea" element={<CreaServizio />} />
                    </Route>
                    <Route path="itinerari">
                        <Route index element={<Itinerari />} />
                        <Route path="crea" element={<CreaItinerario />} />
                    </Route>
                    <Route path="tematiche">
                        <Route index element={<Tematiche />} />
                        <Route path="crea" element={<CreaTematica />} />
                    </Route>
                    <Route path="guide">
                        <Route index element={<GuideTuristiche />} />
                        <Route path="crea" element={<CreaGuidaTuristica />} />
                    </Route>
                    <Route path="banner">
                        <Route index element={<Banner />} />
                        <Route path="crea" element={<CreaBunner />} />
                    </Route>
                    <Route path="partners">
                        <Route index element={<Partners />} />
                        <Route path="crea" element={<CreaPartners />} />
                    </Route>
                    <Route path="categorie">
                        <Route index element={<Categorie />} />
                        <Route path="crea" element={<CreaCategoria />} />
                    </Route>
                    <Route path="catalogo-icone">
                        <Route index element={<CatalogoIcone />} />
                    </Route>
                    <Route path="cms">
                        <Route index element={<HomeCms />} />
                        <Route path="gestisci-pagina" element={<GestisciPagina />} />
                        <Route path="gestisci-sezione" element={<GestisciSezione />} />
                    </Route>
                    <Route path="media-kit">
                        <Route index element={<HomeMediaKit />} />
                        <Route path="categoria" element={<CategorieMediaKit />} />
                        <Route path="immagine" element={<ImmagineMediaKit />} />
                        <Route path="video" element={<VideoMediaKit />} />
                    </Route>
                    <Route path="profilo" element={<Profilo />} />
                    <Route path="gestione-utenti">
                        <Route index element={<GestionUtenti />} />
                        <Route path="crea" element={<CreaAdmin />} />
                        <Route path="modifica" element={<ModificaAdmin />} />
                    </Route>
                </Route>

            </Routes>
        </BrowserRouter>
    );
}

export default Navigator;