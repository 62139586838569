import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import useAuth from '../../controllers/auth/useAuth';

function TabellaUtenti({utenti, premiModifica, premiElimina}) {

    const {utente} = useAuth()

    const scriviRuolo = (admin) => {
        if(!admin) return '-'
        if(admin.is_super || admin.username === 'elena') return 'Super Admin'
        if(admin.is_admin) return 'Editor'
        return 'Sola lettura'
    }

    const isSuper = (admin) => {
        if(admin.is_super || admin.username === 'elena') return true
        return false
    }

    return (
        <List 
            component="div" aria-label="main mailbox folders"
            sx={{marginTop:3}}
        >
            {utenti.map((admin, index) => (
                <Box
                    key={index}
                    sx={{padding:'10px 0', display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between',
                        borderBottom:'1px solid #dcdcdc', marginRight:'20px', marginLeft:'5px'
                    }}
                >
                    <Typography component='span' sx={{display:'flex', alignItems:'center', fontSize:20, fontWeight:'bold', width:'30%'}}>
                        {admin.username}
                        {admin.id == utente?.id &&
                            <Box sx={{width:'20px', height:'20px', borderRadius:'20px', backgroundColor:'green',
                                marginLeft:'10px'
                            }}></Box>
                        }
                    </Typography>
                    <Typography sx={{fontSize:20, fontWeight:'500', width:'30%', color:'gray', textAlign:'center'}}>{scriviRuolo(admin)}</Typography>
                    <Box sx={{width:'20%', display:'flex', justifyContent:'flex-end'}}>
                        {isSuper(admin) ? (
                            <span style={{marginRight:'15px'}}>-</span>
                        ) : (
                            <IconButton
                                size="medium"
                                onClick={() => premiModifica(admin)}
                            >
                                <EditIcon />
                            </IconButton>
                        )}
                    </Box>
                    <Box sx={{width:'20%', display:'flex', justifyContent:'flex-end'}}>
                        {isSuper(admin) ? (
                            <span style={{marginRight:'15px'}}>-</span>
                        ) : (
                            <IconButton
                                size="medium"
                                onClick={() => premiElimina(admin)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Box>
                </Box>
            ))}
        </List>
    );
}

export default TabellaUtenti;