import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Checkbox from '@mui/material/Checkbox';
import Fade from '@mui/material/Fade';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckIcon from '@mui/icons-material/Check';

function SceltaLingua({lingua, cambiaLingua, selezionate, cambiaLingueSelezionate, confermaCopia}) {

    const [anchorCopia, setAnchorCopia] = useState(null);
    const openCopia = Boolean(anchorCopia);

    return (
        <React.Fragment>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <h3 className="admin-titoletto">SCEGLI LA LINGUA</h3>
            <Button
                size="small"
                variant={"outlined"}
                color={openCopia ? "primary" : "chiaro"}
                onClick={(event) => setAnchorCopia(event.currentTarget)}
            >COPIA</Button>
            <Menu
                id="fade-menu"
                MenuListProps={{'aria-labelledby': 'fade-button',}}
                anchorEl={anchorCopia}
                open={openCopia}
                onClose={() => setAnchorCopia(null)}
                TransitionComponent={Fade}
            >
                <Stack direction="column" width={200} paddingLeft={2} paddingRight={2}>
                    {lingua != 1 && <FormControlLabel control={<Checkbox checked={selezionate[0]} onChange={() => cambiaLingueSelezionate(0)} />} label="Italiano" />}
                    {lingua != 2 && <FormControlLabel control={<Checkbox checked={selezionate[1]} onChange={() => cambiaLingueSelezionate(1)} />} label="Inglese" />}
                    {lingua != 3 && <FormControlLabel control={<Checkbox checked={selezionate[2]} onChange={() => cambiaLingueSelezionate(2)} />} label="Tedesco" />}
                    {lingua != 4 && <FormControlLabel control={<Checkbox checked={selezionate[3]} onChange={() => cambiaLingueSelezionate(3)} />} label="Olandese" />}
                    {lingua != 5 && <FormControlLabel control={<Checkbox checked={selezionate[4]} onChange={() => cambiaLingueSelezionate(4)} />} label="Danese" />}
                    <Stack direction="column" spacing={2} paddingTop={1} paddingBottom={1}>
                        <Button
                            size="small"
                            variant={"contained"}
                            color={"success"}
                            onClick={() => {confermaCopia(); setAnchorCopia(null)}}
                            startIcon={<CheckIcon />}
                        >APPLICA</Button>
                        <Button
                            size="small"
                            variant={"contained"}
                            color={"error"}
                            onClick={() => setAnchorCopia(null)}
                            startIcon={<HighlightOffIcon />}
                        >ANNULLA</Button>
                    </Stack>
                </Stack>
            </Menu>
        </Stack>
        <Stack direction="row" spacing={2}>
            <FormControlLabel value={1} control={<Radio checked={lingua === 1} onChange={() => cambiaLingua(1)} />} label="Italiano" />
            <FormControlLabel value={2} control={<Radio checked={lingua === 2} onChange={() => cambiaLingua(2)} />} label="Inglese" />
            <FormControlLabel value={3} control={<Radio checked={lingua === 3} onChange={() => cambiaLingua(3)} />} label="Tedesco" />
            <FormControlLabel value={4} control={<Radio checked={lingua === 4} onChange={() => cambiaLingua(4)} />} label="Olandese" />
            <FormControlLabel value={5} control={<Radio checked={lingua === 5} onChange={() => cambiaLingua(5)} />} label="Danese" />
        </Stack>
        </React.Fragment>
    );
}

export default SceltaLingua;