import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function FormTematica({handleChange, values, errors, touched, isModifica}) {
    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <Stack direction="row" spacing={7} paddingTop={1} paddingBottom={1}>
                <FormControlLabel
                    control={
                        <Checkbox 
                            size="medium"
                            color="primary"
                            name="attiva"
                            checked={values.attiva}
                            onChange={handleChange}
                        />
                    } 
                    label="Attiva"
                />
            </Stack>
            <Stack direction="row" paddingTop={1} paddingBottom={1}>
                <TextField 
                    id="outlined-basic"
                    label="Video"
                    variant="outlined"
                    name="video"
                    onChange={handleChange}
                    value={values.video}
                    error={touched.video && Boolean(errors.video)}
                    helperText={touched.video && errors.video}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>
        </div>
    );
}

export default FormTematica;