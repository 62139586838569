import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { Editor } from "react-draft-wysiwyg";

import Italiana from '../../images/it.png';
import Inglese from '../../images/uk.png';
import Tedesco from '../../images/de.png';
import Olandese from '../../images/ne.png';
import Danese from '../../images/dk.png';
import SceltaLingua from './SceltaLingua';

function ContenutoScheda({
    titolo, cambiaTitolo, contenuto, cambiaContenuto, lingua, cambiaLingua, copiaContenuti, 
    isModifica, soloTitolo, isMeta, valoriMeta, cambiaValoriMeta
}) {

    const [selezionate, setSelezionate] = useState(new Array(5).fill(true))

    const cambiaLingueSelezionate = (position) => {
        const nuovo = selezionate.map((item, index) =>
            index === position ? !item : item
        );
        setSelezionate(nuovo)
    }

    const confermaCopia = () => {
        //ottengo il titolo da copiare
        const titoloBase = lingua == 1 ? titolo.italiano : (
            lingua == 2 ? titolo.inglese : (lingua == 3 ? titolo.tedesco : (
                lingua == 4 ? titolo.olandese : titolo.danese
            ))
        );
        //ottengo il contenuto da copiare
        const contenutoBase = !soloTitolo ? (lingua == 1 ? contenuto.italiano : (
            lingua == 2 ? contenuto.inglese : (lingua == 3 ? contenuto.tedesco : (
                lingua == 4 ? contenuto.olandese : contenuto.danese
            ))
        )) : null;
        //calcolo titolo
        const appoTitolo = {
            italiano: selezionate[0] ? titoloBase : titolo.italiano,
            inglese: selezionate[1] ? titoloBase : titolo.inglese,
            tedesco: selezionate[2] ? titoloBase : titolo.tedesco,
            olandese: selezionate[3] ? titoloBase : titolo.olandese,
            danese: selezionate[4] ? titoloBase : titolo.danese
        }
        //calcolo contenuti
        const appoContenuti = !soloTitolo ? {
            italiano: selezionate[0] ? contenutoBase : contenuto.italiano,
            inglese: selezionate[1] ? contenutoBase : contenuto.inglese,
            tedesco: selezionate[2] ? contenutoBase : contenuto.tedesco,
            olandese: selezionate[3] ? contenutoBase : contenuto.olandese,
            danese: selezionate[4] ? contenutoBase : contenuto.danese
        } : null;
        //confermo la modifica
        copiaContenuti(appoTitolo, appoContenuti);
        setSelezionate(new Array(5).fill(true))
    }

    return (
        <React.Fragment>
            {soloTitolo && <>
                <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                    <SceltaLingua
                        lingua={lingua}
                        cambiaLingua={cambiaLingua}
                        selezionate={selezionate}
                        cambiaLingueSelezionate={cambiaLingueSelezionate}
                        confermaCopia={confermaCopia}
                    ></SceltaLingua>
                    <Stack direction="row" spacing={2} paddingTop={2} paddingBottom={2}>
                        <img src={lingua == 1 ? Italiana : (lingua == 2 ? Inglese : (lingua == 3 ? Tedesco : (lingua == 4 ? Olandese : Danese)))} className="admin-bandiera"></img>
                        <h3 className="admin-titoletto">TITOLO</h3>
                    </Stack>
                    <Stack direction="row" spacing={2} paddingBottom={1}>
                        <TextField 
                            id="outlined-basic"
                            label="Titolo"
                            variant="outlined"
                            onChange={cambiaTitolo}
                            value={lingua == 1 ? titolo.italiano : (lingua == 2 ? titolo.inglese : (lingua == 3 ? titolo.tedesco : (lingua == 4 ? titolo.olandese : titolo.danese)))}
                            size={"small"}
                            fullWidth
                            autoComplete="off"
                        />
                    </Stack>
                </div>
                {isMeta &&
                    <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                        <Stack direction="row" spacing={2} paddingBottom={2}>
                            <img src={lingua == 1 ? Italiana : (lingua == 2 ? Inglese : (lingua == 3 ? Tedesco : (lingua == 4 ? Olandese : Danese)))} className="admin-bandiera"></img>
                            <h3 className="admin-titoletto">META DESCRIPTION</h3>
                        </Stack>
                        <TextField 
                            id="outlined-basic"
                            label="Meta description"
                            variant="outlined"
                            onChange={cambiaValoriMeta}
                            value={lingua == 1 ? valoriMeta.italiano : (lingua == 2 ? valoriMeta.inglese : (lingua == 3 ? valoriMeta.tedesco : (lingua == 4 ? valoriMeta.olandese : valoriMeta.danese)))}
                            size={"small"}
                            multiline={true}
                            rows={5}
                            fullWidth
                            autoComplete="off"
                        />
                    </div>
                }
            </>}
            {!soloTitolo && <>
                <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                    <SceltaLingua
                        lingua={lingua}
                        cambiaLingua={cambiaLingua}
                        selezionate={selezionate}
                        cambiaLingueSelezionate={cambiaLingueSelezionate}
                        confermaCopia={confermaCopia}
                    ></SceltaLingua>
                    
                </div>
                <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                    <Stack direction="row" spacing={2} paddingBottom={2}>
                        <img src={lingua == 1 ? Italiana : (lingua == 2 ? Inglese : (lingua == 3 ? Tedesco : (lingua == 4 ? Olandese : Danese)))} className="admin-bandiera"></img>
                        <h3 className="admin-titoletto">TITOLO</h3>
                    </Stack>
                    <TextField 
                        id="outlined-basic"
                        label="Titolo"
                        variant="outlined"
                        onChange={cambiaTitolo}
                        value={lingua == 1 ? titolo.italiano : (lingua == 2 ? titolo.inglese : (lingua == 3 ? titolo.tedesco : (lingua == 4 ? titolo.olandese : titolo.danese)))}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </div>
                <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                    <Stack direction="row" spacing={2} paddingBottom={2}>
                        <img src={lingua == 1 ? Italiana : (lingua == 2 ? Inglese : (lingua == 3 ? Tedesco : (lingua == 4 ? Olandese : Danese)))} className="admin-bandiera"></img>
                        <h3 className="admin-titoletto">DESCRIZIONE</h3>
                    </Stack>
                    <Editor
                        editorState={lingua == 1 ? contenuto.italiano : (lingua == 2 ? contenuto.inglese : (lingua == 3 ? contenuto.tedesco : (lingua == 4 ? contenuto.olandese : contenuto.danese)))}
                        onEditorStateChange={cambiaContenuto}
                        toolbar={{
                            options: ['inline', 'link', 'history', 'list', 'textAlign', 'fontSize', 'colorPicker', 'blockType'],
                            inline: {inDropdown: false, options: ['bold', 'italic', 'underline']},
                            link: { inDropdown: false },
                            history: { inDropdown: false },
                            fontSize: {inDropdown: true},
                            list: {inDropdown: false, options: ['unordered', 'ordered']},
                            blockType: {inDropdown:true, options:['Normal', 'H2', 'H3', 'H4']}
                        }}
                    />
                </div>
                {isMeta &&
                    <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                        <Stack direction="row" spacing={2} paddingBottom={2}>
                            <img src={lingua == 1 ? Italiana : (lingua == 2 ? Inglese : (lingua == 3 ? Tedesco : (lingua == 4 ? Olandese : Danese)))} className="admin-bandiera"></img>
                            <h3 className="admin-titoletto">META DESCRIPTION</h3>
                        </Stack>
                        <TextField 
                            id="outlined-basic"
                            label="Meta description"
                            variant="outlined"
                            onChange={cambiaValoriMeta}
                            value={lingua == 1 ? valoriMeta.italiano : (lingua == 2 ? valoriMeta.inglese : (lingua == 3 ? valoriMeta.tedesco : (lingua == 4 ? valoriMeta.olandese : valoriMeta.danese)))}
                            size={"small"}
                            multiline={true}
                            rows={5}
                            fullWidth
                            autoComplete="off"
                        />
                    </div>
                }
            </>}
        </React.Fragment>
    );
}

export default ContenutoScheda;