import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

function TitoloImgComponente({index, componente, cambiaTitoloImmagine, lingua}) {

    return (
        <Box sx={{p:1, pt:2, pl:0}}>
            <Stack direction="row" spacing={2} paddingBottom={1}>
                <TextField 
                    id="outlined-basic"
                    variant="outlined"
                    onChange={(event) => cambiaTitoloImmagine(index, lingua, event.target.value)}
                    value={lingua == 1 ? componente.nome_img_italiano : (lingua == 2 ? componente.nome_img_inglese : (lingua == 3 ? componente.nome_img_tedesco : (lingua == 4 ? componente.nome_img_olandese : componente.nome_img_danese)))}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>
        </Box>
    );
}

export default TitoloImgComponente;