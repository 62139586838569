import { useContext } from "react";

import EsperienzeContext from "./context";

const useEventi = () => {
    const { esperienze, setEsperienze } = useContext(EsperienzeContext);

    const popolaEventi = (vett) => {
        const appo = {...esperienze};
        appo.eventi = vett;
        setEsperienze(appo);
    }

    const importanzaMaxEventi = () => {
        let appo = esperienze.eventi;
        let valore = appo[appo.length - 1]?.importanza;
        return valore ? (valore + 1) : 1
    }

    const addEvento = (e) => {
        const appo = {...esperienze};
        appo.eventi.push(e);
        setEsperienze(appo);
    }

    const aggEvento = (ev) => {
        const appo = {...esperienze};
        for(let i = 0; i < appo.eventi.length; i++) {
            if(appo.eventi[i].id == ev.id) appo.eventi[i] = ev;
        }
        setEsperienze(appo);
    }

    const togliEvento = (id) => {
        const appo = {...esperienze};
        appo.eventi = esperienze.eventi.filter((a) => a.id != id);
        setEsperienze(appo)
    }

    return { 
        eventi: esperienze.eventi,
        popolaEventi,
        importanzaMaxEventi,
        addEvento,
        aggEvento,
        togliEvento
    }
}

export default useEventi;