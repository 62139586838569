import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import mediaApi from '../../api/media'
import useMediaKit from '../../controllers/mediaKit/useMediaKit'
import BottoniScheda from '../../components/scheda/BottoniScheda'
import FormCategoriaMedia from '../../components/mediaKit/FormCategoriaMedia'

function CategorieMediaKit(props) {

    const {state} = useLocation()
    const navigate = useNavigate()

    const {categorie, addCategoria, aggCategoria} = useMediaKit()

    const [errore, setErrore] = useState(null)
    const [loading, setLoading] = useState(false)
    const [categoria, setCategoria] = useState(null)
    const [isModifiche, setIsModifiche] = useState(false)

    useEffect(() => {
        const aggCat = () => {
            if(state?.categoria) return setCategoria(state?.categoria)
            setCategoria({
                id:0, nome_italiano:'', nome_inglese:'', 
                nome_tedesco:'', nome_olandese:'', nome_danese:''
            })
        }
        aggCat()
    }, [state])

    const scriviTitolo = () => {
        if(!categoria) return 'MEDIA KIT'
        if(categoria.id) return 'MEDIA KIT: MODIFICA CATEGORIA'
        return 'MEDIA KIT: NUOVA CATEGORIA'
    }

    const cambiaNomeCategoria = (lingua, valore) => {
        const appo = {...categoria}
        if(lingua == 1) appo.nome_italiano = valore
        else if(lingua == 2) appo.nome_inglese = valore
        else if(lingua == 3) appo.nome_tedesco = valore
        else if(lingua == 4) appo.nome_olandese = valore
        else if(lingua == 5) appo.nome_danese = valore
        setCategoria(appo)
        if(!isModifiche) setIsModifiche(true)
    }

    const conferma = async() => {
        //controllo valori
        if(!categoria.nome_italiano?.length) return setErrore('Inserire nome italiano')
        if(!categoria.nome_inglese?.length) return setErrore('Inserire nome inglese')
        if(!categoria.nome_tedesco?.length) return setErrore('Inserire nome tedesco')
        if(!categoria.nome_olandese?.length) return setErrore('Inserire nome olandese')
        if(!categoria.nome_danese?.length) return setErrore('Inserire nome danese')
        //proseguo con le api
        let results = null
        setLoading(true)
        if(categoria.id) { //modifica
            results = await mediaApi.modificaCategoriaMedia({...categoria})
            if(!results.ok) {
                setLoading(false)
                return setErrore(results.data)
            }
            return setTimeout(() => {
                aggCategoria(results.data)
                setLoading(false)
                setErrore(null)
                navigate("..", {replace: true, state:{isCatModificata:true}});
            }, 700);
        }
        //creazione
        results = await mediaApi.creaCategoriaMedia({
            nome_italiano:categoria.nome_italiano, nome_inglese:categoria.nome_inglese, 
            nome_tedesco:categoria.nome_tedesco, nome_olandese:categoria.nome_olandese, 
            nome_danese:categoria.nome_danese, importanza:calcolaImportanzaMax()
        })
        if(!results.ok) {
            setLoading(false)
            return setErrore(results.data)
        }
        setTimeout(() => {
            addCategoria(results.data)
            setLoading(false)
            setErrore(null)
            navigate("..", {replace: true, state:{isNuovaCat:true}});
        }, 700);
    }

    const calcolaImportanzaMax = () => {
        let importanza = 0;
        for(let i = 0; i < categorie.length; i++) {
            if(categorie[i].importanza >= importanza) importanza = categorie[i].importanza + 1;
        }
        return importanza
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setErrore(null);
    }

    return (
        <React.Fragment>
            <div className="admin-container">
                <h2 className="admin-titoletto">{scriviTitolo()}</h2>
                <BottoniScheda
                    loading={loading}
                    conferma={conferma}
                    annulla={() => navigate('..')}
                ></BottoniScheda>
            </div>

            {categoria && <FormCategoriaMedia
                categoria={categoria}
                cambiaNome={cambiaNomeCategoria}
            ></FormCategoriaMedia>}

            {isModifiche && <BottoniScheda
                loading={loading}
                conferma={conferma}
                annulla={() => navigate('..')}
            ></BottoniScheda>}

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default CategorieMediaKit;