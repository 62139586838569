import client from "./client";
import config from '../config.json';

const endpoint = 'admin/cms/'

//PAGINA
const creaPagina = (nome, link) => {
    return client.post(config.serverUrl + endpoint + "pagina", {nome, link});
}
const modificaPagina = (id, nome, link) => {
    return client.put(config.serverUrl + endpoint + "pagina", {id, nome, link});
}
const eliminaPagina = (id) => {
    return client.delete(config.serverUrl + endpoint + "pagina/" + id);
}

//ELEMENTO
const creaElemento = (elemento, componenti) => {
    return client.post(config.serverUrl + endpoint + "elemento", {elemento, componenti});
}
const modificaElemento  = (elemento, componenti, eliminati) => {
    return client.put(config.serverUrl + endpoint + "elemento", {elemento, componenti, eliminati});
}
const eliminaElemento  = (id) => {
    return client.delete(config.serverUrl + endpoint + "elemento/" + id);
}

//COMPONENTI
const modificaComponenti  = (idElemento, componenti) => {
    return client.put(config.serverUrl + endpoint + "componenti", {idElemento, componenti});
}

export default {
    creaPagina,
    modificaPagina,
    eliminaPagina,
    creaElemento,
    modificaElemento,
    eliminaElemento,
    modificaComponenti
}