import { useContext } from "react";

import EsperienzeContext from "./context";

const useItinerari = () => {
    const { esperienze, setEsperienze } = useContext(EsperienzeContext);

    const popolaItinerari = (vett) => {
        const appo = {...esperienze};
        appo.itinerari = vett;
        setEsperienze(appo);
    }

    const importanzaMaxItinerari = () => {
        let appo = esperienze.itinerari;
        let valore = appo[appo.length - 1]?.importanza;
        return valore ? (valore + 1) : 1
    }

    const addItinerario = (i) => {
        const appo = {...esperienze};
        appo.itinerari.push(i);
        setEsperienze(appo);
    }

    const aggItinerario = (iti) => {
        const appo = {...esperienze};
        for(let i = 0; i < appo.itinerari.length; i++) {
            if(appo.itinerari[i].id == iti.id) appo.itinerari[i] = iti;
        }
        setEsperienze(appo);
    }

    const togliItinerario = (id) => {
        const appo = {...esperienze};
        appo.itinerari = esperienze.itinerari.filter((a) => a.id != id);
        setEsperienze(appo)
    }

    return { 
        itinerari: esperienze.itinerari,
        popolaItinerari,
        importanzaMaxItinerari,
        addItinerario,
        aggItinerario,
        togliItinerario
    }
}

export default useItinerari;