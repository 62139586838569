import { useContext } from "react";

import CampeggiContext from "./context";

const useSistemazioni = () => {
    const { campeggi, setCampeggi } = useContext(CampeggiContext);

    const addCategoriaSistemazione = (c) => {
        const appo = {...campeggi};
        appo.sistemazioni.push(c);
        setCampeggi(appo);
    }

    const aggCategoriaSistemazione = (cat) => {
        const appo = {...campeggi};
        for(let i = 0; i < appo.sistemazioni.length; i++) {
            if(appo.sistemazioni[i].id == cat.id) appo.sistemazioni[i] = cat;
        }
        setCampeggi(appo);
    }

    const togliCategoriaSistemazione = (id) => {
        const appo = {...campeggi};
        appo.sistemazioni = campeggi.sistemazioni.filter((a) => a.id != id);
        setCampeggi(appo)
    }

    const categoriaSistemazioneById = (id) => campeggi.sistemazioni.filter((e) => e.id == id)[0]

    return { 
        categorieSistemazioni: campeggi.sistemazioni,
        addCategoriaSistemazione,
        aggCategoriaSistemazione,
        togliCategoriaSistemazione,
        categoriaSistemazioneById
    }
}

export default useSistemazioni;