import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';

import Italiana from '../../images/it.png';
import Inglese from '../../images/uk.png';
import Tedesco from '../../images/de.png';
import Olandese from '../../images/ne.png';
import Danese from '../../images/dk.png';

function FormCategoriaMedia({categoria, cambiaNome}) {

    const [lingua, setLingua] = useState(1); //1 italiano, ...

    return (
        <Box className='admin-container'>
            <Box sx={{
                borderRadius:'10px', border:'none', padding:0, marginBottom:2, backgroundColor:'white'
            }}>
                <Typography sx={{fontSize:20, fontWeight:'bold'}}>Seleziona la lingua</Typography>
                <Stack direction="row" spacing={2} paddingTop={1} paddingBottom={1}>
                    <FormControlLabel value={1} control={<Radio checked={lingua === 1} onChange={() => setLingua(1)} />} label="Italiano" />
                    <FormControlLabel value={2} control={<Radio checked={lingua === 2} onChange={() => setLingua(2)} />} label="Inglese" />
                    <FormControlLabel value={3} control={<Radio checked={lingua === 3} onChange={() => setLingua(3)} />} label="Tedesco" />
                    <FormControlLabel value={4} control={<Radio checked={lingua === 4} onChange={() => setLingua(4)} />} label="Olandese" />
                    <FormControlLabel value={5} control={<Radio checked={lingua === 5} onChange={() => setLingua(5)} />} label="Danese" />
                </Stack>
            </Box>

            <Box sx={{p:1, pt:2, pl:0}}>
                <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <img src={lingua == 1 ? Italiana : (lingua == 2 ? Inglese : (lingua == 3 ? Tedesco : (lingua == 4 ? Olandese : Danese)))} className="admin-bandiera-cms"></img>    
                        <Typography sx={{fontSize:18, fontWeight:'bold', marginLeft:1.5}}>Nome della categoria</Typography>
                    </Box>
                </Box>
                <Stack direction="row" spacing={0} paddingBottom={1} paddingTop={2}>
                    <TextField 
                        id="outlined-basic"
                        variant="outlined"
                        onChange={(event) => cambiaNome(lingua, event.target.value)}
                        value={lingua == 1 ? categoria.nome_italiano : (lingua == 2 ? categoria.nome_inglese : (lingua == 3 ? categoria.nome_tedesco : (lingua == 4 ? categoria.nome_olandese : categoria.nome_danese)))}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
            </Box>
        </Box>
    );
}

export default FormCategoriaMedia;