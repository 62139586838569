import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import cmsApi from '../../api/cms';
import upload from '../../api/upload';
import config from '../../config.json'
import RicercaCms from '../../components/cms/RicercaCms';
import useCms from '../../controllers/cms/useCms';
import useAuth from '../../controllers/auth/useAuth';
import BarraPagineCms from '../../components/cms/BarraPagineCms';
import HeaderCms from '../../components/cms/HeaderCms';
import GestisciComponenti from '../../components/cms/GestisciComponenti';
import BottoniScheda from '../../components/scheda/BottoniScheda';
import ModalAnnullaCms from '../../components/cms/ModalAnnullaCms';

function HomeCms(props) {

    const {state, pathname} = useLocation();
    const navigate = useNavigate();
    const {pagine, elementi, modificaElemento} = useCms();
    const {utente} = useAuth();

    const [pagina, setPagina] = useState();
    const [sezioni, setSezioni] = useState([])
    const [sezioneSel, setSezioneSel] = useState();
    const [modifiche, setModifiche] = useState({tipo:0, appoPagina:null})
    const [altro, setAltro] = useState({loading:false, errore:null, openAlert:0, modifiche:false, visible:false})

    useEffect(() => aggStato(), [state, pagine])
    useEffect(() => aggSezioni(), [pagina, elementi])

    const aggStato = () => {
        if(!state) return;
        if(state.paginaCreata) {
            setAltro({...altro, openAlert:1})
            setPagina(state.paginaCreata)
            return navigate(pathname)
        }
        if(state.paginaModificata) {
            setAltro({...altro, openAlert:2})
            setPagina(state.paginaModificata)
            return navigate(pathname)
        }
        if(state.sezioneCreata) {
            setAltro({...altro, openAlert:3})
            const idPagina = state.sezioneCreata.pagina;
            const appo = pagine.filter((p) => p.id == idPagina)[0]
            setPagina(appo)
            setSezioneSel(state.sezioneCreata)
            return navigate(pathname)
        }
        if(state.sezioneModificata) {
            setAltro({...altro, openAlert:4})
            const idPagina = state.sezioneModificata.pagina;
            const appo = pagine.filter((p) => p.id == idPagina)[0]
            setPagina(appo)
            setSezioneSel(state.sezioneModificata)
            return navigate(pathname)
        }
        if(state.annullaSezione) {
            let appo
            if(state.idSezione) {
                appo = JSON.parse(JSON.stringify(elementi)).filter((el) => el.id == state.idSezione)[0]
                if(appo) {
                    setSezioneSel(appo)
                    const idPagina = appo.pagina;
                    appo = pagine.filter((p) => p.id == idPagina)[0]
                    setPagina(appo)
                }
            }
            if(state.idPagina) {
                appo = pagine.filter((p) => p.id == state.idPagina)[0]
                setPagina(appo)
            }
        }
        if(state.paginaEliminata) {
            setAltro({...altro, openAlert:6})
            return navigate(pathname)
        }
        if(state.sezioneEliminata) {
            setAltro({...altro, openAlert:7})
            return navigate(pathname)
        }
    }

    const aggSezioni = () => {
        if(!pagina) setSezioneSel(null)
        if(!pagina) return setSezioni([])
        const vett = JSON.parse(JSON.stringify(elementi))
        setSezioni(vett.filter((el) => el.pagina == pagina.id))
    }

    const scegliPagina = (pagina) => {
        if(altro.modifiche) {
            setModifiche({tipo:1, appoPagina: pagina})
            return setAltro({...altro, visible:true})
        }
        setPagina(pagina)
        setSezioneSel(null)
    }

    const scegliSezione = (id) => {
        const appo = sezioni.filter((s) => s.id == id)[0]
        if(appo) setSezioneSel(appo)
        else setSezioneSel(null)
    }

    const annullaSezione = () => {
        if(altro.modifiche) {
            setModifiche({tipo:2, appoPagina: null})
            return setAltro({...altro, visible:true})
        }
        setSezioneSel(null)
    }

    const cambiaLink = (pos, lang, valore) => {
        if(!sezioneSel) return;
        const appo = {...sezioneSel}
        if(lang == 1) appo.componenti[pos].link_italiano = valore;
        if(lang == 2) appo.componenti[pos].link_inglese = valore;
        if(lang == 3) appo.componenti[pos].link_tedesco = valore;
        if(lang == 4) appo.componenti[pos].link_olandese = valore;
        if(lang == 5) appo.componenti[pos].link_danese = valore;
        setSezioneSel(appo)
        setAltro({...altro, modifiche:true})
    }

    const cambiaBanner = (pos, valore) => {
        if(!sezioneSel) return;
        const appo = {...sezioneSel}
        appo.componenti[pos].id_banner = valore;
        setSezioneSel(appo)
        setAltro({...altro, modifiche:true})
    }

    const cambiaTitolo = (pos, lang, valore) => {
        if(!sezioneSel) return;
        const appo = {...sezioneSel}
        if(lang == 1) appo.componenti[pos].titolo_italiano = valore.trim();
        if(lang == 2) appo.componenti[pos].titolo_inglese = valore.trim();
        if(lang == 3) appo.componenti[pos].titolo_tedesco = valore.trim();
        if(lang == 4) appo.componenti[pos].titolo_olandese = valore.trim();
        if(lang == 5) appo.componenti[pos].titolo_danese = valore.trim();
        setSezioneSel(appo)
        setAltro({...altro, modifiche:true})
    }

    const cambiaTesto = (pos, lang, valore) => {
        if(!sezioneSel) return;
        const appo = {...sezioneSel}
        if(lang == 1) appo.componenti[pos].valore_italiano = valore;
        if(lang == 2) appo.componenti[pos].valore_inglese = valore;
        if(lang == 3) appo.componenti[pos].valore_tedesco = valore;
        if(lang == 4) appo.componenti[pos].valore_olandese = valore;
        if(lang == 5) appo.componenti[pos].valore_danese = valore;
        //setSezioneSel(appo)
        setAltro({...altro, modifiche:true})
    }

    const cambiaTitoloImmagine = (pos, lang, valore) => {
        if(!sezioneSel) return;
        const appo = {...sezioneSel}
        if(lang == 1) appo.componenti[pos].nome_img_italiano = valore;
        if(lang == 2) appo.componenti[pos].nome_img_inglese = valore;
        if(lang == 3) appo.componenti[pos].nome_img_tedesco = valore;
        if(lang == 4) appo.componenti[pos].nome_img_olandese = valore;
        if(lang == 5) appo.componenti[pos].nome_img_danese = valore;
        setSezioneSel(appo)
        setAltro({...altro, modifiche:true})
    }

    const cambiaImmagine = async(pos, event) => {
        //controllo che sia immagine
        const file = event.target.files[0]
        if(file.type.split("/")[0] != "image") return setAltro({...altro, errore:"Formato del file non valido. Richiesta Immagine."})
        //preparo il body
        let formData = new FormData();
        formData.append('immagine', file, file.name);
        formData.append('nome_italiano', sezioneSel.componenti[pos].nome_img_italiano)
        formData.append('nome_inglese', sezioneSel.componenti[pos].nome_img_inglese)
        formData.append('nome_tedesco', sezioneSel.componenti[pos].nome_img_tedesco)
        formData.append('nome_olandese', sezioneSel.componenti[pos].nome_img_olandese)
        formData.append('nome_danese', sezioneSel.componenti[pos].nome_img_danese)
        formData.append('componente', sezioneSel.componenti[pos].id);
        //chiama api per creare immagini
        const results = await upload.creaImmagineCms(formData);
        if(!results.ok) return setAltro({...altro, errore: results.data})
        //modifico il componente
        const appo = {...sezioneSel}
        appo.componenti[pos].link_immagine = results.data.link;
        appo.componenti[pos].id_immagine = results.data.id;
        appo.componenti[pos].nome_img_italiano = results.data.nome_italiano;
        appo.componenti[pos].nome_img_inglese = results.data.nome_inglese;
        appo.componenti[pos].nome_img_tedesco = results.data.nome_tedesco;
        appo.componenti[pos].nome_img_olandese = results.data.nome_olandese;
        appo.componenti[pos].nome_img_danese = results.data.nome_danese;
        setSezioneSel(appo)
        console.log(appo.componenti[pos])
        setAltro({...altro, modifiche:true})
    }

    const cliccaElemento = (sezione) => {
        const idPagina = sezione.pagina;
        const appo = pagine.filter((p) => p.id == idPagina)[0]
        if(appo) {
            setPagina(appo)
            setSezioneSel(sezione)
        }
    }

    const premiAnnulla = () => {
        if(!sezioneSel || !pagina) return;
        //pulisco sezioni
        const vett = JSON.parse(JSON.stringify(elementi))
        const lista = vett.filter((el) => el.pagina == pagina.id)
        setSezioni(lista)
        //pulisco sezione sel
        const appo = lista.filter((s) => s.id == sezioneSel.id)[0]
        if(appo) setSezioneSel(appo)
        else setSezioneSel(null)
        //annullo modifiche
        setAltro({...altro, modifiche:false})
    }

    const confermaModifiche = async() => {
        if(altro.loading) return
        setAltro({...altro, loading:true, errore:null})
        const results = await cmsApi.modificaComponenti(sezioneSel.id, sezioneSel.componenti)
        if(!results.ok) return setAltro({...altro, loading:false, errore:results.data})
        setTimeout(() => {
            modificaElemento(results.data)
            aggSezioni()
            setSezioneSel(results.data)
            setAltro({...altro, loading:false, errore:null, modifiche:false, openAlert:5})
        }, 500);
    }

    const confermaModal = () => {
        if(modifiche.tipo == 1) {
            //cambio pagina
            setPagina(modifiche.appoPagina)
            setSezioneSel(null)
        } else {
            //cambio sezione
            setSezioneSel(null)
            aggSezioni()
        }
        setAltro({...altro, modifiche:false, visible:false})
    }

    const vediPagina = () => {
        const link = config.sitoUrl + pagina.link
        window.open(link, '_blank', 'noopener,noreferrer')
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setAltro({...altro, loading:false, errore:null, openAlert:0})
    }

    return (
        <React.Fragment>

            <div className="admin-corpo-modifica">
                <div className="admin-corpo-modifica-sx">
                    <BarraPagineCms
                        pagine={pagine}
                        paginaSel={pagina}
                        isSuper={utente?.is_super}
                        loading={altro.loading}
                        scegliPagina={scegliPagina}
                        goToNuova={() => navigate('/cms/gestisci-pagina', {state:{fromCms:true}})}
                        premiAnnulla={premiAnnulla}
                        confermaModifiche={confermaModifiche}
                    ></BarraPagineCms>
                </div>

                <div className="admin-corpo-modifica-dx">
                    <HeaderCms
                        pagina={pagina}
                        sezioni={sezioni}
                        sezioneSel={sezioneSel}
                        isSuper={utente?.is_super}
                        isModifiche={Boolean(altro.modifiche && sezioneSel)}
                        scegliSezione={scegliSezione}
                        goToModifica={() => navigate('/cms/gestisci-pagina', {state:{fromCms:true, pagina}})}
                        goToNuovaSezione={()=> navigate('/cms/gestisci-sezione', {state:{idPagina:pagina.id}})}
                        goToModificaSezione={()=> navigate('/cms/gestisci-sezione', {state:{idPagina:pagina.id, sezione:sezioneSel}})}
                        annullaSezione={annullaSezione}
                        vediPagina={() => pagina ? vediPagina() : null}
                        loading={altro.loading}
                        premiAnnulla={() => premiAnnulla()}
                        confermaModifiche={async() => await confermaModifiche()}
                        cambiaPagina={() => setPagina(null)}
                    ></HeaderCms>

                    {!sezioneSel && !pagina &&
                        <RicercaCms
                            elementi={elementi}
                            pagina={pagina}
                            cliccaElemento={cliccaElemento}
                        ></RicercaCms>
                    }

                    {sezioneSel && 
                        <GestisciComponenti
                            componenti={sezioneSel.componenti}
                            cambiaLink={cambiaLink}
                            cambiaTitolo={cambiaTitolo}
                            cambiaImmagine={cambiaImmagine}
                            cambiaTesto={cambiaTesto}
                            cambiaTitoloImmagine={cambiaTitoloImmagine}
                            cambiaBanner={cambiaBanner}
                        ></GestisciComponenti>
                    }

                    {sezioneSel && altro.modifiche &&
                        <BottoniScheda
                            loading={altro.loading}
                            conferma={async() => await confermaModifiche()}
                            annulla={() => premiAnnulla()}
                        ></BottoniScheda>
                    }
                </div>
            </div>

            <ModalAnnullaCms
                visible={altro.visible}
                loading={altro.loading}
                annulla={() => setAltro({...altro, visible:false})}
                conferma={confermaModal}
            ></ModalAnnullaCms>

            <Snackbar open={altro.errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{altro.errore}</Alert>
            </Snackbar>
            <Snackbar open={altro.openAlert == 1} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Pagina creata con successo!</Alert>
            </Snackbar>
            <Snackbar open={altro.openAlert == 2} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Pagina modificata con successo!</Alert>
            </Snackbar>
            <Snackbar open={altro.openAlert == 3} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Sezione creata con successo!</Alert>
            </Snackbar>
            <Snackbar open={altro.openAlert == 4} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Sezione modificata con successo!</Alert>
            </Snackbar>
            <Snackbar open={altro.openAlert == 5} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Sezione modificata con successo!</Alert>
            </Snackbar>
            <Snackbar open={altro.openAlert == 6} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Pagina eliminata con successo!</Alert>
            </Snackbar>
            <Snackbar open={altro.openAlert == 7} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Sezione eliminata con successo!</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default HomeCms;