import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import FormBanner from '../components/form/FormBanner';
import HeaderScheda from '../components/scheda/HeaderScheda';
import ContenutoScheda from '../components/scheda/ContenutoScheda';
import creazione from '../api/creazione';
import BottoniScheda from '../components/scheda/BottoniScheda';
import useGenerale from '../controllers/generale/useGenerale';
import validators from '../utils/validators';
import defaultValues from '../utils/defaultValues';
import useSchedaCopiata from '../controllers/schedaCopiata/useSchedaCopiata';
import utils from '../utils/utils';
import SceltaLogo from '../components/scheda/SceltaLogo';
import upload from '../api/upload';

function CreaBunner(props) {

    const navigate = useNavigate();
    const {addBanner, importanzaMaxBanner} = useGenerale();
    const {schedaCopiata, popolaSchedaCopiata} = useSchedaCopiata();

    const [openAlert, setOpenAlert] = useState(0);
    const [situazione, setSituazione] = useState(1);
    const [lingua, setLingua] = useState(1); //1 italiano, ...
    const [loading, setLoading] = useState(false);
    const [errore, setErrore] = useState();
    const [titolo, setTitolo] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [logo, setLogo] = useState({preview: null, file: null})

    const formik = useFormik({
        validationSchema: validators.banner,
        initialValues: defaultValues.banner,
        onSubmit: async(v) => {
            if(!titolo.italiano?.length) return setErrore("Inserire il nome in Italiano.");
            setLoading(true);
            let values = {...v}
            let results;
            //aggiungo importanza
            values.importanza = importanzaMaxBanner()
            //aggiungo i titoli
            values.nome_italiano = titolo.italiano;
            values.nome_inglese = titolo.inglese;
            values.nome_tedesco = titolo.tedesco;
            values.nome_olandese = titolo.olandese;
            values.nome_danese = titolo.danese;
            //creo il logo
            if(logo.file) {
                let formData = new FormData();
                formData.append('immagine', logo.file, logo.file.name);
                results = await upload.creaImmagine(formData);
                if(!results.ok) {
                    setLoading(false)
                    return setErrore(results.data)
                }
                values.immagine = results.data.link;
            } else values.immagine = null;
            //creo l'oggetto
            results = await creazione.creaBanner(values);
            if(!results.ok) {
                setErrore(results.data);
                return setLoading(false);
            }
            //aggiungo l'oggetto al context
            addBanner(results.data)
            //manda indietro
            setTimeout(() => {
                setLoading(false);
                navigate("..", {replace: true, state:{nuovoBanner: results.data}});
            }, 500);
        }
    });

    const cambiaTitolo = (event) => {
        const data = event.target.value.trim();
        const appo = {...titolo};
        if(lingua == 1) {
            appo.italiano = data;
            return setTitolo(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setTitolo(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setTitolo(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setTitolo(appo)
        } else {
            appo.danese = data;
            return setTitolo(appo)
        }
    }

    const copiaContenuti = (appoTitolo) => {
        setTitolo(appoTitolo)
        setOpenAlert(4)
    }

    const doIncollaSchedaCopiata = () => {
        //cambio il form
        formik.setValues(utils.incollaElementi(formik.values, schedaCopiata.values))
        //cambio il titolo
        setTitolo(schedaCopiata.titolo);
        //alert success
        setOpenAlert(5)
        popolaSchedaCopiata()
    }

    const caricaLogo = async(event) => {
        const file = event.target.files[0]
        //controllo che sia immagine
        if(file.type.split("/")[0] != "image") return setErrore("Formato del file non valido. Richiesta Immagine.")
        //creo l'immagine
        const preview = URL.createObjectURL(file);
        setLogo({file: file, preview: preview})
    }

    const pulisciLogo = () => setLogo({file: null, preview: null})

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;
        setErrore();
        setOpenAlert(0)
    }

    return (
        <React.Fragment>
            <HeaderScheda
                titolo={"NUOVO BANNER PUBBLICITARIO"}
                elementi={[true, false]}
                situazione={situazione}
                cambiaSituazione={v => setSituazione(v)}
                annulla={() => navigate(-1)}
                nome={titolo.italiano.length > 0 ? titolo.italiano : null}
                schedaCopiata={schedaCopiata}
                doIncollaSchedaCopiata={doIncollaSchedaCopiata}
                loading={loading}
                modifiche={true}
                conferma={formik.handleSubmit}
            ></HeaderScheda>

            {situazione == 1 && <>
                <SceltaLogo
                    logo={logo}
                    caricaLogo={caricaLogo}
                    pulisciLogo={pulisciLogo}
                ></SceltaLogo>
                <FormBanner
                    values={formik.values}
                    errors={formik.errors}
                    touched={formik.touched}
                    handleChange={formik.handleChange}
                    setFieldValue={formik.setFieldValue}
                ></FormBanner>
                <ContenutoScheda
                    lingua={lingua}
                    cambiaLingua={(v) => setLingua(v)}
                    titolo={titolo}
                    cambiaTitolo={cambiaTitolo}
                    contenuto={null}
                    cambiaContenuto={() => null}
                    copiaContenuti={copiaContenuti}
                    soloTitolo={true}
                ></ContenutoScheda>
            </>}
            <BottoniScheda
                loading={loading}
                conferma={formik.handleSubmit}
                annulla={() => navigate(-1)}
            ></BottoniScheda>

            <Snackbar open={openAlert == 4} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Contenuti copiati nelle diverse linue.</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 5} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Scheda Incollata.</Alert>
            </Snackbar>
            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default CreaBunner;