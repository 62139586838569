import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import FormServizio from '../components/form/FormServizio';
import HeaderScheda from '../components/scheda/HeaderScheda';
import ContenutoScheda from '../components/scheda/ContenutoScheda';
import creazione from '../api/creazione';
import BottoniScheda from '../components/scheda/BottoniScheda';
import useServizio from '../controllers/campeggi/useServizi';
import validators from '../utils/validators';
import defaultValues from '../utils/defaultValues';
import useSchedaCopiata from '../controllers/schedaCopiata/useSchedaCopiata';
import SceltaIconaBooking from '../components/scheda/SceltaIconaBooking';
import SceltaLogo from '../components/scheda/SceltaLogo';
import upload from '../api/upload';
import utils from '../utils/utils';

function CreaServizio(props) {

    const navigate = useNavigate();
    const {addServizio, categorieServizi} = useServizio();
    const {schedaCopiata, popolaSchedaCopiata} = useSchedaCopiata();

    const [openAlert, setOpenAlert] = useState(0);
    const [situazione, setSituazione] = useState(1);
    const [lingua, setLingua] = useState(1); //1 italiano, ...
    const [loading, setLoading] = useState(false);
    const [errore, setErrore] = useState();
    const [titolo, setTitolo] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [logo, setLogo] = useState({preview: null, file: null})
    const [iconaBooking, setIconaBooking] = useState('')

    const formik = useFormik({
        validationSchema: validators.servizio,
        initialValues: defaultValues.servizio,
        onSubmit: async(v) => {
            if(!titolo.italiano?.length) return setErrore("Inserire il nome in Italiano.");
            setLoading(true);
            let values = {...v}
            let results;
            //aggiungo i titoli
            values.nome_italiano = titolo.italiano;
            values.nome_inglese = titolo.inglese;
            values.nome_tedesco = titolo.tedesco;
            values.nome_olandese = titolo.olandese;
            values.nome_danese = titolo.danese;
            //modifico la categoria
            values.categoria = values.categoria ? parseInt(values.categoria) : null;
            //creo la icona booking
            values.icona_booking = null
            if(iconaBooking.length > 0) values.icona_booking = iconaBooking
            //creo il logo
            if(logo.file) {
                let formData = new FormData();
                formData.append('immagine', logo.file, logo.file.name);
                results = await upload.creaImmagine(formData);
                if(!results.ok) {
                    setLoading(false)
                    return setErrore(results.data)
                }
                values.icona = results.data.link;
            }
            //creo l'oggetto
            results = await creazione.creaServizio(values);
            if(!results.ok) {
                setErrore(results.data);
                return setLoading(false);
            }
            //aggiungo l'oggetto al context
            addServizio(results.data)
            //manda indietro
            setTimeout(() => {
                setLoading(false);
                navigate("..", {replace: true, state:{nuovoServizio: results.data}});
            }, 500);
        }
    });

    const cambiaTitolo = (event) => {
        const data = event.target.value.trim();
        const appo = {...titolo};
        if(lingua == 1) {
            appo.italiano = data;
            return setTitolo(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setTitolo(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setTitolo(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setTitolo(appo)
        } else {
            appo.danese = data;
            return setTitolo(appo)
        }
    }

    const copiaContenuti = (appoTitolo) => {
        setTitolo(appoTitolo)
        setOpenAlert(4)
    }

    const doIncollaSchedaCopiata = () => {
        //cambio il form
        const values = utils.incollaElementi(formik.values, schedaCopiata.values);
        let trovata = false;
        for(let i = 0; i < categorieServizi.length; i++) {
            if(categorieServizi[i].id == values.categoria) trovata = true;
        }
        if(!trovata) values.categoria = 0;
        formik.setValues(values)
        //cambio il titolo
        setTitolo(schedaCopiata.titolo);
        //alert success
        setOpenAlert(5)
        popolaSchedaCopiata()
    }

    const caricaLogo = async(event) => {
        const file = event.target.files[0]
        //controllo che sia immagine
        if(file.type.split("/")[0] != "image") return setErrore("Formato del file non valido. Richiesta Immagine.")
        //creo l'immagine
        const preview = URL.createObjectURL(file);
        setLogo({file: file, preview: preview})
    }

    const pulisciLogo = () => setLogo({file: null, preview: null})

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;
        setErrore();
        setOpenAlert(0)
    }

    return (
        <React.Fragment>
            <HeaderScheda
                titolo={"NUOVO SERVIZIO"}
                elementi={[true, false, false, false, false, false]}
                situazione={situazione}
                cambiaSituazione={v => setSituazione(v)}
                annulla={() => navigate(-1)}
                nome={titolo.italiano.length > 0 ? titolo.italiano : null}
                schedaCopiata={schedaCopiata}
                doIncollaSchedaCopiata={doIncollaSchedaCopiata}
                loading={loading}
                modifiche={true}
                conferma={formik.handleSubmit}
            ></HeaderScheda>

            {situazione == 1 && <>
                <FormServizio
                    values={formik.values}
                    errors={formik.errors}
                    touched={formik.touched}
                    handleChange={formik.handleChange}
                    setFieldValue={formik.setFieldValue}
                    categorieServizi={categorieServizi}
                ></FormServizio>
                <ContenutoScheda
                    lingua={lingua}
                    cambiaLingua={(v) => setLingua(v)}
                    titolo={titolo}
                    cambiaTitolo={cambiaTitolo}
                    contenuto={null}
                    cambiaContenuto={() => null}
                    copiaContenuti={copiaContenuti}
                    soloTitolo={true}
                ></ContenutoScheda>
                {/* <SceltaLogo
                    logo={logo}
                    caricaLogo={caricaLogo}
                    pulisciLogo={pulisciLogo}
                    isIcona={true}
                ></SceltaLogo> */}
                <SceltaIconaBooking
                    iconaBooking={iconaBooking}
                    cambiaIconaBooking={(e) => setIconaBooking(e.target.value)}
                ></SceltaIconaBooking>
            </>}
            <BottoniScheda
                loading={loading}
                conferma={formik.handleSubmit}
                annulla={() => navigate(-1)}
            ></BottoniScheda>

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 5} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Scheda Incollata.</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 4} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Contenuti copiati nelle diverse linue.</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default CreaServizio;