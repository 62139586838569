import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import upload from '../../api/upload'
import mediaApi from '../../api/media'
import useMediaKit from '../../controllers/mediaKit/useMediaKit'
import BottoniScheda from '../../components/scheda/BottoniScheda'
import FormImmagineMedia from '../../components/mediaKit/FormImmagineMedia'

function ImmagineMediaKit(props) {

    const {state} = useLocation()
    const navigate = useNavigate()

    const {addMedia, aggMedia, categorie, immagini} = useMediaKit()

    const [errore, setErrore] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadImg, setLoadImg] = useState(false)
    const [datiImmagine, setDatiImmagine] = useState(null)
    const [isModifiche, setIsModifiche] = useState(false)

    useEffect(() => {
        const aggImg = () => {
            if(state?.immagine) return setDatiImmagine({
                ...state?.immagine, 
                categoria: state?.immagine?.categoria ? state.immagine.categoria : 0
            })
            setDatiImmagine({
                id:0, categoria:0, nome_italiano:'', nome_inglese:'',
                nome_tedesco:'', nome_olandese:'', nome_danese:'', link:''
            })
        }
        aggImg()
    }, [state])

    const scriviTitolo = () => {
        if(!datiImmagine) return 'MEDIA KIT'
        if(datiImmagine.id) return 'MEDIA KIT: MODIFICA IMMAGINE'
        return 'MEDIA KIT: NUOVA IMMAGINE'
    }

    const cambiaNomeImmagine = (lingua, valore) => {
        const appo = {...datiImmagine}
        if(lingua == 1) appo.nome_italiano = valore
        else if(lingua == 2) appo.nome_inglese = valore
        else if(lingua == 3) appo.nome_tedesco = valore
        else if(lingua == 4) appo.nome_olandese = valore
        else if(lingua == 5) appo.nome_danese = valore
        setDatiImmagine(appo)
        if(!isModifiche) setIsModifiche(true)
    }

    const cambiaCategoriaImmagine = (valore) => {
        const appo = {...datiImmagine}
        appo.categoria = valore
        setDatiImmagine(appo)
        if(!isModifiche) setIsModifiche(true)
    }

    const conferma = async() => {
        //controllo valori
        if(!datiImmagine.link?.length) return setErrore("E' necessario fare l'upload dell'immagine")
        if(!datiImmagine.categoria) return setErrore("Inserire la categoria dell'immagine")
        if(!datiImmagine.nome_italiano?.length) return setErrore('Inserire nome italiano')
        if(!datiImmagine.nome_inglese?.length) return setErrore('Inserire nome inglese')
        if(!datiImmagine.nome_tedesco?.length) return setErrore('Inserire nome tedesco')
        if(!datiImmagine.nome_olandese?.length) return setErrore('Inserire nome olandese')
        if(!datiImmagine.nome_danese?.length) return setErrore('Inserire nome danese')
        //proseguo con le api
        let results = null, filtro = null
        setLoading(true)
        if(datiImmagine.id) { //modifica
            results = await mediaApi.modificaMedia({...datiImmagine})
            if(!results.ok) {
                setLoading(false)
                return setErrore(results.data)
            }
            return setTimeout(() => {
                aggMedia(results.data)
                setLoading(false)
                setErrore(null)
                filtro = state?.filtro ? state.filtro : 0
                navigate("..", {replace: true, state:{isModificaImg:true, filtro:filtro}});
            }, 700);
        }
        //creazione
        results = await mediaApi.creaMediaImmagine({
            nome_italiano:datiImmagine.nome_italiano, nome_inglese:datiImmagine.nome_inglese, 
            nome_tedesco:datiImmagine.nome_tedesco, nome_olandese:datiImmagine.nome_olandese, 
            nome_danese:datiImmagine.nome_danese, categoria:datiImmagine.categoria, link:datiImmagine.link,
            importanza:calcolaImportanzaMax()
        })
        if(!results.ok) {
            setLoading(false)
            return setErrore(results.data)
        }
        setTimeout(() => {
            addMedia(results.data)
            setLoading(false)
            setErrore(null)
            filtro = state?.filtro ? state.filtro : 0
            navigate("..", {replace: true, state:{isNuovaImg:true, filtro:filtro}});
        }, 700);
    }

    const calcolaImportanzaMax = () => {
        let importanza = 0;
        for(let i = 0; i < immagini.length; i++) {
            if(immagini[i].importanza >= importanza) importanza = immagini[i].importanza + 1;
        }
        return importanza
    }

    const creaImmagine = async(file) => {
        setLoadImg(true)
        //preparo il body
        let formData = new FormData();
        formData.append('immagine', file, file.name);
        //chiama api per creare immagini
        const results = await upload.creaImmagineMediaKit(formData);
        if(!results.ok) return setErrore(results.data)
        //modifico il link
        const newLink = results.data
        const appo = {...datiImmagine}
        appo.link = newLink
        setDatiImmagine(appo)
        if(!isModifiche) setIsModifiche(true)
        setTimeout(() => {
            setLoadImg(false)
        }, 200);
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setErrore(null);
    }

    return (
        <React.Fragment>
            <div className="admin-container">
                <h2 className="admin-titoletto">{scriviTitolo()}</h2>
                {!loadImg && <BottoniScheda
                    loading={loading}
                    conferma={conferma}
                    annulla={() => navigate("..", {replace: true, state:{isImgSel:true, filtro:state?.filtro ? state.filtro : 0}})}
                ></BottoniScheda>}
            </div>

            {datiImmagine && <FormImmagineMedia
                categorie={categorie}
                loadImg={loadImg}
                datiImmagine={datiImmagine}
                cambiaNome={cambiaNomeImmagine}
                cambiaCategoria={cambiaCategoriaImmagine}
                creaImmagine={creaImmagine}
                cambiaErrore={setErrore}
            ></FormImmagineMedia>}

            {isModifiche && !loadImg && <BottoniScheda
                loading={loading}
                conferma={conferma}
                annulla={() => navigate("..", {replace: true, state:{isImgSel:true, filtro:state?.filtro ? state.filtro : 0}})}
            ></BottoniScheda>}

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default ImmagineMediaKit;