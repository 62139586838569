import client from "./client";
import config from '../config.json';

const getEsperienze = () => {
    return client.get(config.serverUrl + "esperienze");
}

const getGenerale = () => {
    return client.get(config.serverUrl + "generale");
}

const getOfferte = () => {
    return client.get(config.serverUrl + "offerte");
}

const getCampeggi = () => {
    return client.get(config.serverUrl + "campeggi");
}

const getNews = () => {
    return client.get(config.serverUrl + "news");
}

const getPartners = () => {
    return client.get(config.serverUrl + "partners");
}

const getCms = () => {
    return client.get(config.serverUrl + "cms");
}

const getMediaKit = () => {
    return client.get(config.serverUrl + "media-kit");
}

export default {
    getEsperienze,
    getGenerale,
    getOfferte,
    getCampeggi,
    getNews,
    getPartners,
    getCms,
    getMediaKit
}