import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';

function FormGuida({handleChange, values, errors, touched, setFieldValue, isModifica}) {
    return (
        <React.Fragment>
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <Stack direction="row" spacing={15}>
                <FormControlLabel
                    control={<Checkbox size="medium" color="primary" name="attiva" value="attiva" checked={values.attiva} onChange={handleChange}/>} 
                    label="Attiva"
                />
            </Stack>
            <Stack direction="row" spacing={7} paddingTop={3}>
                <TextField 
                    id="outlined-basic"
                    label="Latitudine"
                    variant="outlined"
                    name="latitudine"
                    onChange={handleChange}
                    value={values.latitudine}
                    error={touched.latitudine && Boolean(errors.latitudine)}
                    helperText={touched.latitudine && errors.latitudine}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
                <TextField 
                    id="outlined-basic"
                    label="Longitudine"
                    variant="outlined"
                    name="longitudine"
                    onChange={handleChange}
                    value={values.longitudine}
                    error={touched.longitudine && Boolean(errors.longitudine)}
                    helperText={touched.longitudine && errors.longitudine}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>
        </div>

        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <FormControlLabel
                control={<Switch size="medium" color="primary" name="is_ecommerce" checked={values.is_ecommerce} onChange={handleChange}/>} 
                label="Ecommerce"
            />
            {values.is_ecommerce == true &&
            <>
                <Stack direction="row" spacing={15} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Italiano"
                        variant="outlined"
                        name="ecommerce_italiano"
                        onChange={handleChange}
                        value={values.ecommerce_italiano}
                        error={touched.ecommerce_italiano && Boolean(errors.ecommerce_italiano)}
                        helperText={touched.ecommerce_italiano && errors.ecommerce_italiano}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={15} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Inglese"
                        variant="outlined"
                        name="ecommerce_inglese"
                        onChange={handleChange}
                        value={values.ecommerce_inglese}
                        error={touched.ecommerce_inglese && Boolean(errors.ecommerce_inglese)}
                        helperText={touched.ecommerce_inglese && errors.ecommerce_inglese}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={15} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Tedesco"
                        variant="outlined"
                        name="ecommerce_tedesco"
                        onChange={handleChange}
                        value={values.ecommerce_tedesco}
                        error={touched.ecommerce_tedesco && Boolean(errors.ecommerce_tedesco)}
                        helperText={touched.ecommerce_tedesco && errors.ecommerce_tedesco}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={15} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Olandese"
                        variant="outlined"
                        name="ecommerce_olandese"
                        onChange={handleChange}
                        value={values.ecommerce_olandese}
                        error={touched.ecommerce_olandese && Boolean(errors.ecommerce_olandese)}
                        helperText={touched.ecommerce_olandese && errors.ecommerce_olandese}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={15} paddingTop={3} paddingBottom={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Danese"
                        variant="outlined"
                        name="ecommerce_danese"
                        onChange={handleChange}
                        value={values.ecommerce_danese}
                        error={touched.ecommerce_danese && Boolean(errors.ecommerce_danese)}
                        helperText={touched.ecommerce_danese && errors.ecommerce_danese}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
            </>
            }
        </div>
        </React.Fragment>
    );
}

export default FormGuida;