import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

function FormServizio({handleChange, values, errors, touched, isModifica, categorieServizi}) {

    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <Stack direction="row" spacing={15} alignItems="center">
                <h3 className="admin-titoletto">SELEZIONA CATEGORIA</h3>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Categoria</InputLabel>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        name="categoria"
                        value={values.categoria}
                        onChange={handleChange}
                        autoWidth
                        size="small"
                        label="Categoria"
                    >
                        <MenuItem value={0}><em>Nessuna</em></MenuItem>
                        {categorieServizi.map((cat, index) => (
                            <MenuItem value={cat.id} key={index}>{cat.nome_italiano}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
        </div>
    );
}

export default FormServizio;