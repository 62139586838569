import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import BottoniScheda from '../../components/scheda/BottoniScheda';
import ModalEliminaCms from '../../components/cms/ModalEliminaCms';
import useCms from '../../controllers/cms/useCms';
import cmsApi from '../../api/cms';
import NuovoComponente from '../../components/cms/NuovoComponente';
import useAuth from '../../controllers/auth/useAuth';

function GestisciSezione(props) {

    const {state, pathname} = useLocation();
    const navigate = useNavigate();
    const {creaElemento, modificaElemento, togliElemento} = useCms()
    const {utente} = useAuth();

    const [nome, setNome] = useState('')
    const [id, setId] = useState(0)
    const [listaC, setListaC] = useState([])
    const [eliminati, setEliminati] = useState([])
    const [altro, setAltro] = useState({loading:false, errore:null, visible:false})

    useEffect(() => aggStato(), [state])

    const aggStato = () => {
        if(!state) return premiAnnulla()
        if(!state.idPagina) return premiAnnulla()
        if(state.sezione) {
            setNome(state.sezione.nome)
            setId(state.sezione.id)
            setEliminati([])
            const appo = []
            for(let i = 0; i < state.sezione.componenti.length; i++) {
                appo.push({
                    id: state.sezione.componenti[i].id,
                    nome: state.sezione.componenti[i].nome,
                    tipo: state.sezione.componenti[i].tipo,
                    sottotipo: state.sezione.componenti[i].sottotipo,
                    is_titolo: state.sezione.componenti[i].is_titolo
                })
            }
            setListaC(appo)
        } else {
            setNome('')
            setId(0)
            setListaC([])
            setEliminati([])
        }
    }

    const addComponente = () => {
        const appo = Array.from(listaC);
        appo.push({nome:'', tipo:1, sottotipo:0, is_titolo:false})
        setListaC(appo)
    }

    const cambiaNome = (pos, value) => {
        const appo = Array.from(listaC)
        appo[pos].nome = value;
        setListaC(appo)
    }

    const cambiaTipo = (pos, value) => {
        const appo = Array.from(listaC)
        appo[pos].tipo = value;
        setListaC(appo)
    }

    const cambiaSottoTipo = (pos, value) => {
        const appo = Array.from(listaC)
        appo[pos].sottotipo = value;
        setListaC(appo)
    }

    const cambiaIsTitolo = (pos) => {
        const appo = Array.from(listaC)
        appo[pos].is_titolo = !appo[pos].is_titolo;
        setListaC(appo)
    }

    const eliminaComponente = (pos) => {
        const c = listaC[pos]
        if(c.id) {
            const appo = Array.from(eliminati)
            appo.push(c.id)
            setEliminati(appo)
        }
        const vett = listaC.filter((c, index) => index != pos)
        setListaC(vett)
    }

    const conferma = async() => {
        //controllo iniziale
        if(!nome.length) return setAltro({...altro, errore:"Il nome è obbligatorio."})
        if(!listaC.length) return setAltro({...altro, errore:"Inserire almeno un componente della sezione."})
        for(let i = 0; i < listaC.length; i++) {
            if(!listaC[i].nome.length) return setAltro({...altro, errore:"Inserire il nome a tutti i componenti."})
        }
        if(altro.loading) return;
        setAltro({...altro, loading:true, errore:null})
        //api
        let results;
        if(!id) results = await cmsApi.creaElemento({nome:nome, pagina:state?.idPagina}, listaC)
        else results = await cmsApi.modificaElemento({id:id, nome:nome, pagina:state?.idPagina}, listaC, eliminati)
        if(!results.ok) return setAltro({...altro, loading:false, errore:results.data})
        //agg context e pagina
        setTimeout(() => {
            setAltro({...altro, loading:false, errore:null})
            if(!id) {
                creaElemento(results.data)
                navigate('..', {replace:true, state:{sezioneCreata: results.data}})
            } else {
                modificaElemento(results.data)
                navigate('..', {replace:true, state:{sezioneModificata: results.data}})
            }
        }, 500);
    }

    const chiediEliminaPagina = () => setAltro({...altro, visible:true})

    const eliminaSezione = async() => {
        if(altro.loading) return;
        setAltro({...altro, loading:true, errore:null})
        const results = await cmsApi.eliminaElemento(id);
        if(!results.ok) return setAltro({...altro, loading:false, errore:results.data})
        setTimeout(() => {
            setAltro({...altro, loading:false, errore:null, visible:false})
            togliElemento(id)
            navigate('..', {replace:true, state:{sezioneEliminata: id}})
        }, 500);
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setAltro({...altro, loading:false, errore:null})
    }

    const premiAnnulla = () => {
        if(id) {
            navigate('..', {replace:true, state:{annullaSezione:true, idSezione:id}})
        } else {
            navigate('..', {replace:true, state:{annullaSezione:true, idPagina:state.idPagina}})
        }
    }

    return (
        <React.Fragment>
            <div className={"admin-container-cms"}>

                <Box sx={{paddingBottom:2, display:'flex', flexDirection:'row'}}>
                    {Boolean(!id) && <h3 className="admin-titoletto">CMS: Crea Nuova Sezione</h3>}
                    {Boolean(id) && <h3 className="admin-titoletto">CMS: Modifica Sezione</h3>}
                    {Boolean(id) && <LoadingButton
                        loading={altro.loading}
                        loadingPosition="start"
                        size="small"
                        variant={"contained"}
                        color={"error"}
                        onClick={chiediEliminaPagina}
                        type="submit"
                        startIcon={<HighlightOffIcon />}
                        sx={{marginLeft:3}}
                    >ELIMINA SEZIONE</LoadingButton>}
                </Box>

                <Stack direction="row" spacing={15} paddingTop={1} paddingBottom={1} width={"40%"}>
                    <TextField 
                        id="outlined-basic"
                        label="Nome della Sezione"
                        variant="outlined"
                        name="sezione"
                        onChange={(event) => setNome(event.target.value)}
                        value={nome}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={3} paddingTop={1}>
                    <LoadingButton
                        loading={altro.loading}
                        loadingPosition="start"
                        size="small"
                        variant={"contained"}
                        color={"success"}
                        onClick={conferma}
                        type="submit"
                        startIcon={<CheckIcon />}
                    >CONFERMA</LoadingButton>
                    <Button
                        size="small"
                        variant={"contained"}
                        color={"error"}
                        onClick={premiAnnulla}
                        startIcon={<HighlightOffIcon />}
                    >ANNULLA</Button>
                </Stack>
            </div>

            <div className={"admin-container-cms"}>
                <h3 className="admin-titoletto">Lista di Componenti</h3>
                {utente?.is_super && <Stack direction="row" spacing={15} paddingTop={2} paddingBottom={1}>
                    <Button
                        size="small"
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => addComponente()}
                        startIcon={<AddCircleOutlineIcon />}
                    >NUOVO COMPONENTE</Button>
                </Stack>}

                {listaC.length > 0 && <Box sx={{paddingTop: 2}}>
                    {listaC.map((comp, index) => (
                        <NuovoComponente
                            key={index}
                            index={index}
                            componente={comp}
                            cambiaNome={cambiaNome}
                            cambiaTipo={cambiaTipo}
                            cambiaSottoTipo={cambiaSottoTipo}
                            eliminaComponente={eliminaComponente}
                            cambiaIsTitolo={cambiaIsTitolo}
                            isSuper={utente?.is_super}
                        ></NuovoComponente>
                    ))}
                </Box>}
            </div>

            <BottoniScheda
                loading={altro.loading}
                conferma={async() => await conferma()}
                annulla={premiAnnulla}
            ></BottoniScheda>

            <ModalEliminaCms
                cosa={2}
                visible={altro.visible}
                loading={altro.loading}
                annulla={() => setAltro({...altro, visible:false})}
                conferma={eliminaSezione}
            ></ModalEliminaCms>

            <Snackbar open={altro.errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{altro.errore}</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default GestisciSezione;