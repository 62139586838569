import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

function BottoniMedia({situazione, cambiaSituazione}) {
    return (
        <Stack direction="row" spacing={2} marginTop={2}>
            <Button
                size="medium"
                variant={"outlined"}
                color={situazione == 1 ? "primary" : "chiaro"}
                onClick={() => cambiaSituazione(1)}
            >CATEGORIE</Button>
            <Button
                size="medium"
                variant={"outlined"}
                color={situazione == 2 ? "primary" : "chiaro"}
                onClick={() => cambiaSituazione(2)}
            >IMMAGINI</Button>
            <Button
                size="medium"
                variant={"outlined"}
                color={situazione == 3 ? "primary" : "chiaro"}
                onClick={() => cambiaSituazione(3)}
            >VIDEO</Button>
        </Stack>
    );
}

export default BottoniMedia;