import React from 'react';
import ReactPlayer from 'react-player'
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Typography from '@mui/material/Typography';

import Italiana from '../../images/it.png';
import Inglese from '../../images/uk.png';
import Tedesco from '../../images/de.png';
import Olandese from '../../images/ne.png';
import Danese from '../../images/dk.png';

function ModalEliminaMedia({visible, tipo, elemento, conferma, annulla, loading}) {

    const scriviTitolo = () => {
        if(tipo == 1) return 'Sei sicuro di voler eliminare questa Categoria?'
        if(tipo == 2) return 'Sei sicuro di voler eliminare questa Immagine?'
        return 'Sei sicuro di voler eliminare questo Video?'
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={Boolean(visible)}
            onClose={annulla}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500}}
            keepMounted
        >
            <Fade in={Boolean(visible)}>
                <Box sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper', border: '2px solid #F9B433', boxShadow: 24, borderRadius:4,
                    paddingTop: 6, paddingBottom: 6, display:'flex', flexDirection:'column', textAlign:'center', 
                    paddingLeft:{xs:3, sm: 6}, paddingRight:{xs:3, sm: 6}, minWidth:600
                }}>
                    <Typography sx={{fontSize:20, fontWeight:600, color:'black'}}>{scriviTitolo()}</Typography>
                    {tipo == 1 &&
                        <Box sx={{marginBottom:2}}>
                            <Typography sx={{fontSize:16, fontWeight:400, color:'black'}}>
                                Tutte le immagini e i video di questa categoria rimarranno senza categoria.    
                            </Typography>
                            <Grid container spacing={2} sx={{width:'98%', marginLeft:'1%', marginTop:4}}>
                                <Grid item md={6} sx={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                    <img src={Italiana} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5}}>{elemento?.nome_italiano}</Typography>
                                </Grid>
                                <Grid item md={6} sx={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                    <img src={Inglese} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5}}>{elemento?.nome_inglese}</Typography>
                                </Grid>
                                <Grid item md={6} sx={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                    <img src={Tedesco} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5}}>{elemento?.nome_tedesco}</Typography>
                                </Grid>
                                <Grid item md={6} sx={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                    <img src={Olandese} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5}}>{elemento?.nome_olandese}</Typography>
                                </Grid>
                                <Grid item md={6} sx={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
                                    <img src={Danese} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5}}>{elemento?.nome_danese}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    }

                    {tipo == 2 &&
                        <Box sx={{marginTop:4, marginBottom:3, width:'100%', display:'flex', flexDirection:'row'}}>
                            <Stack direction='column' spacing={2} sx={{width:'50%'}}>
                                <Box sx={{display:'flex', flexDirection:'row'}}>
                                    <img src={Italiana} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5, textAlign:'left', lineHeight:'1.1'}}>{elemento?.nome_italiano}</Typography>
                                </Box>
                                <Box sx={{display:'flex', flexDirection:'row'}}>
                                    <img src={Inglese} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5, textAlign:'left', lineHeight:'1.1'}}>{elemento?.nome_inglese}</Typography>
                                </Box>
                                <Box sx={{display:'flex', flexDirection:'row'}}>
                                    <img src={Tedesco} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5, textAlign:'left', lineHeight:'1.1'}}>{elemento?.nome_tedesco}</Typography>
                                </Box>
                                <Box sx={{display:'flex', flexDirection:'row'}}>
                                    <img src={Olandese} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5, textAlign:'left', lineHeight:'1.1'}}>{elemento?.nome_olandese}</Typography>
                                </Box>
                                <Box sx={{display:'flex', flexDirection:'row'}}>
                                    <img src={Danese} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5, textAlign:'left', lineHeight:'1.1'}}>{elemento?.nome_danese}</Typography>
                                </Box>
                            </Stack>
                            <Box sx={{width:'50%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <Box component='img' alt='' src={elemento.link} sx={{width:'300px', height:'auto'}} />
                            </Box>
                        </Box>
                    }

                    {tipo == 3 &&
                        <Box sx={{marginTop:4, marginBottom:3, width:'100%', display:'flex', flexDirection:'row'}}>
                            <Stack direction='column' spacing={2} sx={{width:'50%'}}>
                                <Box sx={{display:'flex', flexDirection:'row'}}>
                                    <img src={Italiana} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5, textAlign:'left', lineHeight:'1.1'}}>{elemento?.nome_italiano}</Typography>
                                </Box>
                                <Box sx={{display:'flex', flexDirection:'row'}}>
                                    <img src={Inglese} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5, textAlign:'left', lineHeight:'1.1'}}>{elemento?.nome_inglese}</Typography>
                                </Box>
                                <Box sx={{display:'flex', flexDirection:'row'}}>
                                    <img src={Tedesco} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5, textAlign:'left', lineHeight:'1.1'}}>{elemento?.nome_tedesco}</Typography>
                                </Box>
                                <Box sx={{display:'flex', flexDirection:'row'}}>
                                    <img src={Olandese} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5, textAlign:'left', lineHeight:'1.1'}}>{elemento?.nome_olandese}</Typography>
                                </Box>
                                <Box sx={{display:'flex', flexDirection:'row'}}>
                                    <img src={Danese} className="admin-bandiera-cms"></img>    
                                    <Typography sx={{fontSize:16, fontWeight:'bold', marginLeft:1.5, textAlign:'left', lineHeight:'1.1'}}>{elemento?.nome_danese}</Typography>
                                </Box>
                            </Stack>
                            <Box sx={{width:'50%', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                <Box sx={{width:'100%', height:'160px', borderTopLeftRadius:'16px', borderTopRightRadius:'16px', position:'relative'}}>
                                    <ReactPlayer
                                        onPlay={() => null}
                                        muted={true}
                                        playing={false}
                                        url={elemento.link}
                                        width='100%'
                                        height='100%'
                                        light={true}
                                        playIcon={<div style={{display:'none'}} />}
                                    />
                                    <Box
                                        onClick={(e) => e.stopPropagation()}
                                        sx={{position:'absolute', top:0, width:'100%', height:'100%', zIndex:100}} 
                                    />
                                </Box>
                            </Box>
                        </Box>
                    }

                    <Stack sx={{display:'flex', width:'100%'}} spacing={3} direction='row' marginTop={3} alignItems='center' justifyContent='center'>
                        <LoadingButton
                            loading={loading}
                            size="medium"
                            variant="contained"
                            color="success"
                            startIcon={<CheckIcon />}
                            onClick={async() => await conferma()}
                        >CONFERMA</LoadingButton>
                        <Button
                            size="medium"
                            variant="contained"
                            color="error"
                            startIcon={<CancelOutlinedIcon />}
                            onClick={annulla}
                        >ANNULLA</Button>
                    </Stack>
                </Box>
            </Fade>
        </Modal>
    );
}

export default ModalEliminaMedia;