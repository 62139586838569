import client from "./client";
import config from '../config.json';

const endpoint = 'admin/carica/'

const creaImmagine = (body) => {
    return client.post(config.serverUrl + endpoint + "immagine", body, {headers: {'Content-Type': 'multipart/form-data'}});
}

const creaImmagineCms = (body) => {
    return client.post(config.serverUrl + endpoint + "immagine/cms", body, {headers: {'Content-Type': 'multipart/form-data'}});
}

const creaImmagineMediaKit = (body) => {
    return client.post(config.serverUrl + endpoint + "immagine/media-kit", body, {headers: {'Content-Type': 'multipart/form-data'}});
}

const modificaImmagine = (body) => {
    return client.put(config.serverUrl + endpoint + "immagine", body);
}

const eliminaImmagine = (id) => {
    return client.delete(config.serverUrl + endpoint + "immagine/" + id);
}

const creaAllegato = (body) => {
    return client.post(config.serverUrl + endpoint + "allegato", body, {headers: {'Content-Type': 'multipart/form-data'}});
}

const creaAllegatoConNome = (body) => {
    return client.post(config.serverUrl + endpoint + "allegatoConNome", body, {headers: {'Content-Type': 'multipart/form-data'}});
}

const modificaAllegato = (body) => {
    return client.put(config.serverUrl + endpoint + "allegato", body);
}

const eliminaAllegato = (id) => {
    return client.delete(config.serverUrl + endpoint + "allegato/" + id);
}

const aggiornaImportanzaImg = (listaIdImmagini) => {
    return client.post(config.serverUrl + endpoint + "immagini/importanza", {listaIdImmagini});
}

export default {
    creaImmagine,
    modificaImmagine,
    eliminaImmagine,
    creaAllegato,
    modificaAllegato,
    eliminaAllegato,
    creaAllegatoConNome,
    aggiornaImportanzaImg,
    creaImmagineCms,
    creaImmagineMediaKit
}