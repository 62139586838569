import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import Italiana from '../../images/it.png';
import Inglese from '../../images/uk.png';
import Tedesco from '../../images/de.png';
import Olandese from '../../images/ne.png';
import Danese from '../../images/dk.png';

function FormImmagineMedia({
    datiImmagine, cambiaNome, categorie, cambiaCategoria, creaImmagine, cambiaErrore, loadImg
}) {

    const [lingua, setLingua] = useState(1); //1 italiano, ...

    const linkImmagine = datiImmagine.link
    const isLink = () => Boolean(linkImmagine?.length)

    const caricaImmagine = async(event) => {
        const file = event.target.files[0]
        if(file.type.split("/")[0] != "image") return cambiaErrore("Formato del file non valido. Richiesta Immagine.")
        await creaImmagine(file);
    }

    return (
        <React.Fragment>
            <div className="admin-container">
                <Box sx={{display:'flex', flexDirection:'row'}}>
                    <Box sx={{width:'40%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                        <h3 className="admin-titoletto">Immagine</h3>
                        <Stack paddingTop={1}>
                            <TextField 
                                id="raised-button-file"
                                label="Immagine"
                                variant="outlined"
                                value=""
                                onChange={async(event) => await caricaImmagine(event)}
                                type="file"
                                size={"small"}
                                fullWidth
                                autoComplete="off"
                                style={{ display: 'none' }}
                            />
                            <label className="admin-linea-bottone-immagine" htmlFor="raised-button-file">
                                <LoadingButton loading={loadImg} variant="contained" color="primary" component="span" startIcon={<FileUploadIcon />}>Upload</LoadingButton>
                            </label>
                        </Stack>
                    </Box>
                    <Box sx={{width:'60%', display:'flex'}}>
                        {!isLink() && <Box sx={{height:'100%', display:'flex', alignItems:'center'}}>
                            <Typography sx={{fontSize:16, fontWeight:'bold', color:'#C62828'}}>Immagine non inserita</Typography>
                        </Box>}
                        {isLink() &&
                            <Box component='img' alt='' src={linkImmagine} sx={{
                                width:'350px', height:'auto'
                            }} />
                        }
                    </Box>
                </Box>
            </div>

            <Box className='admin-container'>
                <Box sx={{
                    borderRadius:'10px', border:'none', padding:0, marginBottom:2, backgroundColor:'white'
                }}>
                    <Typography sx={{fontSize:20, fontWeight:'bold'}}>Seleziona la lingua</Typography>
                    <Stack direction="row" spacing={2} paddingTop={1} paddingBottom={1}>
                        <FormControlLabel value={1} control={<Radio checked={lingua === 1} onChange={() => setLingua(1)} />} label="Italiano" />
                        <FormControlLabel value={2} control={<Radio checked={lingua === 2} onChange={() => setLingua(2)} />} label="Inglese" />
                        <FormControlLabel value={3} control={<Radio checked={lingua === 3} onChange={() => setLingua(3)} />} label="Tedesco" />
                        <FormControlLabel value={4} control={<Radio checked={lingua === 4} onChange={() => setLingua(4)} />} label="Olandese" />
                        <FormControlLabel value={5} control={<Radio checked={lingua === 5} onChange={() => setLingua(5)} />} label="Danese" />
                    </Stack>
                </Box>

                <Box sx={{p:1, pt:2, pl:0}}>
                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                        <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <img src={lingua == 1 ? Italiana : (lingua == 2 ? Inglese : (lingua == 3 ? Tedesco : (lingua == 4 ? Olandese : Danese)))} className="admin-bandiera-cms"></img>    
                            <Typography sx={{fontSize:18, fontWeight:'bold', marginLeft:1.5}}>Nome dell'immagine</Typography>
                        </Box>
                    </Box>
                    <Stack direction="row" spacing={0} paddingBottom={1} paddingTop={2}>
                        <TextField 
                            id="outlined-basic"
                            variant="outlined"
                            onChange={(event) => cambiaNome(lingua, event.target.value)}
                            value={lingua == 1 ? datiImmagine.nome_italiano : (lingua == 2 ? datiImmagine.nome_inglese : (lingua == 3 ? datiImmagine.nome_tedesco : (lingua == 4 ? datiImmagine.nome_olandese : datiImmagine.nome_danese)))}
                            size={"small"}
                            fullWidth
                            autoComplete="off"
                        />
                    </Stack>
                </Box>
            </Box>
            
            <Box className='admin-container'>
                <Box sx={{
                    borderRadius:'10px', border:'none', padding:0, marginBottom:2, backgroundColor:'white'
                }}>
                    <Typography sx={{fontSize:20, fontWeight:'bold'}}>Seleziona la categoria</Typography>
                </Box>
                <Stack direction="row" paddingTop={1}>
                    <FormControl sx={{ minWidth: 150, maxWidth: 300 }}>
                        <InputLabel id="demo-simple-select-autowidth-label-555">Categoria</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label-555"
                            id="demo-simple-select-autowidth-555"
                            name="categoria"
                            value={datiImmagine.categoria}
                            onChange={(event) => cambiaCategoria(event.target.value)}
                            autoWidth
                            label="Categoria"
                            size="small"
                        >
                            <MenuItem value={0}><em>Nessuna</em></MenuItem>
                            {categorie.map((cat, index) => (
                                <MenuItem value={cat.id} key={index}>{cat.nome_italiano}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </Box>
        </React.Fragment>
    );
}

export default FormImmagineMedia;