import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable  } from 'react-beautiful-dnd';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';

import ContenutoScheda from './ContenutoScheda';
import BottoniScheda from './BottoniScheda';

function SceltaImmagini({selezionata, cambiaSelezionata, immagini, creaImmagine, eliminaImmagine, aggiornaImmagine, aggiornaImportanzaImmagini, isModifica}) {

    const numero = 5; //quante immagini per riga
    const [lista, setLista] = useState([])
    const [agg, setAgg] = useState(false)
    const [openAlert, setOpenAlert] = useState(0);
    const [errore, setErrore] = useState();
    const [loading, setLoading] = useState(false);
    const [titolo, setTitolo] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [lingua, setLingua] = useState(1); //1 italiano, ...

    useEffect(() => {
        creaListeImmagini()
    }, [agg, immagini])

    const creaListeImmagini = () => {
        const appo = new Array();
        let vett = new Array();
        for(let i = 0; i < immagini.length; i++) {
            if(i > 0 && i % numero == 0) {
                appo.push(vett)
                vett = new Array()
                vett.push(immagini[i])
            } else {
                vett.push(immagini[i])
            }
        }
        if(vett.length > 0) appo.push(vett)
        setLista(appo)
    }

    const cambiaTitolo = (event) => {
        const data = event.target.value.trim();
        const appo = {...titolo};
        if(lingua == 1) {
            appo.italiano = data;
            return setTitolo(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setTitolo(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setTitolo(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setTitolo(appo)
        } else {
            appo.danese = data;
            return setTitolo(appo)
        }
    }

    const caricaImmagine = async(event) => {
        const file = event.target.files[0]
        //controllo che sia immagine
        if(file.type.split("/")[0] != "image") return setErrore("Formato del file non valido. Richiesta Immagine.")
        //creo l'immagine
        let importanza = calcolaImportanzaMax()
        await creaImmagine(file, importanza);
        //aggiorno la lista
        setAgg(!agg)
    }

    const confermaModificaImg = async() => {
        setLoading(true)
        await aggiornaImmagine(titolo);
        setTimeout(() => {
            cambiaSelezionata({img: null, isModifica: false})
            setLoading(false)
            setLingua(1)
            //aggiorno la lista
            setAgg(!agg)
        }, 500);
    }

    const selezionaImmagine = (immagine) => {
        cambiaSelezionata({img: immagine, isModifica: true})
        setTitolo({italiano: immagine.nome_italiano, inglese: immagine.nome_inglese, tedesco: immagine.nome_tedesco, olandese: immagine.nome_olandese, danese: immagine.nome_danese})
    }

    const annullaModificaImg = () => {
        setErrore()
        setLingua(1)
        cambiaSelezionata({img: null, isModifica: false})
    }

    const confermaEliminaImmagine = async(img) => {
        await eliminaImmagine(img)
        //aggiorno la lista
        setAgg(!agg)
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;
        setErrore();
        setOpenAlert(0)
    }

    const copiaTitoloImmagine = (appoTitolo) => {
        setTitolo(appoTitolo)
        setOpenAlert(1)
    }

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const listCopy = Array.from(lista);
        //rimuovo elemento dalla lista di partenza
        const sourceList = listCopy[result.source.droppableId];
        const [removedElement, newSourceList] = removeFromList(
            sourceList,
            result.source.index
        );
        listCopy[result.source.droppableId] = newSourceList;
        //aggiungo elemento alla  di destinazione
        const destinationList = listCopy[result.destination.droppableId];
        listCopy[result.destination.droppableId] = addToList(
            destinationList,
            result.destination.index,
            removedElement
        );
        //sistemo le liste con numero immagini ciascuna
        const risultato = [];
        let appoLista = [];
        let i = 0;
        while(i < listCopy.length) {
            //se ha numero elementi ok
            if(listCopy[i].length == numero) {
                risultato.push(listCopy[i])
                i++
            }
            //se non ha elementi la salto
            else if(listCopy[i].length == 0) i++
            //se ha più di numero
            else if(listCopy[i].length > numero) {
                //rimuovo il quarto
                const [removedElement, newSourceList] = removeFromList(listCopy[i], numero);
                listCopy[i] = newSourceList;
                //lo aggiungo come primo alla lista dopo
                appoLista = [];
                if(listCopy[i + 1]) {
                    appoLista = addToList(listCopy[i + 1], 0, removedElement);
                    listCopy[i + 1] = appoLista;
                } else {
                    appoLista.push(removedElement);
                    listCopy.push(appoLista);
                }
            }
            //se ne ha meno di numero
            else if(listCopy[i].length < numero) {
                //se non esiste quello successivo ok
                if(!listCopy[i + 1]) {
                    risultato.push(listCopy[i])
                    i++
                }
                //se esiste quello successivo
                else {
                    //rimuovo il primo dalla prossima lista
                    const [removedElement, newSourceList] = removeFromList(listCopy[i + 1], 0);
                    listCopy[i + 1] = newSourceList;
                    //lo aggiungo come ultimo a questa
                    appoLista = addToList(listCopy[i], listCopy[i].length, removedElement);
                    listCopy[i] = appoLista;
                }
            }
        }
        setLista(risultato);
        startAggiornaImportanza(risultato)
    }

    const startAggiornaImportanza = async(vett) => {
        const lista = [];
        for(let i = 0; i < vett.length; i++) {
            for(let j = 0; j < vett[i].length; j++) {
                lista.push(vett[i][j].id)
            }
        }
        aggiornaImportanzaImmagini(lista)
    }

    const removeFromList = (list, index) => {
        const result = Array.from(list);
        const [removed] = result.splice(index, 1);
        return [removed, result];
    }
      
    const addToList = (list, index, element) => {
        const result = Array.from(list);
        result.splice(index, 0, element);
        return result;
    }

    const calcolaImportanzaMax = () => {
        let importanza = 0;
        for(let i = 0; i < immagini.length; i++) {
            if(immagini[i].importanza >= importanza) importanza = immagini[i].importanza + 1;
        }
        return importanza
    }

    return (
        <React.Fragment>
            {!selezionata.isModifica && <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <h3 className="admin-titoletto">NUOVA IMMAGINE</h3>
                <Stack paddingTop={1}>
                    <TextField 
                        id="raised-button-file"
                        label="Immagine"
                        variant="outlined"
                        value=""
                        onChange={async(event) => await caricaImmagine(event)}
                        type="file"
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                        style={{ display: 'none' }}
                    />
                    <label className="admin-linea-bottone-immagine" htmlFor="raised-button-file">
                        <Button variant="contained" color="primary" component="span" startIcon={<FileUploadIcon />}>Upload</Button>
                    </label>
                </Stack>
            </div>}

            {!selezionata.isModifica && <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <h3 className="admin-titoletto">IMMAGINI</h3>
                {immagini.length == 0 && <span className="admin-titoletto">Nessuna Immagine presente.</span>}
                {immagini.length > 0 &&
                    <DragDropContext onDragEnd={onDragEnd}>
                        {lista.map((elementi, index) => (
                            <Droppable key={index} droppableId={index.toString()} direction="horizontal">
                                {(provided) => (
                                    <div className="admin-lista-immagini-drag" {...provided.droppableProps} ref={provided.innerRef}>
                                        {elementi.map((img, index) => (
                                            <Draggable key={img.id} draggableId={img.id.toString()} index={index}>
                                                {(provided2) => (
                                                    <div
                                                        ref={provided2.innerRef}
                                                        {...provided2.draggableProps}
                                                        {...provided2.dragHandleProps}
                                                        className="admin-skill-immagine-container"
                                                    >
                                                        <img
                                                            src={img.link}
                                                            alt={img.nome_italiano}
                                                            loading="lazy"
                                                            className="admin-skill-immagine-image"
                                                        />
                                                        <div className="admin-skill-immagine-overlay">
                                                            <span className="admin-skill-immagine-text">
                                                                {img.nome_italiano.length > 0 ? img.nome_italiano.substring(0, 29) : "Senza Nome"}
                                                                <Stack direction="row" spacing={4}>
                                                                    <IconButton className="admin-bottone-skill-immagine" color="nero" aria-label="upload picture" component="span" onClick={() => selezionaImmagine(img)}>
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    <IconButton className="admin-bottone-skill-immagine" color="nero" aria-label="upload picture" component="span" onClick={async() => await confermaEliminaImmagine(img)}>
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </Stack>
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        ))}
                    </DragDropContext>
                }
            </div>}

            {selezionata.isModifica && <>
                <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                    <Stack direction="row" alignItems="center" justifyContent="space-around">
                        <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
                            <h3 className="admin-titoletto">MODIFICA IMMAGINE</h3>
                            <a className="admin-link-bottone-download" href={selezionata.img.link} download target="_blank">
                                <Button variant="contained" color="primary" component="span" startIcon={<DownloadIcon />}>Download</Button>
                            </a>
                        </Stack>
                        <img className="admin-anteprima-img" src={selezionata.img.link}></img>
                    </Stack>
                </div>
                <ContenutoScheda
                    lingua={lingua}
                    cambiaLingua={(v) => setLingua(v)}
                    titolo={titolo}
                    cambiaTitolo={cambiaTitolo}
                    contenuto={null}
                    cambiaContenuto={() => null}
                    soloTitolo={true}
                    copiaContenuti={copiaTitoloImmagine}
                    isModifica={true}
                ></ContenutoScheda>
                <BottoniScheda
                    loading={loading}
                    conferma={confermaModificaImg}
                    annulla={annullaModificaImg}
                ></BottoniScheda>
            </>}

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 1} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Titolo copiato nelle diverse lingue.</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default SceltaImmagini;