import { useContext } from "react";

import GeneraleContext from "./context";

const useStagionalita = () => {

    const { generale } = useContext(GeneraleContext);

    return { 
        stagioni: generale.stagioni,
        meteo: generale.meteo
    }
}

export default useStagionalita;