import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';

import '../styles/icone.css'
import '../styles/icone-nuove.css'

const listaTUTTEIcone = [

    'service-wc_separati', 'service-cuscini', 'service-asciugatrice', 'service-lavastoviglie', 'service-giardino',
    'service-coperte', 'service-specchio', 'service-garage_auto', 

    'service-bagno', 'service-camera_letto',
    'service-cucina', 'service-divano_letto_doppio', 'service-divano_letto_singolo', 'service-letto_castello', 'service-letto_doppio',
    'service-letto_singolo', 'service-soggiorno', 'service-windsurf', 'service-wifi', 'service-wellness', 'service-wc_piazzola',
    'service-wc_disabili', 'service-volley', 'service-veranda', 'service-vela', 'service-tv_satellitare',
    'service-tiro_arco', 'service-tennis', 'service-tabaccheria', 'service-strutture_accessibili', 'service-stagionali',

    'service-spiaggia_attrezzata', 'service-spiaggia_animali', 'service-solarium', 'service-soccorso', 'service-servizio_pulizia',
    'service-servizio_navetta', 'service-servizi_igienici', 'service-sala_giochi', 'service-ristorante', 'service-riscaldamento',
    'service-rafting', 'service-pizzeria', 'service-piscina_calda', 'service-piscina', 'service-pingpong',
    'service-piazzole_ombreggiate', 'service-piazzole', 'service-pentolame', 'service-parco_giochi', 'service-parco_acquatico',
    'service-parcheggio', 'service-pallacanestro', 'service-palestra', 'service-noleggio_scooter', 'service-noleggio_gommoni',

    'service-noleggio_bici', 'service-minigolf', 'service-miniclub', 'service-market', 'service-maneggio',
    'service-luce_piazzola', 'service-lodgetent', 'service-lavanderia', 'service-lavaggio_auto', 'service-lavaggio_animali',
    'service-kitesurf', 'service-internet', 'service-impianti_scii', 'service-idromassaggio', 'service-escursioni',
    'service-edicola', 'service-docce_calde', 'service-diving', 'service-contanti', 'service-climbing',
    'service-chiosco_spiaggia', 'service-chalet', 'service-centro_fitness', 'service-cassaforte', 'service-case_mobili',
    'service-carta_credito', 'service-canoa', 'service-camper', 'service-camere', 'service-calcetto',
    'service-bungalow', 'service-bocce', 'service-biancheria_letto', 'service-biancheria_bagno', 'service-bazar',
    'service-barbecue', 'service-bar', 'service-bancomat', 'service-aria_condizionata', 'service-area_animali',
    'service-appartamenti', 'service-animazione', 'service-animali_non_ammessi', 'service-animali_limitazioni', 'service-animali_ammessi',
    'service-angolo_cottura', 'service-accesso_spiaggia', 'service-animali_campeggio', 'service-divertimento', 'service-animali_medi',

    'service-animali_medi-alt', 'service-animali_piccoli', 'service-animali_piccoli-alt', 'service-animali_grandi', 'service-animali_grandi-alt',
    'service-fasciatoio', 'service-armadio', 'service-tostapane', 'service-tostapane-alt', 'service-telefono',
    'service-tavolo_pranzo', 'service-stendi_biancheria', 'service-seggiolone', 'service-prodotti_pulizia', 'service-prodotti_bagno',
    'service-predisposizione_tv', 'service-predisposizione_tv-alt', 'service-zanzariera', 'service-divano', 'service-divano-alt',
    'service-decoder-satellitare', 'service-culla', 'service-cassetta_sicurezza', 'service-cassetta_sicurezza-alt', 'service-canali_satellitari',
    'service-pay_per_view', 'service-caffettiera', 'service-caffe_americano', 'service-baby_kit', 'service-asse_stiro',
    'service-asciugacapelli', 'service-arredamento_esterno', 'service-piano_cottura', 'service-d27', 'service-caffe_cialde',
    'service-lettino_bambini', 'service-frigorifero_freezer', 'service-frigorifero', 'service-microonde', 'service-forno',

    'service-ventilatore', 'service-pagamento_bonifico', 'service-pagamento_assegni', 'service-fotocopiatrice_fax', 'service-deposito_sci',
    'service-deposito_sci-alt', 'service-custodia_valori', 'service-aperto_anno', 'service-aperto_anno-alt', 'service-videogiochi',
    'service-stampa_internazionale', 'service-reception_multilingua', 'service-reception_24', 'service-zona_pranzo', 'service-zona_pranzo_esterna',
    'service-soggiorno2', 'service-soggiorno-alt', 'service-accesso_piste_sci', 'service-anfiteatro', 'service-ascensore',
    'service-baby_club', 'service-baby_club-alt', 'service-balcone', 'service-biblioteca', 'service-biblioteca-alt',

    'service-bidet', 'service-camino', 'service-cappella', 'service-cucina2', 'service-cucina_comune',
    'service-deposito_bagagli', 'service-discoteca', 'service-doccia', 'service-fattoria', 'service-ingresso_indipendente',
    'service-karaoke', 'service-lavanderia_gettoni', 'service-lavanderia_gettoni-alt', 'service-luna_park', 'service-musica_live',
    'service-negozi', 'service-parco_avventura', 'service-parco_avventura-alt', 'service-parrucchiere', 'service-parrucchiere-alt',
    'service-piano_bar', 'service-allaccio_acqua', 'service-allaccio_acqua-alt', 'service-animali_villaggio', 'service-piscina_idromassaggio',

    'service-piscina_bambini', 'service-piscina_coperta', 'service-wc_chimico', 'service-wc_comune', 'service-wc_comune-alt',
    'service-wc_comune-alt2', 'service-wc_comune-alt3', 'service-wc_fasciatoi', 'service-wc_fasciatoi-alt', 'service-wc_fasciatoi-alt2',
    'service-sistemazione_disabili', 'service-sistemazione_fumatori', 'service-sistemazione_non_fumatori', 'service-spiaggia_scogli', 'service-spiaggia_libera',
    'service-spiaggia_nudista', 'service-spiaggia_privata', 'service-spiaggia_sabbia', 'service-telefono_pubblico', 'service-tessera_club',
    'service-terrazzo', 'service-tornei', 'service-vasca', 'service-vasca-alt', 'service-vasca-alt2',

    'service-vista_citta', 'service-vista_fiume', 'service-vista_giardino', 'service-vista_lago', 'service-vista_mare',
    'service-vista_montagna', 'service-vista_piscina', 'service-zona_pesca', 'service-lettini_piscina', 'service-lettini_piscina-alt',
    'service-lettini_spiaggia', 'service-lettini_spiaggia-alt', 'service-boa', 'service-shuttle', 'service-noleggio_tv',
    'service-noleggio_scooter2', 'service-noleggio_pedalo', 'service-noleggio_imbarcazioni', 'service-noleggio_frigo', 'service-noleggio_auto',
    'service-terreno_pianura', 'service-terreno_collina', 'service-montagna', 'service-mare', 'service-lago',

    'service-entroterra', 'service-collina', 'service-citta-arte', 'service-tavola_calda', 'service-tavola_calda-alt',
    'service-self_service', 'service-colazione_buffet', 'service-pranzo_sacco', 'service-pranzo_sacco-alt', 'service-pranzo_sacco-alt2',
    'service-pizza_asporto', 'service-pernottamento_colazione', 'service-celiaci', 'service-celiaci-alt', 'service-r12',
    'service-pensione_completa', 'service-mezza_pensione', 'service-bar_spiaggia', 'service-pallavolo', 'service-freccette',
    'service-corsi_nuoto', 'service-corsi_equitazione', 'service-centro_surf', 'service-calcio', 'service-biliardo',

    'service-pet_friend', 'service-vacanze_culturali', 'service-vacanze_culturali-alt', 'service-comfort', 'service-per_sportivi',
    'service-per_risparmiare', 'service-per_risparmiare-alt', 'service-per_relax', 'service-per_naturisti', 'service-lui_lei',

    'service-natura', 'service-gruppi', 'service-giovani', 'service-famiglie', 'service-ombra_artificiale',
    'service-stoviglie', 'service-ferro_da_stiro', 'service-parcheggio_sistemazione', 'service-scarico_piazzola', 'service-wc_acqua_calda',
    'service-golf', 'service-wc_privati', 'service-spiaggia_ghiaia', 'service-calcio_balilla', 'service-corsi_sci',
    'service-corsi_sub', 'service-pattinaggio', 'service-navetta'
]

const listaIcone = [
    'service-wc_separati', 'service-wifi', 'service-wellness', 'service-wc_piazzola',
    'service-wc_disabili', 'service-volley', 'service-tiro_arco', 'service-tennis', 'service-tabaccheria', 'service-strutture_accessibili',

    'service-spiaggia_attrezzata', 'service-spiaggia_animali', 'service-solarium', 'service-soccorso',
    'service-servizio_navetta', 'service-sala_giochi', 'service-ristorante',
    'service-pizzeria', 'service-piscina_calda', 'service-piscina', 'service-pingpong',
    'service-piazzole', 'service-parco_giochi', 'service-parco_acquatico',
    'service-parcheggio', 'service-pallacanestro', 'service-palestra', 'service-noleggio_scooter', 'service-noleggio_gommoni',

    'service-noleggio_bici', 'service-minigolf', 'service-miniclub', 'service-market', 'service-maneggio',
    'service-luce_piazzola', 'service-lodgetent', 'service-lavanderia', 'service-lavaggio_auto', 'service-lavaggio_animali',
    'service-edicola', 'service-contanti',
    'service-chiosco_spiaggia', 'service-chalet', 'service-centro_fitness', 'service-case_mobili',
    'service-carta_credito', 'service-camper', 'service-calcetto',
    'service-bungalow', 'service-bocce', 'service-bazar', 'service-barbecue', 'service-bar', 'service-bancomat', 'service-area_animali',
    'service-appartamenti', 'service-animazione', 'service-animali_non_ammessi', 'service-animali_limitazioni', 'service-animali_ammessi',
    'service-accesso_spiaggia', 'service-animali_campeggio', 'service-divertimento', 'service-animali_medi',

    'service-animali_medi-alt', 'service-animali_piccoli', 'service-animali_piccoli-alt', 'service-animali_grandi', 'service-animali_grandi-alt',
    'service-fasciatoio', 'service-pagamento_bonifico', 'service-pagamento_assegni', 'service-custodia_valori', 'service-videogiochi',
    'service-stampa_internazionale', 'service-reception_multilingua', 'service-reception_24',
    'service-anfiteatro', 'service-ascensore', 'service-baby_club', 'service-baby_club-alt',

    'service-cappella', 'service-deposito_bagagli', 'service-discoteca', 'service-fattoria',
    'service-karaoke', 'service-lavanderia_gettoni', 'service-lavanderia_gettoni-alt', 'service-musica_live',
    'service-negozi', 'service-parco_avventura-alt', 'service-parrucchiere', 'service-parrucchiere-alt',
    'service-piano_bar', 'service-animali_villaggio', 'service-piscina_idromassaggio',

    'service-piscina_bambini', 'service-piscina_coperta', 'service-wc_fasciatoi', 'service-wc_fasciatoi-alt', 'service-wc_fasciatoi-alt2',
    'service-sistemazione_disabili', 'service-spiaggia_scogli', 'service-spiaggia_libera',
    'service-spiaggia_nudista', 'service-spiaggia_privata', 'service-spiaggia_sabbia', 'service-tessera_club', 'service-tornei',

    'service-zona_pesca', 'service-lettini_piscina', 'service-boa', 'service-shuttle',
    'service-noleggio_scooter2', 'service-noleggio_pedalo', 'service-noleggio_imbarcazioni', 'service-noleggio_frigo', 'service-noleggio_auto',

    'service-tavola_calda', 'service-tavola_calda-alt', 'service-self_service', 'service-colazione_buffet', 'service-pranzo_sacco', 'service-pranzo_sacco-alt',
    'service-pizza_asporto', 'service-r12', 'service-bar_spiaggia', 'service-pallavolo', 'service-freccette',
    'service-corsi_nuoto', 'service-corsi_equitazione', 'service-calcio', 'service-biliardo',

    'service-pet_friend', 'service-vacanze_culturali', 'service-vacanze_culturali-alt', 'service-comfort', 'service-per_sportivi',
    'service-per_relax', 'service-parcheggio_sistemazione', 'service-navetta',
    'service-wc_privati', 'service-spiaggia_ghiaia', 'service-calcio_balilla', 'service-corsi_sci',

    //nuove
    'ldgcicons-spiaggia-sabbia', 'ldgcicons-spiaggia-ghiaia', 'ldgcicons-spiaggia-erba', 'ldgcicons-spettacoli-serali',
    'ldgcicons-junior-club', 'ldgcicons-area-giochi-indoor', 'ldgcicons-ping-pong', 'ldgcicons-sport-acquatici',
    'ldgcicons-spray-park', 'ldgcicons-attracco-natanti', 'ldgcicons-scivolo-barche', 'ldgcicons-info-point', 
]

const listaIconeEsp = [
    'service-windsurf',
    'service-vela',
    'service-kitesurf',
    'service-escursioni',
    'service-diving',
    'service-climbing',
    'service-canoa',
    'service-luna_park',
    'service-centro_surf',
    'service-golf',
    'service-corsi_sub', 
    'service-rafting',
    'service-pattinaggio',

    //nuove
    'ldgcicons-canyoning', 'ldgcicons-climbing', 'ldgcicons-nordic-walking', 'ldgcicons-parapendio', 'ldgcicons-sport-fiume', 'ldgcicons-sport-invernali',
    'ldgcicons-trekking', 'ldgcicons-wakeboard', 'ldgcicons-cicloturismo', 'ldgcicons-dolci-formaggi', 'ldgcicons-olio',
    'ldgcicons-pesce', 'ldgcicons-prodotti-terra', 'ldgcicons-prodotti-tradizione', 'ldgcicons-vini-cantine', 'ldgcicons-funivie-panoramiche',
    'ldgcicons-parchi-acquatici', 'ldgcicons-parchi-divertimento', 'ldgcicons-parchi-naturali', 'ldgcicons-terme', 'ldgcicons-itinerari-cicloturistici',
    'ldgcicons-itinerari-bambini', 'ldgcicons-itinerari-paesi', 'ldgcicons-itinerari-panoramici', 'ldgcicons-itinerari-storici-religiosi', 'ldgcicons-itinerari-trekking',
    'ldgcicons-monte-baldo', 'ldgcicons-tour-barca', 'ldgcicons-eventi-festivita', 'ldgcicons-eventi-enogastronomici', 'ldgcicons-eventi-folkloristici',
    'ldgcicons-eventi-sportivi', 'ldgcicons-feste-sagre', 'ldgcicons-fuochi-artificio', 'ldgcicons-mercati', 'ldgcicons-eventi-natalizi',
    'ldgcicons-eventi-storici-culturali', 'ldgcicons-spettacoli-teatro', 'ldgcicons-eventi-musicali', 'ldgcicons-guida-cicloturismo', 'ldgcicons-guida-family', 'ldgcicons-guida-paesi'
]

function CatalogoIcone(props) {

    const [visible, setVisible] = useState(false)

    const premiIcona = (icona) => {
        setVisible(true)
        navigator.clipboard.writeText(icona)
        setTimeout(() => {
            setVisible(false)
        }, 1000);
    }

    const scriviNomeIconaGeneric = (icona) => {
        let appo = icona.replace('service-', '')
        appo = appo.replace('ldgcicons-', '')
        return appo
    }

    return (
        <React.Fragment>
            <div className="admin-container">
                <h1>CATALOGO ICONE SERVIZI</h1>
                <Grid 
                    container
                    spacing='32px'
                    sx={{paddingTop:'20px'}}
                >
                    {listaIcone.map((icona, index) => (
                        <Grid item key={index} md={1}>
                            <Tooltip title={scriviNomeIconaGeneric(icona)} placement="top-end" arrow>
                                <Button
                                    onClick={() => premiIcona(icona)}
                                    size="medium"
                                    variant={"white"}
                                    color={"primary"}
                                    sx={{
                                        width:'100%', height:'80px', border:'2px solid #A9A9A9', display:'flex',
                                        alignItems:'center', justifyContent:'center', fontSize:'30px', color:'black'
                                    }}
                                >
                                    <i className={icona}></i>
                                </Button>
                            </Tooltip>
                        </Grid>
                    ))}
                </Grid>
            </div>

            <div className="admin-container">
                <h1>CATALOGO ICONE ESPERIENZE</h1>
                <Grid 
                    container
                    spacing='32px'
                    sx={{paddingTop:'20px'}}
                >
                    {listaIconeEsp.map((icona, index) => (
                        <Grid item key={index} md={1.3}>
                            <Tooltip title={scriviNomeIconaGeneric(icona)} placement="top-end" arrow>
                                <Button
                                    onClick={() => premiIcona(icona)}
                                    size="medium"
                                    variant={"white"}
                                    color={"primary"}
                                    sx={{
                                        width:'100%', height:'80px', border:'2px solid #A9A9A9', display:'flex',
                                        alignItems:'center', justifyContent:'center', fontSize:'30px', color:'black'
                                    }}
                                >
                                    <i className={icona}></i>
                                </Button>
                            </Tooltip>
                        </Grid>
                    ))}
                </Grid>
            </div>

            <Snackbar open={visible} onClose={() => setVisible(false)} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={() => setVisible(false)} variant="filled" severity="success" sx={{ width: '100%' }}>Icona copiata</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default CatalogoIcone;