import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable  } from 'react-beautiful-dnd';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import List from '@mui/material/List';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import ElementoLaterale from './ElementoLaterale';
import './style.css';

function BarraLaterale({
    titolo, lista, goToNuova, titoloBottone, cambiaElemento, elementoSelezionato, disableAttiva, 
    hasCategorie, categorie, isEsperienze, isMacro, onDragEnd, togliDnd, isOfferte, strutture,
    isAttrazioni, macroCategorie, isNews, tipologieNews
}) {

    const [macroSelezionata, setMacroSelezionata] = useState(0);
    const [catSelezionata, setCatSelezionata] = useState(0);
    const [tipologiaSel, setTipologiaSel] = useState(0);
    const [strutturaSel, setStrutturaSel] = useState(0);
    const [ricerca, setRicerca] = useState();

    useEffect(() => azzerSel(), [lista.length])
    useEffect(() => azzerSel(), [macroSelezionata])

    const azzerSel = () => setCatSelezionata(0)
    const categoriaById = (id) => categorie.filter((cat) => cat.id == id)[0]

    const filtraLista = () => {
        let vett = Array.from(lista); //la lista di elementi
        //fitro strutture per le offerte
        if(isOfferte && strutturaSel > 0) {
            vett = vett.filter((off) => off.strutture.filter((strutt) => strutt.struttura == strutturaSel).length > 0)
        }
        //fitro strutture per le news
        if(isNews && strutturaSel > 0) {
            vett = vett.filter((n) => n.strutture.filter((strutt) => strutt.struttura == strutturaSel).length > 0)
        }
        //filtro tipologie per le news
        if(isNews && tipologiaSel > 0) {
            vett = vett.filter((n) => n.tipologia == tipologiaSel)
        }
        //filtro in base alla macro se presente
        if(macroSelezionata > 0 && !catSelezionata) {
            vett = vett.filter((e) => {
                let idCat = null, cat = null
                for(let i = 0; i < e.categorie.length; i++) {
                    idCat = e.categorie[i].categoria
                    cat = categorie.filter((c) => c.id == idCat)[0]
                    if(!cat) return false
                    if(cat.macro == macroSelezionata) return true
                }
                return false
            })
        }
        //filtro in base alla categoria se presente
        if(catSelezionata > 0) {
            if(!isMacro) {
                if(isEsperienze) {
                    vett = vett.filter((e) => e.categorie.filter((cat) => cat.categoria == catSelezionata).length > 0)
                } else vett = vett.filter((e) => e.categoria == catSelezionata);
            } else vett = vett.filter((e) => e.macro == catSelezionata);
        }
        //controllo ricerca
        if(!ricerca || ricerca.length == 0) return vett;
        return vett.filter((e) => {
            var appo = ricerca.toLowerCase();
            var appo2 = e.nome_italiano.toLowerCase();
            return appo2.includes(appo);
        });
    }

    const filtraCategorie = () => {
        if(isAttrazioni) {
            if(macroSelezionata > 0) {
                return categorie.filter((c) => c.macro == macroSelezionata)
            }
        }
        return categorie
    }

    const beforeDragEnd = (result) => {
        //preparo i dati
        const appo = {...result}
        const indexDest = result.destination.index
        const indexSource = result.source.index
        //cerco gli id degli elementi
        const vett = filtraLista()
        const idDest = vett[indexDest].id
        const idSource = vett[indexSource].id
        //cerco le posizioni nella lista originale
        const posDest = lista.findIndex((e) => e.id == idDest)
        const posSource = lista.findIndex((e) => e.id == idSource)
        //sistemo e confermo
        appo.destination.index = posDest
        appo.source.index = posSource
        onDragEnd(appo)
    }

    return (
        <div className="container-laterale">
            <div className="prima-riga-container-laterale">
                <h3 className="admin-titoletto">{titolo}</h3>
                <Button
                    size="medium"
                    variant={"contained"}
                    color={"primary"}
                    onClick={goToNuova}
                    startIcon={<AddCircleOutlineIcon />}
                >{titoloBottone}</Button>
            </div>
            {(hasCategorie || isOfferte || isNews) && <Stack direction='row' spacing={3} marginTop={1}>
                {isAttrazioni &&
                    <Stack direction="row" paddingTop={2}>
                        <FormControl sx={{ minWidth: 150, maxWidth: 300 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Macro Categoria</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label-2"
                                id="demo-simple-select-autowidth-2"
                                name="macroCategoria"
                                value={macroSelezionata}
                                onChange={(event) => setMacroSelezionata(event.target.value)}
                                autoWidth
                                label="Macro Categoria"
                                size="small"
                            >
                                <MenuItem value={0}><em>Tutte</em></MenuItem>
                                {macroCategorie.filter((el) => el.id != 7 && el.id != 8 && el.id != 9).map((macro, index) => (
                                    <MenuItem value={macro.id} key={index}>{macro.nome_italiano}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                }
                {hasCategorie &&
                    <Stack direction="row" paddingTop={2}>
                        <FormControl sx={{ minWidth: 150, maxWidth: 300 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Categoria</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                name="categoria"
                                value={catSelezionata}
                                onChange={(event) => setCatSelezionata(event.target.value)}
                                autoWidth
                                label="Categoria"
                                size="small"
                            >
                                <MenuItem value={0}><em>Tutte</em></MenuItem>
                                {filtraCategorie().map((cat, index) => (
                                    <MenuItem value={cat.id} key={index}>{cat.nome_italiano}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                }
                {(isOfferte || isNews) &&
                    <Stack direction="row" paddingTop={2}>
                        <FormControl sx={{ minWidth: 150, maxWidth: 300 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Struttura</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-2-label"
                                id="demo-simple-select-autowidth-2"
                                name="struttura"
                                value={strutturaSel}
                                onChange={(event) => setStrutturaSel(event.target.value)}
                                autoWidth
                                label="Struttura"
                                size="small"
                            >
                                <MenuItem value={0}><em>Tutte</em></MenuItem>
                                {strutture.map((struttura, index) => (
                                    <MenuItem value={struttura.id} key={index}>{struttura.nome_italiano}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                }
                {isNews && 
                    <Stack direction="row" paddingTop={2}>
                        <FormControl sx={{ minWidth: 150, maxWidth: 300 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Tipologia</InputLabel>
                            <Select
                                labelId="demo-simple-select-autowidth-2-label"
                                id="demo-simple-select-autowidth-2"
                                name="tipologia"
                                value={tipologiaSel}
                                onChange={(event) => setTipologiaSel(event.target.value)}
                                autoWidth
                                label="Tipologia"
                                size="small"
                            >
                                <MenuItem value={0}><em>Tutte</em></MenuItem>
                                {tipologieNews.map((tipo, index) => (
                                    <MenuItem value={tipo.id} key={index}>{tipo.nome_italiano}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                }
            </Stack>}
            <Stack direction="row" spacing={15} paddingTop={2} paddingBottom={1}>
                <TextField 
                    id="outlined-basic"
                    label="Ricerca"
                    variant="outlined"
                    name="ricerca"
                    onChange={(event) => setRicerca(event.target.value)}
                    value={ricerca}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>

            {!togliDnd && <DragDropContext onDragEnd={beforeDragEnd}>
            <Droppable droppableId={"drop-laterale"} direction="vertical">
            {(provided) => (
                <List component="div" aria-label="main mailbox folders" {...provided.droppableProps} ref={provided.innerRef}>
                    {filtraLista().length == 0 && <h3 className="admin-titoletto">Nessun elemento presente</h3>}
                    {filtraLista().map((elemento, index) => (
                        <Draggable key={elemento.id} draggableId={elemento.id.toString()} index={index}>
                        {(provided) => {
                            if(!isMacro) return(
                                <ElementoLaterale
                                    provided={provided}
                                    disableAttiva={disableAttiva}
                                    attivo={elemento.attivo}
                                    attiva={elemento.attiva}
                                    nome={elemento.nome_italiano}
                                    onClick={() => cambiaElemento(elemento)}
                                    selezionato={elementoSelezionato?.id == elemento.id}
                                    key={index}
                                    sottotitolo={(hasCategorie && parseInt(elemento.categoria)) ? categoriaById(elemento.categoria).nome_italiano : null}
                                ></ElementoLaterale>
                            )
                            if(isMacro) return(
                                <ElementoLaterale
                                    provided={provided}
                                    disableAttiva={disableAttiva}
                                    attivo={elemento.attivo}
                                    attiva={elemento.attiva}
                                    nome={elemento.nome_italiano}
                                    onClick={() => cambiaElemento(elemento)}
                                    selezionato={elementoSelezionato?.id == elemento.id}
                                    key={index}
                                    sottotitolo={(hasCategorie && parseInt(elemento.macro)) ? categoriaById(elemento.macro).nome_italiano : null}
                                ></ElementoLaterale>
                            )
                        }}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </List>
            )}
            </Droppable>
            </DragDropContext>}

            {togliDnd &&
                <List component="div" aria-label="main mailbox folders">
                    {filtraLista().length == 0 && <h3 className="admin-titoletto">Nessun elemento presente</h3>}
                    {filtraLista().map((elemento, index) => {
                        if(!isMacro) return(
                            <ElementoLaterale
                                provided={null}
                                disableAttiva={disableAttiva}
                                attivo={elemento.attivo}
                                attiva={elemento.attiva}
                                nome={elemento.nome_italiano}
                                onClick={() => cambiaElemento(elemento)}
                                selezionato={elementoSelezionato?.id == elemento.id}
                                key={index}
                                sottotitolo={(hasCategorie && parseInt(elemento.categoria)) ? categoriaById(elemento.categoria).nome_italiano : null}
                            ></ElementoLaterale>
                        )
                        if(isMacro) return(
                            <ElementoLaterale
                                provided={null}
                                disableAttiva={disableAttiva}
                                attivo={elemento.attivo}
                                attiva={elemento.attiva}
                                nome={elemento.nome_italiano}
                                onClick={() => cambiaElemento(elemento)}
                                selezionato={elementoSelezionato?.id == elemento.id}
                                key={index}
                                sottotitolo={(hasCategorie && parseInt(elemento.macro)) ? categoriaById(elemento.macro).nome_italiano : null}
                            ></ElementoLaterale>
                        )
                    })}
                </List>
            }
        </div>
    );
}

export default BarraLaterale;