import client from "./client";
import config from '../config.json';

const endpoint = 'auth/admin/'

//login
const loginAdmin = (data) => {
    return client.post(config.serverUrl + endpoint + "login", data);
}

//operazioni su me stesso
const refreshMe = () => {
    return client.get(config.serverUrl + endpoint + "me/refresh");
}
const modificaPassword = (data) => {
    return client.put(config.serverUrl + endpoint + "me/modifica-password", data);
}

//operazioni super admin
const listaAdmin = () => {
    return client.get(config.serverUrl + endpoint + "lista");
}
const creaAdmin = (data) => {
    return client.post(config.serverUrl + endpoint + "crea", data);
}
const modificaAdmin = (data) => {
    return client.put(config.serverUrl + endpoint + "modifica", data);
}
const eliminaAdmin = (id) => {
    return client.delete(config.serverUrl + endpoint + "elimina/" + id);
}
const modificaPasswordSuper = (data) => {
    return client.put(config.serverUrl + endpoint + "modifica-password", data);
}

export default {
    loginAdmin,

    refreshMe,
    modificaPassword,

    listaAdmin,
    creaAdmin,
    modificaAdmin,
    eliminaAdmin,
    modificaPasswordSuper
}