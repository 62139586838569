import { useContext } from "react";

import SchedaCopiataContext from "./context";

const useSchedaCopiata = () => {
    const { schedaCopiata, setSchedaCopiata } = useContext(SchedaCopiataContext);

    const popolaSchedaCopiata = (value) => setSchedaCopiata(value)

    return { 
        schedaCopiata,
        popolaSchedaCopiata
    }
}

export default useSchedaCopiata;