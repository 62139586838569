import { useContext } from "react";

import GeneraleContext from "./context";

const useLocalita = () => {
    const { generale, setGenerale } = useContext(GeneraleContext);

    const popolaLocalita = (vett) => {
        const appo = {...generale};
        appo.localita = vett;
        setGenerale(appo);
    }

    const importanzaMaxLocalita = () => {
        let appo = generale.localita;
        let valore = appo[appo.length - 1]?.importanza;
        return valore ? valore : 0
    }

    const addLocalita = (l) => {
        const appo = {...generale};
        appo.localita.push(l);
        setGenerale(appo);
    }

    const aggLocalita = (loc) => {
        const appo = {...generale};
        for(let i = 0; i < appo.localita.length; i++) {
            if(appo.localita[i].id == loc.id) appo.localita[i] = loc;
        }
        setGenerale(appo);
    }

    const togliLocalita = (id) => {
        const appo = {...generale};
        appo.localita = generale.localita.filter((l) => l.id != id);
        setGenerale(appo);
    }

    return { 
        localita: generale.localita,
        popolaLocalita,
        importanzaMaxLocalita,
        addLocalita,
        aggLocalita,
        togliLocalita
    }
}

export default useLocalita;