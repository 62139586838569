import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import itLocale from 'date-fns/locale/it';

function FormNews({handleChange, values, errors, touched, setFieldValue, tipologieNews, isModifica}) {
    return (
        <React.Fragment>
            <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <Stack direction="row" spacing={15}>
                    <FormControlLabel
                        control={<Checkbox size="medium" color="primary" name="attivo" value="attivo" checked={values.attivo} onChange={handleChange}/>} 
                        label="Attivo"
                    />
                    <FormControlLabel
                        control={<Checkbox size="medium" color="primary" name="evidenza" value="evidenza" checked={values.evidenza} onChange={handleChange}/>} 
                        label="In Evidenza"
                    />
                </Stack>
                <Stack direction="row" spacing={10} alignItems="center" paddingTop={3}>
                    <h3 className="admin-titoletto">SELEZIONA TIPOLOGIA</h3>
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Tipologia</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name="tipologia"
                            value={values.tipologia}
                            onChange={handleChange}
                            autoWidth
                            size="small"
                            label="tipologia"
                        >
                            <MenuItem value={0}><em>Nessuna</em></MenuItem>
                            {tipologieNews.map((tipo, index) => (
                                <MenuItem value={tipo.id} key={index}>{tipo.nome_italiano}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Indirizzo"
                        variant="outlined"
                        name="indirizzo"
                        onChange={handleChange}
                        value={values.indirizzo}
                        error={touched.indirizzo && Boolean(errors.indirizzo)}
                        helperText={touched.indirizzo && errors.indirizzo}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    {/* <TextField 
                        id="outlined-basic"
                        label="CAP"
                        variant="outlined"
                        name="cap"
                        onChange={handleChange}
                        value={values.cap}
                        error={touched.cap && Boolean(errors.cap)}
                        helperText={touched.cap && errors.cap}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    /> */}
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Latitudine"
                        variant="outlined"
                        name="latitudine"
                        onChange={handleChange}
                        value={values.latitudine}
                        error={touched.latitudine && Boolean(errors.latitudine)}
                        helperText={touched.latitudine && errors.latitudine}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Longitudine"
                        variant="outlined"
                        name="longitudine"
                        onChange={handleChange}
                        value={values.longitudine}
                        error={touched.longitudine && Boolean(errors.longitudine)}
                        helperText={touched.longitudine && errors.longitudine}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={3} paddingBottom={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Telefono"
                        variant="outlined"
                        name="telefono"
                        onChange={handleChange}
                        value={values.telefono}
                        error={touched.telefono && Boolean(errors.telefono)}
                        helperText={touched.telefono && errors.telefono}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Whatsapp"
                        variant="outlined"
                        name="wathsapp"
                        onChange={handleChange}
                        value={values.wathsapp}
                        error={touched.wathsapp && Boolean(errors.wathsapp)}
                        helperText={touched.wathsapp && errors.wathsapp}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={7} paddingBottom={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Sito"
                        variant="outlined"
                        name="sito"
                        onChange={handleChange}
                        value={values.sito}
                        error={touched.sito && Boolean(errors.sito)}
                        helperText={touched.sito && errors.sito}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
            </div>

            <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <h3 className="admin-titoletto">Date Validità</h3>
                <Stack direction='row' spacing={7} paddingTop={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
                        <DatePicker
                            label="Data Inizio"
                            value={values.data_inizio}
                            onChange={value => setFieldValue("data_inizio", value)}
                            renderInput={(params) => <TextField {...params} />}
                            mask='__/__/____'
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
                        <DatePicker
                            label="Data Fine"
                            value={values.data_fine}
                            onChange={value => setFieldValue("data_fine", value)}
                            renderInput={(params) => <TextField {...params} />}
                            mask='__/__/____'
                        />
                    </LocalizationProvider>
                </Stack>

                {/* <Box sx={{paddingTop: 2}}><h3 className="admin-titoletto">Date Visibilità</h3></Box>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
                    <Stack direction="row" spacing={7} paddingBottom={3} paddingTop={2}>
                        <DatePicker
                            label="Data Inizio"
                            value={values.data_visibile_inizio}
                            onChange={value => setFieldValue("data_visibile_inizio", value)}
                            renderInput={(params) => <TextField {...params} />}
                            mask='__/__/____'
                        />
                        <DatePicker
                            label="Data Fine"
                            value={values.data_visibile_fine}
                            onChange={value => setFieldValue("data_visibile_fine", value)}
                            renderInput={(params) => <TextField {...params} />}
                            mask='__/__/____'
                        />
                    </Stack>
                </LocalizationProvider> */}
            </div>

            <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <Stack direction="row" spacing={7} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Video"
                        variant="outlined"
                        name="video"
                        onChange={handleChange}
                        value={values.video}
                        error={touched.video && Boolean(errors.video)}
                        helperText={touched.video && errors.video}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Facebook"
                        variant="outlined"
                        name="facebook"
                        onChange={handleChange}
                        value={values.facebook}
                        error={touched.facebook && Boolean(errors.facebook)}
                        helperText={touched.facebook && errors.facebook}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>

                <Stack direction="row" spacing={7} paddingTop={3} paddingBottom={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Instagram"
                        variant="outlined"
                        name="instagram"
                        onChange={handleChange}
                        value={values.instagram}
                        error={touched.instagram && Boolean(errors.instagram)}
                        helperText={touched.instagram && errors.instagram}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Youtube"
                        variant="outlined"
                        name="youtube"
                        onChange={handleChange}
                        value={values.youtube}
                        error={touched.youtube && Boolean(errors.youtube)}
                        helperText={touched.youtube && errors.youtube}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
            </div>
        </React.Fragment>
    );
}

export default FormNews;