
//import config from '../config/config.json';
import authStorage from '../controllers/auth/storage';
import {create} from 'apisauce';

const apiClient = create ({
    baseUrl: ""
});

apiClient.addAsyncRequestTransform(async (request) => {
    const token = await authStorage.getToken();
    if(token) request.headers["x-auth-token"] = token;
});
  
apiClient.addRequestTransform((request) => {
    request.headers["x-frame-auth"] = "ABCDEFGHRTAJSKDHDNKSL12343";
});
    
export default apiClient;