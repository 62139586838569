import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import useServizi from '../../controllers/campeggi/useServizi';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    }
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function SceltaServizi({serviziSel, aggiungiServizio, togliServizio, modificaEvidenzaServizio, isModifica, isEvidenza}) {

    const {servizi, categorieServizi, categoriaServizioById} = useServizi();
    const [catSelezionata, setCatSelezionata] = useState(0)

    const scriviNomeCategoria = (id) => categoriaServizioById(id)?.nome_italiano;

    const calcolaAttivo = (id) => serviziSel.filter((s) => s.id == id).length > 0;

    const calcolaEvidenzaPreview = (id) => serviziSel.filter((s) => s.id == id)[0]?.evidenza_preview == true ? true : false;

    const calcolaEvidenzaScheda = (id) => serviziSel.filter((s) => s.id == id)[0]?.evidenza_scheda == true ? true : false;

    const filtraServizi = () => {
        if(catSelezionata == 0) return servizi;
        return servizi.filter((servizio) => servizio.categoria == catSelezionata)
    }

    return (
        <React.Fragment>
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <Stack direction="row" spacing={10} alignItems="center">
                <h3 className="admin-titoletto">FILTRA CATEGORIE</h3>
                <FormControl sx={{ minWidth: 150, maxWidth: 300 }}>
                    <InputLabel id="demo-simple-select-autowidth-label">Categoria</InputLabel>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        name="categoria"
                        value={catSelezionata}
                        onChange={(event) => setCatSelezionata(event.target.value)}
                        autoWidth
                        label="Categoria"
                        size="small"
                    >
                        <MenuItem value={0}><em>Tutte</em></MenuItem>
                        {categorieServizi.map((cat, index) => (
                            <MenuItem key={index} value={cat.id}>{cat.nome_italiano}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
        </div>

        <TableContainer className="admin-tabella" sx={{width: isModifica ? "98%" : "73%", marginRight: isModifica ? "2%" : 0}}>
            <Table sx={{ width: "100%" }} size="small">
                <TableHead>
                    <TableRow>
                        <StyledTableCell sx={{width: "12%"}} align="center">Selezionato</StyledTableCell>
                        <StyledTableCell sx={{width: "32%"}} align="center">Nome</StyledTableCell>
                        {isEvidenza && <>
                            <StyledTableCell sx={{width: "12%"}} align="center">Evidenza Scheda (max 6)</StyledTableCell>
                            <StyledTableCell sx={{width: "12%"}} align="center">Evidenza Preview (max 8)</StyledTableCell>
                        </>}
                        <StyledTableCell sx={{width: "32%"}} align="center">Categoria</StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {filtraServizi().map((servizio, index) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell align="center" sx={{width: "15%"}}>
                                <Checkbox
                                    size="medium"
                                    color="primary" 
                                    checked={calcolaAttivo(servizio.id)}
                                    onChange={() => calcolaAttivo(servizio.id) ? togliServizio(servizio.id) : aggiungiServizio(servizio.id)}
                                />
                            </StyledTableCell>
                            <StyledTableCell align="center" sx={{width: "35%"}}>{servizio.nome_italiano}</StyledTableCell>
                            {isEvidenza && <>
                                <StyledTableCell align="center" sx={{width: "15%"}}>
                                    <Checkbox
                                        size="medium"
                                        color="primary" 
                                        checked={calcolaEvidenzaScheda(servizio.id)}
                                        disabled={!calcolaAttivo(servizio.id)}
                                        onChange={() => modificaEvidenzaServizio(servizio.id, 2, calcolaEvidenzaScheda(servizio.id))}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center" sx={{width: "15%"}}>
                                    <Checkbox
                                        size="medium"
                                        color="primary" 
                                        checked={calcolaEvidenzaPreview(servizio.id)}
                                        disabled={!calcolaAttivo(servizio.id)}
                                        onChange={() => modificaEvidenzaServizio(servizio.id, 1, calcolaEvidenzaPreview(servizio.id))}
                                    />
                                </StyledTableCell>
                            </>}
                            <StyledTableCell align="center" sx={{width: "35%"}}>{scriviNomeCategoria(servizio.categoria)}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </React.Fragment>
    );
}

export default SceltaServizi;