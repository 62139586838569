import React, { useState } from 'react';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';

import Logo from '../../images/logo.png';
import Utente from '../../images/user.png';
import useAuth from '../../controllers/auth/useAuth';
import './style.css';

function Header(props) {

    const location = useLocation();
    const navigate = useNavigate();
    const {utente, logout} = useAuth();

    const [tipo, setTipo] = useState(0);
    const isSuper = Boolean(utente?.is_super || utente?.username == 'elena')

    const [anchorAltro, setAnchorAltro] = useState(null);
    const open = Boolean(anchorAltro);

    const [anchorUtente, setAnchorUtente] = React.useState(null);
    const open2 = Boolean(anchorUtente);

    const [anchorCategorie, setAnchorCategorie] = useState(null);
    const open3 = Boolean(anchorCategorie);

    const handleMenu2 = (event) => {
        setAnchorUtente(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorUtente(null);
    };

    const handleClick = (event) => {
        setAnchorAltro(event.currentTarget);
    };

    const handleClickCategorie = (event) => {
        setAnchorCategorie(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorAltro(null);
    };

    const handleClose3 = () => {
        setAnchorCategorie(null);
    };

    const isRouteAttuale = (value) => {
        const vett = location.pathname.split('/');
        if(vett.length >= 2) {
            if(vett[1] == value) return true
            return false
        }
    }

    const isAltroSelected = () => {
        if(
            (
                isRouteAttuale("localita") || isRouteAttuale("tematiche") || isRouteAttuale("partners") || isRouteAttuale("banner") || isRouteAttuale("catalogo-icone") || isRouteAttuale("media-kit") || open
            ) && !open2 && !open3) return true;
        return false;
    }

    const premutoCategoria = (num) => {
        setTipo(num)
        navigate('/categorie', {state:{tipologia: num}});
    }
    
    return (
        <React.Fragment>
            <div className="header-container">
                <div className="header-zona">
                    <img src={Logo} className="header-img-logo" alt="Logo Lago di Garda Camping"></img>
                </div>
                <div className="header-bottoni">
                    <Button
                        size="small"
                        variant={((isRouteAttuale("") || isRouteAttuale("strutture")) && !open && !open2 && !open3) ? "contained" : "text"}
                        color={((isRouteAttuale("") || isRouteAttuale("strutture")) && !open && !open2 && !open3) ? "primary" : "chiaro"}
                        onClick={() => navigate('/')}
                    >STRUTTURE</Button>
                    <Button
                        size="small"
                        variant={(isRouteAttuale("offerte") && !open && !open2 && !open3) ? "contained" : "text"}
                        color={(isRouteAttuale("offerte") && !open && !open2 && !open3) ? "primary" : "chiaro"}
                        onClick={() => navigate('/offerte')}
                    >OFFERTE</Button>
                    <Button
                        size="small"
                        variant={(isRouteAttuale("servizi") && !open && !open2 && !open3) ? "contained" : "text"}
                        color={(isRouteAttuale("servizi") && !open && !open2 && !open3) ? "primary" : "chiaro"}
                        onClick={() => navigate('/servizi')}
                    >SERVIZI</Button>
                    <Button
                        size="small"
                        variant={(isRouteAttuale("attrazioni") && !open && !open2 && !open3) ? "contained" : "text"}
                        color={(isRouteAttuale("attrazioni") && !open && !open2 && !open3) ? "primary" : "chiaro"}
                        onClick={() => navigate('/attrazioni')}
                    >ATTRAZIONI</Button>
                    <Button
                        size="small"
                        variant={(isRouteAttuale("itinerari") && !open && !open2 && !open3) ? "contained" : "text"}
                        color={(isRouteAttuale("itinerari") && !open && !open2 && !open3) ? "primary" : "chiaro"}
                        onClick={() => navigate('/itinerari')}
                    >ITINERARI</Button>
                    <Button
                        size="small"
                        variant={(isRouteAttuale("eventi") && !open && !open2 && !open3) ? "contained" : "text"}
                        color={(isRouteAttuale("eventi") && !open && !open2 && !open3) ? "primary" : "chiaro"}
                        onClick={() => navigate('/eventi')}
                    >EVENTI</Button>
                    <Button
                        size="small"
                        variant={(isRouteAttuale("guide") && !open && !open2 && !open3) ? "contained" : "text"}
                        color={(isRouteAttuale("guide") && !open && !open2 && !open3) ? "primary" : "chiaro"}
                        onClick={() => navigate('/guide')}
                    >GUIDE TURISTICHE</Button>
                    <Button
                        size="small"
                        variant={(isRouteAttuale("news") && !open && !open2 && !open3) ? "contained" : "text"}
                        color={(isRouteAttuale("news") && !open && !open2 && !open3) ? "primary" : "chiaro"}
                        onClick={() => navigate('/news')}
                    >NEWS</Button>
                    <Button
                        id="fade-button"
                        aria-controls={open3 ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open3 ? 'true' : undefined}
                        onClick={handleClickCategorie}
                        size="small"
                        variant={((open3 || isRouteAttuale('categorie')) && !open && !open2) ? "contained" : "text"}
                        color={((open3 || isRouteAttuale('categorie')) && !open && !open2) ? "primary" : "chiaro"}
                    >CATEGORIE</Button>
                    <Menu
                        id="fade-menu"
                        MenuListProps={{'aria-labelledby': 'fade-button',}}
                        anchorEl={anchorCategorie}
                        open={open3}
                        onClose={handleClose3}
                        TransitionComponent={Fade}
                    >
                        <MenuItem onClick={() => {premutoCategoria(1);handleClose3()}}>
                            <Button
                                size="small"
                                variant={"text"}
                                color={isRouteAttuale('categorie') && tipo == 1 ? "primary" : "chiaro"}
                                onClick={() => premutoCategoria(1)}
                            >MACRO CATEGORIE</Button>
                        </MenuItem>
                        <MenuItem onClick={() => {premutoCategoria(2);handleClose3()}}>
                            <Button
                                size="small"
                                variant={"text"}
                                color={isRouteAttuale('categorie') && tipo == 2 ? "primary" : "chiaro"}
                                onClick={() => premutoCategoria(2)}
                            >CATEGORIE ESPERIENZE</Button>
                        </MenuItem>
                        <MenuItem onClick={() => {premutoCategoria(3);handleClose3()}}>
                            <Button
                                size="small"
                                variant={"text"}
                                color={isRouteAttuale('categorie') && tipo == 3 ? "primary" : "chiaro"}
                                onClick={() => premutoCategoria(3)}
                            >CATEGORIE SERVIZI</Button>
                        </MenuItem>
                        <MenuItem onClick={() => {premutoCategoria(4);handleClose3()}}>
                            <Button
                                size="small"
                                variant={"text"}
                                color={isRouteAttuale('categorie') && tipo == 4 ? "primary" : "chiaro"}
                                onClick={() => premutoCategoria(4)}
                            >CATEGORIE SISTEMAZIONE</Button>
                        </MenuItem>
                        <MenuItem onClick={() => {premutoCategoria(5);handleClose3()}}>
                            <Button
                                size="small"
                                variant={"text"}
                                color={isRouteAttuale('categorie') && tipo == 5 ? "primary" : "chiaro"}
                                onClick={() => premutoCategoria(5)}
                            >CATEGORIE PARTNER</Button>
                        </MenuItem>
                    </Menu>

                    <Button
                        size="small"
                        variant={isRouteAttuale("cms") ? "contained" : "text"}
                        color={isRouteAttuale("cms") ? "primary" : "chiaro"}
                        onClick={() => navigate('/cms')}
                    >CMS</Button>

                    <Button
                        id="fade-button"
                        aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        size="small"
                        variant={isAltroSelected() ? "contained" : "text"}
                        color={isAltroSelected() ? "primary" : "chiaro"}
                    >ALTRO</Button>
                    <Menu
                        id="fade-menu"
                        MenuListProps={{'aria-labelledby': 'fade-button',}}
                        anchorEl={anchorAltro}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        <MenuItem onClick={() => {handleClose(); navigate('/localita')}}>
                            <Button
                                size="small"
                                variant={"text"}
                                color={isRouteAttuale("localita") ? "primary" : "chiaro"}
                                onClick={() => navigate('/localita')}
                            >LOCALITA'</Button>
                        </MenuItem>
                        <MenuItem onClick={() => {handleClose(); navigate('/partners')}}>
                            <Button
                                size="small"
                                variant={"text"}
                                color={isRouteAttuale("partners") ? "primary" : "chiaro"}
                                onClick={() => navigate('/partners')}
                            >PARTNERS</Button>
                        </MenuItem>
                        <MenuItem onClick={() => {handleClose(); navigate('/tematiche')}}>
                            <Button
                                size="small"
                                variant={"text"}
                                color={isRouteAttuale("tematiche") ? "primary" : "chiaro"}
                                onClick={() => navigate('/tematiche')}
                            >VACANZE TEMATICHE</Button>
                        </MenuItem>
                        <MenuItem onClick={() => {handleClose(); navigate('/banner')}}>
                            <Button
                                size="small"
                                variant={"text"}
                                color={isRouteAttuale("banner") ? "primary" : "chiaro"}
                                onClick={() => navigate('/banner')}
                            >BANNER PUBBLICITARI</Button>
                        </MenuItem>
                        <MenuItem onClick={() => {handleClose(); navigate('/media-kit')}}>
                            <Button
                                size="small"
                                variant={"text"}
                                color={isRouteAttuale("media-kit") ? "primary" : "chiaro"}
                                onClick={() => navigate('/media-kit')}
                            >MEDIA KIT</Button>
                        </MenuItem>
                        <MenuItem onClick={() => {handleClose(); navigate('/catalogo-icone')}}>
                            <Button
                                size="small"
                                variant={"text"}
                                color={isRouteAttuale("catalogo-icone") ? "primary" : "chiaro"}
                                onClick={() => navigate('/catalogo-icone')}
                            >CATALOGO ICONE</Button>
                        </MenuItem>
                    </Menu>
                    <IconButton
                        size="small"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu2}
                        color="inherit"
                    >
                        <img src={Utente} className="header-img-utente" alt="Utente Lago di Garda Camping"></img>
                    </IconButton>
                    <Menu
                        id="fade-menu"
                        MenuListProps={{'aria-labelledby': 'fade-button',}}
                        anchorEl={anchorUtente}
                        open={open2}
                        onClose={handleClose2}
                        TransitionComponent={Fade}
                    >
                        <MenuItem onClick={() => {
                            handleClose2()
                            navigate('/profilo')
                        }}>Il mio Profilo</MenuItem>
                        {isSuper && <MenuItem onClick={() => {
                            handleClose2()
                            navigate('/gestione-utenti')
                        }}>Tutti gli Account</MenuItem>}
                        <MenuItem onClick={async() => await logout()}>Logout</MenuItem>
                    </Menu>
                </div>
            </div>
            <div className='admin-content'>
                <Outlet />
            </div>
        </React.Fragment>
    );
}

export default Header;