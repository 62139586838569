import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';

import utils from '../../utils/utils';

function HeaderScheda({titolo, nome, situazione, cambiaSituazione, elementi, dataCreazione, dataModifica, schedaCopiata, doPopolaSchedaCopiata, doIncollaSchedaCopiata, copiaAbilitato, isModifica, doElimina, modifiche, loading, conferma, annulla}) {

    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <div className="admin-container-prima-riga">
                <h3 className="admin-titoletto">{titolo}{nome ? (": " + nome) : ""}</h3>
                {!isModifica && schedaCopiata &&
                    <Stack direction="row" spacing={3}>
                        <Button
                            size="small"
                            variant={"contained"}
                            color={"primary"}
                            onClick={doIncollaSchedaCopiata}
                            startIcon={<ContentPasteGoIcon />}
                        >INCOLLA</Button>
                    </Stack>
                }
                {isModifica && copiaAbilitato && <Stack direction='row' spacing={3}>
                    <Button
                        size="small"
                        variant={"contained"}
                        color={"primary"}
                        onClick={doPopolaSchedaCopiata}
                        startIcon={<ContentCopyIcon />}
                    >COPIA</Button>
                    <Button
                        size="small"
                        variant={"contained"}
                        color={"error"}
                        onClick={doElimina}
                        startIcon={<HighlightOffIcon />}
                    >ELIMINA</Button>
                </Stack>}
            </div>

            {(dataCreazione || dataModifica) && 
                <Stack direction="row" alignItems="center" spacing={7} paddingBottom={2}>
                    {dataCreazione && <span className="admin-date-header-bold">Creazione: <span className="admin-date-header">{utils.scriviData(new Date(dataCreazione))} alle {utils.scriviOra(new Date(dataCreazione))}</span></span>}
                    {dataModifica && <span className="admin-date-header-bold">Modifica: <span className="admin-date-header">{utils.scriviData(new Date(dataModifica))} alle {utils.scriviOra(new Date(dataModifica))}</span></span>}
                </Stack>
            }

            <div className="admin-container-seconda-riga">
                <Stack direction="row" spacing={2}>
                    {elementi[0] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 1 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(1)}
                    >FORM</Button>}
                    {elementi[1] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 2 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(2)}
                    >CONTENUTI</Button>}
                    {elementi[5] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 6 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(6)}
                    >DATE</Button>}
                    {elementi[2] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 3 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(3)}
                    >STAGIONALITA'</Button>}
                    {elementi[3] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 4 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(4)}
                    >LOCALITA'</Button>}
                    {elementi[4] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 5 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(5)}
                    >STRUTTURE</Button>}
                    {elementi[6] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 7 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(7)}
                    >SISTEMAZIONI</Button>}
                    {elementi[7] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 8 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(8)}
                    >SERVIZI</Button>}
                    {elementi[8] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 9 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(9)}
                    >ESPERIENZE</Button>}
                    {elementi[11] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 12 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(12)}
                    >ALLOGGI</Button>}
                    {elementi[12] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 13 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(13)}
                    >TEMATICHE</Button>}
                    {elementi[13] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 14 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(14)}
                    >CATEGORIE</Button>}
                    {elementi[14] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 15 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(15)}
                    >ATTRAZIONI</Button>}
                    {elementi[15] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 16 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(16)}
                    >ITINERARI</Button>}
                    {elementi[16] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 17 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(17)}
                    >GUIDE</Button>}
                    {elementi[17] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 18 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(18)}
                    >EVENTI</Button>}
                    {elementi[18] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 19 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(19)}
                    >NEWS</Button>}
                    {elementi[9] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 10 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(10)}
                    >IMMAGINI</Button>}
                    {elementi[10] && <Button
                        size="small"
                        variant={"outlined"}
                        color={situazione == 11 ? "primary" : "chiaro"}
                        onClick={() => cambiaSituazione(11)}
                    >ALLEGATI</Button>}
                </Stack>
            </div>

            {modifiche && <Stack direction="row" spacing={5} paddingTop={3}>
                <LoadingButton
                    loading={loading}
                    loadingPosition="start"
                    size="small"
                    variant={"contained"}
                    color={"success"}
                    onClick={conferma}
                    type="submit"
                    startIcon={<CheckIcon />}
                >CONFERMA</LoadingButton>
                <Button
                    size="small"
                    variant={"contained"}
                    color={"error"}
                    onClick={annulla}
                    startIcon={<HighlightOffIcon />}
                >ANNULLA</Button>
            </Stack>}
        </div>
    );
}

export default HeaderScheda;