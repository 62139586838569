import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import itLocale from 'date-fns/locale/it';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function FormStruttura({handleChange, values, errors, touched, setFieldValue, isModifica}) {
    return (
        <React.Fragment>
            <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <Stack direction="row" spacing={7} paddingTop={1}>
                    <FormControlLabel
                        control={<Checkbox size="medium" color="primary" name="attiva" checked={values.attiva} onChange={handleChange}/>} 
                        label="Attiva"
                    />
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Stelle</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name="stelle"
                            value={values.stelle}
                            onChange={handleChange}
                            autoWidth
                            size="small"
                            label="Stelle"
                        >
                            <MenuItem value={0}><em>0</em></MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Nome Completo"
                        variant="outlined"
                        name="nome_completo"
                        onChange={handleChange}
                        value={values.nome_completo}
                        error={touched.nome_completo && Boolean(errors.nome_completo)}
                        helperText={touched.nome_completo && errors.nome_completo}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="ID Booking"
                        variant="outlined"
                        name="id_booking"
                        onChange={handleChange}
                        value={values.id_booking}
                        error={touched.id_booking && Boolean(errors.id_booking)}
                        helperText={touched.id_booking && errors.id_booking}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Indirizzo"
                        variant="outlined"
                        name="indirizzo"
                        onChange={handleChange}
                        value={values.indirizzo}
                        error={touched.indirizzo && Boolean(errors.indirizzo)}
                        helperText={touched.indirizzo && errors.indirizzo}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Latitudine"
                        variant="outlined"
                        name="latitudine"
                        onChange={handleChange}
                        value={values.latitudine}
                        error={touched.latitudine && Boolean(errors.latitudine)}
                        helperText={touched.latitudine && errors.latitudine}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Longitudine"
                        variant="outlined"
                        name="longitudine"
                        onChange={handleChange}
                        value={values.longitudine}
                        error={touched.longitudine && Boolean(errors.longitudine)}
                        helperText={touched.longitudine && errors.longitudine}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Telefono"
                        variant="outlined"
                        name="telefono"
                        onChange={handleChange}
                        value={values.telefono}
                        error={touched.telefono && Boolean(errors.telefono)}
                        helperText={touched.telefono && errors.telefono}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Whatsapp"
                        variant="outlined"
                        name="wathsapp"
                        onChange={handleChange}
                        value={values.wathsapp}
                        error={touched.wathsapp && Boolean(errors.wathsapp)}
                        helperText={touched.wathsapp && errors.wathsapp}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Sito Italiano"
                        variant="outlined"
                        name="sito_italiano"
                        onChange={handleChange}
                        value={values.sito_italiano}
                        error={touched.sito_italiano && Boolean(errors.sito_italiano)}
                        helperText={touched.sito_italiano && errors.sito_italiano}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Sito Inglese"
                        variant="outlined"
                        name="sito_inglese"
                        onChange={handleChange}
                        value={values.sito_inglese}
                        error={touched.sito_inglese && Boolean(errors.sito_inglese)}
                        helperText={touched.sito_inglese && errors.sito_inglese}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Sito Tedesco"
                        variant="outlined"
                        name="sito_tedesco"
                        onChange={handleChange}
                        value={values.sito_tedesco}
                        error={touched.sito_tedesco && Boolean(errors.sito_tedesco)}
                        helperText={touched.sito_tedesco && errors.sito_tedesco}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={7} paddingTop={3} paddingBottom={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Sito Olandese"
                        variant="outlined"
                        name="sito_olandese"
                        onChange={handleChange}
                        value={values.sito_olandese}
                        error={touched.sito_olandese && Boolean(errors.sito_olandese)}
                        helperText={touched.sito_olandese && errors.sito_olandese}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Sito Danese"
                        variant="outlined"
                        name="sito_danese"
                        onChange={handleChange}
                        value={values.sito_danese}
                        error={touched.sito_danese && Boolean(errors.sito_danese)}
                        helperText={touched.sito_danese && errors.sito_danese}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
            </div>

            <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
                    <Stack direction="row" spacing={7} paddingBottom={3}>
                        <DatePicker
                            label="Data Apertura"
                            value={values.data_apertura}
                            onChange={value => setFieldValue("data_apertura", value)}
                            renderInput={(params) => <TextField {...params} />}
                            mask='__/__/____'
                        />
                        <DatePicker
                            label="Data Chiusura"
                            value={values.data_chiusura}
                            onChange={value => setFieldValue("data_chiusura", value)}
                            renderInput={(params) => <TextField {...params} />}
                            mask='__/__/____'
                        />
                    </Stack>
                </LocalizationProvider>
            </div>

            <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <Stack direction="row" spacing={7} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Video"
                        variant="outlined"
                        name="video"
                        onChange={handleChange}
                        value={values.video}
                        error={touched.video && Boolean(errors.video)}
                        helperText={touched.video && errors.video}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Facebook"
                        variant="outlined"
                        name="facebook"
                        onChange={handleChange}
                        value={values.facebook}
                        error={touched.facebook && Boolean(errors.facebook)}
                        helperText={touched.facebook && errors.facebook}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>

                <Stack direction="row" spacing={7} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Instagram"
                        variant="outlined"
                        name="instagram"
                        onChange={handleChange}
                        value={values.instagram}
                        error={touched.instagram && Boolean(errors.instagram)}
                        helperText={touched.instagram && errors.instagram}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Youtube"
                        variant="outlined"
                        name="youtube"
                        onChange={handleChange}
                        value={values.youtube}
                        error={touched.youtube && Boolean(errors.youtube)}
                        helperText={touched.youtube && errors.youtube}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>

                <Stack direction="row" spacing={7} paddingTop={3} paddingBottom={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Twitter"
                        variant="outlined"
                        name="twitter"
                        onChange={handleChange}
                        value={values.twitter}
                        error={touched.twitter && Boolean(errors.twitter)}
                        helperText={touched.twitter && errors.twitter}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="TikTok"
                        variant="outlined"
                        name="tiktok"
                        onChange={handleChange}
                        value={values.tiktok}
                        error={touched.tiktok && Boolean(errors.tiktok)}
                        helperText={touched.tiktok && errors.tiktok}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
            </div>
        </React.Fragment>
    );
}

export default FormStruttura;