import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useFormik } from 'formik';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import HeaderScheda from '../components/scheda/HeaderScheda';
import creazione from '../api/creazione';
import useEventi from '../controllers/esperienze/useEventi';
import BarraLaterale from '../components/barraLaterale/BarraLaterale';
import FormEvento from '../components/form/FormEvento';
import ContenutoScheda from '../components/scheda/ContenutoScheda';
import BottoniScheda from '../components/scheda/BottoniScheda';
import useStagionalita from '../controllers/generale/useStagionalita';
import useLocalita from '../controllers/generale/useLocalita';
import useStrutture from '../controllers/campeggi/useStrutture';
import useEsperienze from '../controllers/esperienze/useEsperienze';
import useGenerale from '../controllers/generale/useGenerale';
import SceltaStagionalita from '../components/scheda/SceltaStagionalita';
import SceltaLocalita from '../components/scheda/SceltaLocalita';
import utils from '../utils/utils';
import validators from '../utils/validators';
import defaultValues from '../utils/defaultValues';
import SceltaDate from '../components/scheda/SceltaDate';
import SceltaStrutture from '../components/scheda/SceltaStrutture';
import SceltaImmagini from '../components/scheda/SceltaImmagini';
import SceltaAllegati from '../components/scheda/SceltaAllegati';
import useSchedaCopiata from '../controllers/schedaCopiata/useSchedaCopiata';
import upload from '../api/upload';
import FormMercato from '../components/form/FormMercato';
import ModalElimina from '../components/scheda/ModalElimina';
import SceltaCategorie from '../components/scheda/SceltaCategorie';
import FormDateVisibili from '../components/form/FormDateVisibili';
import SceltaTematiche from '../components/scheda/SceltaTematiche';

function Eventi(props) {

    const idEventi = 8

    const {state, pathname} = useLocation();
    const navigate = useNavigate();
    const {categorieEsperienza, macroCategorie} = useEsperienze()
    const {eventi, aggEvento, togliEvento, popolaEventi} = useEventi();
    const {stagioni, meteo} = useStagionalita();
    const {localita} = useLocalita();
    const {strutture} = useStrutture();
    const {tematiche} = useGenerale()
    const {popolaSchedaCopiata} = useSchedaCopiata();

    const [openAlert, setOpenAlert] = useState(0);
    const [elemento, setElemento] = useState();
    const [situazione, setSituazione] = useState(1);
    const [lingua, setLingua] = useState(1); //1 italiano, ...
    const [loading, setLoading] = useState(false);
    const [errore, setErrore] = useState();
    const [modifica, setModifica] = useState(false);
    const [visible, setVisible] = useState(false);
    const [altro, setAltro] = useState({dateEvento: [], stagioni: [], meteo: [], localita: [], strutture: [], tematiche:[], categorie:[], immagini: [], allegati: []});
    const [imgSelezionata, setImgSelezionata] = useState({img: null, isModifica: false})
    const [allSelezionato, setAllSelezionato] = useState({allegato: null, isModifica: false})
    const [contenuto, setContenuto] = useState({italiano: EditorState.createEmpty(), inglese: EditorState.createEmpty(), tedesco: EditorState.createEmpty(), olandese: EditorState.createEmpty(), danese: EditorState.createEmpty()});
    const [titolo, setTitolo] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [valoriMeta, setValoriMeta] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});

    useEffect(() => {
        cambioStato();
    }, [state])

    useEffect(() => {
        if(elemento) aggiornamentoElemento()
    }, [elemento])

    useEffect(() => {
        if(situazione && imgSelezionata.isModifica) pulisciImgSelezionata()
        if(situazione && allSelezionato.isModifica) pulisciAllegatoSelezionato()
    }, [situazione])

    const cambioStato = () => {
        if(!state) return;
        if(state.nuovoEvento) {
            setOpenAlert(true);
            setElemento(state.nuovoEvento)
            navigate(pathname, { replace: true });
        }
    }

    const cambiaElemento = (value) => setElemento(value)

    const formik = useFormik({
        validationSchema: validators.evento,
        initialValues: defaultValues.evento,
        onSubmit: async(v) => {
            if(!titolo.italiano?.length) return setErrore("Inserire il nome in Italiano.");
            setLoading(true);
            let values = {...v}
            //aggiungo i titoli
            values.nome_italiano = titolo.italiano;
            values.nome_inglese = titolo.inglese;
            values.nome_tedesco = titolo.tedesco;
            values.nome_olandese = titolo.olandese;
            values.nome_danese = titolo.danese;
            //aggiungo le descrizioni
            values.descrizione_italiano = draftToHtml(convertToRaw(contenuto.italiano.getCurrentContent()));
            values.descrizione_inglese = draftToHtml(convertToRaw(contenuto.inglese.getCurrentContent()));
            values.descrizione_tedesco = draftToHtml(convertToRaw(contenuto.tedesco.getCurrentContent()));
            values.descrizione_olandese = draftToHtml(convertToRaw(contenuto.olandese.getCurrentContent()));
            values.descrizione_danese = draftToHtml(convertToRaw(contenuto.danese.getCurrentContent()));
            //aggiungo i meta tag
            values.meta_italiano = valoriMeta.italiano;
            values.meta_inglese = valoriMeta.inglese;
            values.meta_tedesco = valoriMeta.tedesco;
            values.meta_olandese = valoriMeta.olandese;
            values.meta_danese = valoriMeta.danese;
            //controllo lat e long
            values.latitudine = parseFloat(values.latitudine) ? parseFloat(values.latitudine) : null;
            values.longitudine = parseFloat(values.longitudine) ? parseFloat(values.longitudine) : null;
            //modifico l'oggetto
            const results = await creazione.modificaEvento(values, altro.dateEvento, utils.componiLista(stagioni, altro.stagioni), utils.componiLista(meteo, altro.meteo), utils.componiLista(localita, altro.localita), utils.componiLista(strutture, altro.strutture), utils.componiLista(tematiche, altro.tematiche), altro.categorie);
            if(!results.ok) {
                setErrore(results.data);
                return setLoading(false);
            }
            //aggiungo l'oggetto al context
            aggEvento(results.data)
            //manda indietro
            setTimeout(() => {
                setOpenAlert(2);
                setLoading(false);
                setElemento(results.data);
            }, 500);
        }
    });

    const cambiaTitolo = (event) => {
        setModifica(true);
        const data = event.target.value.trim();
        const appo = {...titolo};
        if(lingua == 1) {
            appo.italiano = data;
            return setTitolo(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setTitolo(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setTitolo(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setTitolo(appo)
        } else {
            appo.danese = data;
            return setTitolo(appo)
        }
    }

    const cambiaValoriMeta = (event) => {
        setModifica(true);
        const data = event.target.value
        const appo = {...valoriMeta};
        if(lingua == 1) {
            appo.italiano = data;
            return setValoriMeta(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setValoriMeta(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setValoriMeta(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setValoriMeta(appo)
        } else {
            appo.danese = data;
            return setValoriMeta(appo)
        }
    }

    const cambiaContenuto = (newState) => {
        const appo = {...contenuto};
        if(lingua == 1) {
            if(appo.italiano.getCurrentContent() != newState.getCurrentContent()) setModifica(true);
            appo.italiano = newState;
            return setContenuto(appo)
        } else if(lingua == 2) {
            if(appo.inglese.getCurrentContent() != newState.getCurrentContent()) setModifica(true);
            appo.inglese = newState;
            return setContenuto(appo)
        } else if(lingua == 3) {
            if(appo.tedesco.getCurrentContent() != newState.getCurrentContent()) setModifica(true);
            appo.tedesco = newState;
            return setContenuto(appo)
        } else if(lingua == 4) {
            if(appo.olandese.getCurrentContent() != newState.getCurrentContent()) setModifica(true);
            appo.olandese = newState;
            return setContenuto(appo)
        } else {
            if(appo.danese.getCurrentContent() != newState.getCurrentContent()) setModifica(true);
            appo.danese = newState;
            return setContenuto(appo)
        }
    }

    const copiaContenuti = (appoTitolo, appoContenuto) => {
        setModifica(true);
        setTitolo(appoTitolo)
        setContenuto(appoContenuto)
        setOpenAlert(4)
    }

    const cambiaStagione = (position) => {
        setModifica(true);
        const appo = {...altro}
        const nuovo = altro.stagioni.map((item, index) =>
            index === position ? !item : item
        );
        appo.stagioni = nuovo;
        setAltro(appo);
    }

    const cambiaMeteo = (position) => {
        setModifica(true);
        const appo = {...altro}
        const nuovo = altro.meteo.map((item, index) =>
            index === position ? !item : item
        );
        appo.meteo = nuovo;
        setAltro(appo);
    }

    const cambiaLocalita = (position) => {
        setModifica(true);
        const appo = {...altro}
        const nuovo = altro.localita.map((item, index) =>
            index === position ? !item : item
        );
        appo.localita = nuovo;
        setAltro(appo);
    }

    const cambiaStruttura = (position) => {
        setModifica(true);
        const appo = {...altro}
        const nuovo = altro.strutture.map((item, index) =>
            index === position ? !item : item
        );
        appo.strutture = nuovo;
        setAltro(appo);
    }

    const cambiaCategoria = (id) => {
        setModifica(true);
        const appo = {...altro}
        const trovato = altro.categorie.filter((c) => c == id)[0]
        if(trovato) {
            appo.categorie = appo.categorie.filter((c) => c != id)
        } else {
            appo.categorie.push(id)
        }
        setAltro(appo);
    }

    const cambiaTematica = (position) => {
        setModifica(true);
        const appo = {...altro}
        const nuovo = altro.tematiche.map((item, index) =>
            index === position ? !item : item
        );
        appo.tematiche = nuovo;
        setAltro(appo);
    }

    const aggiungiDate = (data_inizio, data_fine) => {
        if(data_inizio > data_fine) return setErrore("La Data finale deve essere maggiore di quella iniziale.")
        if(altro.dateEvento.filter((d) => utils.scriviData(d.data_inizio) == utils.scriviData(data_inizio) || utils.scriviData(d.data_fine) == utils.scriviData(data_fine)).length > 0) return setErrore("Date già inserita.");
        setModifica(true)
        const appo = {...altro}
        appo.dateEvento.push({data_inizio, data_fine});
        setAltro(appo)
    }

    const togliDate = (pos) => {
        setModifica(true)
        const appo = {...altro}
        appo.dateEvento = appo.dateEvento.filter((d, index) => index != pos);
        setAltro(appo)
    }

    const creaImmagine = async(file, importanza) => {
        //preparo il body
        let formData = new FormData();
        formData.append('immagine', file, file.name);
        formData.append('idElemento', elemento.id)
        formData.append('tipologia', 3)
        formData.append('nome_italiano', '')
        formData.append('nome_inglese', '')
        formData.append('nome_tedesco', '')
        formData.append('nome_olandese', '')
        formData.append('nome_danese', '')
        formData.append('importanza', importanza)
        //chiama api per creare immagini
        const results = await upload.creaImmagine(formData);
        if(!results.ok) return setErrore(results.data)
        //modifico gli state
        const appo = {...altro}
        appo.immagini.push(results.data);
        elemento.immagini.push(results.data)
        setAltro(appo)
    }

    const aggiornaImmagine = async(titolo) => {
        //preparo il body
        const img = imgSelezionata.img;
        let formData = new FormData();
        formData.append('id', img.id)
        formData.append('nome_italiano', titolo.italiano)
        formData.append('nome_inglese', titolo.inglese)
        formData.append('nome_tedesco', titolo.tedesco)
        formData.append('nome_olandese', titolo.olandese)
        formData.append('nome_danese', titolo.danese)
        //chiama api per modificare immagini
        const results = await upload.modificaImmagine(formData);
        if(!results.ok) return setErrore(results.data)
        //modifico gli state
        const appo = {...altro}
        for(let i = 0; i < appo.immagini.length; i++) {
            if(appo.immagini[i].id == img.id) appo.immagini[i] = results.data;
        }
        elemento.immagini = appo.immagini;
        setAltro(appo)
    }

    const chiediEliminaImmagine = (img) => {
        setImgSelezionata({img, isModifica: false})
        setOpenAlert(200)
    }

    const chiudiModalImmagine = () => {
        setImgSelezionata({img: null, isModifica: false})
        setOpenAlert(0)
    }

    const eliminaImmagine = async() => {
        if(loading) return;
        setLoading(true)
        //elimino l'immagine
        const id = imgSelezionata.img.id
        const results = await upload.eliminaImmagine(id);
        if(!results.ok) return setErrore(results.data)
        setTimeout(() => {
            //modifico gli state
            const appo = {...altro}
            appo.immagini = appo.immagini.filter((img) => img.id != id);
            elemento.immagini = elemento.immagini.filter((img) => img.id != id);
            setAltro(appo)
            setLoading(false)
            setOpenAlert(0)
            setImgSelezionata({img: null, isModifica: false})
        }, 300);
    }

    const aggiornaImportanzaImmagini = async(listaId) => {
        //aggiorno sul server
        const results = await upload.aggiornaImportanzaImg(listaId)
        if(!results.ok) return setErrore(results.data)
        setErrore()
        //devo aggiornarle anche in locale
        const listaImg = []
        let appo = null
        for(let i = 0; i < listaId.length; i++) {
            appo = elemento.immagini.filter((img) => img.id == listaId[i])[0]
            if(appo) listaImg.push(appo)
        }
        elemento.immagini = listaImg
    }

    const creaAllegato = async(file, lingua) => {
        //preparo il body
        let formData = new FormData();
        formData.append('allegato', file, file.name);
        formData.append('idElemento', elemento.id)
        formData.append('tipologia', 3)
        formData.append('nome', '')
        formData.append('lingua', lingua)
        //chiama api per creare allegati
        const results = await upload.creaAllegato(formData);
        if(!results.ok) return setErrore(results.data)
        //modifico gli state
        const appo = {...altro}
        appo.allegati.push(results.data);
        elemento.allegati.push(results.data)
        setAltro(appo)
    }

    const isLinguaAllegato = (idLingua) => {
        if(idLingua == 1 && titolo.italiano.length) return true
        if(idLingua == 2 && titolo.inglese.length) return true
        if(idLingua == 3 && titolo.tedesco.length) return true
        if(idLingua == 4 && titolo.olandese.length) return true
        if(idLingua == 5 && titolo.danese.length) return true
        return false
    }

    const aggiornaAllegato = async(titolo) => {
        //preparo il body
        const all = allSelezionato.allegato;
        let formData = new FormData();
        formData.append('id', all.id)
        formData.append('nome', titolo)
        //chiama api per modificare allegati
        const results = await upload.modificaAllegato(formData);
        if(!results.ok) return setErrore(results.data)
        //modifico gli state
        const appo = {...altro}
        for(let i = 0; i < appo.allegati.length; i++) {
            if(appo.allegati[i].id == all.id) appo.allegati[i] = results.data;
        }
        elemento.allegati = appo.allegati;
        setAltro(appo)
    }

    const chiediEliminaAllegato = (allegato) => {
        setAllSelezionato({allegato, isModifica: false})
        setOpenAlert(100)
    }

    const chiudiModalAllegato = () => {
        setAllSelezionato({allegato: null, isModifica: false})
        setOpenAlert(0)
    }

    const eliminaAllegato = async() => {
        if(loading) return;
        setLoading(true)
        //elimino l'allegato
        const id = allSelezionato.allegato.id
        const results = await upload.eliminaAllegato(id);
        if(!results.ok) return setErrore(results.data)
        setTimeout(() => {
            //modifico gli state
            const appo = {...altro}
            appo.allegati = appo.allegati.filter((all) => all.id != id);
            elemento.allegati = elemento.allegati.filter((all) => all.id != id);
            setAltro(appo)
            setLoading(false)
            setOpenAlert(0)
            setAllSelezionato({allegato: null, isModifica: false})
        }, 400);
    }

    const aggiornamentoElemento = async() => {
        //cambio modifica
        setModifica(false)
        //cambio nome
        setTitolo({italiano: elemento.nome_italiano, inglese: elemento.nome_inglese, tedesco: elemento.nome_tedesco, olandese: elemento.nome_olandese, danese: elemento.nome_danese})
        //cambio meta
        setValoriMeta({
            italiano: elemento.meta_italiano ? elemento.meta_italiano : '', 
            inglese: elemento.meta_inglese ? elemento.meta_inglese : '', 
            tedesco: elemento.meta_tedesco ? elemento.meta_tedesco : '', 
            olandese: elemento.meta_olandese ? elemento.meta_olandese : '', 
            danese: elemento.meta_danese ? elemento.meta_danese : ''
        })
        //cambio contenuti
        setContenuto({italiano: aggEditorState(elemento.descrizione_italiano), inglese: aggEditorState(elemento.descrizione_inglese), tedesco: aggEditorState(elemento.descrizione_tedesco), olandese: aggEditorState(elemento.descrizione_olandese), danese: aggEditorState(elemento.descrizione_danese)})
        //cambio valori formik
        if(!elemento.latitudine) elemento.latitudine = '';
        if(!elemento.longitudine) elemento.longitudine = '';
        await formik.setValues(utils.omit(elemento, 'tipologia', 'categorie', 'tematiche', 'collaborazioni', 'dateEvento', 'stagioni', 'meteo', 'localita', 'strutture', 'immagini', 'allegati', 'data_creazione', 'data_modifica', 'nome_italiano', 'nome_inglese', 'nome_tedesco', 'nome_olandese', 'nome_danese', 'descrizione_italiano', 'descrizione_inglese', 'descrizione_tedesco', 'descrizione_olandese', 'descrizione_danese', 'slug_italiano', 'slug_inglese', 'slug_tedesco', 'slug_olandese', 'slug_danese', 'meta_italiano', 'meta_inglese', 'meta_tedesco', 'meta_olandese', 'meta_danese'))
        //pulisco i cross
        setAltro({
            dateEvento: utils.caricaDate(elemento.dateEvento),
            stagioni: utils.componiListaBool(stagioni, elemento.stagioni, 'stagione'),
            meteo: utils.componiListaBool(meteo, elemento.meteo, 'meteo'),
            localita: utils.componiListaBool(localita, elemento.localita, 'localita'),
            strutture: utils.componiListaBool(strutture, elemento.strutture, 'struttura'),
            tematiche: utils.componiListaBool(tematiche, elemento.tematiche, 'tematica'),
            categorie: utils.componiListaCategorie(elemento.categorie),
            immagini: elemento.immagini.map((img) => img),
            allegati: Array.from(elemento.allegati)
        });
        //pulisco le immagini e gli allegati
        pulisciImgSelezionata()
        pulisciAllegatoSelezionato()
    }

    const aggEditorState = (valore) => {
        const blocksFromHtml = htmlToDraft(valore);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const appo = EditorState.createWithContent(contentState);
        return appo;
    }

    const pulisciImgSelezionata = () => setImgSelezionata({img: null, isModifica: false})
    const pulisciAllegatoSelezionato = () => setAllSelezionato({allegato: null, isModifica: false})

    const doPopolaSchedaCopiata = () => {
        if(!elemento) return;
        let valori = formik.values;
        valori.attivo = false;
        popolaSchedaCopiata({values: valori, titolo, contenuto, altro: utils.omit(altro, "immagini", "allegati")})
        setOpenAlert(3)
    }

    const eliminaElemento = async() => {
        if(!elemento || loading) return;
        setLoading(true)
        const results = await creazione.eliminaEvento(elemento.id)
        if(!results.ok) {
            setLoading(false)
            return setErrore(results.data)
        }
        setTimeout(() => {
            togliEvento(elemento.id)
            setOpenAlert(5)
            setLoading(false)
            setVisible(false)
            setElemento()
        }, 600);
    }

    const onDragEnd = async(result) => {
        if(!result.destination) return;
        let listCopy = Array.from(eventi);
        //rimuovo elemento dalla lista di partenza
        const [removedElement, newSourceList] = utils.removeFromList(
            listCopy,
            result.source.index
        );
        listCopy = newSourceList;
        //aggiungo elemento alla  di destinazione
        listCopy = utils.addToList(
            listCopy,
            result.destination.index,
            removedElement
        );
        //aggiorno il tutto
        popolaEventi(listCopy)
        //aggiorno le api
        const vett = []
        for(let i = 0; i < listCopy.length; i++) vett.push(listCopy[i].id)
        const results = await creazione.importanzaEvento(vett)
        if(!results.ok) return setErrore(results.data)
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setOpenAlert(false)
        setErrore()
    }

    const goToNuova = () => navigate('/eventi/crea')

    return (
        <div className="admin-corpo-modifica">
            <div className="admin-corpo-modifica-sx">
                <BarraLaterale
                    titolo={"LISTA di EVENTI"}
                    titoloBottone={"NUOVO"}
                    lista={eventi}
                    goToNuova={goToNuova}
                    cambiaElemento={cambiaElemento}
                    elementoSelezionato={elemento}
                    hasCategorie={true}
                    categorie={categorieEsperienza.filter((c) => c.macro == idEventi)}
                    isEsperienze={true}
                    onDragEnd={onDragEnd}
                ></BarraLaterale>
            </div>

            <div className="admin-corpo-modifica-dx">
                <HeaderScheda
                    titolo={"EVENTO"}
                    elementi={[true, true, true, true, true, true, false, false, false, true, true, false, true, true]}
                    situazione={situazione}
                    cambiaSituazione={v => {if(elemento) setSituazione(v)}}
                    nome={elemento?.nome_italiano.length > 0 ? elemento?.nome_italiano : null}
                    dataCreazione={elemento ? elemento.data_creazione : null}
                    dataModifica={elemento ? elemento.data_modifica : null}
                    doPopolaSchedaCopiata={() => doPopolaSchedaCopiata()}
                    copiaAbilitato={Boolean(elemento)}
                    isModifica={true}
                    doElimina={() => setVisible(1)}
                    loading={loading}
                    modifiche={modifica}
                    conferma={formik.handleSubmit}
                    annulla={() => aggiornamentoElemento()}
                ></HeaderScheda>

                {!elemento &&
                    <div className="admin-container-lungo"><h3 className="admin-titoletto">Nessun Evento Selezionato</h3></div>
                }
                {elemento &&
                    <>
                        {situazione == 1 && 
                            <FormEvento
                                values={formik.values}
                                errors={formik.errors}
                                touched={formik.touched}
                                handleChange={(value) => {setModifica(true);formik.handleChange(value)}}
                                setFieldValue={(name, value) => {setModifica(true);formik.setFieldValue(name, value)}}
                                isModifica={true}
                            ></FormEvento>
                        }
                        {situazione == 2 &&
                            <ContenutoScheda
                                lingua={lingua}
                                cambiaLingua={(v) => setLingua(v)}
                                titolo={titolo}
                                cambiaTitolo={cambiaTitolo}
                                contenuto={contenuto}
                                cambiaContenuto={cambiaContenuto}
                                copiaContenuti={copiaContenuti}
                                isModifica={true}
                                isMeta={true}
                                valoriMeta={valoriMeta}
                                cambiaValoriMeta={cambiaValoriMeta}
                            ></ContenutoScheda>
                        }
                        {situazione == 3 &&
                            <SceltaStagionalita
                                meteo={meteo}
                                stagioni={stagioni}
                                stagioniSel={altro.stagioni}
                                meteoSel={altro.meteo}
                                cambiaStagione={cambiaStagione}
                                cambiaMeteo={cambiaMeteo}
                                isModifica={true}
                            ></SceltaStagionalita>
                        }
                        {situazione == 4 &&
                            <SceltaLocalita
                                listaSel={altro.localita}
                                localita={localita}
                                cambiaLocalita={cambiaLocalita}
                                isModifica={true}
                            ></SceltaLocalita>
                        }
                        {situazione == 5 &&
                            <SceltaStrutture
                                strutture={strutture}
                                struttureSelezionate={altro.strutture}
                                cambiaStruttura={cambiaStruttura}
                                isModifica={true}
                            ></SceltaStrutture>
                        }
                        {situazione == 6 && <>
                            <FormMercato
                                values={formik.values}
                                errors={formik.errors}
                                touched={formik.touched}
                                handleChange={(value) => {setModifica(true);formik.handleChange(value)}}
                                setFieldValue={(name, value) => {setModifica(true);formik.setFieldValue(name, value)}}
                                isModifica={true}
                            ></FormMercato>
                            {/* <FormDateVisibili
                                values={formik.values}
                                errors={formik.errors}
                                touched={formik.touched}
                                handleChange={(value) => {setModifica(true);formik.handleChange(value)}}
                                setFieldValue={(name, value) => {setModifica(true);formik.setFieldValue(name, value)}}
                                isModifica={true}
                            ></FormDateVisibili> */}
                            <SceltaDate
                                listaDate={altro.dateEvento}
                                aggiungiDate={aggiungiDate}
                                togliDate={togliDate}
                                cambiaErrore={(v) => setErrore(v)}
                                isModifica={true}
                            ></SceltaDate>
                        </>}
                        {situazione == 10 &&
                            <SceltaImmagini
                                immagini={altro.immagini}
                                creaImmagine={creaImmagine}
                                eliminaImmagine={chiediEliminaImmagine}
                                aggiornaImmagine={aggiornaImmagine}
                                selezionata={imgSelezionata}
                                cambiaSelezionata={(v) => setImgSelezionata(v)}
                                aggiornaImportanzaImmagini={aggiornaImportanzaImmagini}
                                isModifica={true}
                            ></SceltaImmagini>
                        }
                        {situazione == 11 &&
                            <SceltaAllegati
                                allegati={altro.allegati}
                                creaAllegato={creaAllegato}
                                eliminaAllegato={chiediEliminaAllegato}
                                aggiornaAllegato={aggiornaAllegato}
                                selezionato={allSelezionato}
                                cambioSelezionato={(v) => setAllSelezionato(v)}
                                isLinguaAllegato={isLinguaAllegato}
                                isModifica={true}
                            ></SceltaAllegati>
                        }
                        {situazione == 13 &&
                            <SceltaTematiche
                                tematiche={tematiche}
                                listaSel={altro.tematiche}
                                cambiaTematica={cambiaTematica}
                                isModifica={true}
                            ></SceltaTematiche>
                        }
                        {situazione == 14 &&
                            <SceltaCategorie
                                macro={macroCategorie}
                                categorie={categorieEsperienza}
                                listaSel={altro.categorie}
                                cambiaCategoria={cambiaCategoria}
                                isModifica={true}
                            ></SceltaCategorie>
                        }
                        {modifica &&
                            <BottoniScheda
                                loading={loading}
                                conferma={formik.handleSubmit}
                                annulla={() => aggiornamentoElemento()}
                            ></BottoniScheda>
                        }
                    </>
                }
            </div>

            <Snackbar open={openAlert == 1} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Evento inserito con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 2} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Evento modificato con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 3} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Evento Copiato</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 4} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Contenuti copiati nelle diverse linue.</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 5} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Evento eliminato con successo.</Alert>
            </Snackbar>
            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>

            {elemento &&
                <ModalElimina
                    nome={"questo Evento"}
                    nome_vero={elemento.nome_italiano}
                    visible={visible}
                    loading={loading}
                    annulla={() => setVisible(false)}
                    conferma={eliminaElemento}
                ></ModalElimina>
            }
            {allSelezionato.allegato &&
                <ModalElimina
                    nome={"questo Allegato"}
                    nome_vero={allSelezionato.allegato.nome}
                    visible={openAlert === 100}
                    loading={loading}
                    annulla={() => chiudiModalAllegato()}
                    conferma={eliminaAllegato}
                ></ModalElimina>
            }
            {imgSelezionata.img &&
                <ModalElimina
                    nome={"questa Immagine"}
                    nome_vero={imgSelezionata.img.nome_italiano}
                    visible={openAlert === 200}
                    loading={loading}
                    annulla={() => chiudiModalImmagine()}
                    conferma={eliminaImmagine}
                ></ModalElimina>
            }
        </div>
    );
}

export default Eventi;