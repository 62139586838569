import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

function SceltaIconaBooking({iconaBooking, cambiaIconaBooking, isModifica}) {

    const isIcona = iconaBooking.length > 0

    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <Stack direction="row" spacing={2} paddingBottom={3}>
                <h3 className="admin-titoletto">ICONA FONT</h3>
            </Stack>
            <TextField 
                id="outlined-basic"
                label="Nome Icona"
                variant="outlined"
                onChange={cambiaIconaBooking}
                value={iconaBooking}
                size={"small"}
                fullWidth
                autoComplete="off"
            />

            {isIcona &&
                <Box sx={{padding:'40px', paddingBottom:'20px', fontSize:'40px', color:'black'}}>
                    <i className={iconaBooking}></i>
                </Box>
            }
        </div>
    );
}

export default SceltaIconaBooking;