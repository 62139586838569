import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FileUploadIcon from '@mui/icons-material/FileUpload';

function SceltaLogo({logo, caricaLogo, pulisciLogo, isIcona, isModifica}) {
    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <Stack direction="row">
                <Stack direction="column" spacing={1} width="30%" alignItems="center" justifyContent="center">
                    <h3 className="admin-titoletto">{isIcona ? "ICONA" : "LOGO"}</h3>
                    {!logo.file && 
                        <Stack paddingTop={1}>
                            <TextField 
                                id="raised-button-file"
                                label="Immagine"
                                variant="outlined"
                                value=""
                                onChange={(event) => caricaLogo(event)}
                                type="file"
                                size={"small"}
                                fullWidth
                                autoComplete="off"
                                style={{ display: 'none' }}
                            />
                            <label className="admin-linea-bottone-immagine" htmlFor="raised-button-file">
                                <Button size="small" variant="contained" color="primary" component="span" startIcon={<FileUploadIcon />}>Upload</Button>
                            </label>
                        </Stack>
                    }
                    {(logo.file || logo.preview) && <Button size="small" variant="contained" color="error" component="span" startIcon={<HighlightOffIcon />} onClick={() => pulisciLogo()}>Elimina</Button>}
                </Stack>
                
                <Stack direction="column" alignItems="center" justifyContent="center">
                    {logo.preview && <img className={isIcona ? "admin-anteprima-icona" : "admin-anteprima-logo"} src={logo.preview}></img>}
                </Stack>
            </Stack>
        </div>
    );
}

export default SceltaLogo;