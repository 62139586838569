import React, {useState, useCallback, useEffect} from 'react';
import { DragDropContext, Droppable, Draggable  } from 'react-beautiful-dnd';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function ElencoImmaginiMedia({immagini, premiImmagine, premiNuova, categorie, premiElimina, defaultFiltro, aggiornaImportanzaImmagini}) {

    const [filtro, setFiltro] = useState(0)
    const [lista, setLista] = useState([])
    const numero = 4; //quante immagini per riga

    useEffect(() => {
        creaListeImmagini()
    }, [immagini, filtro])

    useEffect(() => {
        const aggFiltro = () => setFiltro(defaultFiltro)
        aggFiltro()
    }, [defaultFiltro])

    const filtraImmagini = () => {
        if(filtro === 0) return immagini
        if(filtro === -1) return immagini.filter((el) => !el.categoria)
        return immagini.filter((el) => el.categoria == filtro)
    }

    const creaListeImmagini = () => {
        const appo = new Array();
        let vett = new Array();
        const listaImg = filtraImmagini()
        for(let i = 0; i < listaImg.length; i++) {
            if(i > 0 && i % numero == 0) {
                appo.push(vett)
                vett = new Array()
                vett.push(listaImg[i])
            } else {
                vett.push(listaImg[i])
            }
        }
        if(vett.length > 0) appo.push(vett)
        setLista(appo)
    }

    const scriviNomeCategoria = (idCat) => {
        const appo = categorie.filter((c) => c.id == idCat)[0]
        if(!appo) return 'SENZA CATEGORIA'
        return appo.nome_italiano
    }

    const isSenzaCategoria = useCallback(() => {
        const appo = immagini.filter((el) => !el.categoria)
        return appo.length > 0
    }, [immagini])

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const listCopy = Array.from(lista);
        //rimuovo elemento dalla lista di partenza
        const sourceList = listCopy[result.source.droppableId];
        const [removedElement, newSourceList] = removeFromList(
            sourceList,
            result.source.index
        );
        listCopy[result.source.droppableId] = newSourceList;
        //aggiungo elemento alla  di destinazione
        const destinationList = listCopy[result.destination.droppableId];
        listCopy[result.destination.droppableId] = addToList(
            destinationList,
            result.destination.index,
            removedElement
        );
        //sistemo le liste con numero immagini ciascuna
        const risultato = [];
        let appoLista = [];
        let i = 0;
        while(i < listCopy.length) {
            //se ha numero elementi ok
            if(listCopy[i].length == numero) {
                risultato.push(listCopy[i])
                i++
            }
            //se non ha elementi la salto
            else if(listCopy[i].length == 0) i++
            //se ha più di numero
            else if(listCopy[i].length > numero) {
                //rimuovo il quarto
                const [removedElement, newSourceList] = removeFromList(listCopy[i], numero);
                listCopy[i] = newSourceList;
                //lo aggiungo come primo alla lista dopo
                appoLista = [];
                if(listCopy[i + 1]) {
                    appoLista = addToList(listCopy[i + 1], 0, removedElement);
                    listCopy[i + 1] = appoLista;
                } else {
                    appoLista.push(removedElement);
                    listCopy.push(appoLista);
                }
            }
            //se ne ha meno di numero
            else if(listCopy[i].length < numero) {
                //se non esiste quello successivo ok
                if(!listCopy[i + 1]) {
                    risultato.push(listCopy[i])
                    i++
                }
                //se esiste quello successivo
                else {
                    //rimuovo il primo dalla prossima lista
                    const [removedElement, newSourceList] = removeFromList(listCopy[i + 1], 0);
                    listCopy[i + 1] = newSourceList;
                    //lo aggiungo come ultimo a questa
                    appoLista = addToList(listCopy[i], listCopy[i].length, removedElement);
                    listCopy[i] = appoLista;
                }
            }
        }
        setLista(risultato);
        startAggiornaImportanza(risultato)
    }

    const startAggiornaImportanza = async(vett) => {
        const lista = [];
        for(let i = 0; i < vett.length; i++) {
            for(let j = 0; j < vett[i].length; j++) {
                lista.push(vett[i][j].id)
            }
        }
        aggiornaImportanzaImmagini(lista)
    }

    const removeFromList = (list, index) => {
        const result = Array.from(list);
        const [removed] = result.splice(index, 1);
        return [removed, result];
    }
      
    const addToList = (list, index, element) => {
        const result = Array.from(list);
        result.splice(index, 0, element);
        return result;
    }

    return (
        <div className="admin-container">
            <h3 className="admin-titoletto">IMMAGINI</h3>
            <Stack direction="row" spacing={2} marginTop={1}>
                <Button
                    size="small"
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => premiNuova(filtro)}
                    startIcon={<AddCircleOutlineIcon />}
                >NUOVA IMMAGINE</Button>
            </Stack>

            <Box marginTop={4}>
                <Typography sx={{fontSize:20, fontWeight:'bold'}}>Filtra per categoria</Typography>
                <Stack direction="row" paddingTop={1}>
                    <FormControl sx={{ minWidth: 150, maxWidth: 300 }}>
                        <InputLabel id="demo-simple-select-autowidth-label-896">Categoria</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label-896"
                            id="demo-simple-select-autowidth-896"
                            name="categoria"
                            value={filtro}
                            onChange={(event) => setFiltro(event.target.value)}
                            autoWidth
                            label="Categoria"
                            size="small"
                        >
                            <MenuItem value={0}><em>Tutte</em></MenuItem>
                            {isSenzaCategoria() && <MenuItem value={-1}><em>Senza Categoria</em></MenuItem>}
                            {categorie.map((cat, index) => (
                                <MenuItem value={cat.id} key={index}>{cat.nome_italiano}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </Box>

            <Box sx={{display:'block', width:'100%', marginTop:8}}>
                {filtraImmagini().length == 0 && <Box sx={{pl:3}}>
                    <h4 className="admin-titoletto">Nessun elemento presente</h4>
                </Box>}
                {filtraImmagini().length > 0 &&
                    <DragDropContext onDragEnd={onDragEnd}>
                        {lista.map((elementi, index) => (
                            <Droppable key={index} droppableId={index.toString()} direction="horizontal">
                            {(provided) => (
                                <Box sx={{width:'100%', display:'flex'}} {...provided.droppableProps} ref={provided.innerRef}>
                                    {elementi.map((img, index) => (
                                        <Draggable key={img.id} draggableId={img.id.toString()} index={index}>
                                        {(provided2) => (
                                            <Box
                                                ref={provided2?.innerRef} {...provided2?.draggableProps} {...provided2?.dragHandleProps}
                                                sx={{width:'25%', marginBottom:'20px'}}
                                            >
                                                <Box sx={{border:'1px solid #dcdcdc', height:'100%', borderRadius:'16px', overflow:'hidden',
                                                    display:'flex', flexDirection:'column', justifyContent:'space-between', 
                                                    minHeight:'270px', margin:'0 20px 0 0'
                                                }}>
                                                    <Box sx={{display:'flex', flexDirection:'column'}}>
                                                        <Box component='img' alt='' src={img.link} sx={{width:'100%', height:'160px', borderTopLeftRadius:'16px', borderTopRightRadius:'16px'}} />
                                                        <Typography sx={{paddingLeft:1, paddingRight:1, pt:'8px', fontSize:'16px', fontWeight:'bold', lineHeight:'1.1'}}>{img.nome_italiano}</Typography>
                                                        <Typography sx={{paddingLeft:1, pt:'4px', color:'#A9A9A9', fontSize:'14px', fontWeight:'normal'}}>{scriviNomeCategoria(img.categoria)}</Typography>
                                                    </Box>
                                                    <Stack direction='row' spacing={0} sx={{
                                                        width:'100%', justifyContent:'flex-end', paddingTop:'2px', paddingBottom:'4px'
                                                    }}>
                                                        <IconButton
                                                            size="small"
                                                            sx={{marginRight:1}}
                                                            onClick={() => premiElimina(2, img)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            sx={{marginRight:1}}
                                                            onClick={() => premiImmagine(img, filtro)}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Stack>
                                                </Box>
                                            </Box>
                                        )}
                                        </Draggable>
                                    ))}
                                </Box>
                            )}
                            </Droppable>
                        ))}
                    </DragDropContext>
                }
            </Box>
        </div>
    );
}

export default ElencoImmaginiMedia;