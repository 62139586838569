import React, { useState } from 'react';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';

import authApi from '../../api/auth'
import validators from '../../utils/validators'
import defaultValues from '../../utils/defaultValues'
import useAuth from '../../controllers/auth/useAuth'
import FormPassword from '../../components/form/FormPassword'
import BottoniScheda from '../../components/scheda/BottoniScheda'

function Profilo(props) {

    const {utente, logout} = useAuth()

    const [errore, setErrore] = useState(null)
    const [loading, setLoading] = useState(false)
    const [openAlert, setOpenAlert] = useState(0)
    const [isPassword, setIsPassword] = useState(false)

    const scriviPermessiUtente = () => {
        if(!utente) return '-'
        if(utente.is_super || utente.username === 'elena') return 'Super Admin'
        if(utente.is_admin) return 'Editor'
        return 'Sola lettura'
    }

    const formik = useFormik({
        validationSchema: validators.cambioPassword,
        initialValues: defaultValues.cambioPassword,
        onSubmit: async(values) => {
            if(values.nuova != values.ripeti) return setErrore('La nuova password deve coincidere.')
            setLoading(true)
            const results = await authApi.modificaPassword({
                vecchia:values.vecchia, nuova:values.nuova
            })
            if(!results.ok) {
                setLoading(false)
                return setErrore(results.data)
            }
            setTimeout(() => {
                setErrore(null)
                setLoading(false)
                setOpenAlert(1)
                setIsPassword(false)
            }, 500);
        }
    });

    const premiAnnulla = () => {
        setIsPassword(false)
        formik.setFieldValue('vecchia', '')
        formik.setFieldValue('nuova', '')
        formik.setFieldValue('ripeti', '')
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setOpenAlert(0);
        setErrore();
    }

    return (
        <React.Fragment>
            <Box sx={{width:'70%', backgroundColor:'white', borderRadius:'10px', border:'1px solid #dcdcdc', p:2, 
                marginTop:'20px', display:'flex', flexDirection:'column'
            }}>
                <Typography sx={{fontSize:24, fontWeight:'bold'}}>Il mio profilo</Typography>
                <Box sx={{padding:'16px 0 0'}}>
                    <Typography sx={{fontSize:20, fontWeight:'500', color:'chiaro.main'}}>
                        Nome Utente:&nbsp;
                        <Typography component='span' sx={{fontSize:20, display: 'inline-block', fontWeight:'500', color:'black'}}>
                            {utente ? utente.username : '-'}
                        </Typography>
                    </Typography>
                    <Typography sx={{fontSize:20, fontWeight:'500', color:'chiaro.main'}}>
                        Permessi:&nbsp;
                        <Typography component='span' sx={{fontSize:20, display: 'inline-block', fontWeight:'500', color:'black'}}>
                            {scriviPermessiUtente()}
                        </Typography>
                    </Typography>
                </Box>
                <Box sx={{padding:'24px 0 0'}}>
                    <Stack direction="row" spacing={2}>
                        {!isPassword && <Button
                            size="small"
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => setIsPassword(true)}
                            startIcon={<KeyIcon />}
                        >MODIFICA PASSWORD</Button>}
                        <Button
                            size="small"
                            variant={"contained"}
                            color={"warning"}
                            onClick={async() => await logout()}
                            sx={{width:'130px'}}
                            startIcon={<LogoutIcon />}
                        >LOGOUT</Button>
                    </Stack>
                </Box>
            </Box>

            <Box sx={{width:'70%', backgroundColor:'white', borderRadius:'10px', border:'1px solid #dcdcdc', p:2, 
                marginTop:'30px', display:isPassword ? 'flex' : 'none', flexDirection:'column'
            }}>
                <Typography sx={{fontSize:24, fontWeight:'bold'}}>Modifica Password</Typography>
                <FormPassword
                    values={formik.values}
                    errors={formik.errors}
                    touched={formik.touched}
                    handleChange={(value) => formik.handleChange(value)}
                ></FormPassword>
                <BottoniScheda
                    loading={loading}
                    conferma={formik.handleSubmit}
                    annulla={() => premiAnnulla()}
                ></BottoniScheda>
            </Box>

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 1} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Password modificata con successo!</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default Profilo;