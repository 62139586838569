import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import itLocale from 'date-fns/locale/it';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import utils from '../../utils/utils';

function SceltaDate({listaDate, aggiungiDate, togliDate, cambiaErrore, isModifica}) {

    const [dataInizio, setDataInizio] = useState(null);
    const [dataFine, setDataFine] = useState(null);

    return (
        <React.Fragment>
            <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <h3 className="admin-titoletto">Inserisci Date</h3>
                <Stack direction="column" spacing={3} paddingTop={3} paddingBottom={3}>
                    <Stack direction='row' spacing={10}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
                            <DatePicker
                                label="Nuova Data Inizio"
                                value={dataInizio}
                                onChange={value => setDataInizio(value)}
                                renderInput={(params) => <TextField {...params} />}
                                mask='__/__/____'
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
                            <DatePicker
                                label="Nuova Data Fine"
                                value={dataFine}
                                onChange={value => setDataFine(value)}
                                renderInput={(params) => <TextField {...params} />}
                                mask='__/__/____'
                            />
                        </LocalizationProvider>
                    </Stack>
                    {dataInizio && dataFine &&
                        <Stack direction="column" spacing={2}>
                            <h3 className="admin-titoletto">
                                <span>Data Selezionata:  </span>
                                <span className="admin-testo-date">{utils.scriviData(dataInizio)}</span>
                                <span className="admin-testo-date"> - {utils.scriviData(dataFine)}</span>
                            </h3>
                            <Button
                                size="small"
                                variant={"contained"}
                                color={"primary"}
                                sx={{width: 220}}
                                onClick={() => {
                                    if(!(dataInizio instanceof Date && !isNaN(dataInizio))) return cambiaErrore("Data Inizio non valida.");
                                    if(!(dataFine instanceof Date && !isNaN(dataFine))) return cambiaErrore("Data Fine non valida.");
                                    cambiaErrore();
                                    aggiungiDate(dataInizio, dataFine);
                                    setDataInizio(null);
                                    setDataFine(null);
                                }}
                                startIcon={<AddCircleOutlineIcon />}
                            >AGGIUNGI</Button>
                        </Stack>
                    }
                </Stack>

                <h3 className="admin-titoletto">Lista Date</h3>
                <List component="div" aria-label="main mailbox folders">
                    {listaDate.length == 0 && <span>Nessuna data inserita.</span>}
                    {listaDate.map((data, index) => (
                        <ListItem key={index}>
                            <ListItemIcon onClick={() => togliDate(index)}><IconButton edge="end" aria-label="delete"><DeleteIcon/></IconButton></ListItemIcon>
                            <ListItemText primary={utils.scriviData(data.data_inizio) + " - " + utils.scriviData(data.data_fine)}></ListItemText>
                        </ListItem>
                    ))}
                </List>
            </div>
        </React.Fragment>
    );
}

export default SceltaDate;