import './App.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import authApi from './api/auth';
import getters from './api/getters';
import Navigator from './navigation/Navigator';
import CmsContext from './controllers/cms/context';
import AuthContext from './controllers/auth/context';
import authStorage from './controllers/auth/storage';
import EsperienzeContext from './controllers/esperienze/context';
import GeneraleContext from './controllers/generale/context';
import OfferteContext from './controllers/offerte/context';
import NewsContext from './controllers/news/context';
import PartnersContext from './controllers/partners/context';
import MediaKitContext from './controllers/mediaKit/context';
import CampeggiContext from './controllers/campeggi/context';
import SchedaCopiataContext from './controllers/schedaCopiata/context';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1000,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: '#F9B433',
            contrastText: "#FFFFFF",
            dark: "#F9B433"
        },
        success: {
            main: '#1B5E20',
            dark: "#1B5E20",
            light: "#1B5E20"
        },
        nero: {
            main: '#000'
        },
        secondary: {
            main: '#FF8338',
        },
        chiaro: {
            main: "#A9A9A9"
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,

        //frontend
        bottoneArancione: {
            main: '#f06018',
            contrastText: "#FFFFFF"
        },
        bottoneBianco: {
            main: '#FFFFFF',
            contrastText: "#000",
            dark:'#FFFFFF'
        },
        bottoneNero: {
            main: '#000',
            contrastText: "#000",
            dark: '#000',
        },
        gialloPrincipale: {
            main: '#faad07',
            contrastText: "#FFFFFF"
        },

        iceBlue: {
            main:'#edf0f2' //ice-blue
        },
        coolBlue: {
            main:'#4294CB' //cool blue
        },

        grigioTitolo: {
            main:'#343a40' //charcoal-grey
        },
        grigioSottotitolo: {
            main:'#4b4b4b'
        },
        grigioIcona: {
            main:'#737a80'
        }
    },

    typography: {
        fontFamily: `"Maven Pro", sans-serif`,
        color:'#343a40'
    }
});

function App() {

    const [user, setUser] = useState(null);
    const [esperienze, setEsperienze] = useState({attrazioni: [], itinerari:[], eventi:[], guide:[], macro:[], categorie:[]})
    const [generale, setGenerale] = useState({stagioni:[], meteo:[], localita:[], banner:[], tematiche:[]});
    const [offerte, setOfferte] = useState({offerte:[], categorie:[], label:[]})
    const [campeggi, setCampeggi] = useState({servizi:[], categorieServizi:[], sistemazioni:[], strutture:[]});
    const [news, setNews] = useState({news:[], tipologie:[]})
    const [partners, setPartners] = useState({partners:[], categorie:[]})
    const [mediaKit, setMediaKit] = useState({media:[], categorie:[]})
    const [schedaCopiata, setSchedaCopiata] = useState();
    const [cms, setCms] = useState({pagine:[], elementi:[]})
    const [errore, setErrore] = useState();
    const [caricamento, setCaricamento] = useState(false);

    useEffect(() => {
        restoreUser();
        chiamateApi();
    }, [])

    const restoreUser = async () => {
        const utente = await authStorage.getUser();
        if(utente) {
            const results = await authApi.refreshMe()
            if(!results.ok) {
                return await authStorage.removeToken()
            }
            await authStorage.storeToken(results.data.token)
            const appo = await authStorage.getUser();
            setUser(appo)
        }
    }

    const chiamateApi = async() => {
        //cms
        let results = await getters.getCms();
        if(!results.ok) return setErrore(results.data);
        setCms(results.data);
        //fine loading
        setCaricamento(true)
        //generale
        results = await getters.getGenerale();
        if(!results.ok) return setErrore(results.data);
        setGenerale(results.data);
        //campeggi
        results = await getters.getCampeggi();
        if(!results.ok) return setErrore(results.data);
        setCampeggi(results.data);
        //esperienze
        results = await getters.getEsperienze();
        if(!results.ok) return setErrore(results.data);
        setEsperienze(results.data);
        //offerte
        results = await getters.getOfferte();
        if(!results.ok) return setErrore(results.data);
        setOfferte(results.data);
        //news
        results = await getters.getNews();
        if(!results.ok) return setErrore(results.data);
        setNews(results.data);
        //partners
        results = await getters.getPartners();
        if(!results.ok) return setErrore(results.data);
        setPartners(results.data);
        //media kit
        results = await getters.getMediaKit();
        if(!results.ok) return setErrore(results.data);
        setMediaKit(results.data);
    }

    return (
        <ThemeProvider theme={theme}>
            <AuthContext.Provider value={{user, setUser}}>
                <EsperienzeContext.Provider value={{esperienze, setEsperienze}}>
                    <GeneraleContext.Provider value={{generale, setGenerale}}>
                        <OfferteContext.Provider value={{offerte, setOfferte}}>
                            <CampeggiContext.Provider value={{campeggi, setCampeggi}}>
                                <NewsContext.Provider value={{news, setNews}}>
                                    <PartnersContext.Provider value={{partners, setPartners}}>
                                        <SchedaCopiataContext.Provider value={{schedaCopiata, setSchedaCopiata}}>
                                            <CmsContext.Provider value={{cms, setCms}}>
                                                <MediaKitContext.Provider value={{mediaKit, setMediaKit}}>
                                                    {caricamento ? <Navigator></Navigator> : <p>Loading...</p>}
                                                </MediaKitContext.Provider>
                                            </CmsContext.Provider>
                                        </SchedaCopiataContext.Provider>
                                    </PartnersContext.Provider>
                                </NewsContext.Provider>
                            </CampeggiContext.Provider>
                        </OfferteContext.Provider>
                    </GeneraleContext.Provider>
                </EsperienzeContext.Provider>
            </AuthContext.Provider>
        </ThemeProvider>
    );
}

export default App;
