import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

function FormCreaAdmin({handleChange, values, errors, touched, setFieldValue}) {
    return (
        <Stack direction="column" spacing={2} paddingTop={3} sx={{width:'60%'}}>
            <TextField 
                id="outlined-basic"
                label="Username"
                variant="outlined"
                name="username"
                onChange={handleChange}
                value={values.username}
                error={touched.username && Boolean(errors.username)}
                helperText={touched.username && errors.username}
                size={"small"}
                fullWidth
                autoComplete="off"
            />
            <TextField 
                id="outlined-basic"
                label="Password"
                variant="outlined"
                name="pwd"
                onChange={handleChange}
                value={values.pwd}
                error={touched.pwd && Boolean(errors.pwd)}
                helperText={touched.pwd && errors.pwd}
                size={"small"}
                fullWidth
                autoComplete="off"
                type={"password"}
            />
            <TextField 
                id="outlined-basic"
                label="Ripeti Password"
                variant="outlined"
                name="ripeti"
                onChange={handleChange}
                value={values.ripeti}
                error={touched.ripeti && Boolean(errors.ripeti)}
                helperText={touched.ripeti && errors.ripeti}
                size={"small"}
                fullWidth
                autoComplete="off"
                type={"password"}
            />

            <Box sx={{paddingTop:3, paddingBottom:2}}>
                <Typography sx={{fontSize:20, fontWeight:'500'}}>Modifica abilitata?</Typography>
                <Switch 
                    size="medium" 
                    color="primary" 
                    name="is_admin" 
                    checked={values.is_admin} 
                    onChange={() => setFieldValue('is_admin', !values.is_admin)}
                />
            </Box>
        </Stack>
    );
}

export default FormCreaAdmin;