import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

function ZonaPasswordAdmin({password, cambiaPassword}) {
    return (
        <Box sx={{width:'70%', backgroundColor:'white', borderRadius:'10px', border:'1px solid #dcdcdc', p:2, 
            marginTop:'30px', display:'flex', flexDirection:'column'
        }}>
            <Typography sx={{fontSize:20, fontWeight:'bold'}}>Cambia Password</Typography>
            <Stack direction="column" spacing={2} paddingTop={3} sx={{width:'60%'}}>
                <TextField 
                    id="outlined-basic"
                    label="Nuova Password"
                    variant="outlined"
                    name="pwd"
                    onChange={(e) => cambiaPassword(e.target.value)}
                    value={password}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>
        </Box>
    );
}

export default ZonaPasswordAdmin;