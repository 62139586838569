import React from 'react';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

function ZonaRuoloAdmin({ruolo, cambiaRuolo}) {
    return (
        <Box sx={{width:'70%', backgroundColor:'white', borderRadius:'10px', border:'1px solid #dcdcdc', p:2, 
            marginTop:'30px', display:'flex', flexDirection:'column'
        }}>
            <Typography sx={{fontSize:20, fontWeight:'bold'}}>Ruolo</Typography>
            <Box sx={{paddingTop:3, paddingBottom:2}}>
                <Typography sx={{fontSize:16, fontWeight:'500'}}>Modifica abilitata?</Typography>
                <Switch 
                    size="medium" 
                    color="primary" 
                    name="is_admin" 
                    checked={ruolo} 
                    onChange={() => cambiaRuolo()}
                />
            </Box>
        </Box>
    );
}

export default ZonaRuoloAdmin;