import React from 'react';
import Box from '@mui/material/Box';

function BannerComponente({index, componente, cambiaBanner}) {
    return (
        <Box sx={{p: 1}}>
            <span>QUA SI POTRA' SCEGLIERE IL BANNER</span>
        </Box>
    );
}

export default BannerComponente;