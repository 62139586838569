import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';

const tipi = [{id:1, nome:'Testo'}, {id:2, nome:'Titolo'}, {id:3, nome:'Immagine'}, {id:4, nome:'Link'}, {id:5, nome:'Banner'}]
const sottotipiTitolo = [{id:1, nome:'H1'}, {id:2, nome:'H2'}, {id:3, nome:'H3'}, {id:4, nome:'H4'}, {id:5, nome:'H5'}, {id:6, nome:'Testo'}]
const sottotipiImmagine = [{id:1, nome:'Immagine'}, {id:2, nome:'Icona'}]

function NuovoComponente({componente, index, cambiaNome, cambiaTipo, cambiaSottoTipo, eliminaComponente, cambiaIsTitolo, isSuper}) {

    const scriviTipo = () => {
        const appo = tipi.filter((t) => t.id == componente.tipo)[0]
        if(appo) return appo.nome
        return "-"
    }

    if(componente.id) return (
        <Box sx={{marginLeft:2, marginRight: 2, borderRadius:'10px', border:'1px solid #dcdcdc', padding:2, marginBottom:2, display:'flex', flexDirection:'row'}}>
            <Box sx={{width:'80%'}}>
                <Box sx={{display:'flex', flexDirection:'column'}}>
                    <h4 className="admin-titoletto">Nome Componente: </h4>
                    <Stack direction="row" spacing={15} paddingTop={1} paddingBottom={1} width={"40%"}>
                        <TextField 
                            variant="outlined"
                            name="componente"
                            onChange={(event) => cambiaNome(index, event.target.value)}
                            value={componente.nome}
                            size={"small"}
                            fullWidth
                            autoComplete="off"
                        />
                    </Stack>
                </Box>

                <Box sx={{display:'flex', flexDirection:'row', paddingTop: 1}}>
                    <h4 className="admin-titoletto">Tipo Componente:&nbsp;</h4>
                    <span>{scriviTipo(componente.tipo)}</span>
                </Box>

                {(componente.tipo == 2 || componente.tipo == 3) && <Box sx={{paddingTop: 1}}>
                    <Stack direction="row" spacing={2} paddingTop={1} paddingBottom={1} width={"40%"} alignItems='center'>
                        <h4 className="admin-titoletto">Sottotipo</h4>
                        <Select
                            value={componente.sottotipo}
                            onChange={(event) => cambiaSottoTipo(index, event.target.value)}
                            autoWidth
                            label={null}
                            size="small"
                        >
                            {(componente.tipo == 2 ? sottotipiTitolo : sottotipiImmagine).map((sottotipo, index) => (
                                <MenuItem value={sottotipo.id} key={index}>{sottotipo.nome}</MenuItem>
                            ))}
                        </Select>
                    </Stack>
                </Box>}
                {componente.tipo == 2 &&
                    <Stack direction="row" spacing={2} paddingTop={1} paddingBottom={1} width={"40%"} alignItems='center'>
                        <h4 className="admin-titoletto">E' il titolo della sezione?</h4>
                        <Switch 
                            size="medium" 
                            color="primary" 
                            name="is_titolo" 
                            checked={componente.is_titolo} 
                            onChange={() => cambiaIsTitolo(index)}
                        />
                    </Stack>
                }
            </Box>
            {isSuper && <Box sx={{width:'20%', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                <IconButton
                    size="small"
                    onClick={() => eliminaComponente(index)}
                >
                    <DeleteIcon />
                </IconButton>
            </Box>}
        </Box>
    );

    return (
        <Box sx={{marginLeft:2, marginRight: 2, borderRadius:'10px', border:'1px solid #dcdcdc', padding:2, marginBottom:2, display:'flex', flexDirection:'row'}}>
            
            <Box sx={{width:'80%'}}>
                <Box>
                    <h4 className="admin-titoletto">Nome Componente:</h4>
                    <Stack direction="row" spacing={15} paddingTop={1} paddingBottom={1} width={"40%"}>
                        <TextField 
                            variant="outlined"
                            name="componente"
                            onChange={(event) => cambiaNome(index, event.target.value)}
                            value={componente.nome}
                            size={"small"}
                            fullWidth
                            autoComplete="off"
                        />
                    </Stack>
                </Box>
                <Box sx={{paddingTop: 1}}>
                    <h4 className="admin-titoletto">Tipo Componente:</h4>
                    <Stack direction="row" spacing={15} paddingTop={1} paddingBottom={1} width={"40%"}>
                        <Select
                            value={componente.tipo}
                            onChange={(event) => cambiaTipo(index, event.target.value)}
                            autoWidth
                            label={null}
                            size="small"
                        >
                            {tipi.map((tipo, index) => (
                                <MenuItem value={tipo.id} key={index}>{tipo.nome}</MenuItem>
                            ))}
                        </Select>
                    </Stack>
                </Box>
                {(componente.tipo == 2 || componente.tipo == 3) && <Box sx={{paddingTop: 1}}>
                    <h4 className="admin-titoletto">Sottotipo</h4>
                    <Stack direction="row" spacing={15} paddingTop={1} paddingBottom={1} width={"40%"}>
                        <Select
                            value={componente.sottotipo}
                            onChange={(event) => cambiaSottoTipo(index, event.target.value)}
                            autoWidth
                            label={null}
                            size="small"
                        >
                            {(componente.tipo == 2 ? sottotipiTitolo : sottotipiImmagine).map((sottotipo, index) => (
                                <MenuItem value={sottotipo.id} key={index}>{sottotipo.nome}</MenuItem>
                            ))}
                        </Select>
                    </Stack>
                </Box>}
                {componente.tipo == 2 &&
                    <Stack direction="row" spacing={2} paddingTop={1} paddingBottom={1} width={"40%"} alignItems='center'>
                        <h4 className="admin-titoletto">E' il titolo della sezione?</h4>
                        <Switch 
                            size="medium" 
                            color="primary" 
                            name="is_titolo" 
                            checked={componente.is_titolo} 
                            onChange={() => cambiaIsTitolo(index)}
                        />
                    </Stack>
                }
            </Box>

            {isSuper && <Box sx={{width:'20%', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                <IconButton
                    size="small"
                    onClick={() => eliminaComponente(index)}
                >
                    <DeleteIcon />
                </IconButton>
            </Box>}
        </Box>
    );
}

export default NuovoComponente;