import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import itLocale from 'date-fns/locale/it';

function FormAttrazione({handleChange, values, errors, touched, setFieldValue, isModifica}) {
    return (
        <React.Fragment>
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <Stack direction="row" spacing={7} paddingTop={1}>
                <FormControlLabel
                    control={<Checkbox size="medium" color="primary" name="attiva" checked={values.attiva} onChange={handleChange}/>} 
                    label="Attiva"
                />
            </Stack>
            <Stack direction="row" spacing={7} paddingTop={3}>
                <TextField 
                    id="outlined-basic"
                    label="Indirizzo"
                    variant="outlined"
                    name="indirizzo"
                    onChange={handleChange}
                    value={values.indirizzo}
                    error={touched.indirizzo && Boolean(errors.indirizzo)}
                    helperText={touched.indirizzo && errors.indirizzo}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
                {/* <TextField 
                    id="outlined-basic"
                    label="CAP"
                    variant="outlined"
                    name="cap"
                    onChange={handleChange}
                    value={values.cap}
                    error={touched.cap && Boolean(errors.cap)}
                    helperText={touched.cap && errors.cap}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                /> */}
            </Stack>
            <Stack direction="row" spacing={7} paddingTop={3}>
                <TextField 
                    id="outlined-basic"
                    label="Latitudine"
                    variant="outlined"
                    name="latitudine"
                    onChange={handleChange}
                    value={values.latitudine}
                    error={touched.latitudine && Boolean(errors.latitudine)}
                    helperText={touched.latitudine && errors.latitudine}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
                <TextField 
                    id="outlined-basic"
                    label="Longitudine"
                    variant="outlined"
                    name="longitudine"
                    onChange={handleChange}
                    value={values.longitudine}
                    error={touched.longitudine && Boolean(errors.longitudine)}
                    helperText={touched.longitudine && errors.longitudine}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>
            <Stack direction="row" spacing={7} paddingTop={3}>
                <TextField 
                    id="outlined-basic"
                    label="Telefono"
                    variant="outlined"
                    name="telefono"
                    onChange={handleChange}
                    value={values.telefono}
                    error={touched.telefono && Boolean(errors.telefono)}
                    helperText={touched.telefono && errors.telefono}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
                <TextField 
                    id="outlined-basic"
                    label="Whatsapp"
                    variant="outlined"
                    name="wathsapp"
                    onChange={handleChange}
                    value={values.wathsapp}
                    error={touched.wathsapp && Boolean(errors.wathsapp)}
                    helperText={touched.wathsapp && errors.wathsapp}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>
            <Stack direction="row" spacing={7} paddingTop={3} paddingBottom={3}>
                <TextField 
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
                <TextField 
                    id="outlined-basic"
                    label="Sito"
                    variant="outlined"
                    name="sito"
                    onChange={handleChange}
                    value={values.sito}
                    error={touched.sito && Boolean(errors.sito)}
                    helperText={touched.sito && errors.sito}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>
        </div>

        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={itLocale}>
                <Stack direction="row" spacing={7} paddingBottom={3}>
                    <DatePicker
                        label="Data Apertura"
                        value={values.data_apertura}
                        onChange={value => setFieldValue("data_apertura", value)}
                        renderInput={(params) => <TextField {...params} />}
                        mask='__/__/____'
                    />
                    <DatePicker
                        label="Data Chiusura"
                        value={values.data_chiusura}
                        onChange={value => setFieldValue("data_chiusura", value)}
                        renderInput={(params) => <TextField {...params} />}
                        mask='__/__/____'
                    />
                </Stack>
            </LocalizationProvider>
        </div>

        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <Stack direction="row" spacing={7} paddingTop={3}>
                <TextField 
                    id="outlined-basic"
                    label="Video"
                    variant="outlined"
                    name="video"
                    onChange={handleChange}
                    value={values.video}
                    error={touched.video && Boolean(errors.video)}
                    helperText={touched.video && errors.video}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
                <TextField 
                    id="outlined-basic"
                    label="Facebook"
                    variant="outlined"
                    name="facebook"
                    onChange={handleChange}
                    value={values.facebook}
                    error={touched.facebook && Boolean(errors.facebook)}
                    helperText={touched.facebook && errors.facebook}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>

            <Stack direction="row" spacing={7} paddingTop={3} paddingBottom={3}>
                <TextField 
                    id="outlined-basic"
                    label="Instagram"
                    variant="outlined"
                    name="instagram"
                    onChange={handleChange}
                    value={values.instagram}
                    error={touched.instagram && Boolean(errors.instagram)}
                    helperText={touched.instagram && errors.instagram}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
                <TextField 
                    id="outlined-basic"
                    label="Youtube"
                    variant="outlined"
                    name="youtube"
                    onChange={handleChange}
                    value={values.youtube}
                    error={touched.youtube && Boolean(errors.youtube)}
                    helperText={touched.youtube && errors.youtube}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>
        </div>

        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <FormControlLabel
                control={<Switch size="medium" color="primary" name="is_ecommerce" checked={values.is_ecommerce} onChange={handleChange}/>} 
                label="Ecommerce"
            />
            {values.is_ecommerce == true &&
            <>
                <Stack direction="row" spacing={15} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Italiano"
                        variant="outlined"
                        name="ecommerce_italiano"
                        onChange={handleChange}
                        value={values.ecommerce_italiano}
                        error={touched.ecommerce_italiano && Boolean(errors.ecommerce_italiano)}
                        helperText={touched.ecommerce_italiano && errors.ecommerce_italiano}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={15} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Inglese"
                        variant="outlined"
                        name="ecommerce_inglese"
                        onChange={handleChange}
                        value={values.ecommerce_inglese}
                        error={touched.ecommerce_inglese && Boolean(errors.ecommerce_inglese)}
                        helperText={touched.ecommerce_inglese && errors.ecommerce_inglese}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={15} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Tedesco"
                        variant="outlined"
                        name="ecommerce_tedesco"
                        onChange={handleChange}
                        value={values.ecommerce_tedesco}
                        error={touched.ecommerce_tedesco && Boolean(errors.ecommerce_tedesco)}
                        helperText={touched.ecommerce_tedesco && errors.ecommerce_tedesco}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={15} paddingTop={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Olandese"
                        variant="outlined"
                        name="ecommerce_olandese"
                        onChange={handleChange}
                        value={values.ecommerce_olandese}
                        error={touched.ecommerce_olandese && Boolean(errors.ecommerce_olandese)}
                        helperText={touched.ecommerce_olandese && errors.ecommerce_olandese}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
                <Stack direction="row" spacing={15} paddingTop={3} paddingBottom={3}>
                    <TextField 
                        id="outlined-basic"
                        label="Danese"
                        variant="outlined"
                        name="ecommerce_danese"
                        onChange={handleChange}
                        value={values.ecommerce_danese}
                        error={touched.ecommerce_danese && Boolean(errors.ecommerce_danese)}
                        helperText={touched.ecommerce_danese && errors.ecommerce_danese}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
            </>
            }
        </div>
        </React.Fragment>
    );
}

export default FormAttrazione;