import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';

function SceltaTematiche({listaSel, tematiche, cambiaTematica, isModifica}) {
    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <h3 className="admin-titoletto">VACANZE TEMATICHE</h3>
            <Stack direction="column" paddingTop={1}>
                {tematiche.map((tematica, index) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="medium"
                                color="primary" 
                                name={tematica.nome_italiano}
                                checked={listaSel[index]}
                                onChange={() => cambiaTematica(index)}
                            />}
                        label={tematica.nome_italiano}
                        key={index}
                    />
                ))}
            </Stack>
        </div>
    );
}

export default SceltaTematiche;