import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import Italiana from '../../images/it.png';
import Inglese from '../../images/uk.png';
import Tedesco from '../../images/de.png';
import Olandese from '../../images/ne.png';
import Danese from '../../images/dk.png';

function ImmagineCat({img, creaImmagine, pulisciImmagine, cambiaTitoloImg, isModifica}) {

    const [lingua, setLingua] = useState(1); //1 italiano, ...

    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <Stack direction="row">
                <Stack direction="column" spacing={1} width="30%" alignItems="center" justifyContent="center">
                    <h3 className="admin-titoletto">IMMAGINE</h3>
                    <Stack paddingTop={1}>
                        <TextField 
                            id="raised-button-file-immagine-cazzo"
                            label="Immagine"
                            variant="outlined"
                            value=""
                            onChange={(event) => creaImmagine(event.target.files[0])}
                            type="file"
                            size={"small"}
                            fullWidth
                            autoComplete="off"
                            style={{ display: 'none' }}
                        />
                        <label className="admin-linea-bottone-immagine" htmlFor="raised-button-file-immagine-cazzo">
                            <Button size="small" variant="contained" color="primary" component="span" startIcon={<FileUploadIcon />}>Upload</Button>
                        </label>
                    </Stack>
                    {img.id > 0 && <Button size="small" variant="contained" color="error" component="span" startIcon={<HighlightOffIcon />} onClick={() => pulisciImmagine()}>Elimina</Button>}
                </Stack>

                <Box>
                    {img.id > 0 && <img className="immagine-cms" src={img.link}></img>}
                </Box>
            </Stack>

            {img.id > 0 && <Box sx={{p: 1, pt:3}}>
                <Stack direction="row" spacing={2} paddingBottom={1}>
                    <FormControlLabel value={1} control={<Radio checked={lingua === 1} onChange={() => setLingua(1)} />} label="Italiano" />
                    <FormControlLabel value={2} control={<Radio checked={lingua === 2} onChange={() => setLingua(2)} />} label="Inglese" />
                    <FormControlLabel value={3} control={<Radio checked={lingua === 3} onChange={() => setLingua(3)} />} label="Tedesco" />
                    <FormControlLabel value={4} control={<Radio checked={lingua === 4} onChange={() => setLingua(4)} />} label="Olandese" />
                    <FormControlLabel value={5} control={<Radio checked={lingua === 5} onChange={() => setLingua(5)} />} label="Danese" />
                </Stack>
                <Stack direction="row" spacing={2} paddingTop={0} paddingBottom={2}>
                    <img src={lingua == 1 ? Italiana : (lingua == 2 ? Inglese : (lingua == 3 ? Tedesco : (lingua == 4 ? Olandese : Danese)))} className="admin-bandiera"></img>
                    <h3 className="admin-titoletto">NOME IMMAGINE</h3>
                </Stack>
                <Stack direction="row" spacing={2} paddingBottom={1}>
                    <TextField 
                        id="outlined-basic"
                        variant="outlined"
                        onChange={(event) => cambiaTitoloImg(lingua, event.target.value)}
                        value={lingua == 1 ? img.italiano : (lingua == 2 ? img.inglese : (lingua == 3 ? img.tedesco : (lingua == 4 ? img.olandese : img.danese)))}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
            </Box>}
        </div>
    );
}

export default ImmagineCat;