import { useContext } from "react";

import EsperienzeContext from "./context";

const useGuide = () => {
    const { esperienze, setEsperienze } = useContext(EsperienzeContext);

    const popolaGuide = (vett) => {
        const appo = {...esperienze};
        appo.guide = vett;
        setEsperienze(appo);
    }

    const importanzaMaxGuide = () => {
        let appo = esperienze.guide;
        let valore = appo[appo.length - 1]?.importanza;
        return valore ? (valore + 1) : 1
    }

    const addGuida = (g) => {
        const appo = {...esperienze};
        appo.guide.push(g);
        setEsperienze(appo);
    }

    const aggGuida = (gg) => {
        const appo = {...esperienze};
        for(let i = 0; i < appo.guide.length; i++) {
            if(appo.guide[i].id == gg.id) appo.guide[i] = gg;
        }
        setEsperienze(appo);
    }

    const togliGuida = (id) => {
        const appo = {...esperienze};
        appo.guide = esperienze.guide.filter((a) => a.id != id);
        setEsperienze(appo)
    }

    return { 
        guide: esperienze.guide,
        popolaGuide,
        importanzaMaxGuide,
        addGuida,
        aggGuida,
        togliGuida
    }
}

export default useGuide;