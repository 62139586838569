import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function SceltaNews({nome, elementi, selezionati, cambiaSelezione, isModifica}) {

    const [ricerca, setRicerca] = useState('')

    const filtraElementi = () => {
        //fitro per ricerca
        if(!ricerca.length) return elementi
        return elementi.filter((e) => {
            var appo = ricerca.toLowerCase();
            var appo2 = e.nome_italiano.toLowerCase();
            return appo2.includes(appo);
        });
    }

    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <h3 className="admin-titoletto">{nome}</h3>

            <Stack direction="row" spacing={2} paddingTop={2} paddingBottom={2} sx={{paddingRight:10}}>
                <TextField 
                    id="outlined-basic"
                    label="Ricerca"
                    variant="outlined"
                    name="ricerca"
                    onChange={(event) => setRicerca(event.target.value)}
                    value={ricerca}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>

            <Stack direction="column">
                {filtraElementi().map((elemento, index) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="medium"
                                color="primary" 
                                name={elemento.nome_italiano}
                                checked={selezionati[index]}
                                onChange={() => cambiaSelezione(index)}
                            />} 
                        label={elemento.nome_italiano}
                        key={index}
                    />
                ))}
            </Stack>
        </div>
    );
}

export default SceltaNews;