import React, { useState, useEffect } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Box from '@mui/material/Box';

function TestoComponente({index, componente, cambiaTesto, lingua}) {

    const [valori, setValori] = useState({
        italiano: EditorState.createEmpty(),
        inglese: EditorState.createEmpty(),
        tedesco: EditorState.createEmpty(),
        olandese: EditorState.createEmpty(),
        danese: EditorState.createEmpty()
    })

    useEffect(() => {
        if(componente) aggValori()
    }, [componente])

    const aggValori = () => {
        setValori({
            italiano: aggEditorState(componente.valore_italiano),
            inglese: aggEditorState(componente.valore_inglese),
            tedesco: aggEditorState(componente.valore_tedesco),
            olandese: aggEditorState(componente.valore_olandese),
            danese: aggEditorState(componente.valore_danese)
        })
    }

    const aggEditorState = (valore) => {
        const blocksFromHtml = htmlToDraft(valore);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const appo = EditorState.createWithContent(contentState);
        return appo;
    }

    const cambiaValori = (newState) => {
        const appo = {...valori};
        if(lingua == 1) {
            appo.italiano = newState;
            cambiaTesto(index, lingua, draftToHtml(convertToRaw(newState.getCurrentContent())))
            return setValori(appo)
        } else if(lingua == 2) {
            appo.inglese = newState;
            cambiaTesto(index, lingua, draftToHtml(convertToRaw(newState.getCurrentContent())))
            return setValori(appo)
        } else if(lingua == 3) {
            appo.tedesco = newState;
            cambiaTesto(index, lingua, draftToHtml(convertToRaw(newState.getCurrentContent())))
            return setValori(appo)
        } else if(lingua == 4) {
            appo.olandese = newState;
            cambiaTesto(index, lingua, draftToHtml(convertToRaw(newState.getCurrentContent())))
            return setValori(appo)
        } else {
            appo.danese = newState;
            cambiaTesto(index, lingua, draftToHtml(convertToRaw(newState.getCurrentContent())), appo.danese.getCurrentContent() != newState.getCurrentContent())
            return setValori(appo)
        }
    }

    return (
        <Box sx={{p:1, pt:2, pl:0}}>
            <Editor
                editorState={lingua == 1 ? valori.italiano : (lingua == 2 ? valori.inglese : (lingua == 3 ? valori.tedesco : (lingua == 4 ? valori.olandese : valori.danese)))}
                onEditorStateChange={cambiaValori}
                toolbar={{
                    options: ['inline', 'link', 'history', 'list', 'textAlign', 'fontSize', 'colorPicker', 'blockType'],
                    inline: {inDropdown: false, options: ['bold', 'italic', 'underline']},
                    link: { inDropdown: false },
                    history: { inDropdown: false },
                    fontSize: {inDropdown: true},
                    list: {inDropdown: false, options: ['unordered', 'ordered']},
                    blockType: {inDropdown:true, options:['Normal', 'H2', 'H3', 'H4']}
                }}
            />
        </Box>
    );
}

export default TestoComponente;