import React, {useState} from 'react';
import ReactPlayer from 'react-player'
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import Italiana from '../../images/it.png';
import Inglese from '../../images/uk.png';
import Tedesco from '../../images/de.png';
import Olandese from '../../images/ne.png';
import Danese from '../../images/dk.png';

function FormVideoMedia({video, categorie, cambiaNome, cambiaLink, cambiaCategoria, cambiaErrore}) {

    const [lingua, setLingua] = useState(1); //1 italiano, ...

    const linkVideo = video.link
    const isLink = () => Boolean(linkVideo?.length)

    return (
        <React.Fragment>
            <div className="admin-container">
                <Box sx={{display:'flex', flexDirection:'row', paddingTop:2, paddingBottom:2}}>
                    <Box sx={{width:'50%', display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                        <h3 className="admin-titoletto">Link al Video</h3>
                        <Stack direction="row" spacing={0} paddingTop={2} sx={{width:'70%'}}>
                            <TextField 
                                id="outlined-basic-link"
                                variant="outlined"
                                onChange={(event) => cambiaLink(event.target.value)}
                                value={video.link}
                                size={"small"}
                                fullWidth
                                autoComplete="off"
                            />
                        </Stack>
                    </Box>
                    <Box sx={{width:'50%', display:'flex'}}>
                        {!isLink() && <Box sx={{height:'100%', display:'flex', alignItems:'center'}}>
                            <Typography sx={{fontSize:16, fontWeight:'bold', color:'#C62828'}}>Video non inserito</Typography>
                        </Box>}
                        {isLink() &&
                            <Box sx={{width:'70%', height:'250px', overflow:'hidden', borderRadius:'16px', marginRight:3}}>
                                <ReactPlayer
                                    onPlay={() => null}
                                    muted={true}
                                    playing={false}
                                    url={video.link}
                                    width='100%'
                                    height='100%'
                                />
                            </Box>
                        }
                    </Box>
                </Box>
            </div>

            <Box className='admin-container'>
                <Box sx={{
                    borderRadius:'10px', border:'none', padding:0, marginBottom:2, backgroundColor:'white'
                }}>
                    <Typography sx={{fontSize:20, fontWeight:'bold'}}>Seleziona la lingua</Typography>
                    <Stack direction="row" spacing={2} paddingTop={1} paddingBottom={1}>
                        <FormControlLabel value={1} control={<Radio checked={lingua === 1} onChange={() => setLingua(1)} />} label="Italiano" />
                        <FormControlLabel value={2} control={<Radio checked={lingua === 2} onChange={() => setLingua(2)} />} label="Inglese" />
                        <FormControlLabel value={3} control={<Radio checked={lingua === 3} onChange={() => setLingua(3)} />} label="Tedesco" />
                        <FormControlLabel value={4} control={<Radio checked={lingua === 4} onChange={() => setLingua(4)} />} label="Olandese" />
                        <FormControlLabel value={5} control={<Radio checked={lingua === 5} onChange={() => setLingua(5)} />} label="Danese" />
                    </Stack>
                </Box>

                <Box sx={{p:1, pt:2, pl:0}}>
                    <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                        <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                            <img src={lingua == 1 ? Italiana : (lingua == 2 ? Inglese : (lingua == 3 ? Tedesco : (lingua == 4 ? Olandese : Danese)))} className="admin-bandiera-cms"></img>    
                            <Typography sx={{fontSize:18, fontWeight:'bold', marginLeft:1.5}}>Nome dell'immagine</Typography>
                        </Box>
                    </Box>
                    <Stack direction="row" spacing={0} paddingBottom={1} paddingTop={2}>
                        <TextField 
                            id="outlined-basic"
                            variant="outlined"
                            onChange={(event) => cambiaNome(lingua, event.target.value)}
                            value={lingua == 1 ? video.nome_italiano : (lingua == 2 ? video.nome_inglese : (lingua == 3 ? video.nome_tedesco : (lingua == 4 ? video.nome_olandese : video.nome_danese)))}
                            size={"small"}
                            fullWidth
                            autoComplete="off"
                        />
                    </Stack>
                </Box>
            </Box>

            <Box className='admin-container'>
                <Box sx={{
                    borderRadius:'10px', border:'none', padding:0, marginBottom:2, backgroundColor:'white'
                }}>
                    <Typography sx={{fontSize:20, fontWeight:'bold'}}>Seleziona la categoria</Typography>
                </Box>
                <Stack direction="row" paddingTop={1}>
                    <FormControl sx={{ minWidth: 150, maxWidth: 300 }}>
                        <InputLabel id="demo-simple-select-autowidth-label-555">Categoria</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label-555"
                            id="demo-simple-select-autowidth-555"
                            name="categoria"
                            value={video.categoria}
                            onChange={(event) => cambiaCategoria(event.target.value)}
                            autoWidth
                            label="Categoria"
                            size="small"
                        >
                            <MenuItem value={0}><em>Nessuna</em></MenuItem>
                            {categorie.map((cat, index) => (
                                <MenuItem value={cat.id} key={index}>{cat.nome_italiano}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </Box>
        </React.Fragment>
    );
}

export default FormVideoMedia;