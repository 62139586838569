import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function FormLocalita({handleChange, values, errors, touched, isModifica}) {
    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <FormControlLabel
                control={
                    <Checkbox 
                        size="medium"
                        color="primary"
                        name="attiva"
                        checked={values.attiva}
                        onChange={handleChange}
                    />
                } 
                label="Attiva"
            />
            <Stack direction="row" spacing={15} paddingTop={1}>
                <TextField 
                    id="outlined-basic"
                    label="Video"
                    variant="outlined"
                    name="video"
                    onChange={handleChange}
                    value={values.video}
                    error={touched.video && Boolean(errors.video)}
                    helperText={touched.video && errors.video}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>
            <Stack direction="row" spacing={7} paddingTop={3} paddingBottom={3}>
                <TextField 
                    id="outlined-basic"
                    label="Latitudine"
                    variant="outlined"
                    name="latitudine"
                    onChange={handleChange}
                    value={values.latitudine}
                    error={touched.latitudine && Boolean(errors.latitudine)}
                    helperText={touched.latitudine && errors.latitudine}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
                <TextField 
                    id="outlined-basic"
                    label="Longitudine"
                    variant="outlined"
                    name="longitudine"
                    onChange={handleChange}
                    value={values.longitudine}
                    error={touched.longitudine && Boolean(errors.longitudine)}
                    helperText={touched.longitudine && errors.longitudine}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>
        </div>
    );
}

export default FormLocalita;