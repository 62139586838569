import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Typography from '@mui/material/Typography';

function ModalAnnullaCms({visible, loading, conferma, annulla}) {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={visible}
            onClose={annulla}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout: 500}}
            keepMounted
        >
            <Fade in={visible}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: '2px solid #F9B433',
                    boxShadow: 24,
                    paddingTop: 6,
                    paddingBottom: 6,
                    display:'flex',
                    flexDirection:'column',
                    textAlign:'center',
                    paddingLeft:{xs:3, sm: 6},
                    paddingRight:{xs:3, sm: 6},
                    maxWidth: {xs:250, sm:'none'},
                    borderRadius:4
                }}>
                    <Typography sx={{fontSize:20, fontWeight:600, color:'black'}}>Sei sicuro di voler procedere?</Typography>
                    <Typography sx={{fontSize:16, fontWeight:400, color:'black'}}>Perderai tutte le modifiche che non hai salvato!</Typography>
                    <Stack sx={{display:'flex', width:'100%'}} spacing={3} direction='row' marginTop={3} alignItems='center' justifyContent='center'>
                        <LoadingButton
                            loading={loading}
                            size="medium"
                            variant="contained"
                            color="success"
                            startIcon={<CheckIcon />}
                            onClick={async() => await conferma()}
                        >CONFERMA</LoadingButton>
                        <Button
                            size="medium"
                            variant="contained"
                            color="error"
                            startIcon={<CancelOutlinedIcon />}
                            onClick={annulla}
                        >ANNULLA</Button>
                    </Stack>
                </Box>
            </Fade>
        </Modal>
    );
}

export default ModalAnnullaCms;