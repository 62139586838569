import { useContext } from "react";

import OfferteContext from "./context";

const useOfferte = () => {
    const { offerte, setOfferte } = useContext(OfferteContext);

    const popolaOfferte = (vett) => {
        const appo = {...offerte};
        appo.offerte = vett;
        setOfferte(appo);
    }

    const importanzaMaxOfferte = () => {
        let appo = offerte.offerte;
        let valore = appo[appo.length - 1]?.importanza;
        return valore ? (valore + 1) : 1
    }

    const addOfferta = (o) => {
        const appo = {...offerte};
        appo.offerte.push(o);
        setOfferte(appo);
    }

    const aggOfferta = (off) => {
        const appo = {...offerte};
        for(let i = 0; i < appo.offerte.length; i++) {
            if(appo.offerte[i].id == off.id) appo.offerte[i] = off;
        }
        setOfferte(appo);
    }

    const togliOfferta = (id) => {
        const appo = {...offerte};
        appo.offerte = offerte.offerte.filter((a) => a.id != id);
        setOfferte(appo)
    }

    return { 
        offerte: offerte.offerte,
        categorieOfferta: offerte.categorie,
        labelOfferta: offerte.label,
        popolaOfferte,
        importanzaMaxOfferte,
        addOfferta,
        aggOfferta,
        togliOfferta
    }
}

export default useOfferte;