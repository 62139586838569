import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import authApi from '../../api/auth';
import TabellaUtenti from '../../components/utenti/TabellaUtenti'
import ModalEliminaAdmin from '../../components/utenti/ModalEliminaAdmin'

function GestionUtenti(props) {

    const navigate = useNavigate()
    const {state, pathname} = useLocation()

    const [utenti, setUtenti] = useState([])
    const [errore, setErrore] = useState(false)
    const [openAlert, setOpenAlert] = useState(0)
    const [loading, setLoading] = useState(false)
    const [elimina, setElimina] = useState({visible:false, admin:null})

    useEffect(() => {
        const aggUtenti = async() => {
            const result = await authApi.listaAdmin()
            if(!result.ok) return setErrore(result.data)
            setUtenti(result.data)
        }
        aggUtenti()
    }, [])

    useEffect(() => {
        const aggState = () => {
            if(state?.nuovoAdmin) {
                const list = Array.from(utenti)
                list.push(state?.nuovoAdmin)
                navigate(pathname, {replace:true})
                setUtenti(list)
                setOpenAlert(1)
            }
            if(state?.modificaAdmin) {
                const list = Array.from(utenti)
                for(let i = 0; i < list.length; i++) {
                    if(list[i].id == state.modificaAdmin.id) list[i] = state.modificaAdmin
                }
                setUtenti(list)
                setOpenAlert(2)
                navigate(pathname, {replace:true})
            }
        }
        aggState()
    }, [state])

    const eliminaAdmin = async() => {
        setLoading(true)
        const result = await authApi.eliminaAdmin(elimina.admin.id)
        if(!result.ok) {
            setLoading(false)
            setElimina({visible:false, admin:null})
            return setErrore(result.data)
        }
        setTimeout(() => {
            const list = Array.from(utenti).filter((el) => el.id != elimina.admin.id)
            setLoading(false)
            setErrore(null)
            setUtenti(list)
            setElimina({visible:false, admin:null})
            setOpenAlert(3)
        }, 500);
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setOpenAlert(0);
        setErrore(null);
    }

    return (
        <React.Fragment>
            <Box sx={{width:'70%', backgroundColor:'white', borderRadius:'10px', border:'1px solid #dcdcdc', p:2, 
                marginTop:'20px', display:'flex', flexDirection:'column'
            }}>
                <Typography sx={{fontSize:24, fontWeight:'bold'}}>Tutti gli Amministratori</Typography>
                <Box sx={{marginTop:1}}>
                    <Button
                        size="small"
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => navigate('crea')}
                        sx={{width:'120px'}}
                        startIcon={<AddCircleOutlineIcon />}
                    >Nuovo</Button>
                </Box>
                <TabellaUtenti
                    utenti={utenti}
                    premiModifica={(admin) => navigate('modifica', {state:{admin}})}
                    premiElimina={(admin) => setElimina({visible:true, admin:admin})}
                ></TabellaUtenti>
            </Box>

            <ModalEliminaAdmin
                visible={elimina.visible}
                admin={elimina.admin}
                loading={loading}
                conferma={eliminaAdmin}
                annulla={() => setElimina({visible:false, admin:null})}
            ></ModalEliminaAdmin>

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 1} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Admin creato con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 2} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Admin modificato con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 3} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Admin eliminato con successo!</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default GestionUtenti;