import { useContext } from "react";

import EsperienzeContext from "./context";

const useAttrazioni = () => {
    const { esperienze, setEsperienze } = useContext(EsperienzeContext);

    const popolaAttrazioni = (vett) => {
        const appo = {...esperienze};
        appo.attrazioni = vett;
        setEsperienze(appo);
    }

    const importanzaMaxAttrazioni = () => {
        let appo = esperienze.attrazioni;
        let valore = appo[appo.length - 1]?.importanza;
        return valore ? (valore + 1) : 1
    }

    const addAttrazione = (att) => {
        const appo = {...esperienze};
        appo.attrazioni.push(att);
        setEsperienze(appo);
    }

    const aggAttrazione = (att) => {
        const appo = {...esperienze};
        for(let i = 0; i < appo.attrazioni.length; i++) {
            if(appo.attrazioni[i].id == att.id) appo.attrazioni[i] = att;
        }
        setEsperienze(appo);
    }

    const togliAttrazione = (id) => {
        const appo = {...esperienze};
        appo.attrazioni = esperienze.attrazioni.filter((a) => a.id != id);
        setEsperienze(appo)
    }

    return { 
        attrazioni: esperienze.attrazioni,
        popolaAttrazioni,
        importanzaMaxAttrazioni,
        addAttrazione,
        aggAttrazione,
        togliAttrazione
    }
}

export default useAttrazioni;