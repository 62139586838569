import React from 'react';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckIcon from '@mui/icons-material/Check';

function BottoniScheda({loading, conferma, annulla}) {
    return (
        <Stack direction="row" spacing={5} paddingTop={3}>
            <LoadingButton
                loading={loading}
                loadingPosition="start"
                size="small"
                variant={"contained"}
                color={"success"}
                onClick={conferma}
                type="submit"
                startIcon={<CheckIcon />}
            >CONFERMA</LoadingButton>
            <Button
                size="small"
                variant={"contained"}
                color={"error"}
                onClick={annulla}
                startIcon={<HighlightOffIcon />}
            >ANNULLA</Button>
        </Stack>
    );
}

export default BottoniScheda;