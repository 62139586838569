import React, { useState } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import HeaderScheda from '../components/scheda/HeaderScheda';
import ContenutoScheda from '../components/scheda/ContenutoScheda';
import creazione from '../api/creazione';
import BottoniScheda from '../components/scheda/BottoniScheda';
import FormStruttura from '../components/form/FormStruttura';
import useStagionalita from '../controllers/generale/useStagionalita';
import useLocalita from '../controllers/generale/useLocalita';
import useStrutture from '../controllers/campeggi/useStrutture';
import useSistemazioni from '../controllers/campeggi/useSistemazioni';
import useGenerale from '../controllers/generale/useGenerale';
import SceltaStagionalita from '../components/scheda/SceltaStagionalita';
import SceltaSistemazioni from '../components/scheda/SceltaSistemazioni';
import SceltaServizi from '../components/scheda/SceltaServizi';
import utils from '../utils/utils';
import validators from '../utils/validators';
import defaultValues from '../utils/defaultValues';
import useSchedaCopiata from '../controllers/schedaCopiata/useSchedaCopiata';
import SceltaLogo from '../components/scheda/SceltaLogo';
import upload from '../api/upload';
import SceltaLocalita from '../components/scheda/SceltaLocalita';
import SceltaTematiche from '../components/scheda/SceltaTematiche';

function CreaStruttura(props) {

    const navigate = useNavigate();
    const {stagioni} = useStagionalita();
    const {localita} = useLocalita();
    const {tematiche} = useGenerale();
    const {categorieSistemazioni} = useSistemazioni();
    const {addStruttura, importanzaMaxStrutture} = useStrutture();
    const {schedaCopiata, popolaSchedaCopiata} = useSchedaCopiata();

    const [openAlert, setOpenAlert] = useState(0);
    const [situazione, setSituazione] = useState(1);
    const [lingua, setLingua] = useState(1); //1 italiano, ...
    const [loading, setLoading] = useState(false);
    const [errore, setErrore] = useState();
    const [altro, setAltro] = useState({
        stagioni: new Array(stagioni.length).fill(false),
        categorie: new Array(categorieSistemazioni.length).fill(false),
        servizi: new Array(),
        localita: new Array(localita.length).fill(false),
        tematiche: new Array(tematiche.length).fill(false)
    });
    const [contenuto, setContenuto] = useState({italiano: EditorState.createEmpty(), inglese: EditorState.createEmpty(), tedesco: EditorState.createEmpty(), olandese: EditorState.createEmpty(), danese: EditorState.createEmpty()});
    const [titolo, setTitolo] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [valoriMeta, setValoriMeta] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [logo, setLogo] = useState({preview: null, file: null})

    const formik = useFormik({
        validationSchema: validators.struttura,
        initialValues: defaultValues.struttura,
        onSubmit: async(v) => {
            if(!titolo.italiano?.length) return setErrore("Inserire il nome in Italiano.");
            setLoading(true);
            let values = {...v}
            let results;
            //aggiungo importanza
            values.importanza = importanzaMaxStrutture()
            //aggiungo i titoli
            values.nome_italiano = titolo.italiano;
            values.nome_inglese = titolo.inglese;
            values.nome_tedesco = titolo.tedesco;
            values.nome_olandese = titolo.olandese;
            values.nome_danese = titolo.danese;
            //aggiungo le descrizioni
            values.descrizione_italiano = draftToHtml(convertToRaw(contenuto.italiano.getCurrentContent()));
            values.descrizione_inglese = draftToHtml(convertToRaw(contenuto.inglese.getCurrentContent()));
            values.descrizione_tedesco = draftToHtml(convertToRaw(contenuto.tedesco.getCurrentContent()));
            values.descrizione_olandese = draftToHtml(convertToRaw(contenuto.olandese.getCurrentContent()));
            values.descrizione_danese = draftToHtml(convertToRaw(contenuto.danese.getCurrentContent()));
            //aggiungo i meta tag
            values.meta_italiano = valoriMeta.italiano;
            values.meta_inglese = valoriMeta.inglese;
            values.meta_tedesco = valoriMeta.tedesco;
            values.meta_olandese = valoriMeta.olandese;
            values.meta_danese = valoriMeta.danese;
            //controllo lat e long
            values.latitudine = parseFloat(values.latitudine) ? parseFloat(values.latitudine) : null;
            values.longitudine = parseFloat(values.longitudine) ? parseFloat(values.longitudine) : null;
            //modifico i select
            values.id_booking = parseInt(values.id_booking) ? parseInt(values.id_booking) : null;
            //creo il logo
            if(logo.file) {
                let formData = new FormData();
                formData.append('immagine', logo.file, logo.file.name);
                results = await upload.creaImmagine(formData);
                if(!results.ok) {
                    setLoading(false)
                    return setErrore(results.data)
                }
                values.logo = results.data.link;
            }
            //creo l'oggetto
            results = await creazione.creaStruttura(values, utils.componiLista(stagioni, altro.stagioni), utils.componiLista(categorieSistemazioni, altro.categorie), altro.servizi, utils.componiLista(tematiche, altro.tematiche), utils.componiLista(localita, altro.localita));
            if(!results.ok) {
                setErrore(results.data);
                return setLoading(false);
            }
            //aggiungo l'oggetto al context
            addStruttura(results.data)
            //manda indietro
            setTimeout(() => {
                setLoading(false);
                navigate("..", {replace: true, state:{nuovaStruttura: results.data}});
            }, 500);
        }
    });

    const cambiaTitolo = (event) => {
        const data = event.target.value.trim();
        const appo = {...titolo};
        if(lingua == 1) {
            appo.italiano = data;
            return setTitolo(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setTitolo(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setTitolo(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setTitolo(appo)
        } else {
            appo.danese = data;
            return setTitolo(appo)
        }
    }

    const cambiaValoriMeta = (event) => {
        const data = event.target.value
        const appo = {...valoriMeta};
        if(lingua == 1) {
            appo.italiano = data;
            return setValoriMeta(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setValoriMeta(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setValoriMeta(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setValoriMeta(appo)
        } else {
            appo.danese = data;
            return setValoriMeta(appo)
        }
    }

    const cambiaContenuto = (newState) => {
        const appo = {...contenuto};
        if(lingua == 1) {
            appo.italiano = newState;
            return setContenuto(appo)
        } else if(lingua == 2) {
            appo.inglese = newState;
            return setContenuto(appo)
        } else if(lingua == 3) {
            appo.tedesco = newState;
            return setContenuto(appo)
        } else if(lingua == 4) {
            appo.olandese = newState;
            return setContenuto(appo)
        } else {
            appo.danese = newState;
            return setContenuto(appo)
        }
    }

    const copiaContenuti = (appoTitolo, appoContenuto) => {
        setTitolo(appoTitolo)
        setContenuto(appoContenuto)
        setOpenAlert(4)
    }

    const cambiaStagione = (position) => {
        const appo = {...altro}
        const nuovo = altro.stagioni.map((item, index) =>
            index === position ? !item : item
        );
        appo.stagioni = nuovo;
        setAltro(appo);
    }

    const cambiaCategoria = (position) => {
        const appo = {...altro}
        const nuovo = altro.categorie.map((item, index) =>
            index === position ? !item : item
        );
        appo.categorie = nuovo;
        setAltro(appo);
    }

    const cambiaTematica = (position) => {
        const appo = {...altro}
        const nuovo = altro.tematiche.map((item, index) =>
            index === position ? !item : item
        );
        appo.tematiche = nuovo;
        setAltro(appo);
    }

    const cambiaLocalita = (position) => {
        const appo = {...altro}
        const nuovo = altro.localita.map((item, index) =>
            index === position ? !item : item
        );
        appo.localita = nuovo;
        setAltro(appo);
    }

    const aggiungiServizio = (id) => {
        const appo = {...altro}
        appo.servizi.push({id: id, evidenza_preview: false, evidenza_scheda: false})
        setAltro(appo)
    }

    const togliServizio = (id) => {
        const appo = {...altro}
        appo.servizi = appo.servizi.filter((s) => s.id != id);
        setAltro(appo)
    }

    const modificaEvidenzaServizio = (id, tipo, isAggiungi) => {
        //controllo se sto togliendo
        if(isAggiungi) {
            let vett
            if(tipo == 1) {
                vett = altro.servizi.filter((s) => s.evidenza_preview);
                if(vett.length == 8) return setErrore("Massimo 8 servizi nella preview.")
            } else {
                vett = altro.servizi.filter((s) => s.evidenza_scheda);
                if(vett.length == 6) return setErrore("Massimo 6 servizi nella scheda.")
            }
        }
        //faccio l'operazione
        const appo = {...altro}
        for(let i = 0; i < appo.servizi.length; i++) {
            if(appo.servizi[i].id == id) {
                if(tipo == 1) appo.servizi[i].evidenza_preview = !appo.servizi[i].evidenza_preview;
                else appo.servizi[i].evidenza_scheda = !appo.servizi[i].evidenza_scheda;
            }
        }
        setAltro(appo)
    }

    const doIncollaSchedaCopiata = () => {
        //cambio il form
        formik.setValues(utils.incollaElementi(formik.values, schedaCopiata.values))
        //cambio il titolo
        setTitolo(schedaCopiata.titolo);
        //cambio il contenuto
        setContenuto(schedaCopiata.contenuto);
        //cambio altro
        setAltro(utils.incollaElementi(altro, schedaCopiata.altro))
        //alert success
        setOpenAlert(5)
        popolaSchedaCopiata()
    }

    const caricaLogo = async(event) => {
        const file = event.target.files[0]
        //controllo che sia immagine
        if(file.type.split("/")[0] != "image") return setErrore("Formato del file non valido. Richiesta Immagine.")
        //creo l'immagine
        const preview = URL.createObjectURL(file);
        setLogo({file: file, preview: preview})
    }

    const pulisciLogo = () => setLogo({file: null, preview: null})

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;
        setErrore();
        setOpenAlert(0)
    }

    return (
        <React.Fragment>
            <HeaderScheda
                titolo={"NUOVA STRUTTURA"}
                elementi={[true, true, true, true, false, false, true, true, false, false, false, false, true]}
                situazione={situazione}
                cambiaSituazione={v => setSituazione(v)}
                annulla={() => navigate(-1)}
                nome={titolo.italiano.length > 0 ? titolo.italiano : null}
                schedaCopiata={schedaCopiata}
                doIncollaSchedaCopiata={doIncollaSchedaCopiata}
                loading={loading}
                modifiche={true}
                conferma={formik.handleSubmit}
            ></HeaderScheda>

            {situazione == 1 && <>
                <FormStruttura
                    values={formik.values}
                    errors={formik.errors}
                    touched={formik.touched}
                    handleChange={formik.handleChange}
                    setFieldValue={formik.setFieldValue}
                ></FormStruttura>
                <SceltaLogo
                    logo={logo}
                    caricaLogo={caricaLogo}
                    pulisciLogo={pulisciLogo}
                ></SceltaLogo>
            </>}
            {situazione == 2 &&
                <ContenutoScheda
                    lingua={lingua}
                    cambiaLingua={(v) => setLingua(v)}
                    titolo={titolo}
                    cambiaTitolo={cambiaTitolo}
                    contenuto={contenuto}
                    cambiaContenuto={cambiaContenuto}
                    copiaContenuti={copiaContenuti}
                    isMeta={true}
                    valoriMeta={valoriMeta}
                    cambiaValoriMeta={cambiaValoriMeta}
                ></ContenutoScheda>
            }
            {situazione == 3 &&
                <SceltaStagionalita
                    meteo={[]}
                    stagioni={stagioni}
                    stagioniSel={altro.stagioni}
                    meteoSel={null}
                    cambiaStagione={cambiaStagione}
                    cambiaMeteo={() => null}
                    soloStagioni={true}
                ></SceltaStagionalita>
            }
            {situazione == 4 &&
                <SceltaLocalita
                    listaSel={altro.localita}
                    localita={localita}
                    cambiaLocalita={cambiaLocalita}
                ></SceltaLocalita>
            }
            {situazione == 7 &&
                <SceltaSistemazioni
                    categorie={categorieSistemazioni}
                    catSelezionate={altro.categorie}
                    cambiaCategoria={cambiaCategoria}
                ></SceltaSistemazioni>
            }
            {situazione == 8 &&
                <SceltaServizi
                    serviziSel={altro.servizi}
                    aggiungiServizio={aggiungiServizio}
                    togliServizio={togliServizio}
                    modificaEvidenzaServizio={modificaEvidenzaServizio}
                    isEvidenza={true}
                ></SceltaServizi>
            }
            {situazione == 13 &&
                <SceltaTematiche
                    tematiche={tematiche}
                    listaSel={altro.tematiche}
                    cambiaTematica={cambiaTematica}
                ></SceltaTematiche>
            }
            <BottoniScheda
                loading={loading}
                conferma={formik.handleSubmit}
                annulla={() => navigate(-1)}
            ></BottoniScheda>

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 5} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Scheda Incollata.</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 4} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Contenuti copiati nelle diverse linue.</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default CreaStruttura;