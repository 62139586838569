import React, { useState } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import FormEvento from '../components/form/FormEvento';
import HeaderScheda from '../components/scheda/HeaderScheda';
import ContenutoScheda from '../components/scheda/ContenutoScheda';
import creazione from '../api/creazione';
import BottoniScheda from '../components/scheda/BottoniScheda';
import useEventi from '../controllers/esperienze/useEventi';
import useStagionalita from '../controllers/generale/useStagionalita';
import useLocalita from '../controllers/generale/useLocalita';
import useStrutture from '../controllers/campeggi/useStrutture';
import useEsperienze from '../controllers/esperienze/useEsperienze';
import useGenerale from '../controllers/generale/useGenerale';
import SceltaStagionalita from '../components/scheda/SceltaStagionalita';
import SceltaLocalita from '../components/scheda/SceltaLocalita';
import utils from '../utils/utils';
import validators from '../utils/validators';
import defaultValues from '../utils/defaultValues';
import SceltaStrutture from '../components/scheda/SceltaStrutture';
import SceltaDate from '../components/scheda/SceltaDate';
import useSchedaCopiata from '../controllers/schedaCopiata/useSchedaCopiata';
import FormMercato from '../components/form/FormMercato';
import SceltaCategorie from '../components/scheda/SceltaCategorie';
import FormDateVisibili from '../components/form/FormDateVisibili';
import SceltaTematiche from '../components/scheda/SceltaTematiche';

function CreaEvento(props) {

    const navigate = useNavigate();
    const {stagioni, meteo} = useStagionalita();
    const {categorieEsperienza, macroCategorie} = useEsperienze()
    const {localita} = useLocalita();
    const {strutture} = useStrutture();
    const {addEvento, importanzaMaxEventi} = useEventi();
    const {tematiche} = useGenerale()
    const {schedaCopiata, popolaSchedaCopiata} = useSchedaCopiata();

    const [openAlert, setOpenAlert] = useState(0);
    const [situazione, setSituazione] = useState(1);
    const [lingua, setLingua] = useState(1); //1 italiano, ...
    const [loading, setLoading] = useState(false);
    const [errore, setErrore] = useState();
    const [altro, setAltro] = useState({
        dateEvento: [],
        stagioni: new Array(stagioni.length).fill(false),
        meteo: new Array(meteo.length).fill(false),
        localita: new Array(localita.length).fill(false),
        strutture: new Array(strutture.length).fill(false),
        tematiche: new Array(tematiche.length).fill(false),
        categorie: []
    });
    const [contenuto, setContenuto] = useState({italiano: EditorState.createEmpty(), inglese: EditorState.createEmpty(), tedesco: EditorState.createEmpty(), olandese: EditorState.createEmpty(), danese: EditorState.createEmpty()});
    const [titolo, setTitolo] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [valoriMeta, setValoriMeta] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});

    const formik = useFormik({
        validationSchema: validators.evento,
        initialValues: defaultValues.evento,
        onSubmit: async(v) => {
            if(!titolo.italiano?.length) return setErrore("Inserire il nome in Italiano.");
            setLoading(true);
            let values = {...v}
            //aggiungo importanza
            values.importanza = importanzaMaxEventi()
            //aggiungo i titoli
            values.nome_italiano = titolo.italiano;
            values.nome_inglese = titolo.inglese;
            values.nome_tedesco = titolo.tedesco;
            values.nome_olandese = titolo.olandese;
            values.nome_danese = titolo.danese;
            //aggiungo le descrizioni
            values.descrizione_italiano = draftToHtml(convertToRaw(contenuto.italiano.getCurrentContent()));
            values.descrizione_inglese = draftToHtml(convertToRaw(contenuto.inglese.getCurrentContent()));
            values.descrizione_tedesco = draftToHtml(convertToRaw(contenuto.tedesco.getCurrentContent()));
            values.descrizione_olandese = draftToHtml(convertToRaw(contenuto.olandese.getCurrentContent()));
            values.descrizione_danese = draftToHtml(convertToRaw(contenuto.danese.getCurrentContent()));
            //aggiungo i meta tag
            values.meta_italiano = valoriMeta.italiano;
            values.meta_inglese = valoriMeta.inglese;
            values.meta_tedesco = valoriMeta.tedesco;
            values.meta_olandese = valoriMeta.olandese;
            values.meta_danese = valoriMeta.danese;
            //controllo lat e long
            values.latitudine = parseFloat(values.latitudine) ? parseFloat(values.latitudine) : null;
            values.longitudine = parseFloat(values.longitudine) ? parseFloat(values.longitudine) : null;
            //creo l'oggetto
            const results = await creazione.creaEvento(values, altro.dateEvento, utils.componiLista(stagioni, altro.stagioni), utils.componiLista(meteo, altro.meteo), utils.componiLista(localita, altro.localita), utils.componiLista(strutture, altro.strutture), utils.componiLista(tematiche, altro.tematiche), altro.categorie);
            if(!results.ok) {
                setErrore(results.data);
                return setLoading(false);
            }
            //aggiungo l'oggetto al context
            addEvento(results.data)
            //manda indietro
            setTimeout(() => {
                setLoading(false);
                navigate("..", {replace: true, state:{nuovoEvento: results.data}});
            }, 500);
        }
    });

    const cambiaTitolo = (event) => {
        const data = event.target.value.trim();
        const appo = {...titolo};
        if(lingua == 1) {
            appo.italiano = data;
            return setTitolo(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setTitolo(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setTitolo(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setTitolo(appo)
        } else {
            appo.danese = data;
            return setTitolo(appo)
        }
    }

    const cambiaValoriMeta = (event) => {
        const data = event.target.value
        const appo = {...valoriMeta};
        if(lingua == 1) {
            appo.italiano = data;
            return setValoriMeta(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setValoriMeta(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setValoriMeta(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setValoriMeta(appo)
        } else {
            appo.danese = data;
            return setValoriMeta(appo)
        }
    }

    const cambiaContenuto = (newState) => {
        const appo = {...contenuto};
        if(lingua == 1) {
            appo.italiano = newState;
            return setContenuto(appo)
        } else if(lingua == 2) {
            appo.inglese = newState;
            return setContenuto(appo)
        } else if(lingua == 3) {
            appo.tedesco = newState;
            return setContenuto(appo)
        } else if(lingua == 4) {
            appo.olandese = newState;
            return setContenuto(appo)
        } else {
            appo.danese = newState;
            return setContenuto(appo)
        }
    }

    const copiaContenuti = (appoTitolo, appoContenuto) => {
        setTitolo(appoTitolo)
        setContenuto(appoContenuto)
        setOpenAlert(4)
    }

    const cambiaStagione = (position) => {
        const appo = {...altro}
        const nuovo = altro.stagioni.map((item, index) =>
            index === position ? !item : item
        );
        appo.stagioni = nuovo;
        setAltro(appo);
    }

    const cambiaMeteo = (position) => {
        const appo = {...altro}
        const nuovo = altro.meteo.map((item, index) =>
            index === position ? !item : item
        );
        appo.meteo = nuovo;
        setAltro(appo);
    }

    const cambiaLocalita = (position) => {
        const appo = {...altro}
        const nuovo = altro.localita.map((item, index) =>
            index === position ? !item : item
        );
        appo.localita = nuovo;
        setAltro(appo);
    }

    const cambiaStruttura = (position) => {
        const appo = {...altro}
        const nuovo = altro.strutture.map((item, index) =>
            index === position ? !item : item
        );
        appo.strutture = nuovo;
        setAltro(appo);
    }

    const cambiaCategoria = (id) => {
        const appo = {...altro}
        const trovato = altro.categorie.filter((c) => c == id)[0]
        if(trovato) {
            appo.categorie = appo.categorie.filter((c) => c != id)
        } else {
            appo.categorie.push(id)
        }
        setAltro(appo);
    }

    const cambiaTematica = (position) => {
        const appo = {...altro}
        const nuovo = altro.tematiche.map((item, index) =>
            index === position ? !item : item
        );
        appo.tematiche = nuovo;
        setAltro(appo);
    }

    const aggiungiDate = (data_inizio, data_fine) => {
        if(data_inizio > data_fine) return setErrore("La Data finale deve essere maggiore di quella iniziale.")
        if(altro.dateEvento.filter((d) => utils.scriviData(d.data_inizio) == utils.scriviData(data_inizio) || utils.scriviData(d.data_fine) == utils.scriviData(data_fine)).length > 0) return setErrore("Date già inserita.");
        const appo = {...altro}
        appo.dateEvento.push({data_inizio, data_fine});
        setAltro(appo)
    }

    const togliDate = (pos) => {
        const appo = {...altro}
        appo.dateEvento = appo.dateEvento.filter((d, index) => index != pos);
        setAltro(appo)
    }

    const doIncollaSchedaCopiata = () => {
        //cambio il form
        formik.setValues(utils.incollaElementi(formik.values, schedaCopiata.values))
        //cambio il titolo
        setTitolo(schedaCopiata.titolo);
        //cambio il contenuto
        setContenuto(schedaCopiata.contenuto);
        //cambio altro
        setAltro(utils.incollaElementi(altro, schedaCopiata.altro))
        //alert success
        setOpenAlert(5)
        popolaSchedaCopiata()
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;
        setErrore();
        setOpenAlert(0)
    }

    return (
        <React.Fragment>
            <HeaderScheda
                titolo={"NUOVO EVENTO"}
                elementi={[true, true, true, true, true, true, false, false, false, false, false, false, true, true]}
                situazione={situazione}
                cambiaSituazione={v => setSituazione(v)}
                annulla={() => navigate(-1)}
                nome={titolo.italiano.length > 0 ? titolo.italiano : null}
                schedaCopiata={schedaCopiata}
                doIncollaSchedaCopiata={doIncollaSchedaCopiata}
                loading={loading}
                modifiche={true}
                conferma={formik.handleSubmit}
            ></HeaderScheda>

            {situazione == 1 && 
                <FormEvento
                    values={formik.values}
                    errors={formik.errors}
                    touched={formik.touched}
                    handleChange={formik.handleChange}
                    setFieldValue={formik.setFieldValue}
                ></FormEvento>
            }
            {situazione == 2 &&
                <ContenutoScheda
                    lingua={lingua}
                    cambiaLingua={(v) => setLingua(v)}
                    titolo={titolo}
                    cambiaTitolo={cambiaTitolo}
                    contenuto={contenuto}
                    cambiaContenuto={cambiaContenuto}
                    copiaContenuti={copiaContenuti}
                    isMeta={true}
                    valoriMeta={valoriMeta}
                    cambiaValoriMeta={cambiaValoriMeta}
                ></ContenutoScheda>
            }
            {situazione == 3 &&
                <SceltaStagionalita
                    meteo={meteo}
                    stagioni={stagioni}
                    stagioniSel={altro.stagioni}
                    meteoSel={altro.meteo}
                    cambiaStagione={cambiaStagione}
                    cambiaMeteo={cambiaMeteo}
                ></SceltaStagionalita>
            }
            {situazione == 4 &&
                <SceltaLocalita
                    listaSel={altro.localita}
                    localita={localita}
                    cambiaLocalita={cambiaLocalita}
                ></SceltaLocalita>
            }
            {situazione == 5 &&
                <SceltaStrutture
                    strutture={strutture}
                    struttureSelezionate={altro.strutture}
                    cambiaStruttura={cambiaStruttura}
                ></SceltaStrutture>
            }
            {situazione == 6 && <>
                <FormMercato
                    values={formik.values}
                    errors={formik.errors}
                    touched={formik.touched}
                    handleChange={formik.handleChange}
                    setFieldValue={formik.setFieldValue}
                ></FormMercato>
                {/* <FormDateVisibili
                    values={formik.values}
                    errors={formik.errors}
                    touched={formik.touched}
                    handleChange={formik.handleChange}
                    setFieldValue={formik.setFieldValue}
                ></FormDateVisibili> */}
                <SceltaDate
                    listaDate={altro.dateEvento}
                    aggiungiDate={aggiungiDate}
                    togliDate={togliDate}
                    cambiaErrore={(v) => setErrore(v)}
                ></SceltaDate>
            </>}
            {situazione == 13 &&
                <SceltaTematiche
                    tematiche={tematiche}
                    listaSel={altro.tematiche}
                    cambiaTematica={cambiaTematica}
                ></SceltaTematiche>
            }
            {situazione == 14 &&
                <SceltaCategorie
                    macro={macroCategorie}
                    categorie={categorieEsperienza}
                    listaSel={altro.categorie}
                    cambiaCategoria={cambiaCategoria}
                ></SceltaCategorie>
            }
            <BottoniScheda
                loading={loading}
                conferma={formik.handleSubmit}
                annulla={() => navigate(-1)}
            ></BottoniScheda>

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 5} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Scheda Incollata.</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 4} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Contenuti copiati nelle diverse linue.</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default CreaEvento;