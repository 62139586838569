import { useContext } from "react";

import CampeggiContext from "./context";

const useServizi = () => {
    const { campeggi, setCampeggi } = useContext(CampeggiContext);

    const popolaServizi = (vett) => {
        const appo = {...campeggi};
        appo.servizi = vett;
        setCampeggi(appo)
    }

    const addServizio = (s) => {
        const appo = {...campeggi};
        appo.servizi.push(s);
        setCampeggi(appo);
    }

    const aggServizio = (servizio) => {
        const appo = {...campeggi};
        for(let i = 0; i < appo.servizi.length; i++) {
            if(appo.servizi[i].id == servizio.id) appo.servizi[i] = servizio;
        }
        setCampeggi(appo);
    }

    const togliServizio = (id) => {
        const appo = {...campeggi};
        appo.servizi = campeggi.servizi.filter((a) => a.id != id);
        setCampeggi(appo)
    }

    const popolaCategorieServizi = (vett) => {
        const appo = {...campeggi};
        appo.categorieServizi = vett;
        setCampeggi(appo)
    }

    const addCategoriaServizio = (c) => {
        const appo = {...campeggi};
        appo.categorieServizi.push(c);
        setCampeggi(appo);
    }

    const aggCategoriaServizio = (cat) => {
        const appo = {...campeggi};
        for(let i = 0; i < appo.categorieServizi.length; i++) {
            if(appo.categorieServizi[i].id == cat.id) appo.categorieServizi[i] = cat;
        }
        setCampeggi(appo);
    }

    const togliCategoriaServizio = (id) => {
        const appo = {...campeggi};
        appo.categorieServizi = campeggi.categorieServizi.filter((a) => a.id != id);
        setCampeggi(appo)
    }

    const categoriaServizioById = (id) => campeggi.categorieServizi.filter((e) => e.id == id)[0]

    return { 
        servizi: campeggi.servizi,
        popolaServizi,
        addServizio,
        aggServizio,
        togliServizio,

        categorieServizi: campeggi.categorieServizi,
        popolaCategorieServizi,
        addCategoriaServizio,
        aggCategoriaServizio,
        togliCategoriaServizio,
        categoriaServizioById
    }
}

export default useServizi;