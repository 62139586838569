import { useContext } from "react";

import EsperienzeContext from "./context";

const useEsperienze = () => {
    const { esperienze, setEsperienze } = useContext(EsperienzeContext);

    const popolaAllEsperienze = (obj) => setEsperienze(obj);

    const addMacro = (m) => {
        const appo = {...esperienze};
        appo.macro.push(m);
        setEsperienze(appo);
    }

    const aggMacro = (m) => {
        const appo = {...esperienze};
        for(let i = 0; i < appo.macro.length; i++) {
            if(appo.macro[i].id == m.id) appo.macro[i] = m;
        }
        setEsperienze(appo);
    }

    const togliMacro = (id) => {
        const appo = {...esperienze};
        appo.macro = esperienze.macro.filter((a) => a.id != id);
        setEsperienze(appo)
    }

    const popolaCategorieEsperienze = (vett) => {
        const appo = {...esperienze};
        appo.categorie = vett;
        setEsperienze(appo)
    }

    const addCategoria = (cat) => {
        const appo = {...esperienze};
        appo.categorie.push(cat);
        setEsperienze(appo);
    }

    const aggCategoria = (cat) => {
        const appo = {...esperienze};
        for(let i = 0; i < appo.categorie.length; i++) {
            if(appo.categorie[i].id == cat.id) appo.categorie[i] = cat;
        }
        setEsperienze(appo);
    }

    const togliCategoria = (id) => {
        const appo = {...esperienze};
        appo.categorie = esperienze.categorie.filter((a) => a.id != id);
        setEsperienze(appo)
    }

    return { 
        popolaAllEsperienze,

        macroCategorie: esperienze.macro,
        addMacro,
        aggMacro,
        togliMacro,

        categorieEsperienza: esperienze.categorie,
        popolaCategorieEsperienze,
        addCategoria,
        aggCategoria,
        togliCategoria
    }
}

export default useEsperienze;