import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import authApi from '../../api/auth'
import BottoniScheda from '../../components/scheda/BottoniScheda'
import ZonaRuoloAdmin from '../../components/utenti/ZonaRuoloAdmin'
import ZonaPasswordAdmin from '../../components/utenti/ZonaPasswordAdmin'

function ModificaAdmin(props) {

    const navigate = useNavigate()
    const {state} = useLocation()

    const [admin, setAdmin] = useState(null)
    const [errore, setErrore] = useState(null)
    const [loading, setLoading] = useState(false)

    const [valori, setValori] = useState({isAdmin:false, pwd:''})
    const [isModifica, setIsModifica] = useState({ruolo:false, password:false})
    const modificaAttivo = isModifica.ruolo || isModifica.password

    useEffect(() => {
        const aggStato = () => {
            if(!state.admin) return navigate('..')
            setAdmin(state.admin)
        }
        aggStato()
    }, [state])

    useEffect(() => {
        const aggDati = () => {
            setValori({isAdmin: admin.is_admin, pwd:''})
            setIsModifica({ruolo:false, password:false})
        }
        if(admin) aggDati()
    }, [admin])

    const scriviPermessiUtente = () => {
        if(!admin) return '-'
        if(admin.is_super || admin.username === 'elena') return 'Super Admin'
        if(admin.is_admin) return 'Editor'
        return 'Sola lettura'
    }

    const conferma = async() => {
        let result = null
        let returnValue = {...admin}
        if(isModifica.ruolo) {
            setLoading(true)
            result = await authApi.modificaAdmin({
                id: admin.id, username: admin.username, is_admin: valori.isAdmin
            })
            if(!result.ok) {
                setLoading(false)
                return setErrore(result.data)
            }
            returnValue = result.data
        }
        if(isModifica.password) {
            if(valori.pwd.length < 6) {
                setLoading(false)
                return setErrore("Inserire password di almeno 6 caratteri.")
            }
            setLoading(true)
            result = await authApi.modificaPasswordSuper({
                id: admin.id, pwd:valori.pwd
            })
            if(!result.ok) {
                setLoading(false)
                return setErrore(result.data)
            }
        }
        setTimeout(() => {
            setLoading(false)
            setErrore(null)
            navigate('..', {state:{modificaAdmin:returnValue}})
        }, 500);
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setErrore();
    }

    if(admin) return (
        <React.Fragment>
            <Box sx={{width:'70%', backgroundColor:'white', borderRadius:'10px', border:'1px solid #dcdcdc', p:2, 
                marginTop:'20px', display:'flex', flexDirection:'column'
            }}>
                <Typography sx={{fontSize:24, fontWeight:'bold'}}>Modifica Amministratore</Typography>
                <Box sx={{marginTop:1}}>
                    <Button
                        size="small"
                        variant={"contained"}
                        color={"error"}
                        onClick={() => navigate('..')}
                        sx={{width:'120px'}}
                    >Annulla</Button>
                </Box>
                <Box sx={{padding:'24px 0 0'}}>
                    <Typography sx={{fontSize:20, fontWeight:'500', color:'chiaro.main'}}>
                        Nome Utente:&nbsp;
                        <Typography component='span' sx={{fontSize:20, display: 'inline-block', fontWeight:'500', color:'black'}}>
                            {admin ? admin.username : '-'}
                        </Typography>
                    </Typography>
                    <Typography sx={{fontSize:20, fontWeight:'500', color:'chiaro.main'}}>
                        Permessi:&nbsp;
                        <Typography component='span' sx={{fontSize:20, display: 'inline-block', fontWeight:'500', color:'black'}}>
                            {scriviPermessiUtente()}
                        </Typography>
                    </Typography>
                </Box>
            </Box>

            <ZonaRuoloAdmin
                ruolo={valori.isAdmin}
                cambiaRuolo={() => {
                    setValori({...valori, isAdmin:!valori.isAdmin})
                    setIsModifica({...isModifica, ruolo:true})
                }}
            ></ZonaRuoloAdmin>

            <ZonaPasswordAdmin
                password={valori.pwd}
                cambiaPassword={(valore) => {
                    setValori({...valori, pwd:valore})
                    setIsModifica({...isModifica, password:true})
                }}
            ></ZonaPasswordAdmin>

            {modificaAttivo &&
                <BottoniScheda
                    loading={loading}
                    conferma={conferma}
                    annulla={() => navigate('..')}
                ></BottoniScheda>
            }

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default ModificaAdmin;