import { useContext } from "react";

import PartnersContext from "./context";

const usePartners = () => {
    const { partners, setPartners } = useContext(PartnersContext);

    const popolaPartners = (vett) => {
        const appo = {...partners};
        appo.partners = vett;
        setPartners(appo);
    }

    const importanzaMaxPartners = () => {
        let appo = partners.partners;
        let valore = appo[appo.length - 1]?.importanza;
        return valore ? (valore + 1) : 1
    }

    const addPartner = (p) => {
        const appo = {...partners};
        appo.partners.push(p);
        setPartners(appo);
    }

    const aggPartner = (n) => {
        const appo = {...partners};
        for(let i = 0; i < appo.partners.length; i++) {
            if(appo.partners[i].id == n.id) appo.partners[i] = n;
        }
        setPartners(appo);
    }

    const togliPartner = (id) => {
        const appo = {...partners};
        appo.partners = partners.partners.filter((l) => l.id != id);
        setPartners(appo);
    }

    //funzioni per le categorie
    const addCategoriaPartner = (cat) => {
        const appo = {...partners};
        appo.categorie.push(cat);
        setPartners(appo);
    }

    const aggCategoriaPartner = (cat) => {
        const appo = {...partners};
        for(let i = 0; i < appo.categorie.length; i++) {
            if(appo.categorie[i].id == cat.id) appo.categorie[i] = cat;
        }
        setPartners(appo);
    }

    const togliCategoriaPartner = (id) => {
        const appo = {...partners};
        appo.categorie = partners.categorie.filter((l) => l.id != id);
        setPartners(appo);
    }

    return { 
        partners: partners.partners,
        popolaPartners,
        importanzaMaxPartners,
        addPartner,
        aggPartner,
        togliPartner,

        categoriePartner: partners.categorie,
        addCategoriaPartner,
        aggCategoriaPartner,
        togliCategoriaPartner
    }
}

export default usePartners;