import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';

function SceltaSistemazioni({categorie, catSelezionate, cambiaCategoria, isModifica}) {
    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <h3 className="admin-titoletto">CATEGORIE</h3>
            <Stack direction="column">
                {categorie.map((cat, index) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                size="medium"
                                color="primary" 
                                name={cat.nome_italiano}
                                checked={catSelezionate[index]}
                                onChange={() => cambiaCategoria(index)}
                            />} 
                        label={cat.nome_italiano}
                        key={index}
                    />
                ))}
            </Stack>
        </div>
    );
}

export default SceltaSistemazioni;