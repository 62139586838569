import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckIcon from '@mui/icons-material/Check';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Logo from '../images/logo.png';
import authApi from '../api/auth';
import useAuth from '../controllers/auth/useAuth';

function Login(props) {

    const {login} = useAuth();

    const [loading, setLoading] = useState(false);
    const [errore, setErrore] = useState();

    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Campo obbligatirio.'),
        pwd: Yup.string().required('Campo obbligatirio.')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            username: '',
            pwd: ''
        },
        onSubmit: async(values) => {
            setLoading(true);
            //preparo i dati
            const data = {
                username: values.username,
                pwd: values.pwd
            }
            //invio la richiesta
            const results = await authApi.loginAdmin(data);
            if(!results.ok) {
                setErrore(results.data)
                return setLoading(false);
            }
            setTimeout(async() => {
                await login(results.data);
                setLoading(false);
            }, 500);
        },
    });

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;
        setErrore();
    }

    return (
        <div className="admin-login-container">
            <div className="admin-login-sx">
                <img className="admin-login-img" src={Logo}></img>
            </div>

            <div className="admin-login-dx">
                <Stack direction="column" spacing={3} className="admin-login-dx-container">
                    <div className="admin-login-dx-header">
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <h3 className="admin-titoletto">Accesso Amministratore</h3>
                    </div>
                    <TextField 
                        id="outlined-basic"
                        label="Username"
                        variant="outlined"
                        name="username"
                        onChange={formik.handleChange}
                        value={formik.values.username}
                        error={formik.touched.username && Boolean(formik.errors.username)}
                        helperText={formik.touched.username && formik.errors.username}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                    <TextField 
                        id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        name="pwd"
                        onChange={formik.handleChange}
                        value={formik.values.pwd}
                        error={formik.touched.pwd && Boolean(formik.errors.pwd)}
                        helperText={formik.touched.pwd && formik.errors.pwd}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                        type={"password"}
                    />
                    <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        size="small"
                        variant={"contained"}
                        color={"primary"}
                        onClick={formik.handleSubmit}
                        type="submit"
                        className='admin-login-bottone'
                        startIcon={<CheckIcon />}
                    >ACCEDI</LoadingButton>
                </Stack>
            </div>

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
            
        </div>
    );
}

export default Login;