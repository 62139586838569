import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import HeaderScheda from '../components/scheda/HeaderScheda';
import creazione from '../api/creazione';
import useGenerale from '../controllers/generale/useGenerale';
import BarraLaterale from '../components/barraLaterale/BarraLaterale';
import FormBanner from '../components/form/FormBanner';
import ContenutoScheda from '../components/scheda/ContenutoScheda';
import BottoniScheda from '../components/scheda/BottoniScheda';
import utils from '../utils/utils';
import validators from '../utils/validators';
import defaultValues from '../utils/defaultValues';
import useSchedaCopiata from '../controllers/schedaCopiata/useSchedaCopiata';
import SceltaLogo from '../components/scheda/SceltaLogo';
import ModalElimina from '../components/scheda/ModalElimina';
import upload from '../api/upload';

function Banner(props) {

    const {state, pathname} = useLocation();
    const navigate = useNavigate();
    const {banner, aggiornaBanner, togliBanner, popolaBanner} = useGenerale();
    const {popolaSchedaCopiata} = useSchedaCopiata();

    const [openAlert, setOpenAlert] = useState(0); //1 creato, 2 modificato
    const [elemento, setElemento] = useState();
    const [situazione, setSituazione] = useState(1);
    const [lingua, setLingua] = useState(1); //1 italiano, ...
    const [loading, setLoading] = useState(false);
    const [errore, setErrore] = useState();
    const [modifica, setModifica] = useState(false);
    const [visible, setVisible] = useState(false);
    const [titolo, setTitolo] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [logo, setLogo] = useState({preview: null, file: null})

    useEffect(() => {
        cambioStato();
    }, [state])

    useEffect(() => {
        if(elemento) aggiornamentoElemento()
    }, [elemento])

    const cambioStato = () => {
        if(!state) return;
        if(state.nuovoBanner) {
            setElemento(state.nuovoBanner);
            setOpenAlert(1);
            navigate(pathname, { replace: true });
        }
    }

    const cambiaElemento = (value) => setElemento(value)

    const formik = useFormik({
        validationSchema: validators.banner,
        initialValues: defaultValues.banner,
        onSubmit: async(v) => {
            if(!titolo.italiano?.length) return setErrore("Inserire il nome in Italiano.");
            setLoading(true);
            let values = {...v}
            let results;
            //aggiungo i titoli
            values.nome_italiano = titolo.italiano;
            values.nome_inglese = titolo.inglese;
            values.nome_tedesco = titolo.tedesco;
            values.nome_olandese = titolo.olandese;
            values.nome_danese = titolo.danese;
            //controllo il logo
            if(logo.file) {
                let formData = new FormData();
                formData.append('immagine', logo.file, logo.file.name);
                results = await upload.creaImmagine(formData);
                if(!results.ok) {
                    setLoading(false)
                    return setErrore(results.data)
                }
                values.immagine = results.data.link;
            } else {
                if(logo.preview) values.immagine = elemento.logo;
                else values.immagine = null;
            }
            //modifico l'oggetto
            results = await creazione.modificaBanner(values);
            if(!results.ok) {
                setErrore(results.data);
                return setLoading(false);
            }
            //modifico l'oggetto nel context
            aggiornaBanner(results.data)
            //aggiorno la pagina
            setTimeout(() => {
                setOpenAlert(2);
                setLoading(false);
                setElemento(results.data);
            }, 500);
        }
    });

    const cambiaTitolo = (event) => {
        setModifica(true);
        const data = event.target.value.trim();
        const appo = {...titolo};
        if(lingua == 1) {
            appo.italiano = data;
            return setTitolo(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setTitolo(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setTitolo(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setTitolo(appo)
        } else {
            appo.danese = data;
            return setTitolo(appo)
        }
    }

    const copiaContenuti = (appoTitolo) => {
        setModifica(true);
        setTitolo(appoTitolo)
        setOpenAlert(4)
    }

    const aggiornamentoElemento = async() => {
        //cambio modifica
        setModifica(false)
        //cambio nome
        setTitolo({italiano: elemento.nome_italiano, inglese: elemento.nome_inglese, tedesco: elemento.nome_tedesco, olandese: elemento.nome_olandese, danese: elemento.nome_danese})
        //cambio valori formik
        await formik.setValues(utils.omit(elemento, 'immagini', 'allegati', 'data_creazione', 'data_modifica', 'nome_italiano', 'nome_inglese', 'nome_tedesco', 'nome_olandese', 'nome_danese', 'descrizione_italiano', 'descrizione_inglese', 'descrizione_tedesco', 'descrizione_olandese', 'descrizione_danese', 'slug_italiano', 'slug_inglese', 'slug_tedesco', 'slug_olandese', 'slug_danese'))
        //carico il logo
        setLogo({file: null, preview: elemento.immagine})
    }

    const doPopolaSchedaCopiata = () => {
        if(!elemento) return;
        let valori = formik.values;
        valori.attivo = false;
        popolaSchedaCopiata({values: valori, titolo, contenuto: null, altro: {}})
        setOpenAlert(3)
    }

    const caricaLogo = async(event) => {
        const file = event.target.files[0]
        //controllo che sia immagine
        if(file.type.split("/")[0] != "image") return setErrore("Formato del file non valido. Richiesta Immagine.")
        setModifica(true)
        //creo l'immagine
        const preview = URL.createObjectURL(file);
        setLogo({file: file, preview: preview})
    }

    const pulisciLogo = () => {
        setModifica(true)
        setLogo({file: null, preview: null})
    }

    const eliminaElemento = async() => {
        if(!elemento || loading) return;
        setLoading(true)
        const results = await creazione.eliminaBanner(elemento.id)
        if(!results.ok) {
            setLoading(false)
            return setErrore(results.data)
        }
        setTimeout(() => {
            togliBanner(elemento.id)
            setOpenAlert(5)
            setLoading(false)
            setVisible(false)
            setElemento()
        }, 600);
    }

    const onDragEnd = async(result) => {
        if(!result.destination) return;
        let listCopy = Array.from(banner);
        //rimuovo elemento dalla lista di partenza
        const [removedElement, newSourceList] = utils.removeFromList(
            listCopy,
            result.source.index
        );
        listCopy = newSourceList;
        //aggiungo elemento alla  di destinazione
        listCopy = utils.addToList(
            listCopy,
            result.destination.index,
            removedElement
        );
        //aggiorno il tutto
        popolaBanner(listCopy)
        //aggiorno le api
        const vett = []
        for(let i = 0; i < listCopy.length; i++) vett.push(listCopy[i].id)
        const results = await creazione.importanzaBanner(vett)
        if(!results.ok) return setErrore(results.data)
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setOpenAlert(0);
        setErrore();
    }

    const goToNuova = () => navigate('/banner/crea')

    return (
        <div className="admin-corpo-modifica">
            <div className="admin-corpo-modifica-sx">
                <BarraLaterale
                    titolo={"LISTA di BANNER PUBBLICITARI"}
                    titoloBottone={"NUOVA"}
                    lista={banner}
                    goToNuova={goToNuova}
                    cambiaElemento={cambiaElemento}
                    elementoSelezionato={elemento}
                    onDragEnd={onDragEnd}
                ></BarraLaterale>
            </div>

            <div className="admin-corpo-modifica-dx">
                <HeaderScheda
                    titolo={"BANNER PUBBLICITARIO"}
                    elementi={[true, false, false, false, false, false]}
                    situazione={situazione}
                    cambiaSituazione={v => {if(elemento) setSituazione(v)}}
                    nome={elemento?.nome_italiano.length > 0 ? elemento?.nome_italiano : null}
                    dataCreazione={elemento ? elemento.data_creazione : null}
                    dataModifica={elemento ? elemento.data_modifica : null}
                    doPopolaSchedaCopiata={() => doPopolaSchedaCopiata()}
                    copiaAbilitato={Boolean(elemento)}
                    doElimina={() => setVisible(true)}
                    isModifica={true}
                    loading={loading}
                    modifiche={modifica}
                    conferma={formik.handleSubmit}
                    annulla={() => aggiornamentoElemento()}
                ></HeaderScheda>

                {!elemento &&
                    <div className="admin-container-lungo"><h3 className="admin-titoletto">Nessun Banner Pubblicitario Selezionato</h3></div>
                }
                {elemento && 
                    <>
                        {situazione == 1 && <>
                            <SceltaLogo
                                logo={logo}
                                caricaLogo={caricaLogo}
                                pulisciLogo={pulisciLogo}
                                isModifica={true}
                            ></SceltaLogo>
                            <FormBanner
                                values={formik.values}
                                errors={formik.errors}
                                touched={formik.touched}
                                handleChange={(value) => {setModifica(true);formik.handleChange(value)}}
                                setFieldValue={(name, value) => {setModifica(true);formik.setFieldValue(name, value)}}
                                isModifica={true}
                            ></FormBanner>
                            <ContenutoScheda
                                lingua={lingua}
                                cambiaLingua={(v) => setLingua(v)}
                                titolo={titolo}
                                cambiaTitolo={cambiaTitolo}
                                contenuto={null}
                                cambiaContenuto={() => null}
                                copiaContenuti={copiaContenuti}
                                isModifica={true}
                                soloTitolo={true}
                            ></ContenutoScheda>
                        </>}
                        {modifica &&
                            <BottoniScheda
                                loading={loading}
                                conferma={formik.handleSubmit}
                                annulla={() => aggiornamentoElemento()}
                            ></BottoniScheda>
                        }
                    </>
                }
            </div>

            <Snackbar open={openAlert == 1} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Banner Pubblicitario inserito con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 2} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Banner Pubblicitario modificato con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 3} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Banner Copiato</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 4} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Contenuti copiati nelle diverse linue.</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 5} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Banner Pubblicitario eliminato con successo.</Alert>
            </Snackbar>
            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>

            {elemento &&
                <ModalElimina
                    nome={"questo Banner"}
                    nome_vero={elemento.nome_italiano}
                    visible={visible}
                    loading={loading}
                    annulla={() => setVisible(false)}
                    conferma={eliminaElemento}
                ></ModalElimina>
            }
        </div>
    );
}

export default Banner;