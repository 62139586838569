import React, {useState} from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function SceltaCategorie({macro, categorie, listaSel, cambiaCategoria, isModifica}) {

    const [macroSel, setMacroSel] = useState(0);

    const filtraCategorie = () => {
        if(!macroSel) return categorie;
        return categorie.filter((cat) => cat.macro == macroSel)
    }

    const isSelezionata = (id) => {
        const appo = listaSel.filter((s) => s == id)[0]
        if(appo) return true
        return false
    }

    return (
        <React.Fragment>
            <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <Stack direction="row" spacing={7} paddingTop={2} paddingBottom={2} alignItems="center">
                    <h3 className="admin-titoletto">Scegli la Macro</h3>
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Macro</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name="label"
                            value={macroSel}
                            onChange={(e) => setMacroSel(e.target.value)}
                            autoWidth
                            size="small"
                            label="Macro"
                        >
                            <MenuItem value={0}><em>Nessuna</em></MenuItem>
                            {macro.map((mac) => (
                                <MenuItem key={mac.id} value={mac.id}>{mac.nome_italiano}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </div>

            <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <h3 className="admin-titoletto">CATEGORIE</h3>
                <Grid 
                    container
                    spacing={0} 
                    direction="row"
                    sx={{paddingTop:1}}
                >
                    {filtraCategorie().map((cat, index) => (
                        <Grid item xs={6} md={6} sm={6} key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="medium"
                                        color="primary" 
                                        name={cat.nome_italiano}
                                        checked={isSelezionata(cat.id)}
                                        onChange={() => cambiaCategoria(cat.id)}
                                    />}
                                label={cat.nome_italiano}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </React.Fragment>
    );
}

export default SceltaCategorie;