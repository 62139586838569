import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import HeaderScheda from '../components/scheda/HeaderScheda';
import creazione from '../api/creazione';
import BarraLaterale from '../components/barraLaterale/BarraLaterale';
import ContenutoScheda from '../components/scheda/ContenutoScheda';
import BottoniScheda from '../components/scheda/BottoniScheda';
import useEsperienze from '../controllers/esperienze/useEsperienze';
import useServizio from '../controllers/campeggi/useServizi';
import useSistemazioni from '../controllers/campeggi/useSistemazioni';
import usePartners from '../controllers/partners/usePartners';
import utils from '../utils/utils';
import useSchedaCopiata from '../controllers/schedaCopiata/useSchedaCopiata';
import SceltaLogo from '../components/scheda/SceltaLogo';
import upload from '../api/upload';
import SceltaMacro from '../components/scheda/SceltaMacro';
import ModalElimina from '../components/scheda/ModalElimina';
import ImmagineCat from '../components/scheda/ImmagineCat';
import SceltaIconaBooking from '../components/scheda/SceltaIconaBooking';

function Categorie(props) {

    const {state} = useLocation();
    const navigate = useNavigate();
    const {macroCategorie, categorieEsperienza, aggMacro, aggCategoria, togliMacro, togliCategoria, popolaCategorieEsperienze} = useEsperienze();
    const {categorieServizi, aggCategoriaServizio, togliCategoriaServizio, popolaCategorieServizi} = useServizio();
    const {categorieSistemazioni, aggCategoriaSistemazione, togliCategoriaSistemazione} = useSistemazioni();
    const {categoriePartner, aggCategoriaPartner, togliCategoriaPartner} = usePartners();
    const {popolaSchedaCopiata} = useSchedaCopiata();

    const [openAlert, setOpenAlert] = useState(false);
    const [tipo, setTipo] = useState();
    const [elemento, setElemento] = useState();
    const [situazione, setSituazione] = useState(2);
    const [lingua, setLingua] = useState(1); //1 italiano, ...
    const [loading, setLoading] = useState(false);
    const [errore, setErrore] = useState();
    const [visible, setVisible] = useState(false);
    const [modifica, setModifica] = useState(false);
    const [titolo, setTitolo] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [img, setImg] = useState({id:0, link:'', italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [catSelezionata, setCatSelezionata] = useState(0);
    const [logo, setLogo] = useState({preview: null, file: null})
    const [iconaBooking, setIconaBooking] = useState('')

    useEffect(() => {
        if(elemento) aggiornamentoElemento()
        cambiaImg()
    }, [elemento])

    useEffect(() => {
        cambioStato()
    }, [state])

    const cambioStato = () => {
        if(!state) return;
        if(state.nuovoElemento) {
            setOpenAlert(true);
            setElemento(state.nuovoElemento)
            setTipo(state.tipologia)
        } else if(state.tipologia) aggiornaTipo()
    }

    const aggiornaTipo = () => {
        setTipo(state.tipologia)
        setElemento()
    }

    const cambiaElemento = (value) => setElemento(value)

    const formik = useFormik({
        initialValues: {},
        onSubmit: async(v) => {
            if(!titolo.italiano?.length) return setErrore("Inserire il nome in Italiano.");
            setLoading(true);
            let values = {...v}
            let results;
            //aggiungo i titoli
            values.nome_italiano = titolo.italiano;
            values.nome_inglese = titolo.inglese;
            values.nome_tedesco = titolo.tedesco;
            values.nome_olandese = titolo.olandese;
            values.nome_danese = titolo.danese;
            //immagine
            if(img.id) {
                values.immagine = img.id;
                values.nome_img_italiano = img.italiano;
                values.nome_img_inglese = img.inglese;
                values.nome_img_tedesco = img.tedesco;
                values.nome_img_olandese = img.olandese;
                values.nome_img_danese = img.danese;
            } else {
                if(tipo == 1 || tipo == 2) values.immagine = null;
            }
            //creo la icona booking
            if(tipo == 1 || tipo == 2) {
                values.icona_booking = null
                if(iconaBooking.length > 0) values.icona_booking = iconaBooking
            }
            //creo il logo
            if(logo.file) {
                let formData = new FormData();
                formData.append('immagine', logo.file, logo.file.name);
                results = await upload.creaImmagine(formData);
                if(!results.ok) {
                    setLoading(false)
                    return setErrore(results.data)
                }
                values.icona = results.data.link;
            } else {
                if(logo.preview) values.icona = elemento.icona;
                else values.icona = null;
            }
            //controllo macro categoria
            if(tipo == 2) {
                if(catSelezionata > 0) values.macro = catSelezionata;
                else values.macro = null;
            }
            //modifico l'oggetto
            if(tipo == 1) results = await creazione.modificaMacroCategoria(values)
            else if(tipo == 2) results = await creazione.modificaCategoriaEsperienza(values)
            else if(tipo == 3) results = await creazione.modificaCategoriaServizio(values)
            else if(tipo == 4) results = await creazione.modificaCategoriaSistemazione(values)
            else if(tipo == 5) results = await creazione.modificaCategoriaPartner(values)
            if(!results.ok) {
                setErrore(results.data);
                return setLoading(false);
            }
            //aggiungo l'oggetto al context
            if(tipo == 1) aggMacro(results.data)
            else if(tipo == 2) aggCategoria(results.data)
            else if(tipo == 3) aggCategoriaServizio(results.data)
            else if(tipo == 4) aggCategoriaSistemazione(results.data)
            else if(tipo == 5) aggCategoriaPartner(results.data)
            //conferma
            setTimeout(() => {
                setOpenAlert(2);
                setLoading(false);
                setElemento(results.data);
            }, 500);
        }
    });

    const cambiaTitolo = (event) => {
        setModifica(true);
        const data = event.target.value.trim();
        const appo = {...titolo};
        if(lingua == 1) {
            appo.italiano = data;
            return setTitolo(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setTitolo(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setTitolo(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setTitolo(appo)
        } else {
            appo.danese = data;
            return setTitolo(appo)
        }
    }

    const copiaContenuti = (appoTitolo) => {
        setModifica(true);
        setTitolo(appoTitolo)
        setOpenAlert(4)
    }

    const cambiaImg = () => {
        if(!elemento || !elemento?.immagine) return setImg({id:0, link:'', italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''})
        setImg({
            id: elemento.immagine, link:elemento.link_immagine, italiano:elemento.nome_img_italiano, inglese:elemento.nome_img_inglese,
            tedesco:elemento.nome_img_tedesco, olandese:elemento.nome_img_olandese, danese:elemento.nome_img_danese
        })
    }

    const creaImmagine = async(file) => {
        //preparo il body
        let formData = new FormData();
        formData.append('immagine', file, file.name);
        formData.append('nome_italiano', img.italiano)
        formData.append('nome_inglese', img.inglese)
        formData.append('nome_tedesco', img.tedesco)
        formData.append('nome_olandese', img.olandese)
        formData.append('nome_danese', img.danese)
        //chiama api per creare immagini
        const results = await upload.creaImmagine(formData);
        if(!results.ok) return setErrore(results.data)
        //modifico l'elemento
        const appo = results.data;
        setImg({
            id: appo.id, link:appo.link, italiano:appo.nome_italiano, inglese:appo.nome_inglese,
            tedesco:appo.nome_tedesco, olandese:appo.nome_olandese, danese:appo.nome_danese
        })
        setModifica(true);
    }

    const pulisciImmagine = () => {
        setImg({id:0, link:'', italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''})
        setModifica(true);
    }

    const cambiaTitoloImg = (lingua, value) => {
        const appo = {...img};
        setModifica(true);
        if(lingua == 1) {
            appo.italiano = value;
            return setImg(appo)
        } else if(lingua == 2) {
            appo.inglese = value;
            return setImg(appo)
        } else if(lingua == 3) {
            appo.tedesco = value;
            return setImg(appo)
        } else if(lingua == 4) {
            appo.olandese = value;
            return setImg(appo)
        } else {
            appo.danese = value;
            return setImg(appo)
        }
    }

    const aggiornamentoElemento = async() => {
        //cambio modifica
        setModifica(false)
        //cambio nome
        setTitolo({italiano: elemento.nome_italiano, inglese: elemento.nome_inglese, tedesco: elemento.nome_tedesco, olandese: elemento.nome_olandese, danese: elemento.nome_danese})
        await formik.setValues(utils.omit(elemento, 'macro', 'stagioni', 'meteo', 'localita', 'strutture', 'immagini', 'allegati', 'data_creazione', 'data_modifica', 'nome_italiano', 'nome_inglese', 'nome_tedesco', 'nome_olandese', 'nome_danese', 'descrizione_italiano', 'descrizione_inglese', 'descrizione_tedesco', 'descrizione_olandese', 'descrizione_danese', 'slug_italiano', 'slug_inglese', 'slug_tedesco', 'slug_olandese', 'slug_danese'))
        //controllo macro categoria
        if(elemento.macro) setCatSelezionata(elemento.macro);
        else setCatSelezionata(0);
        //carico il logo e l'immagine
        setLogo({file: null, preview: elemento.icona})
        if(elemento.immagine) {
            setImg({
                id: elemento.immagine, link:elemento.link_immagine, italiano:elemento.nome_img_italiano, inglese:elemento.nome_img_inglese,
                tedesco:elemento.nome_img_tedesco, olandese:elemento.nome_img_olandese, danese:elemento.nome_img_danese
            })
        } else setImg({id:0, link:'', italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''})
        //carico la icona booking
        if(tipo == 1 || tipo == 2) {
            if(elemento.icona_booking) setIconaBooking(elemento.icona_booking)
            else setIconaBooking('')
        }
    }

    const doPopolaSchedaCopiata = () => {
        if(!elemento) return;
        popolaSchedaCopiata({values: formik.values, titolo, contenuto: null, altro: {}})
        setOpenAlert(3)
    }

    const caricaLogo = async(event) => {
        const file = event.target.files[0]
        //controllo che sia immagine
        if(file.type.split("/")[0] != "image") return setErrore("Formato del file non valido. Richiesta Immagine.")
        setModifica(true)
        //creo l'immagine
        const preview = URL.createObjectURL(file);
        setLogo({file: file, preview: preview})
    }

    const pulisciLogo = () => {
        setModifica(true)
        setLogo({file: null, preview: null})
    }

    const eliminaElemento = async() => {
        if(!elemento || !tipo || loading) return;
        setLoading(true)
        let results;
        if(tipo == 1) results = await creazione.eliminaMacroCategoria(elemento.id)
        if(tipo == 2) results = await creazione.eliminaCategoriaEsperienza(elemento.id)
        if(tipo == 3) results = await creazione.eliminaCategoriaServizio(elemento.id)
        if(tipo == 4) results = await creazione.eliminaCategoriaSistemazione(elemento.id)
        if(tipo == 5) results = await creazione.eliminaCategoriaPartner(elemento.id)
        if(!results.ok) {
            setLoading(false)
            return setErrore(results.data)
        }
        setTimeout(() => {
            if(tipo == 1) togliMacro(elemento.id)
            if(tipo == 2) togliCategoria(elemento.id)
            if(tipo == 3) togliCategoriaServizio(elemento.id)
            if(tipo == 4) togliCategoriaSistemazione(elemento.id)
            if(tipo == 5) togliCategoriaPartner(elemento.id)
            setOpenAlert(5)
            setLoading(false)
            setVisible(false)
            setElemento()
        }, 600);
    }

    const onDragEnd = async(result) => {
        if(tipo !=2 && tipo != 3) return
        if(!result.destination) return;
        let listCopy = Array.from(tipo == 2 ? categorieEsperienza : categorieServizi);
        //rimuovo elemento dalla lista di partenza
        const [removedElement, newSourceList] = utils.removeFromList(
            listCopy,
            result.source.index
        );
        listCopy = newSourceList;
        //aggiungo elemento alla  di destinazione
        listCopy = utils.addToList(
            listCopy,
            result.destination.index,
            removedElement
        );
        //aggiorno il tutto
        if(tipo == 2) popolaCategorieEsperienze(listCopy)
        else if(tipo == 3) popolaCategorieServizi(listCopy)
        //aggiorno le api
        const vett = []
        let results = null
        for(let i = 0; i < listCopy.length; i++) vett.push(listCopy[i].id)
        if(tipo == 2) results = await creazione.importanzaCategorieEsperienza(vett)
        else if(tipo == 3) results = await creazione.importanzaCategorieServizi(vett)
        if(!results.ok) return setErrore(results.data)
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setOpenAlert(0);
        setErrore();
    }

    const goToNuova = () => navigate('/categorie/crea', {state: {tipologia: tipo}})

    const caricaLista = () => {
        if(!tipo) return []
        if(tipo == 1) return macroCategorie;
        if(tipo == 2) return categorieEsperienza;
        if(tipo == 3) return categorieServizi;
        if(tipo == 4) return categorieSistemazioni;
        if(tipo == 5) return categoriePartner;
    }

    const scriviTitolo = () => {
        if(tipo == 1) return "MACRO CATEGORIE";
        if(tipo == 2) return "CATEGORIE ESPERIENZA";
        if(tipo == 3) return "CATEGORIE SERVIZI";
        if(tipo == 4) return "CATEGORIE SISTEMAZIONI";
        if(tipo == 5) return "CATEGORIE PARTNER";
    }

    const scriviFraseInserito = () => {
        if(tipo == 1) return "Macro Categoria inserita con successo!";
        if(tipo == 2) return "Categoria Esperienza inserita con successo!";
        if(tipo == 3) return "Categoria Servizi inserita con successo!";
        if(tipo == 4) return "Categoria Sistemazione inserita con successo!";
        if(tipo == 5) return "Categoria Partner inserita con successo!";
    }

    const scriviFraseModificato = () => {
        if(tipo == 1) return "Macro Categoria modificata con successo!";
        if(tipo == 2) return "Categoria Esperienza modificata con successo!";
        if(tipo == 3) return "Categoria Servizi modificata con successo!";
        if(tipo == 4) return "Categoria Sistemazione modificata con successo!";
        if(tipo == 5) return "Categoria Partner modificata con successo!";
    }

    const scriviNomeLista = () => {
        if(tipo == 1) return "LISTA di MACRO CATEGORIE";
        if(tipo == 2) return "LISTA di CATEGORIE ESPERIENZA";
        if(tipo == 3) return "LISTA di CATEGORIE SERVIZI";
        if(tipo == 4) return "LISTA di CATEGORIE SISTEMAZIONE";
        if(tipo == 5) return "LISTA di CATEGORIE PARTNER";
    }

    const scriviFraseCopiato = () => {
        if(tipo == 1) return "Macro Categoria copiata";
        if(tipo == 2) return "Categoria Esperienza copiata";
        if(tipo == 3) return "Categoria Servizi copiata";
        if(tipo == 4) return "Categoria Sistemazione copiato";
        if(tipo == 5) return "Categoria Partner copiata";
    }

    const scriviFraseEliminato = () => {
        if(tipo == 1) return "Macro Categoria eliminata con successo!";
        if(tipo == 2) return "Categoria Esperienza eliminata con successo!";
        if(tipo == 3) return "Categoria Servizi eliminata con successo!";
        if(tipo == 4) return "Categoria Sistemazione eliminato con successo!";
        if(tipo == 5) return "Categoria Partner eliminata con successo!";
    }

    const scriviFraseModal = () => {
        if(tipo == 1) return "questa Macro Categoria";
        if(tipo == 2) return "questa Categoria Esperienza";
        if(tipo == 3) return "questa Categoria Servizi";
        if(tipo == 4) return "questa Categoria Sistemazione";
        if(tipo == 5) return "questa Categoria Partner";
    }

    return (
        <div className="admin-corpo-modifica">
            <div className="admin-corpo-modifica-sx">
                <BarraLaterale
                    titolo={scriviNomeLista()}
                    titoloBottone={"NUOVA"}
                    lista={caricaLista()}
                    goToNuova={goToNuova}
                    cambiaElemento={cambiaElemento}
                    elementoSelezionato={elemento}
                    hasCategorie={tipo == 2}
                    categorie={macroCategorie}
                    isMacro={tipo == 2}
                    disableAttiva={true}
                    togliDnd={(tipo == 2 || tipo == 3) ? false : true}
                    onDragEnd={onDragEnd}
                ></BarraLaterale>
            </div>

            <div className="admin-corpo-modifica-dx">
                <HeaderScheda
                    titolo={scriviTitolo()}
                    elementi={[false, true, false, false, false, false]}
                    situazione={situazione}
                    cambiaSituazione={v => {if(elemento) setSituazione(v)}}
                    nome={elemento?.nome_italiano.length > 0 ? elemento?.nome_italiano : null}
                    dataCreazione={elemento ? elemento.data_creazione : null}
                    dataModifica={elemento ? elemento.data_modifica : null}
                    doPopolaSchedaCopiata={() => doPopolaSchedaCopiata()}
                    copiaAbilitato={Boolean(elemento)}
                    doElimina={() => setVisible(true)}
                    isModifica={true}
                    loading={loading}
                    modifiche={modifica}
                    conferma={formik.handleSubmit}
                    annulla={() => aggiornamentoElemento()}
                ></HeaderScheda>

                {!elemento &&
                    <div className="admin-container-lungo"><h3 className="admin-titoletto">Nessun Elemento Selezionato</h3></div>
                }
                {elemento &&
                    <>
                        {situazione == 2 && <>
                            <ContenutoScheda
                                lingua={lingua}
                                cambiaLingua={(v) => setLingua(v)}
                                titolo={titolo}
                                cambiaTitolo={cambiaTitolo}
                                contenuto={null}
                                cambiaContenuto={() => null}
                                copiaContenuti={copiaContenuti}
                                isModifica={true}
                                soloTitolo={true}
                            ></ContenutoScheda>
                            {tipo == 2 &&
                                <SceltaMacro
                                    lista={macroCategorie}
                                    catSelezionata={catSelezionata}
                                    cambiaCatSelezionata={(event) => {
                                        setModifica(true)
                                        setCatSelezionata(event.target.value)
                                    }}
                                    isModifica={true}
                                ></SceltaMacro>
                            }
                            {(tipo == 1 || tipo == 2) &&
                                <SceltaIconaBooking
                                    iconaBooking={iconaBooking}
                                    cambiaIconaBooking={(e) => {
                                        setIconaBooking(e.target.value)
                                        setModifica(true)
                                    }}
                                    isModifica={true}
                                ></SceltaIconaBooking>
                            }
                            {tipo === 4 &&
                                <SceltaLogo
                                    logo={logo}
                                    caricaLogo={caricaLogo}
                                    pulisciLogo={pulisciLogo}
                                    isIcona={true}
                                    isModifica={true}
                                ></SceltaLogo>
                            }
                            {(tipo === 1 || tipo === 2) &&
                                <ImmagineCat
                                    img={img}
                                    creaImmagine={creaImmagine}
                                    pulisciImmagine={pulisciImmagine}
                                    cambiaTitoloImg={cambiaTitoloImg}
                                    isModifica={true}
                                ></ImmagineCat> 
                            }
                        </>}
                        {modifica &&
                            <BottoniScheda
                                loading={loading}
                                conferma={formik.handleSubmit}
                                annulla={() => aggiornamentoElemento()}
                            ></BottoniScheda>
                        }
                    </>
                }
            </div>

            <Snackbar open={openAlert == 1} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>{scriviFraseInserito()}</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 2} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>{scriviFraseModificato()}</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 3} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>{scriviFraseCopiato()}</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 4} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Contenuti copiati nelle diverse linue.</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 5} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>{scriviFraseEliminato()}</Alert>
            </Snackbar>
            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>

            {elemento &&
                <ModalElimina
                    nome={scriviFraseModal()}
                    nome_vero={elemento.nome_italiano}
                    visible={visible}
                    loading={loading}
                    annulla={() => setVisible(false)}
                    conferma={eliminaElemento}
                ></ModalElimina>
            }
        </div>
    );
}

export default Categorie;