import React from 'react';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const giorni = [
    {valore: 0, nome: 'Domenica'}, {valore: 1, nome: 'Lunedì'}, 
    {valore: 2, nome: 'Martedì'}, {valore: 3, nome: 'Mercoledì'},
    {valore: 4, nome: 'Giovedì'}, {valore: 5, nome: 'Venerdì'},
    {valore: 6, nome: 'Sabato'}
]

function FormMercato({handleChange, values, errors, touched, setFieldValue, isModifica}) {
    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <FormControlLabel
                control={<Checkbox size="medium" color="primary" name="is_ripetizione" checked={values.is_ripetizione} onChange={handleChange}/>} 
                label="Evento in Ripetizione"
            />
            {values.is_ripetizione &&
                <Stack direction="row" spacing={7} paddingTop={2} paddingBottom={2} alignItems="center">
                    <h3 className="admin-titoletto">Scegli il Giorno</h3>
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Giorno</InputLabel>
                        <Select
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            name="giorno_ripetizione"
                            value={values.giorno_ripetizione}
                            onChange={handleChange}
                            autoWidth
                            size="small"
                            label="Giorno"
                        >
                            {giorni.map((gg) => (
                                <MenuItem key={gg.valore} value={gg.valore}>{gg.nome}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            }
        </div>
    );
}

export default FormMercato;