import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';

function ElementoPaginaLaterale({nome, onClick, selezionato}) {
    return (
        <ListItemButton
          selected={selezionato}
          onClick={onClick}
          sx={{paddingLeft: 1, paddingRight: 1}}
        >
            <div className="elemento-laterale">
                <div className="riga-elemento-laterale">
                    <span className="nome-elemento-laterale">{nome}</span>
                </div>
            </div>
        </ListItemButton>
    );
}

export default ElementoPaginaLaterale;