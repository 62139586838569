import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import HeaderScheda from '../components/scheda/HeaderScheda';
import ContenutoScheda from '../components/scheda/ContenutoScheda';
import creazione from '../api/creazione';
import BottoniScheda from '../components/scheda/BottoniScheda';
import usePartners from '../controllers/partners/usePartners';
import useEsperienze from '../controllers/esperienze/useEsperienze';
import useServizio from '../controllers/campeggi/useServizi';
import useSistemazioni from '../controllers/campeggi/useSistemazioni';
import useSchedaCopiata from '../controllers/schedaCopiata/useSchedaCopiata';
import SceltaLogo from '../components/scheda/SceltaLogo';
import upload from '../api/upload';
import utils from '../utils/utils';
import SceltaMacro from '../components/scheda/SceltaMacro';
import ImmagineCat from '../components/scheda/ImmagineCat';
import SceltaIconaBooking from '../components/scheda/SceltaIconaBooking';

function CreaCategoria(props) {

    const navigate = useNavigate();
    const {state} = useLocation();
    const {macroCategorie, addMacro, addCategoria} = useEsperienze();
    const {addCategoriaServizio} = useServizio();
    const {addCategoriaSistemazione} = useSistemazioni();
    const {addCategoriaPartner} = usePartners();
    const {schedaCopiata, popolaSchedaCopiata} = useSchedaCopiata();

    const [openAlert, setOpenAlert] = useState(0);
    const [situazione, setSituazione] = useState(2);
    const [lingua, setLingua] = useState(1); //1 italiano, ...
    const [loading, setLoading] = useState(false);
    const [errore, setErrore] = useState();
    const [tipo, setTipo] = useState();
    const [titolo, setTitolo] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [img, setImg] = useState({id:0, link:'', italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [logo, setLogo] = useState({preview: null, file: null})
    const [catSelezionata, setCatSelezionata] = useState(0);
    const [iconaBooking, setIconaBooking] = useState('')

    useEffect(() => {
        if(state.tipologia) aggiornaTipo()
    }, [state])

    const aggiornaTipo = () => {
        setTipo(state.tipologia)
    }

    const formik = useFormik({
        initialValues: {},
        onSubmit: async(v) => {
            if(!titolo.italiano?.length) return setErrore("Inserire il nome in Italiano.");
            setLoading(true);
            let values = {...v}
            let results;
            //aggiungo i titoli
            values.nome_italiano = titolo.italiano;
            values.nome_inglese = titolo.inglese;
            values.nome_tedesco = titolo.tedesco;
            values.nome_olandese = titolo.olandese;
            values.nome_danese = titolo.danese;
            //immagine
            if(img.id) {
                values.immagine = img.id;
                values.nome_img_italiano = img.italiano;
                values.nome_img_inglese = img.inglese;
                values.nome_img_tedesco = img.tedesco;
                values.nome_img_olandese = img.olandese;
                values.nome_img_danese = img.danese;
            }
            //controllo macro categoria
            if(tipo == 2) {
                if(catSelezionata > 0) values.macro = catSelezionata;
                else values.macro = null;
            }
            //creo la icona booking
            if(tipo == 1 || tipo == 2) {
                values.icona_booking = null
                if(iconaBooking.length > 0) values.icona_booking = iconaBooking
            }
            //creo il logo
            if(logo.file) {
                let formData = new FormData();
                formData.append('immagine', logo.file, logo.file.name);
                results = await upload.creaImmagine(formData);
                if(!results.ok) {
                    setLoading(false)
                    return setErrore(results.data)
                }
                values.icona = results.data.link;
            }
            //creo l'oggetto
            if(tipo == 1) results = await creazione.creaMacroCategoria(values)
            else if(tipo == 2) results = await creazione.creaCategoriaEsperienza(values)
            else if(tipo == 3) results = await creazione.creaCategoriaServizio(values)
            else if(tipo == 4) results = await creazione.creaCategoriaSistemazione(values)
            else if(tipo == 5) results = await creazione.creaCategoriaPartner(values)
            if(!results.ok) {
                setErrore(results.data);
                return setLoading(false);
            }
            //aggiungo l'oggetto al context
            if(tipo == 1) addMacro(results.data)
            else if(tipo == 2) addCategoria(results.data)
            else if(tipo == 3) addCategoriaServizio(results.data)
            else if(tipo == 4) addCategoriaSistemazione(results.data)
            else if(tipo == 5) addCategoriaPartner(results.data)
            //manda indietro
            setTimeout(() => {
                setLoading(false);
                navigate("..", {replace: true, state:{tipologia: tipo, nuovoElemento: results.data}});
            }, 500);
        }
    });

    const scriviTitolo = () => {
        if(tipo == 1) return "NUOVA MACRO CATEGORIA";
        if(tipo == 2) return "NUOVA CATEGORIA ESPERIENZA";
        if(tipo == 3) return "NUOVA CATEGORIA SERVIZI";
        if(tipo == 4) return "NUOVO CATEGORIA SISTEMAZIONE";
        if(tipo == 5) return "NUOVA CATEGORIA PARTNER";
    }

    const cambiaTitolo = (event) => {
        const data = event.target.value.trim();
        const appo = {...titolo};
        if(lingua == 1) {
            appo.italiano = data;
            return setTitolo(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setTitolo(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setTitolo(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setTitolo(appo)
        } else {
            appo.danese = data;
            return setTitolo(appo)
        }
    }

    const copiaContenuti = (appoTitolo) => {
        setTitolo(appoTitolo)
        setOpenAlert(4)
    }

    const doIncollaSchedaCopiata = () => {
        //cambio il form
        formik.setValues(utils.incollaElementi(formik.values, schedaCopiata.values))
        //cambio il titolo
        setTitolo(schedaCopiata.titolo);
        //alert success
        setOpenAlert(5)
        popolaSchedaCopiata()
    }

    const caricaLogo = async(event) => {
        const file = event.target.files[0]
        //controllo che sia immagine
        if(file.type.split("/")[0] != "image") return setErrore("Formato del file non valido. Richiesta Immagine.")
        //creo l'immagine
        const preview = URL.createObjectURL(file);
        setLogo({file: file, preview: preview})
    }

    const pulisciLogo = () => setLogo({file: null, preview: null})

    const creaImmagine = async(file) => {
        //preparo il body
        let formData = new FormData();
        formData.append('immagine', file, file.name);
        formData.append('nome_italiano', img.italiano)
        formData.append('nome_inglese', img.inglese)
        formData.append('nome_tedesco', img.tedesco)
        formData.append('nome_olandese', img.olandese)
        formData.append('nome_danese', img.danese)
        //chiama api per creare immagini
        const results = await upload.creaImmagine(formData);
        if(!results.ok) return setErrore(results.data)
        //modifico l'elemento
        const appo = results.data;
        setImg({
            id: appo.id, link:appo.link, italiano:appo.nome_italiano, inglese:appo.nome_inglese,
            tedesco:appo.nome_tedesco, olandese:appo.nome_olandese, danese:appo.nome_danese
        })
    }

    const pulisciImmagine = () => setImg({id:0, link:'', italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''})

    const cambiaTitoloImg = (lingua, value) => {
        const appo = {...img};
        if(lingua == 1) {
            appo.italiano = value;
            return setImg(appo)
        } else if(lingua == 2) {
            appo.inglese = value;
            return setImg(appo)
        } else if(lingua == 3) {
            appo.tedesco = value;
            return setImg(appo)
        } else if(lingua == 4) {
            appo.olandese = value;
            return setImg(appo)
        } else {
            appo.danese = value;
            return setImg(appo)
        }
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;
        setErrore();
        setOpenAlert(0)
    }

    return (
        <React.Fragment>
            <HeaderScheda
                titolo={scriviTitolo()}
                elementi={[false, true]}
                situazione={situazione}
                cambiaSituazione={v => setSituazione(v)}
                annulla={() => navigate(-1)}
                nome={titolo.italiano.length > 0 ? titolo.italiano : null}
                schedaCopiata={schedaCopiata}
                doIncollaSchedaCopiata={doIncollaSchedaCopiata}
                loading={loading}
                modifiche={true}
                conferma={formik.handleSubmit}
            ></HeaderScheda>

            {situazione == 2 && <>
                <ContenutoScheda
                    lingua={lingua}
                    cambiaLingua={(v) => setLingua(v)}
                    titolo={titolo}
                    cambiaTitolo={cambiaTitolo}
                    contenuto={null}
                    cambiaContenuto={() => null}
                    copiaContenuti={copiaContenuti}
                    soloTitolo={true}
                ></ContenutoScheda>
                {tipo === 2 &&
                    <SceltaMacro
                        lista={macroCategorie}
                        catSelezionata={catSelezionata}
                        cambiaCatSelezionata={(event) => setCatSelezionata(event.target.value)}
                    ></SceltaMacro>
                }
                {(tipo == 1 || tipo == 2) &&
                    <SceltaIconaBooking
                        iconaBooking={iconaBooking}
                        cambiaIconaBooking={(e) => setIconaBooking(e.target.value)}
                    ></SceltaIconaBooking>
                }
                {tipo === 4 &&
                    <SceltaLogo
                        logo={logo}
                        caricaLogo={caricaLogo}
                        pulisciLogo={pulisciLogo}
                        isIcona={true}
                    ></SceltaLogo>
                }
                {(tipo === 1 || tipo === 2) &&
                    <ImmagineCat
                        img={img}
                        creaImmagine={creaImmagine}
                        pulisciImmagine={pulisciImmagine}
                        cambiaTitoloImg={cambiaTitoloImg}
                    ></ImmagineCat> 
                }
            </>}
            <BottoniScheda
                loading={loading}
                conferma={formik.handleSubmit}
                annulla={() => navigate(-1)}
            ></BottoniScheda>

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 5} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Scheda Incollata.</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 4} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Contenuti copiati nelle diverse linue.</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default CreaCategoria;