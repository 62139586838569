import { useContext } from "react";

import CmsContext from "./context";

const useCms = () => {
    const { cms, setCms } = useContext(CmsContext);

    const popolaAllCms = (obj) => setCms(obj);

    //pagina
    const creaPagina = (p) => {
        const appo = {...cms}
        appo.pagine.push(p)
        setCms(appo)
    }
    const modificaPagina = (p) => {
        const appo = {...cms}
        for(let i = 0; i < appo.pagine.length; i++) {
            if(appo.pagine[i].id == p.id) appo.pagine[i] = p;
        }
        setCms(appo)
    }
    const togliPagina = (id) => {
        const appo = {...cms}
        appo.pagine = appo.pagine.filter((p) => p.id != id)
        appo.elementi = appo.elementi.filter((el) => el.pagina != id)
        setCms(appo)
    }

    //elemento
    const creaElemento = (el) => {
        const appo = {...cms}
        appo.elementi.push(el)
        setCms(appo)
    }
    const modificaElemento = (el) => {
        const appo = {...cms}
        for(let i = 0; i < appo.elementi.length; i++) {
            if(appo.elementi[i].id == el.id) appo.elementi[i] = el;
        }
        setCms(appo)
    }
    const togliElemento = (id) => {
        const appo = {...cms}
        appo.elementi = appo.elementi.filter((el) => el.id != id)
        setCms(appo)
    }

    return { 
        popolaAllCms,

        pagine: cms.pagine,
        elementi: cms.elementi,
        sezioni: cms.elementi,

        creaPagina,
        modificaPagina,
        togliPagina,

        creaElemento,
        modificaElemento,
        togliElemento,
    }
}

export default useCms;