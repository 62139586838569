const omit = (obj, ...props) => {
    const result = { ...obj };
    props.forEach(function(prop) {
        delete result[prop];
    });
    return result;
}

const componiLista = (lista, checked) => {
    const vett = []
    for(let i = 0; i < lista.length; i++) {
        if(checked[i]) vett.push(lista[i].id)
    }
    return vett;
}

const componiListaId = (lista) => {
    const vett = []
    for(let i = 0; i < lista.length; i++) {
        vett.push(lista[i].id)
    }
    return vett;
}

const componiListaCategorie = (lista) => {
    const vett = []
    for(let i = 0; i < lista.length; i++) {
        vett.push(lista[i].categoria)
    }
    return vett;
}

const componiListaAttrazioni = (lista) => {
    const vett = []
    for(let i = 0; i < lista.length; i++) {
        vett.push(lista[i].attrazione)
    }
    return vett;
}

const componiListaItinerari = (lista) => {
    const vett = []
    for(let i = 0; i < lista.length; i++) {
        vett.push(lista[i].itinerario)
    }
    return vett;
}

const componiListaEventi = (lista) => {
    const vett = []
    for(let i = 0; i < lista.length; i++) {
        vett.push(lista[i].evento)
    }
    return vett;
}

const componiListaGuide = (lista) => {
    const vett = []
    for(let i = 0; i < lista.length; i++) {
        vett.push(lista[i].guida)
    }
    return vett;
}

const componiListaServizi = (lista) => {
    const vett = []
    for(let i = 0; i < lista.length; i++) {
        vett.push({id: lista[i].servizio, evidenza_preview: lista[i].evidenza_preview ? true : false, evidenza_scheda: lista[i].evidenza_scheda ? true : false})
    }
    return vett;
}

const componiListaBool = (lista, checked, valore) => {
    const vett = [];
    let trovato = false;
    for(let i = 0; i < lista.length; i++) {
        trovato = false;
        for(let j = 0; j < checked.length; j++) {
            if(checked[j][valore] == lista[i].id) trovato = true;
        }
        if(trovato) vett.push(true)
        else vett.push(false)
    }
    return vett;
}

const caricaDate = (lista) => {
    const vett = []
    for(let i = 0; i < lista.length; i++) {
        vett.push({data_inizio: new Date(lista[i].data_inizio), data_fine: new Date(lista[i].data_fine)})
    }
    return vett
}

const scriviData = (v) => {
    if(!(v instanceof Date && !isNaN(v))) return "Data non valida.";
    return new Intl.DateTimeFormat("it-IT", {year: "numeric",month: "2-digit",day: "2-digit"}).format(v)
}

const scriviOra = (v) => {
    if(!(v instanceof Date && !isNaN(v))) return "Ora non valida.";
    return v.toLocaleTimeString().substring(0, 5)
}

const incollaElementi = (vecchio, nuovo) => {
    const result = {}
    //scorro il vecchio altro
    for(const property in vecchio) {
        if(nuovo[property]) result[property] = nuovo[property]
        else result[property] = vecchio[property]
    }
    return result
}

const removeFromList = (list, index) => {
    const result = Array.from(list);
    const [removed] = result.splice(index, 1);
    return [removed, result];
}
  
const addToList = (list, index, element) => {
    const result = Array.from(list);
    result.splice(index, 0, element);
    return result;
}

const scriviNumeroData = (d) => {
    const num = d.getDate()
    if(parseInt(num) < 10) return '0' + num
    return num
}

const scriviMeseAbbr = (m) => {
    if(m == 0) return "Gen"
    else if(m == 1) return "Feb"
    else if(m == 2) return "Mar"
    else if(m == 3) return "Apr"
    else if(m == 4) return "Mag"
    else if(m == 5) return "Giu"
    else if(m == 6) return "Lug"
    else if(m == 7) return "Ago"
    else if(m == 8) return "Set"
    else if(m == 9) return "Ott"
    else if(m == 10) return "Nov"
    else if(m == 11) return "Dec"
}

const scriviFraseOspiti = (ospiti) => {
    let result = '';
    if(ospiti.adulti > 0) {
        result += ospiti.adulti + " adulti"
    }
    const num = ospiti.bambini.length;
    if(num > 0) {
        if(result.length > 0) result += ', '
        result += num + " bambini"
    }
    if(ospiti.neonati) {
        if(result.length > 0) result += ', '
        result += ospiti.neonati + " neonati"
    }
    return result.length > 0 ? result : 'Ospiti'
}

const scriviFraseDate = (date) => {
    if(!date) return "Date";
    if(date.isFirst) return "Date";
    const num1 = scriviNumeroData(date.startDate)
    const num2 = scriviNumeroData(date.endDate)
    const m1 = date.startDate.getMonth()
    const m2 = date.endDate.getMonth()
    if(m1 == m2) {
        return num1 + "-" + num2 + " " + scriviMeseAbbr(m1)
    } else {
        return num1 + " " + scriviMeseAbbr(m1) + " - " + num2 + " " + scriviMeseAbbr(m2)
    }
}

const scriviSiglaLingua = (linguaggio) => {
    if(linguaggio == 1) return "IT"
    if(linguaggio == 2) return "EN"
    if(linguaggio == 3) return "DE"
    if(linguaggio == 4) return "NL"
    if(linguaggio == 5) return "DK"
}

export default {
    omit,
    componiLista,
    componiListaBool,
    caricaDate,
    scriviData,
    componiListaCategorie,
    componiListaAttrazioni,
    componiListaItinerari,
    componiListaEventi,
    componiListaGuide,
    componiListaServizi,
    componiListaId,
    scriviOra,
    incollaElementi,
    removeFromList,
    addToList,
    scriviNumeroData,
    scriviMeseAbbr,

    scriviFraseOspiti,
    scriviFraseDate,
    scriviSiglaLingua
}