import React, { useState } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import HeaderScheda from '../components/scheda/HeaderScheda';
import ContenutoScheda from '../components/scheda/ContenutoScheda';
import creazione from '../api/creazione';
import BottoniScheda from '../components/scheda/BottoniScheda';
import FormPartner from '../components/form/FormPartner';
import usePartners from '../controllers/partners/usePartners';
import useAttrazioni from '../controllers/esperienze/useAttrazioni';
import useItinerari from '../controllers/esperienze/useItinerari';
import useEventi from '../controllers/esperienze/useEventi';
import useGuide from '../controllers/esperienze/useGuide';
import useNews from '../controllers/news/useNews';
import useLocalita from '../controllers/generale/useLocalita';
import utils from '../utils/utils';
import validators from '../utils/validators';
import defaultValues from '../utils/defaultValues';
import useSchedaCopiata from '../controllers/schedaCopiata/useSchedaCopiata';
import SceltaLogo from '../components/scheda/SceltaLogo';
import SceltaEsperienze from '../components/scheda/SceltaEsperienze';
import SceltaLocalita from '../components/scheda/SceltaLocalita';
import useEsperienze from '../controllers/esperienze/useEsperienze';
import SceltaNews from '../components/scheda/SceltaNews';
import upload from '../api/upload';

function CreaPartners(props) {

    const navigate = useNavigate();
    const {attrazioni} = useAttrazioni();
    const {itinerari} = useItinerari();
    const {eventi} = useEventi();
    const {guide} = useGuide();
    const {news} = useNews()
    const {localita} = useLocalita();
    const {macroCategorie, categorieEsperienza} = useEsperienze()
    const {categoriePartner, addPartner, importanzaMaxPartners} = usePartners();
    const {schedaCopiata, popolaSchedaCopiata} = useSchedaCopiata();

    const [openAlert, setOpenAlert] = useState(0);
    const [situazione, setSituazione] = useState(1);
    const [lingua, setLingua] = useState(1); //1 italiano, ...
    const [loading, setLoading] = useState(false);
    const [errore, setErrore] = useState();
    const [altro, setAltro] = useState({
        localita: new Array(localita.length).fill(false),
        attrazioni: [],
        itinerari: [],
        eventi: [],
        guide: [],
        news: new Array(news.length).fill(false)
    });
    const [contenuto, setContenuto] = useState({italiano: EditorState.createEmpty(), inglese: EditorState.createEmpty(), tedesco: EditorState.createEmpty(), olandese: EditorState.createEmpty(), danese: EditorState.createEmpty()});
    const [titolo, setTitolo] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [valoriMeta, setValoriMeta] = useState({italiano: '', inglese: '', tedesco: '', olandese: '', danese: ''});
    const [logo, setLogo] = useState({preview: null, file: null})

    const formik = useFormik({
        validationSchema: validators.partner,
        initialValues: defaultValues.partner,
        onSubmit: async(v) => {
            if(!titolo.italiano?.length) return setErrore("Inserire il nome in Italiano.");
            setLoading(true);
            let values = {...v}
            let results;
            //aggiungo importanza
            values.importanza = importanzaMaxPartners()
            //aggiungo i titoli
            values.nome_italiano = titolo.italiano;
            values.nome_inglese = titolo.inglese;
            values.nome_tedesco = titolo.tedesco;
            values.nome_olandese = titolo.olandese;
            values.nome_danese = titolo.danese;
            //aggiungo le descrizioni
            values.descrizione_italiano = draftToHtml(convertToRaw(contenuto.italiano.getCurrentContent()));
            values.descrizione_inglese = draftToHtml(convertToRaw(contenuto.inglese.getCurrentContent()));
            values.descrizione_tedesco = draftToHtml(convertToRaw(contenuto.tedesco.getCurrentContent()));
            values.descrizione_olandese = draftToHtml(convertToRaw(contenuto.olandese.getCurrentContent()));
            values.descrizione_danese = draftToHtml(convertToRaw(contenuto.danese.getCurrentContent()));
            //aggiungo i meta tag
            values.meta_italiano = valoriMeta.italiano;
            values.meta_inglese = valoriMeta.inglese;
            values.meta_tedesco = valoriMeta.tedesco;
            values.meta_olandese = valoriMeta.olandese;
            values.meta_danese = valoriMeta.danese;
            //controllo lat e long e categoria
            values.latitudine = parseFloat(values.latitudine) ? parseFloat(values.latitudine) : null;
            values.longitudine = parseFloat(values.longitudine) ? parseFloat(values.longitudine) : null;
            values.categoria = parseInt(values.categoria) ? parseInt(values.categoria) : null;
            //creo il logo
            if(logo.file) {
                let formData = new FormData();
                formData.append('immagine', logo.file, logo.file.name);
                results = await upload.creaImmagine(formData);
                if(!results.ok) {
                    setLoading(false)
                    return setErrore(results.data)
                }
                values.logo = results.data.link;
            }
            //creo l'oggetto
            results = await creazione.creaPartner(values, utils.componiLista(localita, altro.localita), altro.attrazioni, altro.itinerari, altro.guide, altro.eventi, utils.componiLista(news, altro.news));
            if(!results.ok) {
                setErrore(results.data);
                return setLoading(false);
            }
            //aggiungo l'oggetto al context
            addPartner(results.data)
            //manda indietro
            setTimeout(() => {
                setLoading(false);
                navigate("..", {replace: true, state:{nuovoPartner: results.data}});
            }, 500);
        }
    });

    const cambiaTitolo = (event) => {
        const data = event.target.value.trim();
        const appo = {...titolo};
        if(lingua == 1) {
            appo.italiano = data;
            return setTitolo(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setTitolo(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setTitolo(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setTitolo(appo)
        } else {
            appo.danese = data;
            return setTitolo(appo)
        }
    }

    const cambiaValoriMeta = (event) => {
        const data = event.target.value
        const appo = {...valoriMeta};
        if(lingua == 1) {
            appo.italiano = data;
            return setValoriMeta(appo)
        } else if(lingua == 2) {
            appo.inglese = data;
            return setValoriMeta(appo)
        } else if(lingua == 3) {
            appo.tedesco = data;
            return setValoriMeta(appo)
        } else if(lingua == 4) {
            appo.olandese = data;
            return setValoriMeta(appo)
        } else {
            appo.danese = data;
            return setValoriMeta(appo)
        }
    }

    const cambiaContenuto = (newState) => {
        const appo = {...contenuto};
        if(lingua == 1) {
            appo.italiano = newState;
            return setContenuto(appo)
        } else if(lingua == 2) {
            appo.inglese = newState;
            return setContenuto(appo)
        } else if(lingua == 3) {
            appo.tedesco = newState;
            return setContenuto(appo)
        } else if(lingua == 4) {
            appo.olandese = newState;
            return setContenuto(appo)
        } else {
            appo.danese = newState;
            return setContenuto(appo)
        }
    }

    const copiaContenuti = (appoTitolo, appoContenuto) => {
        setTitolo(appoTitolo)
        setContenuto(appoContenuto)
        setOpenAlert(4)
    }

    const cambiaAttrazione = (id) => {
        const appo = {...altro}
        const trovato = altro.attrazioni.filter((c) => c == id)[0]
        if(trovato) {
            appo.attrazioni = appo.attrazioni.filter((c) => c != id)
        } else {
            appo.attrazioni.push(id)
        }
        setAltro(appo);
    }
    
    const cambiaItinerario = (id) => {
        const appo = {...altro}
        const trovato = altro.itinerari.filter((c) => c == id)[0]
        if(trovato) {
            appo.itinerari = appo.itinerari.filter((c) => c != id)
        } else {
            appo.itinerari.push(id)
        }
        setAltro(appo);
    }

    const cambiaEvento = (id) => {
        const appo = {...altro}
        const trovato = altro.eventi.filter((c) => c == id)[0]
        if(trovato) {
            appo.eventi = appo.eventi.filter((c) => c != id)
        } else {
            appo.eventi.push(id)
        }
        setAltro(appo);
    }

    const cambiaGuida = (id) => {
        const appo = {...altro}
        const trovato = altro.guide.filter((c) => c == id)[0]
        if(trovato) {
            appo.guide = appo.guide.filter((c) => c != id)
        } else {
            appo.guide.push(id)
        }
        setAltro(appo);
    }

    const cambiaLocalita = (position) => {
        const appo = {...altro}
        const nuovo = altro.localita.map((item, index) =>
            index === position ? !item : item
        );
        appo.localita = nuovo;
        setAltro(appo);
    }

    const cambiaNews = (position) => {
        const appo = {...altro}
        const nuovo = altro.news.map((item, index) =>
            index === position ? !item : item
        );
        appo.news = nuovo;
        setAltro(appo);
    }

    const doIncollaSchedaCopiata = () => {
        //cambio il form
        formik.setValues(utils.incollaElementi(formik.values, schedaCopiata.values))
        //cambio il titolo
        setTitolo(schedaCopiata.titolo);
        //cambio il contenuto
        setContenuto(schedaCopiata.contenuto);
        //alert success
        setOpenAlert(5)
        popolaSchedaCopiata()
    }

    const caricaLogo = async(event) => {
        const file = event.target.files[0]
        //controllo che sia immagine
        if(file.type.split("/")[0] != "image") return setErrore("Formato del file non valido. Richiesta Immagine.")
        //creo l'immagine
        const preview = URL.createObjectURL(file);
        setLogo({file: file, preview: preview})
    }

    const pulisciLogo = () => setLogo({file: null, preview: null})

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;
        setErrore();
        setOpenAlert(0)
    }

    return (
        <React.Fragment>
            <HeaderScheda
                titolo={"NUOVO PARTNER"}
                elementi={[true, true, false, false, false, false, false, false, false, false, false, false, false, false, true, true, true, true, true]}
                situazione={situazione}
                cambiaSituazione={v => setSituazione(v)}
                annulla={() => navigate(-1)}
                nome={titolo.italiano.length > 0 ? titolo.italiano : null}
                schedaCopiata={schedaCopiata}
                doIncollaSchedaCopiata={doIncollaSchedaCopiata}
                loading={loading}
                modifiche={true}
                conferma={formik.handleSubmit}
            ></HeaderScheda>

            {situazione == 1 && <>
                <SceltaLogo
                    logo={logo}
                    caricaLogo={caricaLogo}
                    pulisciLogo={pulisciLogo}
                ></SceltaLogo>
                <FormPartner
                    values={formik.values}
                    errors={formik.errors}
                    touched={formik.touched}
                    handleChange={formik.handleChange}
                    setFieldValue={formik.setFieldValue}
                    categoriePartner={categoriePartner}
                ></FormPartner>
            </>}
            {situazione == 2 &&
                <ContenutoScheda
                    lingua={lingua}
                    cambiaLingua={(v) => setLingua(v)}
                    titolo={titolo}
                    cambiaTitolo={cambiaTitolo}
                    contenuto={contenuto}
                    cambiaContenuto={cambiaContenuto}
                    copiaContenuti={copiaContenuti}
                    isMeta={true}
                    valoriMeta={valoriMeta}
                    cambiaValoriMeta={cambiaValoriMeta}
                ></ContenutoScheda>
            }
            {situazione == 4 &&
                <SceltaLocalita
                    listaSel={altro.localita}
                    localita={localita}
                    cambiaLocalita={cambiaLocalita}
                ></SceltaLocalita>
            }
            {situazione == 15 &&
                <SceltaEsperienze
                    nome={"ATTRAZIONI"}
                    elementi={attrazioni}
                    selezionati={altro.attrazioni}
                    cambiaSelezione={cambiaAttrazione}
                    macroCategorie={macroCategorie.filter((el) => el.id != 7 && el.id != 8 && el.id != 9)}
                    categorieEsperienza={categorieEsperienza}
                    isSceltaMacro={true}
                ></SceltaEsperienze>
            }
            {situazione == 16 &&
                <SceltaEsperienze
                    nome={"ITINERARI"}
                    elementi={itinerari}
                    selezionati={altro.itinerari}
                    cambiaSelezione={cambiaItinerario}
                    macroCategorie={macroCategorie}
                    categorieEsperienza={categorieEsperienza}
                    isSceltaMacro={false}
                    idMacroFissa={7} //Itinerari e Tour
                ></SceltaEsperienze>
            }
            {situazione == 17 &&
                <SceltaEsperienze
                    nome={"GUIDE TURISTICHE"}
                    elementi={guide}
                    selezionati={altro.guide}
                    cambiaSelezione={cambiaGuida}
                    macroCategorie={macroCategorie}
                    categorieEsperienza={categorieEsperienza}
                    isSceltaMacro={false}
                    idMacroFissa={9} //Guide Turistiche
                ></SceltaEsperienze>
            }
            {situazione == 18 &&
                <SceltaEsperienze
                    nome={"EVENTI"}
                    elementi={eventi}
                    selezionati={altro.eventi}
                    cambiaSelezione={cambiaEvento}
                    macroCategorie={macroCategorie}
                    categorieEsperienza={categorieEsperienza}
                    isSceltaMacro={false}
                    idMacroFissa={8} //Eventi e festività
                ></SceltaEsperienze>
            }
            {situazione == 19 &&
                <SceltaNews
                    nome={"NEWS"}
                    elementi={news}
                    selezionati={altro.news}
                    cambiaSelezione={cambiaNews}
                ></SceltaNews>
            }
            <BottoniScheda
                loading={loading}
                conferma={formik.handleSubmit}
                annulla={() => navigate(-1)}
            ></BottoniScheda>

            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 4} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Contenuti copiati nelle diverse linue.</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 5} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Scheda Incollata.</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default CreaPartners;