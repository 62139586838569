import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import BottoniScheda from '../../components/scheda/BottoniScheda';
import ModalEliminaCms from '../../components/cms/ModalEliminaCms';
import useCms from '../../controllers/cms/useCms';
import cmsApi from '../../api/cms';

function GestisciPagina(props) {

    const {state, pathname} = useLocation();
    const navigate = useNavigate();
    const {creaPagina, modificaPagina, togliPagina} = useCms();

    const [nome, setNome] = useState('')
    const [link, setLink] = useState('')
    const [id, setId] = useState(0)
    const [altro, setAltro] = useState({loading:false, errore:null, visible:false})

    useEffect(() => aggStato(), [state])

    const aggStato = () => {
        if(!state) return navigate('..', {replace:true})
        if(state.pagina) {
            setNome(state.pagina.nome)
            setId(state.pagina.id)
            setLink(state.pagina.link)
        } else {
            setNome('')
            setId(0)
            setLink('')
        }
    }

    const conferma = async() => {
        if(altro.loading) return;
        if(!nome.length) return setAltro({...altro, errore: "Il nome deve essere compilato"})
        setAltro({...altro, loading:true, errore:null})
        let results;
        if(!id) results = await cmsApi.creaPagina(nome, link)
        else results = await cmsApi.modificaPagina(id, nome, link);
        if(!results.ok) return setAltro({...altro, loading:false, errore:results.data})
        setTimeout(() => {
            setAltro({...altro, loading:false, errore:null})
            //agg context
            if(id) modificaPagina(results.data)
            else creaPagina(results.data)
            //navigo indietro
            if(id) navigate('..', {replace:true, state:{paginaModificata: results.data}})
            else navigate('..', {replace:true, state:{paginaCreata: results.data}})
        }, 500);
    }

    const chiediEliminaPagina = () => setAltro({...altro, visible:true})

    const eliminaPagina = async() => {
        if(altro.loading) return;
        setAltro({...altro, loading:true, errore:null})
        const results = await cmsApi.eliminaPagina(id);
        if(!results.ok) return setAltro({...altro, loading:false, errore:results.data})
        setTimeout(() => {
            setAltro({...altro, loading:false, errore:null, visible:false})
            togliPagina(id)
            navigate('..', {replace:true, state:{paginaEliminata: id}})
        }, 500);
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setAltro({...altro, loading:false, errore:null})
    }

    if(!id) return (
        <React.Fragment>
            <div className={"admin-container-cms"}>
                <Box sx={{paddingBottom:2}}><h3 className="admin-titoletto">CMS: Crea Nuova Pagina</h3></Box>

                <h5 className="admin-titoletto">Nome</h5>
                <Stack direction="row" spacing={15} paddingTop={1} paddingBottom={2} width={"40%"}>
                    <TextField 
                        id="outlined-basic"
                        placeholder="Nome della Pagina"
                        variant="outlined"
                        name="pagina"
                        onChange={(event) => setNome(event.target.value)}
                        value={nome}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>

                <h5 className="admin-titoletto">Link</h5>
                <Stack direction="row" spacing={15} paddingTop={1} paddingBottom={1} width={"40%"}>
                    <TextField 
                        id="outlined-basic"
                        placeholder="Link della Pagina"
                        variant="outlined"
                        name="link"
                        onChange={(event) => setLink(event.target.value)}
                        value={link}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
            </div>
            <BottoniScheda
                loading={altro.loading}
                conferma={async() => await conferma()}
                annulla={() => navigate('..', {replace:true})}
            ></BottoniScheda>

            <Snackbar open={altro.errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{altro.errore}</Alert>
            </Snackbar>
        </React.Fragment>
    );

    return(
        <React.Fragment>
            <div className={"admin-container-cms"}>
                <Box sx={{paddingBottom:2, display:'flex', flexDirection:'row'}}>
                    <h3 className="admin-titoletto">CMS: Modifica Pagina</h3>
                    <LoadingButton
                        loading={altro.loading}
                        loadingPosition="start"
                        size="small"
                        variant={"contained"}
                        color={"error"}
                        onClick={chiediEliminaPagina}
                        type="submit"
                        startIcon={<HighlightOffIcon />}
                        sx={{marginLeft:2}}
                    >ELIMINA PAGINA</LoadingButton>
                </Box>

                <h5 className="admin-titoletto">Nome</h5>
                <Stack direction="row" spacing={15} paddingTop={2} paddingBottom={2} width={"40%"}>
                    <TextField 
                        id="outlined-basic"
                        placeholder="Nome della Pagina"
                        variant="outlined"
                        name="pagina"
                        onChange={(event) => setNome(event.target.value)}
                        value={nome}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>

                <h5 className="admin-titoletto">Link</h5>
                <Stack direction="row" spacing={15} paddingTop={1} paddingBottom={1} width={"40%"}>
                    <TextField 
                        id="outlined-basic"
                        placeholder="Link della Pagina"
                        variant="outlined"
                        name="link"
                        onChange={(event) => setLink(event.target.value)}
                        value={link}
                        size={"small"}
                        fullWidth
                        autoComplete="off"
                    />
                </Stack>
            </div>
            <BottoniScheda
                loading={altro.loading}
                conferma={async() => await conferma()}
                annulla={() => navigate('..', {replace:true})}
            ></BottoniScheda>

            <ModalEliminaCms
                cosa={1}
                visible={altro.visible}
                loading={altro.loading}
                annulla={() => setAltro({...altro, visible:false})}
                conferma={eliminaPagina}
            ></ModalEliminaCms>

            <Snackbar open={altro.errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{altro.errore}</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default GestisciPagina;