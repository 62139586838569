import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import utils from '../../utils/utils'
import mediaApi from '../../api/media'
import getters from '../../api/getters'
import useMediaKit from '../../controllers/mediaKit/useMediaKit'
import BottoniMedia from '../../components/mediaKit/BottoniMedia'
import ElencoVideoMedia from '../../components/mediaKit/ElencoVideoMedia'
import ElencoImmaginiMedia from '../../components/mediaKit/ElencoImmaginiMedia'
import ElencoCategorieMedia from '../../components/mediaKit/ElencoCategorieMedia'
import ModalEliminaMedia from '../../components/mediaKit/ModalEliminaMedia';

function HomeMediaKit(props) {

    const navigate = useNavigate()
    const {state, pathname} = useLocation()

    const {
        categorie, immagini, video,
        togliCategoria, togliMedia,
        popolaCategorieMedia, popolaMedia
    } = useMediaKit()

    const [errore, setErrore] = useState(null)
    const [loading, setLoading] = useState(false)
    const [openAlert, setOpenAlert] = useState(0)
    const [elimina, setElimina] = useState({tipo:0, valore:null, visible:false})
    const [filtro, setFiltro] = useState({img:0, video:0})
    const [situazione, setSituazione] = useState(1) //1 categorie, 2 immagini, 3 video

    useEffect(() => {
        const cambioStato = () => {
            if(!state) return;
            //gestione categoria
            if(state.isNuovaCat) {
                setOpenAlert(1)
                navigate(pathname, {replace:true})
            }
            if(state.isCatModificata) {
                setOpenAlert(2)
                navigate(pathname, {replace:true})
            }
            //gestione immagini
            if(state.isImgSel) {
                setSituazione(2)
                setFiltro({...filtro, img:state?.filtro})
                navigate(pathname, {replace:true})
            }
            if(state.isNuovaImg) {
                setSituazione(2)
                setOpenAlert(3)
                setFiltro({...filtro, img:state?.filtro})
                navigate(pathname, {replace:true})
            }
            if(state.isModificaImg) {
                setSituazione(2)
                setOpenAlert(4)
                setFiltro({...filtro, img:state?.filtro})
                navigate(pathname, {replace:true})
            }
            //gestione video
            if(state.isVideoSel) {
                setSituazione(3)
                setFiltro({...filtro, video:state?.filtro})
                navigate(pathname, {replace:true})
            }
            if(state.isNuovoVideo) {
                setSituazione(3)
                setOpenAlert(5)
                setFiltro({...filtro, video:state?.filtro})
                navigate(pathname, {replace:true})
            }
            if(state.isModificaVideo) {
                setSituazione(3)
                setOpenAlert(6)
                setFiltro({...filtro, video:state?.filtro})
                navigate(pathname, {replace:true})
            }
        }
        cambioStato()
    }, [state])

    const premiCategoria = (cat, filtro) => navigate('/media-kit/categoria', {state:{categoria: {...cat}, filtro:filtro}});
    const premiImmagine = (img, filtro) => navigate('/media-kit/immagine', {state:{immagine: {...img}, filtro:filtro}});
    const premiVideo = (video) => navigate('/media-kit/video', {state:{video: {...video}}});

    const confermaElimina = async() => {
        let results = null
        const id = elimina.valore.id
        setLoading(true)
        if(elimina.tipo == 1) { //categoria
            results = await mediaApi.eliminaCategoriaMedia(id)
            if(!results.ok) {
                setLoading(false)
                setErrore(results.data)
            }
            setTimeout(() => {
                togliCategoria(id)
                setLoading(false)
                setErrore(null)
                setOpenAlert(7)
                setElimina({tipo:0, valore:null, visible:false})
            }, 600);
        } else if(elimina.tipo == 2 || elimina.tipo == 3) { //immagine e video
            results = await mediaApi.eliminaMedia(id)
            if(!results.ok) {
                setLoading(false)
                setErrore(results.data)
            }
            setTimeout(() => {
                togliMedia(id)
                setLoading(false)
                setErrore(null)
                setOpenAlert(elimina.tipo == 2 ? 8 : 9)
                setElimina({tipo:0, valore:null, visible:false})
            }, 600);
        }
    }

    const onDragEndCategorie = async(result) => {
        if(!result.destination) return;
        let listCopy = Array.from(categorie);
        //rimuovo elemento dalla lista di partenza
        const [removedElement, newSourceList] = utils.removeFromList(
            listCopy,
            result.source.index
        );
        listCopy = newSourceList;
        //aggiungo elemento alla  di destinazione
        listCopy = utils.addToList(
            listCopy,
            result.destination.index,
            removedElement
        );
        //aggiorno il tutto
        popolaCategorieMedia(listCopy)
        //aggiorno le api
        const vett = []
        for(let i = 0; i < listCopy.length; i++) vett.push(listCopy[i].id)
        const results = await mediaApi.importanzaCategorieMedia(vett)
        if(!results.ok) return setErrore(results.data)
    }

    const aggiornaImportanzaMedia = async(listaId) => {
        //aggiorno sul server
        const results = await mediaApi.importanzaMedia(listaId)
        if(!results.ok) return setErrore(results.data)
        setErrore(null)
        //devo aggiornarle anche in locale
        const response = await getters.getMediaKit()
        if(!response.ok) return setErrore(response.data)
        popolaMedia(response.data.media)
    }

    const chiudiAlert = (event, reason) => {
        if (reason === 'clickaway') return;   
        setOpenAlert(0);
    }

    return (
        <React.Fragment>
            <div className="admin-container">
                <h2 className="admin-titoletto">HOME MEDIA KIT</h2>

                <BottoniMedia
                    situazione={situazione}
                    cambiaSituazione={setSituazione}
                ></BottoniMedia>
            </div>

            {situazione == 1 &&
                <ElencoCategorieMedia
                    categorie={categorie}
                    premiCategoria={premiCategoria}
                    premiNuova={() => navigate('/media-kit/categoria')}
                    premiElimina={(tipo, valore) => setElimina({tipo, valore, visible:true})}
                    onDragEnd={onDragEndCategorie}
                ></ElencoCategorieMedia>
            }

            {situazione == 2 &&
                <ElencoImmaginiMedia
                    immagini={immagini}
                    categorie={categorie}
                    premiImmagine={premiImmagine}
                    premiNuova={(filtro) => navigate('/media-kit/immagine', {state:{filtro}})}
                    premiElimina={(tipo, valore) => setElimina({tipo, valore, visible:true})}
                    defaultFiltro={filtro.img}
                    aggiornaImportanzaImmagini={aggiornaImportanzaMedia}
                ></ElencoImmaginiMedia>
            }

            {situazione == 3 &&
                <ElencoVideoMedia
                    video={video}
                    categorie={categorie}
                    premiVideo={premiVideo}
                    premiNuovo={(filtro) => navigate('/media-kit/video', {state:{filtro}})}
                    premiElimina={(tipo, valore) => setElimina({tipo, valore, visible:true})}
                    defaultFiltro={filtro.video}
                    aggiornaImportanzaVideo={aggiornaImportanzaMedia}
                ></ElencoVideoMedia>
            }

            <ModalEliminaMedia
                visible={elimina.visible}
                tipo={elimina.tipo}
                elemento={elimina.valore}
                loading={loading}
                conferma={confermaElimina}
                annulla={() => setElimina({tipo:0, valore:null, visible:false})}
            ></ModalEliminaMedia>

            <Snackbar open={openAlert == 1} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Categoria inserita con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 2} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Categoria modificata con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 3} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Immagine inserita con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 4} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Immagine modificata con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 5} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Video inserito con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 6} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Video modificato con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 7} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Categoria eliminata con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 8} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Immagine eliminata con successo!</Alert>
            </Snackbar>
            <Snackbar open={openAlert == 9} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="success" sx={{ width: '100%' }}>Video eliminato con successo!</Alert>
            </Snackbar>
            <Snackbar open={errore?.length > 0} onClose={chiudiAlert} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "right" }} style={{top: 70}} >
                <Alert onClose={chiudiAlert} variant="filled" severity="error" sx={{ width: '100%' }}>{errore}</Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default HomeMediaKit;