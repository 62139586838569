import React, { useEffect, useState, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

function RicercaCms({elementi, pagina, cliccaElemento}) {

    const [ricerca, setRicerca] = useState('')
    const [lista, setLista] = useState([])

    useEffect(() => aggLista(), [pagina, elementi])

    const aggLista = () => {
        if(!pagina) return setLista(elementi);
        const appo = elementi.filter((el) => el.pagina == pagina.id)
        setLista(appo)
    }

    const getListaFiltrata = useCallback(() => {
        if(!ricerca || ricerca.length == 0) return lista;
        return lista.filter((e) => {
            var appo = ricerca.toLowerCase();
            var appo2 = e.nome.toLowerCase().replaceAll('<br>', '').replaceAll('  ', ' ');
            return appo2.includes(appo);
        });
      }, [ricerca]);

    return (
        <Box sx={{width:'94%', marginRight:'2%', backgroundColor:'white', borderRadius:'10px', border:'1px solid #dcdcdc', p:2, marginTop:'20px'}}>
            <Typography sx={{fontSize:20, fontWeight:'bold'}}>Ricerca Sezioni</Typography>
            {pagina && <Typography sx={{fontSize:17, fontWeight:'400'}}>Stai cercando nella pagina <b>{pagina.nome}</b></Typography>}
            <Stack direction="row" paddingTop={1} paddingBottom={2}>
                <TextField 
                    id="outlined-basic"
                    placeholder="Ricerca"
                    variant="outlined"
                    name="ricerca"
                    onChange={(event) => setRicerca(event.target.value)}
                    value={ricerca}
                    size={"small"}
                    fullWidth
                    autoComplete="off"
                />
            </Stack>

            {!(ricerca.length > 2) && <Typography sx={{fontSize:17, fontWeight:'400'}}>Digita almeno 3 lettere per iniziare la ricerca.</Typography>}
            {ricerca.length > 2 && 
                <Box>
                    {getListaFiltrata().map((sezione, index) => (
                        <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', border:'1px solid #dcdcdc', borderRadius:10, p:1, marginBottom:'15px'}} key={index}>
                            <IconButton
                                size="medium"
                                onClick={() => {
                                    setTimeout(() => {
                                        cliccaElemento(sezione)
                                        setRicerca('')
                                    }, 300);
                                }}
                                color="inherit"
                            >
                                <EditIcon />
                            </IconButton>
                            <span className='admin-titolo'>{sezione.nome.replaceAll('<br>', '')}</span>
                        </Box>
                    ))}
                </Box>
            }
        </Box>
    );
}

export default RicercaCms;