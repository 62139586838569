import client from "./client";
import config from '../config.json';

const endpoint = 'admin/media-kit/'

//CATEGORIE
const creaCategoriaMedia = (data) => {
    return client.post(config.serverUrl + endpoint + "categoria", data);
}
const modificaCategoriaMedia = (data) => {
    return client.put(config.serverUrl + endpoint + "categoria", data);
}
const eliminaCategoriaMedia = (id) => {
    return client.delete(config.serverUrl + endpoint + "categoria/" + id);
}
const importanzaCategorieMedia = (data) => {
    return client.put(config.serverUrl + endpoint + "categoria/importanza", data);
}


//MEDIA
const creaMediaImmagine = (data) => {
    return client.post(config.serverUrl + endpoint + "immagine", data);
}
const creaMediaVideo = (data) => {
    return client.post(config.serverUrl + endpoint + "video", data);
}
const modificaMedia = (data) => {
    return client.put(config.serverUrl + endpoint + "media", data);
}
const eliminaMedia = (id) => {
    return client.delete(config.serverUrl + endpoint + "media/" + id);
}
const importanzaMedia = (data) => {
    return client.put(config.serverUrl + endpoint + "media/importanza", data);
}

export default {
    //categorie
    creaCategoriaMedia,
    modificaCategoriaMedia,
    eliminaCategoriaMedia,
    importanzaCategorieMedia,

    //media
    creaMediaImmagine,
    creaMediaVideo,
    modificaMedia,
    eliminaMedia,
    importanzaMedia
}