import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Stack } from '@mui/material';

function SceltaMacro({lista, catSelezionata, cambiaCatSelezionata, isModifica}) {
    return (
        <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
            <Stack direction="row" spacing={5} alignItems="center">
                <h3 className="admin-titoletto">MACRO CATEGORIA</h3>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                    <InputLabel>Macro</InputLabel>
                    <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        name="categoria"
                        value={catSelezionata}
                        onChange={cambiaCatSelezionata}
                        autoWidth
                        size="small"
                        label="Categoria"
                    >
                        <MenuItem value={0}><em>Nessuna</em></MenuItem>
                        {lista.map((cat, index) => (
                            <MenuItem value={cat.id} key={index}>{cat.nome_italiano}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
        </div>
    );
}

export default SceltaMacro;