import { useContext } from "react";

import GeneraleContext from "./context";

const useGenerale = () => {
    const { generale, setGenerale } = useContext(GeneraleContext);

    const popolaAllGenerale = (obj) => setGenerale({...obj});

    const popolaBanner = (vett) => {
        const appo = {...generale};
        appo.banner = vett;
        setGenerale(appo);
    }

    const importanzaMaxBanner = () => {
        let appo = generale.banner;
        let valore = appo[appo.length - 1]?.importanza;
        return valore ? (valore + 1) : 1
    }

    const addBanner = (b) => {
        const appo = {...generale};
        appo.banner.push(b);
        setGenerale(appo);
    }

    const aggBanner = (ban) => {
        const appo = {...generale};
        for(let i = 0; i < appo.banner.length; i++) {
            if(appo.banner[i].id == ban.id) appo.banner[i] = ban;
        }
        setGenerale(appo);
    }

    const togliBanner = (id) => {
        const appo = {...generale};
        appo.banner = generale.banner.filter((l) => l.id != id);
        setGenerale(appo);
    }

    const popolaTematiche = (vett) => {
        const appo = {...generale};
        appo.tematiche = vett;
        setGenerale(appo);
    }

    const importanzaMaxTematiche = () => {
        let appo = generale.tematiche;
        let valore = appo[appo.length - 1]?.importanza;
        return valore ? (valore + 1) : 1
    }

    const addTematica = (t) => {
        const appo = {...generale};
        appo.tematiche.push(t);
        setGenerale(appo);
    }

    const aggTematica = (ban) => {
        const appo = {...generale};
        for(let i = 0; i < appo.tematiche.length; i++) {
            if(appo.tematiche[i].id == ban.id) appo.tematiche[i] = ban;
        }
        setGenerale(appo);
    }

    const togliTematica = (id) => {
        const appo = {...generale};
        appo.tematiche = generale.tematiche.filter((l) => l.id != id);
        setGenerale(appo);
    }

    return {
        popolaAllGenerale,

        //banner
        banner: generale.banner,
        popolaBanner,
        importanzaMaxBanner,
        addBanner,
        aggBanner,
        togliBanner,

        //vacanze tematiche
        tematiche: generale.tematiche,
        popolaTematiche,
        importanzaMaxTematiche,
        addTematica,
        aggTematica,
        togliTematica
        
    }
}

export default useGenerale;