import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

function FormPassword({handleChange, values, errors, touched}) {

    return (
        <Stack direction="column" spacing={2} paddingTop={3} sx={{width:'60%'}}>
            <TextField 
                id="outlined-basic"
                label="Vecchia Password"
                variant="outlined"
                name="vecchia"
                onChange={handleChange}
                value={values.vecchia}
                error={touched.vecchia && Boolean(errors.vecchia)}
                helperText={touched.vecchia && errors.vecchia}
                size={"small"}
                fullWidth
                autoComplete="off"
                type={"password"}
            />
            <TextField 
                id="outlined-basic"
                label="Nuova Password"
                variant="outlined"
                name="nuova"
                onChange={handleChange}
                value={values.nuova}
                error={touched.nuova && Boolean(errors.nuova)}
                helperText={touched.nuova && errors.nuova}
                size={"small"}
                fullWidth
                autoComplete="off"
                type={"password"}
            />
            <TextField 
                id="outlined-basic"
                label="Ripeti nuova Password"
                variant="outlined"
                name="ripeti"
                onChange={handleChange}
                value={values.ripeti}
                error={touched.ripeti && Boolean(errors.ripeti)}
                helperText={touched.ripeti && errors.ripeti}
                size={"small"}
                fullWidth
                autoComplete="off"
                type={"password"}
            />
        </Stack>
    );
}

export default FormPassword;