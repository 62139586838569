import * as Yup from 'yup';

const itinerario = Yup.object().shape({
    attivo: Yup.bool(),
    evidenza: Yup.bool(),
    indirizzo: Yup.string(),
    cap: Yup.string(),
    latitudine: Yup.number().typeError("Campo non corretto."),
    longitudine: Yup.number().typeError("Campo non corretto."),
    telefono: Yup.string(),
    wathsapp: Yup.string(),
    email: Yup.string(),
    video: Yup.string(),
    sito: Yup.string(),
    facebook: Yup.string(),
    instagram: Yup.string(),
    youtube: Yup.string(),
    twitter: Yup.string(),
    is_ecommerce: Yup.bool(),
    ecommerce_italiano: Yup.string(),
    ecommerce_inglese: Yup.string(),
    ecommerce_tedesco: Yup.string(),
    ecommerce_olandese: Yup.string(),
    ecommerce_danese: Yup.string()
})

const attrazione = Yup.object().shape({
    attiva: Yup.bool(),
    data_apertura: Yup.date().nullable(),
    data_chiusura: Yup.date().nullable(),
    sito: Yup.string(),
    indirizzo: Yup.string(),
    cap: Yup.string(),
    latitudine: Yup.number().typeError("Campo non corretto."),
    longitudine: Yup.number().typeError("Campo non corretto."),
    telefono: Yup.string(),
    wathsapp: Yup.string(),
    email: Yup.string(),
    video: Yup.string(),
    facebook: Yup.string(),
    instagram: Yup.string(),
    youtube: Yup.string(),
    twitter: Yup.string(),
    is_ecommerce: Yup.bool(),
    ecommerce_italiano: Yup.string(),
    ecommerce_inglese: Yup.string(),
    ecommerce_tedesco: Yup.string(),
    ecommerce_olandese: Yup.string(),
    ecommerce_danese: Yup.string()
})

const evento = Yup.object().shape({
    attivo: Yup.bool(),
    evidenza: Yup.bool(),
    is_ripetizione: Yup.bool(),
    giorno_ripetizione: Yup.number(),
    indirizzo: Yup.string(),
    cap: Yup.string(),
    latitudine: Yup.number().typeError("Campo non corretto."),
    longitudine: Yup.number().typeError("Campo non corretto."),
    telefono: Yup.string(),
    wathsapp: Yup.string(),
    email: Yup.string(),
    video: Yup.string(),
    facebook: Yup.string(),
    instagram: Yup.string(),
    youtube: Yup.string(),
    twitter: Yup.string(),
    is_ecommerce: Yup.bool(),
    ecommerce_italiano: Yup.string(),
    ecommerce_inglese: Yup.string(),
    ecommerce_tedesco: Yup.string(),
    ecommerce_olandese: Yup.string(),
    ecommerce_danese: Yup.string(),
    data_visibile_inizio: Yup.date().nullable(),
    data_visibile_fine: Yup.date().nullable()
})

const guida = Yup.object().shape({
    attiva: Yup.bool(),
    latitudine: Yup.number().typeError("Campo non corretto."),
    longitudine: Yup.number().typeError("Campo non corretto."),
    is_ecommerce: Yup.bool(),
    ecommerce_italiano: Yup.string(),
    ecommerce_inglese: Yup.string(),
    ecommerce_tedesco: Yup.string(),
    ecommerce_olandese: Yup.string(),
    ecommerce_danese: Yup.string()
})

const news = Yup.object().shape({
    attivo: Yup.bool(),
    evidenza: Yup.bool(),
    tipologia: Yup.number(),
    indirizzo: Yup.string(),
    cap: Yup.string(),
    latitudine: Yup.number().typeError("Campo non corretto."),
    longitudine: Yup.number().typeError("Campo non corretto."),
    telefono: Yup.string(),
    wathsapp: Yup.string(),
    email: Yup.string(),
    video: Yup.string(),
    facebook: Yup.string(),
    instagram: Yup.string(),
    youtube: Yup.string(),
    twitter: Yup.string(),
    data_inizio: Yup.date().nullable(),
    data_fine: Yup.date().nullable(),
    data_visibile_inizio: Yup.date().nullable(),
    data_visibile_fine: Yup.date().nullable(),
})

const localita = Yup.object().shape({
    attiva: Yup.bool(),
    video: Yup.string(),
    latitudine: Yup.number().typeError("Campo non corretto."),
    longitudine: Yup.number().typeError("Campo non corretto.")
})

const servizio = Yup.object().shape({
    categoria: Yup.number().nullable(),
})

const banner = Yup.object().shape({
    attivo: Yup.bool(),
    data_inizio: Yup.date().nullable(),
    data_fine: Yup.date().nullable(),
    link: Yup.string()
})

const partner = Yup.object().shape({
    attivo: Yup.bool(),
    categoria: Yup.number(),
    sito: Yup.string(),
    indirizzo: Yup.string(),
    cap: Yup.string(),
    latitudine: Yup.number().typeError("Campo non corretto."),
    longitudine: Yup.number().typeError("Campo non corretto."),
    telefono: Yup.string(),
    wathsapp: Yup.string(),
    email: Yup.string(),
    video: Yup.string(),
    facebook: Yup.string(),
    instagram: Yup.string(),
    youtube: Yup.string(),
    twitter: Yup.string()
})

const struttura = Yup.object().shape({
    nome_completo: Yup.string(),
    id_booking: Yup.number().typeError("Campo non corretto."),
    id_trustyou: Yup.number().typeError("Campo non corretto."),
    stelle: Yup.number().typeError("Campo non corretto."),
    attiva: Yup.bool(),
    sito: Yup.string(),
    indirizzo: Yup.string(),
    cap: Yup.string(),
    latitudine: Yup.number().typeError("Campo non corretto."),
    longitudine: Yup.number().typeError("Campo non corretto."),
    data_apertura: Yup.date().nullable(),
    data_chiusura: Yup.date().nullable(),
    telefono: Yup.string(),
    wathsapp: Yup.string(),
    email: Yup.string(),
    video: Yup.string(),
    facebook: Yup.string(),
    instagram: Yup.string(),
    youtube: Yup.string(),
    twitter: Yup.string(),
    tiktok: Yup.string(),
    sito_italiano: Yup.string(),
    sito_inglese: Yup.string(),
    sito_tedesco: Yup.string(),
    sito_olandese: Yup.string(),
    sito_danese: Yup.string()
})

const offerta = Yup.object().shape({
    id_booking: Yup.number().typeError("Campo non corretto."),
    link_booking: Yup.string(),
    attiva: Yup.bool(),
    evidenza: Yup.bool(),
    categoria: Yup.number().typeError("Campo non corretto."),
    label: Yup.number().typeError("Campo non corretto."),
    prezzo: Yup.number().typeError("Campo non corretto."),
    persone_min: Yup.number().typeError("Campo non corretto."),
    persone_max: Yup.number().typeError("Campo non corretto."),
    num_notti: Yup.number().typeError("Campo non corretto."),
    num_notti_min: Yup.number().typeError("Campo non corretto."),
    indirizzo: Yup.string(),
    cap: Yup.string(),
    latitudine: Yup.number().typeError("Campo non corretto."),
    longitudine: Yup.number().typeError("Campo non corretto."),
    telefono: Yup.string(),
    wathsapp: Yup.string(),
    email: Yup.string(),
    sito: Yup.string()
})

const tematica = Yup.object().shape({
    attiva: Yup.bool(),
    video: Yup.string()
})

const cambioPassword = Yup.object().shape({
    vecchia: Yup.string().required(),
    nuova: Yup.string().required(),
    ripeti: Yup.string().required()
})

const admin = Yup.object().shape({
    username: Yup.string().required(),
    pwd: Yup.string().required(),
    ripeti: Yup.string().required(),
    is_admin: Yup.boolean().required()
})

export default {
    itinerario,
    attrazione,
    localita,
    evento,
    servizio,
    banner,
    guida,
    partner,
    struttura,
    offerta,
    tematica,
    news,
    cambioPassword,
    admin
}