import React from 'react';
import { DragDropContext, Droppable, Draggable  } from 'react-beautiful-dnd';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import './style.css'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function ElencoCategorieMedia({categorie, premiCategoria, premiNuova, premiElimina, onDragEnd}) {

    return (
        <div className="admin-container">
            <h3 className="admin-titoletto">CATEGORIE DEL MEDIA KIT</h3>
            <Stack direction="row" spacing={2} marginTop={1}>
                <Button
                    size="small"
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => premiNuova()}
                    startIcon={<AddCircleOutlineIcon />}
                >NUOVA CATEGORIA</Button>
            </Stack>

            <Box marginTop={4}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={"drop-categorie-media"} direction="vertical">
                {(provided) => (
                    <List component="div" aria-label="main mailbox folders" {...provided.droppableProps} ref={provided.innerRef}>
                        {categorie.length == 0 && <h3 className="admin-titoletto">Nessun elemento presente</h3>}
                        {categorie.map((cat, index) => (
                            <Draggable key={cat.id} draggableId={cat.id.toString()} index={index}>
                            {(provided) => (
                                <Box
                                    key={index}
                                    sx={{paddingLeft:0, paddingRight:0}}
                                    ref={provided?.innerRef} {...provided?.draggableProps} {...provided?.dragHandleProps}
                                >
                                    <div className="elemento-laterale">
                                        <div className="riga-elemento-laterale-media">
                                            <span className="nome-elemento-laterale">{cat.nome_italiano}</span>
                                            <Stack direction="row" spacing={2}>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => premiElimina(1, cat)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => premiCategoria(cat)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </Stack>
                                        </div>
                                    </div>
                                </Box>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </List>
                )}
                </Droppable>
            </DragDropContext>
            </Box>
        </div>
    );
}

export default ElencoCategorieMedia;