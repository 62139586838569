import { useContext } from "react";

import NewsContext from "./context";

const useNews = () => {
    const { news, setNews } = useContext(NewsContext);

    const popolaNews = (vett) => {
        const appo = {...news};
        appo.news = vett;
        setNews(appo);
    }

    const importanzaMaxNews = () => {
        let appo = news.news;
        let valore = appo[appo.length - 1]?.importanza;
        return valore ? (valore + 1) : 1
    }

    const addNews = (n) => {
        const appo = {...news};
        appo.news.push(n);
        setNews(appo);
    }

    const aggNews = (n) => {
        const appo = {...news};
        for(let i = 0; i < appo.news.length; i++) {
            if(appo.news[i].id == n.id) appo.news[i] = n;
        }
        setNews(appo);
    }

    const togliNews = (id) => {
        const appo = {...news};
        appo.news = news.news.filter((l) => l.id != id);
        setNews(appo);
    }

    return { 
        news: news.news,
        tipologieNews: news.tipologie,
        popolaNews,
        importanzaMaxNews,
        addNews,
        aggNews,
        togliNews
    }
}

export default useNews;