import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';

function SceltaStagionalita({meteo, stagioni, stagioniSel, meteoSel, cambiaStagione, cambiaMeteo, isModifica, soloStagioni}) {

    return (
        <React.Fragment>
            <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                <h3 className="admin-titoletto">STAGIONI</h3>
                <Stack direction="row" spacing={10} paddingTop={1}>
                    {stagioni.map((stagione, index) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="medium"
                                    color="primary" 
                                    name={stagione.nome_italiano}
                                    checked={stagioniSel[index]}
                                    onChange={() => cambiaStagione(index)}
                                />} 
                            label={stagione.nome_italiano}
                            key={index}
                        />
                    ))}
                </Stack>
            </div>
            {!soloStagioni && 
                <div className={isModifica ? "admin-container-lungo" : "admin-container"}>
                    <h3 className="admin-titoletto">METEO</h3>
                    <Stack direction="row" spacing={10} paddingTop={1}>
                        {meteo.map((met, index) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="medium"
                                        color="primary" 
                                        name={met.nome_italiano}
                                        checked={meteoSel[index]}
                                        onChange={() => cambiaMeteo(index)}
                                    />} 
                                label={met.nome_italiano}
                                key={index}
                            />
                        ))}
                    </Stack>
                </div>
            }
        </React.Fragment>
    );
}

export default SceltaStagionalita;